import {
  negativeBoolsObjToNegativesArray,
  NegativeMatchBools,
  NegativeMatchType,
} from '@/modules/negative-targets/api/negative-targets-contracts';
import { CampaignAdType, TargetingType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { KeywordHarvestingPreviewDataRow } from '@/modules/search-terms/components/keyword-harvesting/models/KeywordHarvestingPreviewDataRow';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { DTO } from '@/types/dto-wrapper';
import { isNil, isNumber } from 'lodash-es';
import {
  BiddingMethod,
  CampaignDataWithCampaignMappingAdGroupDataDTO,
  CampaignMappingAdGroupDataDTO,
  CampaignMappingDTO,
  CampaignMappingItemDTO,
  CampaignMappingResponseDTO,
  DeleteCampaignMappingItemDTO,
  UpsertCampaignMappingInnerDTO,
  UpsertCampaignMappingItemDTO,
} from '../api/campaign-mapping-contracts';
import { CampaignMappingInnerModel } from './CampaignMappingInnerModel';
import { CampaignMappingModelWithValidation } from './CampaignMappingModelWithValidation';

export class CampaignMappingModel extends DTO<CampaignMappingDTO> {
  private _inner: CampaignMappingInnerModel[] = [];

  // Need a separate array to store detail grid selection because detail grid doesn't exist when collapsed
  private _selectedMatchTypes: MatchType[] = [];
  constructor(dto: CampaignMappingDTO) {
    super(dto);

    this._inner = dto.mi.map((innerDto) => new CampaignMappingInnerModel(innerDto, dto));
  }

  public get id(): string | null {
    return this.dto.sai + this.dto.dai;
  }

  public get matchTypes(): MatchType[] {
    return this._inner.map((inner) => inner.matchType);
  }

  public get selectedMatchTypes(): MatchType[] {
    return this._selectedMatchTypes;
  }

  public set selectedMatchTypes(value: MatchType[]) {
    this._selectedMatchTypes = value;
  }

  public get inner(): CampaignMappingInnerModel[] {
    return this._inner;
  }
  public get sourceCampaignId(): string {
    return this.dto.sci;
  }

  // TODO: all setters can be removed?
  public set sourceCampaignId(value: string) {
    this.dto.sci = value;
  }

  public get sourceCampaignName(): string {
    return this.dto.scn;
  }

  public set sourceCampaignName(value: string) {
    this.dto.scn = value;
  }

  public get sourceCampaignAdType(): CampaignAdType {
    return this.dto.sct;
  }

  public set sourceCampaignAdType(value: CampaignAdType) {
    this.dto.sct = value;
  }

  public get destinationCampaignId(): string {
    return this.dto.dci;
  }

  public set destinationCampaignId(value: string) {
    this.dto.dci = value;
  }

  public get destinationCampaignName(): string {
    return this.dto.dcn;
  }

  public set destinationCampaignName(value: string) {
    this.dto.dcn = value;
  }

  public get sourceAdGroupId(): string {
    return this.dto.sai;
  }

  public set sourceAdGroupId(value: string) {
    this.dto.sai = value;
  }

  public get sourceAdGroupName(): string {
    return this.dto.sagn;
  }

  public set sourceAdGroupName(value: string) {
    this.dto.sagn = value;
  }

  public get sourceAdGroupEntityType(): TargetEntityType {
    return this.dto.set == TargetEntityType.UNSET ? TargetEntityType.KEYWORD : this.dto.set;
  }

  public set sourceAdGroupEntityType(value: TargetEntityType) {
    this.dto.set = value;
  }

  public get destinationAdGroupId(): string {
    return this.dto.dai;
  }

  public set destinationAdGroupId(value: string) {
    this.dto.dai = value;
  }

  public get destinationAdGroupName(): string {
    return this.dto.dagn;
  }

  public set destinationAdGroupName(value: string) {
    this.dto.dagn = value;
  }

  public get destinationCampaignAdType(): CampaignAdType {
    return this.dto.dct;
  }

  public set destinationCampaignAdType(value: CampaignAdType) {
    this.dto.dct = value;
  }

  public get destinationAdGroupEntityType(): TargetEntityType {
    return this.dto.det == TargetEntityType.UNSET ? TargetEntityType.KEYWORD : this.dto.det;
  }

  public set destinationAdGroupEntityType(value: TargetEntityType) {
    this.dto.det = value;
  }

  // NEG CAMPAIGN //
  public get campaignNegativeExact(): boolean {
    return this.dto.cne;
  }

  public set campaignNegativeExact(value: boolean) {
    this.dto.cne = value;
  }

  public get campaignNegativePhrase(): boolean {
    return this.dto.cnp;
  }

  public set campaignNegativePhrase(value: boolean) {
    this.dto.cnp = value;
  }

  public get campaignNegativeProductTarget(): boolean {
    return this.dto.cnpt;
  }

  public set campaignNegativeProductTarget(value: boolean) {
    this.dto.cnpt = value;
  }

  // NEG AD GROUP //
  public get adGroupNegativeExact(): boolean {
    return this.dto.agne;
  }

  public set adGroupNegativeExact(value: boolean) {
    this.dto.agne = value;
  }

  public get adGroupNegativePhrase(): boolean {
    return this.dto.agnp;
  }

  public set adGroupNegativePhrase(value: boolean) {
    this.dto.agnp = value;
  }

  public get adGroupNegativeProductTarget(): boolean {
    return this.dto.agnpt;
  }

  public set adGroupNegativeProductTarget(value: boolean) {
    this.dto.agnpt = value;
  }

  public get createdAt(): string {
    return this.dto.cr;
  }

  public get updatedAt(): string {
    return this.dto.up;
  }

  public get negativeMatchTypes(): NegativeMatchType[] {
    return negativeBoolsObjToNegativesArray({
      cne: this.campaignNegativeExact,
      cnp: this.campaignNegativePhrase,
      cnpt: this.campaignNegativeProductTarget,
      agne: this.adGroupNegativeExact,
      agnp: this.adGroupNegativePhrase,
      agnpt: this.adGroupNegativeProductTarget,
    });
  }

  // TODO: Use this also in CampaignMappingModelWithValidation to reduce rule duplication
  public createNegativeAdGroupsWarning(campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType): string | null {
    const isSourceCampaignTypeManual = campaignToAdGroupsMap[this.sourceCampaignId ?? '']?.targetingType === TargetingType.MANUAL;

    if (isSourceCampaignTypeManual) {
      if (
        this.sourceAdGroupEntityType === TargetEntityType.KEYWORD &&
        this.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET
      ) {
        return 'Cannot have negative keywords in non-AUTO target ad group';
      }

      if (
        this.sourceAdGroupEntityType === TargetEntityType.PRODUCT_TARGET &&
        this.destinationAdGroupEntityType === TargetEntityType.KEYWORD
      ) {
        return 'Cannot have negative product targets in non-AUTO target ad group';
      }
    }

    // Can't do negative PT if match type is "expanded"
    if (
      this.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET &&
      this.matchTypes.length === 1 &&
      this.matchTypes[0] === MatchType.EXPANDED
    ) {
      return 'Cannot have negatives in expanded match type';
    }

    return null;
  }

  // TODO: Use this also in CampaignMappingModelWithValidation to reduce rule duplication
  public createNegativeCampaignWarning(campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType): string | null {
    const isSourceCampaignTypeManual = campaignToAdGroupsMap[this.sourceCampaignId ?? '']?.targetingType === TargetingType.MANUAL;

    // Rule 1
    const sourceCampaign = campaignToAdGroupsMap[this.sourceCampaignId ?? ''];
    if (isSourceCampaignTypeManual && sourceCampaign?.campaignAdType != CampaignAdType.PRODUCTS) {
      return `Only AUTO SP campaigns can have campaign-level negative targets`;
    }

    // Rule 2
    if (this.sourceCampaignAdType === CampaignAdType.BRANDS) {
      return 'Campaign Negative Product Target is not supported when source campaign is of type BRANDS';
    }

    // Rule 3 - same as in ad group
    if (isSourceCampaignTypeManual) {
      if (
        this.sourceAdGroupEntityType === TargetEntityType.KEYWORD &&
        this.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET
      ) {
        return 'Cannot have any negatives in non-AUTO target ad group when source is keyword and target product target';
      }
    }

    // Rule 4 - Can't do negative PT if match type is "expanded" (and it's the only one selected)
    if (
      this.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET &&
      this.matchTypes.length === 1 &&
      this.matchTypes[0] === MatchType.EXPANDED
    ) {
      return 'Cannot have negatives in expanded match type';
    }

    return null;
  }

  public static fromResponse(dto: CampaignMappingDTO): CampaignMappingModel {
    return new CampaignMappingModel(dto);
  }

  public static fromResponseArray(dtos: CampaignMappingDTO[]): CampaignMappingModel[] {
    if (dtos.length === 0) {
      return [];
    }
    return dtos.map(CampaignMappingModel.fromResponse);
  }

  public static fromResponseObject(dto: CampaignMappingResponseDTO): CampaignMappingModel[] {
    return CampaignMappingModel.fromResponseArray(dto.mappings);
  }

  public static fromCampaignMappingModelWithValidation(c: CampaignMappingModelWithValidation): CampaignMappingModel {
    CampaignMappingModel.validateConvertCampaignMappingWithValidationToCampaignMapping(c);

    const campaignMappingInnerDTOs: CampaignMappingItemDTO[] = [];
    const addCampaignMapping = (matchType: MatchType, condition: boolean) => {
      if (condition) {
        campaignMappingInnerDTOs.push({
          m: matchType,
          bdm: c.biddingMethod as BiddingMethod,
          bdmv: c.biddingMethodValue as number,
          bf: c.bidFloor as number,
          bc: c.bidCeiling as number,
          cr: '',
          up: '',
        });
      }
    };

    addCampaignMapping(MatchType.EXACT, c.exactMatch);
    addCampaignMapping(MatchType.BROAD, c.broadMatch);
    addCampaignMapping(MatchType.PHRASE, c.phraseMatch);
    addCampaignMapping(MatchType.INDIVIDUAL, c.individualProductTarget);
    addCampaignMapping(MatchType.EXPANDED, c.expandedProductTarget);

    return new CampaignMappingModel({
      sci: c.sourceCampaignId as string,
      scn: c.sourceCampaignName as string,
      dci: c.destinationCampaignId as string,
      dcn: c.destinationCampaignName as string,
      dcb: c.destinationCampaignBudgetAmount as number,
      sai: c.sourceAdGroupId as string,
      sagn: c.sourceAdGroupName as string,
      dai: c.destinationAdGroupId as string,
      dagn: c.destinationAdGroupName as string,
      dct: c.destinationCampaignAdType as CampaignAdType,
      det: c.destinationAdGroupEntityType as TargetEntityType,

      mi: campaignMappingInnerDTOs,

      cne: c.campaignNegativeExact,
      cnp: c.campaignNegativePhrase,
      cnpt: c.campaignNegativeProductTarget,
      agne: c.adGroupNegativeExact,
      agnp: c.adGroupNegativePhrase,
      agnpt: c.adGroupNegativeProductTarget,

      sct: c.sourceCampaignAdType as CampaignAdType,
      set: c.sourceAdGroupEntityType as TargetEntityType,
      cr: '',
      up: '',
    });
  }

  public static fromKeywordHarvestingPreviewDataRow(c: KeywordHarvestingPreviewDataRow): CampaignMappingModel {
    const innerDto: CampaignMappingItemDTO = {
      m: c.match,
      bdm: c.biddingMethod,
      bdmv: c.biddingMethodValue,
      bf: c.bidFloor,
      bc: c.bidCeiling,
      cr: null,
      up: null,
    };

    const dto: CampaignMappingDTO = {
      sci: c.dto.sci,
      scn: c.dto.scn,
      sct: c.dto.sct,
      sai: c.dto.sai,
      sagn: c.dto.sagn,
      set: c.dto.set,
      dci: c.dto.dci,
      dcn: c.dto.dcn,
      dcb: c.dto.dcb,
      dct: c.dto.dct,
      dai: c.dto.dai,
      dagn: c.dto.dagn,
      det: c.dto.det,
      cne: c.dto.cne,
      cnp: c.dto.cnp,
      cnpt: c.dto.cnpt,
      agne: c.dto.agne,
      agnp: c.dto.agnp,
      agnpt: c.dto.agnpt,
      cr: '',
      up: '',
      mi: [innerDto],
    };

    return new CampaignMappingModel(dto);
  }

  public static validateConvertCampaignMappingWithValidationToCampaignMapping(c: CampaignMappingModelWithValidation): void {
    if (isNil(c.sourceCampaignName)) {
      throw new Error('Invalid source campaign name');
    }

    if (isNil(c.destinationCampaignName)) {
      throw new Error('Invalid destination campaign name');
    }

    if (isNil(c.sourceAdGroupId)) {
      throw new Error('Invalid source ad group id');
    }

    if (isNil(c.sourceAdGroupName)) {
      throw new Error('Invalid source ad group name');
    }

    if (isNil(c.destinationAdGroupName)) {
      throw new Error('Invalid destination ad group name');
    }

    if (!isNumber(c.bidFloor) && c.bidFloor !== null) {
      throw new Error('Invalid bid floor');
    }

    if (isNumber(c.bidFloor) && c.bidFloor <= 0) {
      throw new Error('Invalid bid floor');
    }

    if (!isNumber(c.bidCeiling) && c.bidCeiling !== null) {
      throw new Error('Invalid bid ceiling');
    }

    if (isNumber(c.bidCeiling) && c.bidCeiling <= 0) {
      throw new Error('Invalid bid ceiling');
    }

    if (!isNumber(c.biddingMethodValue) && c.biddingMethodValue !== null) {
      throw new Error('Invalid bidding method value');
    }

    if (isNumber(c.biddingMethodValue) && c.biddingMethodValue <= 0) {
      throw new Error('Invalid bidding method value');
    }

    if (isNil(c.sourceCampaignId)) {
      throw new Error('Invalid source campaign id');
    }

    if (isNil(c.destinationCampaignId)) {
      throw new Error('Invalid destination campaign id');
    }

    if (isNil(c.destinationAdGroupId)) {
      throw new Error('Invalid destination ad group id');
    }

    if (isNil(c.sourceCampaignAdType) || !Object.values(CampaignAdType).includes(c.sourceCampaignAdType as CampaignAdType)) {
      throw new Error('Invalid source campaign ad type');
    }

    if (isNil(c.sourceAdGroupEntityType) || !Object.values(TargetEntityType).includes(c.sourceAdGroupEntityType as TargetEntityType)) {
      throw new Error('Invalid source ad group entity type');
    }

    if (isNil(c.destinationCampaignAdType) || !Object.values(CampaignAdType).includes(c.destinationCampaignAdType as CampaignAdType)) {
      throw new Error('Invalid destination campaign ad type');
    }

    if (
      isNil(c.destinationAdGroupEntityType) ||
      !Object.values(TargetEntityType).includes(c.destinationAdGroupEntityType as TargetEntityType)
    ) {
      throw new Error('Invalid destination ad group entity type');
    }

    if (isNil(c.biddingMethod) || !Object.values(BiddingMethod).includes(c.biddingMethod as BiddingMethod)) {
      throw new Error('Invalid bidding method');
    }
  }

  public static convertCampaignMappingWithValidationArrayToCampaignMappingArray(
    campaignMappingWithValidationArray: CampaignMappingModelWithValidation[],
  ): CampaignMappingModel[] {
    // group by s+d
    const sourceDestinationToCampaignMappingModelWithValidationArrayRecord: Record<string, CampaignMappingModelWithValidation[]> = {};
    campaignMappingWithValidationArray.forEach((c) => {
      const key = `${c.sourceAdGroupId}-${c.destinationAdGroupId}`;
      if (sourceDestinationToCampaignMappingModelWithValidationArrayRecord[key]) {
        sourceDestinationToCampaignMappingModelWithValidationArrayRecord[key].push(c);
      } else {
        sourceDestinationToCampaignMappingModelWithValidationArrayRecord[key] = [c];
      }
    });

    // group merge
    const campaignMappingModelArray: CampaignMappingModel[] = [];
    Object.values(sourceDestinationToCampaignMappingModelWithValidationArrayRecord).forEach((c) => {
      const merged = CampaignMappingModel.mergeAllCampaignMappingWithValidationArrayToSingleCampaignMapping(c);
      campaignMappingModelArray.push(merged);
    });

    return campaignMappingModelArray;
  }

  // Within group, create separate items based on match type
  private static mergeAllCampaignMappingWithValidationArrayToSingleCampaignMapping(
    campaignMappingWithValidationArray: CampaignMappingModelWithValidation[],
  ): CampaignMappingModel {
    // Assumes s+d are same for all
    // Takes last if duplicate s+d+match

    // Split single mapping (excel row) to multiple mapping items by match type
    const matchToCampaignMappingItemDTORecord: Record<string, CampaignMappingItemDTO> = {};
    campaignMappingWithValidationArray.forEach((c) => {
      CampaignMappingModel.validateConvertCampaignMappingWithValidationToCampaignMapping(c);

      const matchTypeConditions = [
        { condition: c.exactMatch, type: MatchType.EXACT },
        { condition: c.broadMatch, type: MatchType.BROAD },
        { condition: c.phraseMatch, type: MatchType.PHRASE },
        { condition: c.individualProductTarget, type: MatchType.INDIVIDUAL },
        { condition: c.expandedProductTarget, type: MatchType.EXPANDED },
      ];

      const existingMatchTypes = matchTypeConditions.filter((item) => item.condition).map((item) => item.type);
      for (const mt of existingMatchTypes) {
        matchToCampaignMappingItemDTORecord[mt] = {
          m: mt,
          bdm: c.biddingMethod,
          bdmv: c.biddingMethodValue,
          bf: c.bidFloor,
          bc: c.bidCeiling,
          cr: '',
          up: '',
        } as CampaignMappingItemDTO;
      }
    });
    // Create single mapping with nested match type array
    return new CampaignMappingModel({
      sci: campaignMappingWithValidationArray[0].sourceCampaignId as string,
      scn: campaignMappingWithValidationArray[0].sourceCampaignName as string,
      sct: campaignMappingWithValidationArray[0].sourceCampaignAdType as CampaignAdType,
      sai: campaignMappingWithValidationArray[0].sourceAdGroupId as string,
      sagn: campaignMappingWithValidationArray[0].sourceAdGroupName as string,
      set: campaignMappingWithValidationArray[0].sourceAdGroupEntityType as TargetEntityType,
      dci: campaignMappingWithValidationArray[0].destinationCampaignId as string,
      dcn: campaignMappingWithValidationArray[0].destinationCampaignName as string,
      dcb: campaignMappingWithValidationArray[0].destinationCampaignBudgetAmount as number,
      dct: campaignMappingWithValidationArray[0].destinationCampaignAdType as CampaignAdType,
      dai: campaignMappingWithValidationArray[0].destinationAdGroupId as string,
      dagn: campaignMappingWithValidationArray[0].destinationAdGroupName as string,
      det: campaignMappingWithValidationArray[0].destinationAdGroupEntityType as TargetEntityType,
      // Union negatives
      cne: campaignMappingWithValidationArray.some((m) => m.campaignNegativeExact),
      cnp: campaignMappingWithValidationArray.some((m) => m.campaignNegativePhrase),
      cnpt: campaignMappingWithValidationArray.some((m) => m.campaignNegativeProductTarget),
      agne: campaignMappingWithValidationArray.some((m) => m.adGroupNegativeExact),
      agnp: campaignMappingWithValidationArray.some((m) => m.adGroupNegativePhrase),
      agnpt: campaignMappingWithValidationArray.some((m) => m.adGroupNegativeProductTarget),
      cr: '',
      up: '',
      mi: Object.values(matchToCampaignMappingItemDTORecord),
    });
  }

  public toUpsertCampaignMappingItemDTO(): UpsertCampaignMappingItemDTO {
    return {
      sci: this.dto.sci,
      dci: this.dto.dci,
      sai: this.dto.sai,
      dai: this.dto.dai,

      cne: this.dto.cne,
      cnp: this.dto.cnp,
      cnpt: this.dto.cnpt,
      agne: this.dto.agne,
      agnp: this.dto.agnp,
      agnpt: this.dto.agnpt,

      // rm unnecessary fields
      mi: this.dto.mi.map<UpsertCampaignMappingInnerDTO>(({ cr: _cr, up: _up, ...inner }) => inner),
    };
  }

  public toDeleteCampaignMappingItemDTO(matchType: MatchType | null): DeleteCampaignMappingItemDTO {
    return {
      sai: this.dto.sai,
      dai: this.dto.dai,
      mt: matchType ? [matchType] : undefined,
    };
  }

  public toDeleteCampaignMappingsOrItemsBySelectedDTO(): DeleteCampaignMappingItemDTO {
    // All selected - delete whole mapping
    if (this.selectedMatchTypes.length === this.matchTypes.length) {
      return {
        sai: this.dto.sai,
        dai: this.dto.dai,
      };
    }

    // Partially selected - delete only selected items
    return {
      sai: this.dto.sai,
      dai: this.dto.dai,
      mt: this.selectedMatchTypes,
    };
  }

  // public toDeleteWholeCampaignMappingDTO(): DeleteCampaignMappingItemDTO {
  //   const matchTypes = this.matchTypes;

  //   return {
  //     sai: this.dto.sai,
  //     dai: this.dto.dai,
  //     mt: matchType ? [matchType] : undefined,
  //   };
  // }

  public toDeleteCampaignMappingNegativeMatchItemDTO(negativeMatchType: NegativeMatchType): DeleteCampaignMappingItemDTO {
    return {
      sai: this.dto.sai,
      dai: this.dto.dai,
      mt: [negativeMatchType],
    };
  }
}

interface CampaignMappingAdGroupData {
  id: string;
  name: string;
  mapCount: number;
  entityType: TargetEntityType;
  isNameDuplicate: boolean;
}

interface CampaignDataWithCampaignMappingAdGroupData {
  id: string;
  name: string;
  targetingType: TargetingType;
  campaignAdType: CampaignAdType;
  adGroups: CampaignMappingAdGroupData[];
  isNameDuplicate: boolean;
  isVideo: boolean;
  budgetAmount: number;
}

export type CampaignToCampaignDataWithCampaignMappingAdGroupDataType = Record<string, CampaignDataWithCampaignMappingAdGroupData>;

export interface MappingCampaignAdGroupsDTO {
  items: CampaignDataWithCampaignMappingAdGroupDataDTO[];
}

function findNameDuplicates(
  items: CampaignDataWithCampaignMappingAdGroupDataDTO[] | CampaignMappingAdGroupDataDTO[],
): Record<string, boolean> {
  const key = 'name';
  const nameCount = items.reduce(
    (acc, item) => {
      const name = item[key];
      acc[name] = (acc[name] || 0) + 1;
      return acc;
    },
    {} as Record<string, number>,
  );

  return Object.keys(nameCount).reduce(
    (acc, name) => {
      acc[name] = nameCount[name] > 1;
      return acc;
    },
    {} as Record<string, boolean>,
  );
}

export function CampaignDataWithCampaignMappingAdGroupData_toMap(
  dto: MappingCampaignAdGroupsDTO,
): CampaignToCampaignDataWithCampaignMappingAdGroupDataType {
  const map = {} as CampaignToCampaignDataWithCampaignMappingAdGroupDataType;

  // Detect duplicate campaign names
  const campaignNameDuplicates = findNameDuplicates(dto.items);

  for (const data of dto.items) {
    // Detect duplicate ad group names within the same campaign
    const adGroupNameDuplicates = data.ad_groups ? findNameDuplicates(data.ad_groups) : {};

    map[data.id] = {
      id: data.id,
      name: data.name,
      targetingType: data.targeting_type,
      campaignAdType: data.campaign_ad_type,
      isNameDuplicate: campaignNameDuplicates[data.name],
      isVideo: data.is_video ? true : false,
      budgetAmount: data.campaign_budget_amount,
      adGroups: isNil(data.ad_groups)
        ? []
        : data.ad_groups.map((adGroup) => ({
            id: adGroup.id,
            name: adGroup.name,
            mapCount: adGroup.map_count,
            // SPECIAL RULE: when entity_type is UNSET (no targets added into the ad group), treat it as KEYWORD
            entityType: adGroup.entity_type == TargetEntityType.UNSET ? TargetEntityType.KEYWORD : adGroup.entity_type,
            isNameDuplicate: adGroupNameDuplicates[adGroup.name],
          })),
    };
  }

  return map;
}

export interface CampaignAdGroupPair {
  campaignId: string;
  campaignName: string;
  adGroupId: string;
  adGroupName: string;
}

export interface NewCampaignMappingPreset {
  sourceCampaignId: string;
  sourceAdGroupId: string;
  destinationCampaignId: string | null;
  destinationAdGroupId: string | null;
  existingMatches: MatchType[] | null;
  existingNegativeMatches: NegativeMatchBools;
}
