import { downloadObjectArrayAsCsv } from '@/modules/application/utils';
import { LogPreviewModalDetails } from '@/modules/log-viewing/components/LogPreviewModal';
import LogPreviewTable from '@/modules/log-viewing/components/LogPreviewTable';
import { toastService } from '@/services/toast.service';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useState } from 'react';
import useOptimizationLogFetch from '../../hooks/useOptimizationLogFetch';

interface OptimizationLogsPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  details: LogPreviewModalDetails | undefined;
}

const OptimizationLogsPreviewModal: FunctionComponent<OptimizationLogsPreviewModalProps> = ({ isOpen, onClose, details }) => {
  const { rowData, isLogLoading, isLogLoadingError, logLoadingError, fetchLogOnDemand, getHeaderNames } = useOptimizationLogFetch({
    jobId: isNaN(Number(details?.jobId)) ? undefined : Number(details?.jobId),
  });

  const [isLoading, setIsLoading] = useState(false);

  async function onDownloadClicked() {
    setIsLoading(true);

    const data = await fetchLogOnDemand(isNaN(Number(details?.jobId)) ? undefined : Number(details?.jobId));

    if (!data || isEmpty(data)) {
      // User feedback is handled in function
      setIsLoading(false);
      toastService.info('No data to download');
      return;
    }

    try {
      const fileName = details?.filename ?? 'unknown';

      downloadObjectArrayAsCsv(data, fileName, getHeaderNames(data));
    } catch (e) {
      console.error(e);
      toastService.error(`Failed to download data: ${e}`);
    }

    setIsLoading(false);
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="video-modal" maxWidth="lg" fullWidth>
      <DialogTitle>
        <div className="flex flex-row">
          {details?.title}
          <div className="flex flex-grow"></div>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="p-6 py-0">
        <LogPreviewTable
          rowData={rowData}
          headerTranslationKey="lists.log_preview_table_header"
          isLoading={isLogLoading}
          isLoadingError={isLogLoadingError}
          loadingErrorMessage={logLoadingError instanceof Error ? logLoadingError.message : ''}
        />
      </DialogContent>
      <DialogActions className="p-4 justify-end pr-6">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" loading={isLoading} onClick={onDownloadClicked} startIcon={<FileDownloadOutlinedIcon />}>
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OptimizationLogsPreviewModal;
