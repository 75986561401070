import { Environment } from '@/config/Environment';
import Gleap from 'gleap';

export function initGleap() {
  Gleap.initialize('uLu9hsekT4onVdFzG4sXfQI16Da27exv', Environment.isDev());
}

export enum GleapWorkflowType {
  BugReport = 'bugreporting',
  // Default = '',
}

export enum GleapTicketAttributeName {
  ProfileId = 'profileId',
  ProfileName = 'profileName',
  TeamId = 'teamId',
  TeamName = 'teamName',
}
