import { ColumnId } from '@/components/grid/columns/columns.enum';
import type { ColumnState } from 'ag-grid-enterprise';

export function generateKeywordHarvestingTableColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.CHECKBOX, hide: false, pinned: 'left' },
    { colId: ColumnId.SEARCH_TERM, hide: false, pinned: 'left' },
    { colId: ColumnId.MATCH, hide: false },
    { colId: ColumnId.BID_METHOD, hide: false },
    { colId: ColumnId.BID_FLOOR, hide: false },
    { colId: ColumnId.BID_CEILING, hide: false },
    { colId: ColumnId.BID, hide: false },
    { colId: ColumnId.ENTITY_TYPE, hide: false },
    { colId: ColumnId.CAMPAIGN_AD_TYPE, hide: false },
    { colId: ColumnId.CAMPAIGN_NAME, hide: false },
    { colId: ColumnId.AD_GROUP, hide: false },
    { colId: ColumnId.CAMPAIGN_AD_TYPE_DESTINATION, hide: false },
    { colId: ColumnId.CAMPAIGN_NAME_DESTINATION, hide: false },
    { colId: ColumnId.AD_GROUP_DESTINATION, hide: false },
    { colId: ColumnId.ENTITY_TYPE_DESTINATION, hide: false },
    { colId: ColumnId.NEGATIVE_CAMPAIGN, hide: false },
    { colId: ColumnId.NEGATIVE_AD_GROUP, hide: false },
    { colId: ColumnId.ID, hide: true },
    { colId: ColumnId.IMPRESSIONS, hide: false, pinned: false },
    { colId: ColumnId.CLICKS, hide: false, pinned: false },
    { colId: ColumnId.ORDERS, hide: false, pinned: false },
    { colId: ColumnId.UNITS, hide: false, pinned: false },
    { colId: ColumnId.SALES, hide: false, pinned: false },
    { colId: ColumnId.SPEND, hide: false, pinned: false },
    { colId: ColumnId.ACOS, hide: false, pinned: false },
    { colId: ColumnId.ACTC, hide: true, pinned: false },
    { colId: ColumnId.CTR, hide: false, pinned: false },
    { colId: ColumnId.CVR, hide: false, pinned: false },
    { colId: ColumnId.CPC, hide: false, pinned: false },
    { colId: ColumnId.ROAS, hide: true, pinned: false },
    { colId: ColumnId.RPC, hide: true, pinned: false },
    { colId: ColumnId.CPA, hide: true, pinned: false },
    { colId: ColumnId.AOV, hide: true, pinned: false },
    { colId: ColumnId.CPM, hide: true, pinned: false },
  ];
}
