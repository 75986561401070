import ExportGridButton from '@/components/buttons/ExportGridButton';
import FormatterToggleButton from '@/components/buttons/FormatterToggleButton';
import SortByToggleButton from '@/components/buttons/SortByToggleButton';
import UnitToggleButton from '@/components/buttons/UnitToggleButton';
import type { GridApi } from 'ag-grid-enterprise';
import { ComparisonUnit, FormatterType, GridToggles, SortByUnit } from '../types';
import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react';
import ColumnConfigurationButton from './ColumnConfigurationButton';
import { isNil } from 'lodash-es';
import { applyColumnConfigurations, ColumnConfiguration, getColumnConfigurations } from './column-categories.tsx';

interface GridToolsProps<T> {
  gridToggles: GridToggles;
  setGridToggles: Dispatch<SetStateAction<GridToggles>>;
  gridApiRef: MutableRefObject<GridApi<T> | undefined | null>;
  overlayColumnGrouping?: boolean;
}

const GridTools = <T,>({ gridToggles, setGridToggles, gridApiRef, overlayColumnGrouping = false }: GridToolsProps<T>) => {
  const [selectedColumns, setSelectedColumns] = useState<ColumnConfiguration[]>([]);

  const updateSelectedColumns = () => {
    if (gridApiRef.current) {
      const updatedColumns = getColumnConfigurations(gridApiRef.current);
      setSelectedColumns(updatedColumns);
    }
  };

  const handleSelectedColumnsChange = (newColumnOrderAndVisibility: ColumnConfiguration[]) => {
    if (!isNil(gridApiRef) && !isNil(gridApiRef.current)) {
      applyColumnConfigurations(newColumnOrderAndVisibility, gridApiRef.current);
    }
  };

  const handleComparisonUnitChange = (newValue: ComparisonUnit) => {
    setGridToggles((previous) => ({ ...previous, comparisonUnit: newValue }));
  };

  const handleSortChange = (newValue: SortByUnit) => {
    setGridToggles((previous) => ({ ...previous, sortByUnit: newValue }));
  };

  const handleFormatterChange = (newValue: FormatterType) => {
    setGridToggles((previous) => ({ ...previous, formatterType: newValue }));
  };

  return (
    <div className={`absolute -bottom-[37px] right-2 justify-between z-10 flex gap-x-4 bg-gray-50 ${overlayColumnGrouping && 'left-0'}`}>
      <div className="flex-1"></div>
      <div className="flex gap-x-4 items-center">
        {gridToggles.comparisonUnit && (
          <UnitToggleButton handleComparisonUnitChange={handleComparisonUnitChange} comparisonUnit={gridToggles.comparisonUnit} />
        )}
        {gridToggles.sortByUnit && gridToggles.comparisonUnit && (
          <SortByToggleButton
            handleSortChange={handleSortChange}
            sortBy={gridToggles.sortByUnit}
            comparisonUnit={gridToggles.comparisonUnit}
          />
        )}
        {gridToggles.formatterType && (
          <FormatterToggleButton handleFormatterChange={handleFormatterChange} formatterType={gridToggles.formatterType} />
        )}
        <ExportGridButton gridApiRef={gridApiRef} />

        <ColumnConfigurationButton
          onSelectedColumnsChange={handleSelectedColumnsChange}
          selectedColumns={selectedColumns}
          onColumnConfigurationModalOpen={updateSelectedColumns}
        />
      </div>
    </div>
  );
};

export default GridTools;
