import { CommonMetricsDTO, MetricDTO } from '@/components/metrics/api/metrics-contracts';
import { CommonMetricField, SellerMetricField, VendorMetricField } from '@/components/metrics/types/MetricField';
import { BidLimitChangeUnitType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';

export interface ProfileDTO {
  id: string;
  account_id: string;
  name: string;
  type: string;
  sub_type: string;
  country_code: string;
  currency_code: string;
  daily_budget: number;
  timezone: string;
  marketplace_id: string;
  state: string;
  created_at: string;
  create_stv_reports: boolean;
  seller_central_auth_at: string | null;
  vendor_central_auth_at: string | null;
  ads_data_start_date: string | null;
  ads_data_end_date: string | null;
  selling_partner_data_start_date: string | null;
  selling_partner_data_end_date: string | null;
}

export enum StatusType {
  PENDING = 'PENDING',
  ONGOING = 'ONGOING',
  DOWNLOADED = 'DOWNLOADED',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
}

export enum ReportingStatusType {
  NEVER = 'NEVER',
  LOADING = 'LOADING',
  COMPLETED = 'COMPLETED',
  OUTDATED = 'OUTDATED',
  ERROR = 'ERROR',
  WAITING_RESPONSE = 'WAITING_RESPONSE', // Frontend only
  UNUSED = '', // Frontend only, profile might not have SB products etc
}

export const REPORT_STATUSES_ORDERED_BY_SEVERITY: ReportingStatusType[] = [
  ReportingStatusType.ERROR,
  ReportingStatusType.LOADING,
  ReportingStatusType.NEVER,
  ReportingStatusType.OUTDATED,
  ReportingStatusType.WAITING_RESPONSE,
];

export interface ProfileReportsStatusDTO {
  products: AdTypeReportStatusDTO;
  brands: AdTypeReportStatusDTO;
  display: AdTypeReportStatusDTO;
  next_possible_update: string;
  update_ongoing: boolean;
}

export interface AllTeamReportStatusesDTO {
  statuses_per_profile: Record<string, ProfileReportsStatusDTO>;
}

export interface AdTypeReportStatusDTO {
  targets: ReportingStatusDTO;
  placements?: ReportingStatusDTO;
  products?: ReportingStatusDTO;
  search_terms?: ReportingStatusDTO;
}

export interface ReportingStatusDTO {
  status: ReportingStatusType;
  updated_at?: string;
}

export interface ProfileReportsDTO {
  profile: string; // Profile ID
  placement: ReportInfoDTO[];
  targeting: ReportInfoDTO[];
  search_terms: ReportInfoDTO[];
  products: ReportInfoDTO[];
}

interface ReportInfoDTO {
  id: string;
  created_at: string;
  updated_at: string;
  ad_product: AdProduct;
  type: ReportType;
  status: StatusType;
}

enum AdProduct {
  SPONSORED_PRODUCTS = 'SPONSORED_PRODUCTS',
  SPONSORED_DISPLAY = 'SPONSORED_DISPLAY',
  SPONSORED_BRANDS = 'SPONSORED_BRANDS',
}

enum ReportType {
  SPONSORED_PRODUCTS_PLACEMENTS = 'spCampaigns',
  SPONSORED_BRANDS_PLACEMENTS = 'sbCampaignPlacement',

  SPONSORED_PRODUCTS_TARGETING = 'spTargeting',
  SPONSORED_BRANDS_TARGETING = 'sbTargeting',
  SPONSORED_DISPLAY_TARGETING = 'sdTargeting',

  SPONSORED_PRODUCTS_SEARCH_TERMS = 'spSearchTerm',
  SPONSORED_BRANDS_SEARCH_TERMS = 'sbSearchTerm',

  SPONSORED_PRODUCTS_ADVERTISED_PRODUCTS = 'spAdvertisedProduct',
  SPONSORED_DISPLAY_ADVERTISED_PRODUCTS = 'sdAdvertisedProduct',
}

export interface OptimizerJobDTO {
  id: number;
  reverted_job_id: number;
  optimizer_version: string;
  user_id: number;
  user_name: string;
  team_id: number;
  campaign_count: number;
  preview_campaign_count: number;
  preview_entity_count: number;
  applied_entity_count: number;
  success_entity_count: number;
  failed_entity_count: number;
  start_date: string;
  end_date: string;
  compare_start_date: string;
  compare_end_date: string;
  tacos: number;
  sentiment: OptimizationPreset;
  bid_ceiling: number;
  bid_ceiling_type: BidLimitChangeUnitType | ''; // Legacy jobs have no type
  bid_floor: number;
  enabled_keyword_groups: string;
  exclude_no_impressions: false;
  state: string;
  created_at: string;
}

export interface ProfilesWithMetricsRequestDTO {
  dates: DateRangeDTO;
  compare_dates: DateRangeDTO;
}

interface DateRangeDTO {
  start_date: string;
  end_date: string;
}

export interface ProfilesMetricsWithTeamDTO extends ProfileDTO {
  team_id: number;
  team_name: string;
  metrics: ProfileCommonMetricsDTO;
  seller_metrics: ProfileSellerMetricsDTO;
  vendor_metrics: ProfileVendorMetricsDTO;
  comparison_missing: boolean;
  performance_low_data: boolean;
  performance: PerformanceMetricsDTO;
  timeline: ProfileMetricsWithTeamTimelineDTO;
  last_optimized: string | null;
}

export interface ProfileMetricsWithTeamTimelineDTO {
  x_axis: string[];
  y_axis: {
    metrics: Record<CommonMetricField, number[]>;
    seller_metrics: Record<SellerMetricField, number[]>;
    vendor_metrics: Record<VendorMetricField, number[]>;
  };
}

export type PerformanceMetricsDTO = {
  acos: PerformanceTargetEstimate | null;
  acots: PerformanceTargetEstimate | null;
  ad_spend: PerformanceTargetEstimate | null;
  ad_sales: PerformanceTargetEstimate | null;
  total_sales: PerformanceTargetEstimate | null;
};

export type PerformanceTargetEstimate = [target: number | null, estimate: number | null];

export interface ProfileCommonMetricsDTO extends Omit<CommonMetricsDTO, 'spend' | 'sales'> {
  spend: MetricDTO; // Override spend
  sales: MetricDTO; // Override sales
}

export interface ProfileSellerMetricsDTO {
  acos: MetricDTO;
  actc: MetricDTO;
  ad_sales_of_total: MetricDTO;
  aov: MetricDTO;
  asp: MetricDTO;
  claims_amount: MetricDTO;
  claims_granted: MetricDTO;
  clicks: MetricDTO;
  cpa: MetricDTO;
  cvr: MetricDTO;
  feedback_received: MetricDTO;
  neg_feedback_received: MetricDTO;
  orders: MetricDTO;
  orders_shipped: MetricDTO;
  org_traffic: MetricDTO;
  org_sales: MetricDTO;
  roas: MetricDTO;
  sales: MetricDTO;
  sales_shipped: MetricDTO;
  unit_sess: MetricDTO;
  unit_view: MetricDTO;
  units: MetricDTO;
  units_refunded: MetricDTO;
  units_refund_rate: MetricDTO;
  units_shipped: MetricDTO;
  views: MetricDTO;
}

export interface ProfileVendorMetricsDTO {
  acos: MetricDTO;
  ad_sales_of_total: MetricDTO;
  asp: MetricDTO;
  customer_returns: MetricDTO;
  glance_views: MetricDTO;
  ordered_revenue: MetricDTO;
  ordered_units: MetricDTO;
  org_sales: MetricDTO;
  return_rate: MetricDTO;
  roas: MetricDTO;
  shipped_cogs: MetricDTO;
  shipped_revenue: MetricDTO;
  shipped_units: MetricDTO;
  unit_view: MetricDTO;
}

export interface UpdateProfilePerformanceTargetsDTO {
  acos?: number;
  acots?: number;
  ad_spend?: number;
  ad_sales?: number;
  total_sales?: number;
  create_stv_reports?: boolean;
}
