/* eslint-disable react/prop-types */
import { Button, Popover } from '@mui/material';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import { FunctionComponent, useState } from 'react';

interface IButtonPopoverCellRendererParams {
  content?: string | null;
}

interface IButtonPopoverCellRendererProps extends ICellRendererParams, IButtonPopoverCellRendererParams {}

const ButtonPopoverCellRenderer: FunctionComponent<IButtonPopoverCellRendererProps> = ({ content }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="flex h-full items-center">
      <Button variant="outlined" color="primary" onClick={handleClick}>
        Debug
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <pre className="text-xs px-2">{content || 'N/A'}</pre>
      </Popover>
    </div>
  );
};

export default ButtonPopoverCellRenderer;
