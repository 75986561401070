import { UserSettingKey, useUserSettingsContext } from '@/modules/users';
import type {
  ColumnMovedEvent,
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnRowGroupChangedEvent,
  ColumnVisibleEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import type { ColDef, ColumnState, GridApi } from 'ag-grid-enterprise';
import { debounce, differenceBy, isBoolean, isEqual, isNil } from 'lodash-es';
import { useCallback, useEffect, useRef } from 'react';

interface GridColumnState {
  isLoading: boolean;
  isError: boolean;
  setColumnStateGridApi: (gridApi: GridApi) => void;
  handleColumnStateChange: (
    event: ColumnPinnedEvent | ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent | ColumnRowGroupChangedEvent | SortChangedEvent,
  ) => void;
  applyStateToDefinitions: (colDefs: ColDef[]) => void;
  isAutoSaveEnabled: boolean;
  setIsAutoSaveEnabled: (isAutoSaveEnabled: boolean) => void;
}

export function useGridColumnState(userSettingKey: UserSettingKey, defaultColumnState: ColumnState[]): GridColumnState {
  const { getUserSettingValueByKey, upsertUserSetting } = useUserSettingsContext();

  const isLoadingRef = useRef(false);
  const isErrorRef = useRef(false);
  const gridApiRef = useRef<GridApi | null>(null);
  const isAutoSaveEnabledRef = useRef(false);

  // Use a ref so an updated version of the userSettingKey is used in handleColumnStateChange as grid internalizes functions
  const userSettingKeyRef = useRef(userSettingKey);
  useEffect(() => {
    userSettingKeyRef.current = userSettingKey;
  }, [userSettingKey]);

  const handleColumnStateChange = useCallback(
    (
      event: ColumnPinnedEvent | ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent | ColumnRowGroupChangedEvent | SortChangedEvent,
    ) => {
      if (['sizeColumnsToFit', 'uiColumnDragged', undefined, 'gridOptionsChanged'].includes(event.source)) {
        return;
      }
      if (gridApiRef.current && isAutoSaveEnabledRef.current) {
        debouncedSaveColumnStateFn();
      }
    },
    [],
  );

  const saveColumnState = useCallback(async () => {
    if (gridApiRef.current) {
      const columnState = gridApiRef.current.getColumnState();

      if (isEqual(columnState, getUserSettingValueByKey<ColumnState[]>(userSettingKeyRef.current))) {
        return;
      }

      isLoadingRef.current = true;
      const success = await upsertUserSetting(userSettingKeyRef.current, columnState);
      isErrorRef.current = !success;
      isLoadingRef.current = false;
    }
  }, [userSettingKey, upsertUserSetting]);

  const debouncedSaveColumnStateFn = useCallback(
    debounce(() => {
      saveColumnState();
    }, 500),
    [saveColumnState],
  );

  const applyStateToDefinitions = useCallback(
    (colDefs: ColDef[]) => {
      const unmigratedState = getUserSettingValueByKey<ColumnState[]>(userSettingKey) ?? defaultColumnState;
      const userColumnState = updateUserColumnStateWithNewDefaultState(defaultColumnState, unmigratedState);

      if (userColumnState && userColumnState.find) {
        colDefs.forEach((def) => {
          const stateForCol = userColumnState.find((s) => s.colId === def.colId);
          if (!stateForCol) {
            return;
          }
          if (isBoolean(stateForCol.hide)) {
            def.hide = stateForCol.hide;
          } else {
            def.hide = true;
          }

          // Handle sorting
          if (stateForCol.sort && (stateForCol.sort === 'asc' || stateForCol.sort === 'desc')) {
            def.sort = stateForCol.sort;
          }

          if (!isNil(stateForCol.sortIndex) && stateForCol.sortIndex >= 0) {
            def.sortIndex = stateForCol.sortIndex;
          }

          // Handle grouping
          if (isBoolean(stateForCol.rowGroup)) {
            def.rowGroup = stateForCol.rowGroup;
          }

          if (!isNil(stateForCol.rowGroupIndex) && stateForCol.rowGroupIndex >= 0) {
            def.rowGroupIndex = stateForCol.rowGroupIndex;
          }

          // Handle width
          if (stateForCol.width && def.width !== stateForCol.width) {
            def.width = stateForCol.width;
          }

          // Check if the column is pinned
          if (isBoolean(stateForCol.pinned) || stateForCol.pinned === 'left' || stateForCol.pinned === 'right') {
            def.pinned = stateForCol.pinned;
          } else {
            def.pinned = null;
          }
        });

        colDefs.sort((a, b) => {
          const aIndex = userColumnState.findIndex((s) => s.colId === a.colId);
          const bIndex = userColumnState.findIndex((s) => s.colId === b.colId);

          return aIndex - bIndex;
        });
      }
    },
    [userSettingKey],
  );

  useEffect(() => {
    return () => {
      gridApiRef.current = null;
    };
  }, []);

  const setColumnStateGridApi = (api: GridApi) => {
    gridApiRef.current = api;
  };

  const setIsAutoSaveEnabled = (value: boolean) => {
    isAutoSaveEnabledRef.current = value;
  };

  /**
   * Migrates the column state of the user, with the default.
   *
   * New Columns are injected before their next neighbor in the default state
   *
   * @param defaultColumnstate The default column state
   * @param userColumnStates The user's column state
   */
  function updateUserColumnStateWithNewDefaultState(defaultColumnstate: ColumnState[], userColumnStates: ColumnState[]) {
    // Get the fields the current user's state is missing
    const missingFields = differenceBy(defaultColumnstate, userColumnStates, 'colId');

    // For each missing field
    missingFields.forEach((missingField) => {
      // Find the position in the default state
      const missingFieldIndex = defaultColumnstate.findIndex((el) => el.colId === missingField.colId);

      // First, look for the field before it
      const fieldBefore = defaultColumnstate
        .slice(0, missingFieldIndex)
        .reverse()
        .find((field) => userColumnStates.some((el) => el.colId === field.colId));

      if (fieldBefore) {
        // If there is a field before, insert the missing field after it in the user's state
        const fieldBeforeIndex = userColumnStates.findIndex((el) => el.colId === fieldBefore.colId);
        userColumnStates.splice(fieldBeforeIndex + 1, 0, missingField);
      } else {
        // Otherwise, look for the field after it
        const fieldAfter = defaultColumnstate
          .slice(missingFieldIndex)
          .find((field) => !missingFields.some((mf) => mf.colId === field.colId) && userColumnStates.some((el) => el.colId === field.colId));

        if (fieldAfter) {
          // Find the index of the field after in the user's state and insert before it
          const fieldAfterIndex = userColumnStates.findIndex((el) => el.colId === fieldAfter.colId);
          userColumnStates.splice(fieldAfterIndex, 0, missingField);
        } else {
          // If no neighboring fields, add to the end
          userColumnStates.push(missingField);
        }
      }
    });

    return userColumnStates;
  }

  return {
    isLoading: isLoadingRef.current,
    isError: isErrorRef.current,
    setColumnStateGridApi,
    handleColumnStateChange,
    applyStateToDefinitions,
    isAutoSaveEnabled: isAutoSaveEnabledRef.current,
    setIsAutoSaveEnabled,
  };
}
