import useFormatting from '@/hooks/useFormatting';
import { CurrencyCode } from '@/modules/amazon-constants/types/CurrencyCode';
import { Autocomplete, Box, TextField } from '@mui/material';
import { FunctionComponent, SyntheticEvent } from 'react';

interface CurrencySelectProps {
  selectedCurrency: CurrencyCode;
  onCurrencyChange: (currency: CurrencyCode) => void;
  optionallyAddedCurrencies?: CurrencyCode[];
}

const CurrencySelect: FunctionComponent<CurrencySelectProps> = ({
  selectedCurrency,
  onCurrencyChange: setSelectedCurrency,
  optionallyAddedCurrencies,
}) => {
  const { getCurrencySymbol } = useFormatting();

  const fixedTopCurrencies = [CurrencyCode.USD, CurrencyCode.EUR, CurrencyCode.GBP, CurrencyCode.CAD];
  const fixedAdditionalCurrencies = [CurrencyCode.AUD, CurrencyCode.JPY, CurrencyCode.INR];

  const sortedCurrencies = [...fixedAdditionalCurrencies, ...(optionallyAddedCurrencies || [])].sort();
  let currencyOptions = [...fixedTopCurrencies, ...sortedCurrencies];
  // Remove duplicates
  currencyOptions = currencyOptions.filter((item, index) => currencyOptions.indexOf(item) === index);

  return (
    <Autocomplete
      className="w-32"
      disableClearable
      value={selectedCurrency}
      onChange={(_: SyntheticEvent<Element, Event>, newValue: CurrencyCode | null) => {
        if (newValue) setSelectedCurrency(newValue);
      }}
      options={currencyOptions}
      getOptionLabel={(currency) => `${getCurrencySymbol(currency)} ${currency}`} // Display currency code as label
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option}>
          {getCurrencySymbol(option)} {option}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          slotProps={{
            inputLabel: { shrink: true },
          }}
          sx={{ margin: 0 }}
        />
      )}
      isOptionEqualToValue={(option, value) => option === value}
    />
  );
};

export default CurrencySelect;
