import { cloneElement, FunctionComponent } from 'react';

interface DashboardWidgetDragHandleProps {
  size?: 'small' | 'medium' | 'large'; // Define size prop
}

const DashboardWidgetDragHandle: FunctionComponent<DashboardWidgetDragHandleProps> = ({ size = 'medium' }) => {
  // Define number of dots based on size
  const sizeMap = {
    small: 6, // Small: fewer dots
    medium: 12, // Medium: default number of dots
    large: 18, // Large: more dots
  };

  const numberOfDots = sizeMap[size];

  // Generate the dots based on the configured number
  const dots = Array.from({ length: numberOfDots }, (_, i) => {
    const x = i * 6 + 1; // Spacing of 6 units between dots
    return <circle key={`${x}-1`} cx={x} cy="1" r="1" className="fill-gray-400" />;
  });

  // Duplicate dots for the second row
  const rowTwoDots = dots.map((dot) =>
    cloneElement(dot, {
      key: `${dot.key}-7`,
      cy: '7', // Move to the second row
    }),
  );

  // Calculate dynamic width based on the number of dots
  const dynamicWidth = numberOfDots * 6 + 1; // Each dot + spacing

  return (
    <div
      style={{ width: `${dynamicWidth + 15}px` }} // Dynamic width for the parent
      className="absolute flex justify-center pl-px left-0 right-0 z-10 mx-auto -top-2 rounded-lg bg-gray-300 drop-shadow-sm shadow-slate-800 draggable cursor-move"
    >
      <div className="flex h-3  mt-1">
        <svg
          width={dynamicWidth - 4} // Adjust SVG width dynamically
          height="8"
          viewBox={`0 0 ${dynamicWidth - 4} 8`} // Adjust viewBox dynamically
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {dots}
          {rowTwoDots}
        </svg>
      </div>
    </div>
  );
};

export default DashboardWidgetDragHandle;
