import { NegativeMatchType } from '../negative-targets/api/negative-targets-contracts';
import { CountryCode } from '../amazon-constants/types/CountryCode';
import { countryDomainMap } from './utils';

const expressionPrefixes = ['asin=', 'asin-expanded=', 'category=', 'brand='];
export function isExpression(testString: string): boolean {
  return expressionPrefixes.some((expression) => testString.startsWith(expression));
}

export function getASINFromExpression(testString: string): string {
  testString = testString.replaceAll('"', '').replaceAll("'", '');

  expressionPrefixes.forEach((expression) => {
    testString = testString.replace(expression, '');
  });

  return testString;
}

function isValidASIN(testString: string): boolean {
  const regex = /^(B[\dA-Z]{9}|\d{9}(X|\d))$/i; // Added the 'i' flag for case insensitivity
  return regex.test(testString);
}

function isValidISBN(isbn: string): boolean {
  const cleanedISBN = isbn.replace(/[-\s]/g, ''); // Remove hyphens & spaces

  if (/^\d{9}[\dX]$/.test(cleanedISBN)) {
    // ISBN-10 validation
    const sum = cleanedISBN
      .split('')
      .map((char, i) => (char === 'X' ? 10 : Number(char)) * (10 - i))
      .reduce((acc, val) => acc + val, 0);
    return sum % 11 === 0;
  }

  if (/^\d{13}$/.test(cleanedISBN)) {
    // ISBN-13 validation
    const sum = cleanedISBN
      .split('')
      .map((char, i) => Number(char) * (i % 2 === 0 ? 1 : 3))
      .reduce((acc, val) => acc + val, 0);
    return sum % 10 === 0;
  }

  return false; // Invalid format
}

export function isValidAsinOrIsbn(testString: string): boolean {
  return isValidASIN(testString) || isValidISBN(testString);
}

export function standardizeASIN(asin: string): string {
  const termsToCheck = ['asin=', 'asin-expanded=', 'category='];
  if (termsToCheck.some((term) => asin.includes(term))) {
    return asin;
  }
  return `asin="${asin}"`;
}

export function cleanAsinsReturnErrorMessage(asins: string[]): { expressions: string[]; errorMessage: string | null } {
  const expressions: string[] = [];
  const invalidAsins: string[] = [];

  for (const expressionOrASIN of asins) {
    if (isValidAsinOrIsbn(expressionOrASIN)) {
      expressions.push(standardizeASIN(expressionOrASIN));
    } else if (isExpression(expressionOrASIN)) {
      if (isValidAsinOrIsbn(getASINFromExpression(expressionOrASIN))) {
        expressions.push(expressionOrASIN);
      } else {
        invalidAsins.push(expressionOrASIN);
      }
    } else {
      invalidAsins.push(expressionOrASIN);
    }
  }

  let errorMessage: string | null = null;

  if (invalidAsins.length > 0) {
    const INVALID_ASIN_SHOW_COUNT = 5;
    const firstX = invalidAsins.slice(0, INVALID_ASIN_SHOW_COUNT);
    const remainingCount = invalidAsins.length - firstX.length;

    errorMessage = `ASIN${firstX.length > 1 ? 's are' : ' is'} invalid: ${firstX.join(', ')}${remainingCount > 0 ? `, with ${remainingCount} others` : ''}.`;
  }

  return { expressions, errorMessage };
}

interface RemoveInvalidKeywordsResult {
  validatedKeywords: string[];
  warnings: Set<string>;
}

export function removeInvalidKeywords(keywords: string[], negativeMatchTypes: NegativeMatchType[] = []): RemoveInvalidKeywordsResult {
  const validatedKeywords: string[] = [];
  const warnings: Set<string> = new Set();

  // Rule-specific constraints
  const maxWordsForNegativePhrase = 4;
  const maxWordsForNegativeExact = 10;
  const maxWordsForKeywords = 10;
  const maxKeywordLength = 80;

  // Regex for invalid use of special characters
  const invalidPeriod = /^[.]|[.]$|\s[.]\s/; // Period can't be at start, end, or between spaces
  const invalidHyphenPlus = /(^[-+])|([-+]\s|\s[-+])|([-+]$)/; // Hyphen/plus cannot be at start, end, or around spaces

  const validCharRegex =
    /^[\u3000-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF00-\uFFEF0-9a-zA-Z®ÁÉÍÑÓÚÜáéíñóúüÄÖŒßäöÀÂÆÇÈÊËÎÏÔÙÛŸàâæçèêëîïôùûÿœ\-&\\+[\]\t\n\r'" ]*$/;

  // Determine the stricter word limit based on the provided match types
  const hasPhraseMatch = negativeMatchTypes.some(
    (type) => type === NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE || type === NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE,
  );
  const hasExactMatch = negativeMatchTypes.some(
    (type) => type === NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT || type === NegativeMatchType.AD_GROUP_NEGATIVE_EXACT,
  );

  // Apply the more restrictive limit: 4 words if Negative Phrase is involved
  const maxWords = hasPhraseMatch ? maxWordsForNegativePhrase : hasExactMatch ? maxWordsForNegativeExact : maxWordsForKeywords;

  // Helper function to count words and ensure they meet "part" constraints
  const countWords = (keyword: string): number => keyword.trim().split(/\s+/).length;

  // Iterate over the keywords
  for (let keyword of keywords) {
    keyword = keyword.trim(); // Remove leading and trailing spaces

    // Check word count
    const wordCount = countWords(keyword);
    if (wordCount > maxWords) {
      warnings.add(`Keyword exceeds the ${maxWords} word limit: "${keyword}"`);
      continue;
    }

    // Check maximum length constraint
    if (keyword.length > maxKeywordLength) {
      warnings.add(`Keyword exceeds the 80 character limit: "${keyword}"`);
      continue;
    }

    // Check for invalid use of period (.)
    if (invalidPeriod.test(keyword)) {
      warnings.add(`Invalid use of a period in keyword: "${keyword}"`);
      continue;
    }

    // Check for invalid use of hyphen (-) or plus (+)
    if (invalidHyphenPlus.test(keyword)) {
      warnings.add(`Invalid use of hyphen or plus sign in keyword: "${keyword}"`);
      continue;
    }

    // Check for invalid use of special characters
    if (!validCharRegex.test(keyword)) {
      warnings.add(`Keyword contains invalid characters: "${keyword}"`);
      continue;
    }

    // Invalid use of double quotes
    if (/["]/.test(keyword)) {
      const doubleQuoteCount = (keyword.match(/"/g) || []).length;
      if (doubleQuoteCount % 2 !== 0) {
        warnings.add(`Invalid use of a double quote in keyword: "${keyword}"`);
        continue;
      }
    }

    // Can't contain comma
    if (keyword.includes(',')) {
      warnings.add(`Keyword contains a comma: "${keyword}"`);
      continue;
    }

    // If keyword is valid, add it to validatedKeywords; if not, only add the warning
    validatedKeywords.push(keyword);
  }

  return { validatedKeywords, warnings };
}

export function getProductLink(asin: string, countryCode: CountryCode): string {
  const domain = countryDomainMap[countryCode];
  return `https://${domain}/dp/${asin}`;
}
