import InfoMessage from '@/components/feedback/InfoMessage';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ButtonGroupCellRenderer, { IButtonGroupCellRendererParams } from '@/components/grid/cells/ButtonGroupCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import RowActionButton from '@/components/grid/components/RowActionButton';
import { AlColDef } from '@/components/grid/types';
import { NewSingleMappingModal } from '@/modules/campaign-mapping/components/NewSingleMappingModal';
import useCampaignToAdGroupsMappingData from '@/modules/campaign-mapping/hooks/useCampaignToAdGroupsMappingData';
import { CampaignAdGroupPair, NewCampaignMappingPreset } from '@/modules/campaign-mapping/models/CampaignMappingModel';
import { SelectedSearchTerm } from '@/modules/search-terms/models/SearchTermModel';
import { Routes } from '@/router/router-paths';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Button } from '@mui/material';
import type { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

interface UnmappedCampaignMappingsTableProps {
  selectedSearchTerms: SelectedSearchTerm[];
}

const UnmappedCampaignMappingsTable: FunctionComponent<UnmappedCampaignMappingsTableProps> = ({ selectedSearchTerms }) => {
  const { campaignToAdGroupsMap, isCampaignToAdGroupsMapLoading, getUnmappedCampaignAdgroupPairs } = useCampaignToAdGroupsMappingData();

  const navigate = useNavigate();
  function onToCampaignMappingClicked() {
    navigate({ pathname: Routes.CAMPAIGN_MAPPING });
  }

  const rowData = useMemo(() => {
    if (isCampaignToAdGroupsMapLoading && !isEmpty(campaignToAdGroupsMap)) {
      return [];
    }

    if (selectedSearchTerms.length === 0) {
      return [];
    }

    const selectedAdGroupIds = new Set(selectedSearchTerms.map((searchTerm) => searchTerm.adGroupId));

    return getUnmappedCampaignAdgroupPairs()?.filter((campaignAdGroupPair) => selectedAdGroupIds.has(campaignAdGroupPair.adGroupId)) ?? [];
  }, [campaignToAdGroupsMap, isCampaignToAdGroupsMapLoading]);

  const columnDefs: ColDef<CampaignAdGroupPair>[] = useMemo(() => {
    const colDefs: AlColDef<CampaignAdGroupPair>[] = [
      {
        colId: ColumnId.CAMPAIGN_NAME,
        headerName: 'Source Campaign',
        field: 'campaignName',
        width: 200,
      },
      {
        colId: ColumnId.AD_GROUP,
        headerName: 'Source Ad Group',
        field: 'adGroupName',
        width: 200,
      },
      {
        colId: ColumnId.ACTIONS,
        headerName: 'Actions',
        width: 150,
        cellClass: 'border-none outline-none',
        cellRenderer: ButtonGroupCellRenderer,
        cellRendererParams: (params: ICellRendererParams<CampaignAdGroupPair, unknown, unknown>): IButtonGroupCellRendererParams => {
          return {
            buttons: [
              <RowActionButton
                key="delete"
                text="Resolve"
                color="default"
                tooltipText={`Create a mapping for this item`}
                onClick={() => onResolveClicked(params)}
                icon={<PlaylistAddCheckIcon />}
              ></RowActionButton>,
            ],
          };
        },
      },
    ];

    return colDefs;
  }, []);

  const customGridOptions: GridOptions<CampaignAdGroupPair> = useMemo(() => {
    return {
      ...DEFAULT_GRID_OPTIONS,
      getRowId: (params) => params.data.campaignId.toString() + params.data.adGroupId.toString(),

      maintainColumnOrder: true,
      sideBar: false,
    };
  }, []);

  // Resolve and new mapping creation
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCampaignMappingPreset, setNewCampaignMappingPreset] = useState<NewCampaignMappingPreset | undefined>(undefined);
  function onResolveClicked(params: ICellRendererParams<CampaignAdGroupPair, unknown, unknown>) {
    if (!params.data) return;

    setNewCampaignMappingPreset({
      sourceCampaignId: params.data.campaignId,
      sourceAdGroupId: params.data.adGroupId,
      destinationCampaignId: null,
      destinationAdGroupId: null,
      existingMatches: null,
      existingNegativeMatches: {
        cne: false,
        cnp: false,
        cnpt: false,
        agne: false,
        agnp: false,
        agnpt: false,
      },
    });

    setIsModalOpen(true);
  }
  return (
    <>
      {isCampaignToAdGroupsMapLoading && <LoadingBlock />}
      {!isCampaignToAdGroupsMapLoading && rowData.length > 0 && (
        <div className="flex flex-col gap-y-2 mb-2">
          <InfoMessage
            content={`${rowData.length} unmapped campaign${rowData.length > 1 ? 's' : ''} detected in selection. Map them or proceed to harvesting without these sources. To Bulk upload mappings, go to Campaign Mapping`}
          />
          <div style={{ height: `200px` }}>
            <AlGrid
              colDefs={columnDefs}
              rowData={rowData}
              gridOptions={customGridOptions}
              isLoading={isCampaignToAdGroupsMapLoading}
              fitToResizeEnabled={true}
              addExtraBottomPadding={true}
            />
          </div>

          <Button onClick={onToCampaignMappingClicked} variant="outlined" endIcon={<EastRoundedIcon />}>
            To Campaign Mapping
          </Button>
        </div>
      )}
      {!isCampaignToAdGroupsMapLoading && rowData.length === 0 && <InfoMessage content={`All selected campaigns/ad groups are mapped.`} />}
      {isModalOpen && (
        <NewSingleMappingModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          newCampaignMappingPreset={newCampaignMappingPreset}
        />
      )}
    </>
  );
};

export default UnmappedCampaignMappingsTable;
