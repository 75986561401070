import { AlMultiSelect } from '@/components/form/AlMultiSelect';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { AlFilterModel } from '../models/AlFilterModel';
import { AlMultiSelectOptionModel } from '../models/AlMultiSelectOptionModel';

interface FilterMultiSelectProps {
  filter: AlFilterModel;
  label: string;
}

export const FilterMultiSelect: FunctionComponent<FilterMultiSelectProps> = ({ filter, label }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);

  const selectedOptionIds = filter.conditions && filter.conditions[0].values ? (filter.conditions[0].values as string[]) : [];
  function setSelectedOptionIds(selectedOptionIds: string[]) {
    filter.conditions = [
      {
        values: selectedOptionIds,
        operator: filter.defaultConditionOperators[0],
      },
    ];

    setFilterValue(filter);
  }

  const [options, setOptions] = useState<AlMultiSelectOptionModel[]>([]);

  // TODO: check why this component is mounted twice
  useEffect(() => {
    filter.getOptions().then((options) => {
      setOptions(options);
    });
  }, [filter]);

  return (
    <div className="flex flex-row gap-2 w-full">
      {selectedOptionIds && (
        <AlMultiSelect
          options={options}
          selectedOptionIds={selectedOptionIds}
          setSelectedOptionIds={setSelectedOptionIds}
          label={label}
          isLoading={filter.isLoading}
        />
      )}
    </div>
  );
};
