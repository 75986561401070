import RichTextEditor from '@/components/form/rich-text-editor/RichTextEditor';
import { FunctionComponent } from 'react';

interface DashboardWidgetRichTextTitleEditorProps {
  content: string;
  onContentChange: (content: string) => void;
}

const DashboardWidgetRichTextTitleEditor: FunctionComponent<DashboardWidgetRichTextTitleEditorProps> = ({ content, onContentChange }) => {
  return (
    <div>
      <div className="text-sm mb-1">Title</div>
      <RichTextEditor content={content} onContentChange={onContentChange} singleLine />
    </div>
  );
};

export default DashboardWidgetRichTextTitleEditor;
