import { useUserContext } from '@/modules/users';
import { toastService } from '@/services/toast.service';
import { Close } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { dashboardService } from '../../api/dashboard/dashboard.service';
import { DashboardModel } from '../../types/DashboardModel';
import { TeamProfile } from '../../types/TeamProfile';

interface ManageDashboardProfilesModalProps {
  isOpen: boolean;
  onClose: () => void;
  dashboard: DashboardModel;
  onProfilesUpdated: () => void;
}

const ManageDashboardProfilesModal: FunctionComponent<ManageDashboardProfilesModalProps> = ({
  isOpen,
  onClose,
  dashboard,
  onProfilesUpdated,
}) => {
  const { user } = useUserContext();
  const [selectedProfiles, setSelectedProfiles] = useState<TeamProfile[]>(dashboard.teamProfiles || []);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch available profiles from userContext
  const profileOptions: TeamProfile[] =
    user?.teams
      .filter((team) => team.id === Number(dashboard.dto.team_id))
      .flatMap((team) =>
        team.profiles.map((profile) => ({
          profileId: profile.id,
          profileName: profile.name,
          teamId: team.id,
          teamName: team.name,
          countryCode: profile.countryCode,
          state: profile.state,
        })),
      )
      .sort((a, b) => a.profileName.localeCompare(b.profileName)) ?? [];

  const handleProfileSelectionChange = (profile: TeamProfile) => {
    if (selectedProfiles.some((p) => p.profileId === profile.profileId)) {
      setSelectedProfiles(selectedProfiles.filter((p) => p.profileId !== profile.profileId));
    } else {
      setSelectedProfiles([...selectedProfiles, profile]);
    }
  };

  const handleSaveProfiles = async () => {
    setIsLoading(true);

    const result = await dashboardService.updateDashboardProfiles(
      dashboard.dto.id,
      selectedProfiles.map((profile) => ({
        profile_id: profile.profileId.toString(),
        team_id: profile.teamId,
      })),
    );

    if (result.isSuccess) {
      toastService.success('Profiles updated successfully');
      onProfilesUpdated();
      onClose();
    } else {
      toastService.error('Error updating profiles');
    }

    setIsLoading(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        if (!isLoading) {
          onClose();
        }
      }}
      fullWidth
    >
      <DialogTitle>
        <div className="flex flex-row">
          Manage Profiles
          <div className="flex flex-grow"></div>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              if (!isLoading) {
                onClose();
              }
            }}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Manage the profiles associated with the &rdquo;{dashboard.dto.name}&rdquo; dashboard.</DialogContentText>
        <Typography variant="body2" className="mt-6 mb-2">
          Available Profiles
        </Typography>
        {profileOptions.length > 0 ? (
          <div className={`grid ${profileOptions.length > 10 ? 'grid-cols-2' : 'grid-cols-1'} `}>
            {profileOptions.map((profile) => (
              <FormControlLabel
                key={profile.profileId}
                control={
                  <Checkbox
                    disabled={isLoading}
                    size="small"
                    checked={selectedProfiles.some((p) => p.profileId === profile.profileId)}
                    onChange={() => handleProfileSelectionChange(profile)}
                  />
                }
                label={`${profile.profileName} (${profile.countryCode})`}
              />
            ))}
          </div>
        ) : (
          <Typography className="text-gray-600">No profiles available</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSaveProfiles} loading={isLoading}>
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageDashboardProfilesModal;
