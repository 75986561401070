import { Button, Drawer, IconButton, Portal } from '@mui/material';
import { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { Close } from '@mui/icons-material';
import { DashboardWidgetConfigurationDrawerDeleteButton } from './DashboardWidgetConfigurationDrawerDeleteButton';
import { DashboardWidgetConfigurationDrawerDuplicateButton } from './DashboardWidgetConfigurationDrawerDuplicateButton';
import { useDashboardQueries } from '../../hooks/useDashboardQueries';
import { IDashboardWidgetBaseConfiguration } from '../../types/IDashboardWidgetBaseConfiguration';

interface DashboardWidgetConfigurationDrawerProps extends PropsWithChildren {
  widgetName: string;
}

const DashboardWidgetConfigurationDrawer: FunctionComponent<DashboardWidgetConfigurationDrawerProps> = ({ children, widgetName }) => {
  const { widgetId, modifiedConfiguration } = useContext(DashboardWidgetContext);
  const editingWidgetId = useDashboardContextValue((context) => context.editingWidgetId);
  const setEditingWidgetId = useDashboardContextValue((context) => context.setEditingWidgetId);
  const updateWidgetConfiguration = useDashboardContextValue((context) => context.updateWidgetConfiguration);
  const { refetchForWidgetWithId } = useDashboardQueries();

  async function applyConfigurationChanges() {
    if (widgetId && modifiedConfiguration) {
      await updateWidgetConfiguration<IDashboardWidgetBaseConfiguration>(widgetId, modifiedConfiguration, true);
      refetchForWidgetWithId(widgetId);
      setEditingWidgetId('');
    }
  }

  const isConfiguringThisWidget = widgetId === editingWidgetId;

  function onClose() {
    setEditingWidgetId('');
  }

  return (
    <Portal>
      <Drawer
        anchor={'right'}
        open={isConfiguringThisWidget}
        variant="temporary"
        ModalProps={{
          BackdropProps: {
            style: { backgroundColor: 'transparent' },
          },
        }}
        onClose={applyConfigurationChanges}
      >
        <div className="flex h-full w-96 flex-col justify-between px-6 py-4 overflow-x-visible">
          <div className="flex flex-col flex-1 h-full">
            <div className="div flex items-center justify-between">
              <div className="text-lg font-medium ">Edit &apos;{widgetName}&apos; Widget</div>
              <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                <Close />
              </IconButton>
            </div>
            <div className=" mt-2">Edit the configuration of this widget below.</div>
            <div className="flex justify-between mt-2 mb-4">
              <DashboardWidgetConfigurationDrawerDeleteButton />
              <DashboardWidgetConfigurationDrawerDuplicateButton />
            </div>
            <div className="flex flex-col gap-y-2 flex-1 overflow-y-auto overflow-x-visible mb-2">{isConfiguringThisWidget && children}</div>
            <div className="w-full pt-2">
              <Button variant="contained" color="primary" className="w-full" size="large" onClick={applyConfigurationChanges}>
                Apply
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </Portal>
  );
};

export default DashboardWidgetConfigurationDrawer;
