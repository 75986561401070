import { ReportingStatusType } from '../api/profile.contracts';

export type ProfileReportsStatus = {
  status: ReportingStatusType;
  updatedAt: string | undefined;
  nextPossibleUpdate: string | undefined;
};

export const defaultProfileReportsStatusInfo: ProfileReportsStatus = {
  status: ReportingStatusType.WAITING_RESPONSE,
  updatedAt: undefined,
  nextPossibleUpdate: undefined,
};

export type SyncStatus = {
  canClickSync: boolean;
  reason: SyncStatusReason | null;
  isLoading: boolean;
};

export enum SyncStatusReason {
  LOADING = 'LOADING', // sync already in progress
  PLAN_RESTRICTION = 'PLAN_RESTRICTION', //plan restriction, for free
  TOO_EARLY = 'TOO_EARLY', //too early, cant sync too often, for pro
  WAITING_RESPONSE = 'WAITING_RESPONSE', //fetching report status in progress
}

export const EMPTY_REPORT_STATUS: ProfileReportsStatus = {
  status: ReportingStatusType.WAITING_RESPONSE,
  updatedAt: undefined,
  nextPossibleUpdate: undefined,
};

export const EMPTY_SYNC_STATUS: SyncStatus = {
  canClickSync: true,
  reason: null,
  isLoading: true,
};
