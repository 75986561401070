import { ColumnId } from '@/components/grid/columns/columns.enum';
import type { ColumnState } from 'ag-grid-enterprise';

export function generateCreateNegativesPreviewTableColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.CHECKBOX, hide: false, pinned: 'left' },
    { colId: ColumnId.ENTITY_TYPE, hide: false, pinned: 'left' },
    { colId: ColumnId.SEARCH_TERM, hide: false, pinned: 'left' },
    { colId: ColumnId.NEGATIVE_MATCH_TYPE, hide: false, pinned: false },
    { colId: ColumnId.CAMPAIGN_NAME, hide: false, pinned: false },
    { colId: ColumnId.CAMPAIGN_AD_TYPE, hide: false, pinned: false },
    { colId: ColumnId.AD_GROUP, hide: false, pinned: false },
  ];
}
