import LoadingOverlay from '@/components/feedback/LoadingOverlay';
import AlGrid, { DEFAULT_GRID_OPTIONS, GRID_DEFAULT_SORTING_ORDER } from '@/components/grid/AlGrid';
import ButtonCallbackCellRenderer, { IButtonCallbackCellRendererParams } from '@/components/grid/cells/ButtonCallbackCellRenderer';
import ButtonGroupCellRenderer, { IButtonGroupCellRendererParams } from '@/components/grid/cells/ButtonGroupCellRenderer';
import ChipArrayCellRenderer, { IChipArrayCellRendererParams } from '@/components/grid/cells/ChipArrayCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import RowActionButton from '@/components/grid/components/RowActionButton';
import DefaultHeaderRenderer from '@/components/grid/headers/DefaultHeaderRenderer';
import { MuiColorVariant } from '@/config/theme/color.type';
import useFormatting from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { sleep } from '@/lib/api/api-utils';
import { downloadObjectArrayAsCsv, sanitizeFilename } from '@/modules/application/utils';
import { LogPreviewModalDetails } from '@/modules/log-viewing/components/LogPreviewModal';
import { LogGridContext } from '@/modules/log-viewing/components/LogsTable';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { optimizationService } from '@/modules/optimizer/components/optimization/api/optimization-service';
import { BiddingEntity } from '@/modules/optimizer/components/optimization/models/OptimizationModel';
import { OptimizerJobModel } from '@/modules/profiles/types/OptimizerJobModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PageviewIcon from '@mui/icons-material/Pageview';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { GridApi, GridOptions, GridReadyEvent, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';
import { isArray, isEmpty, isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useOptimizationLogFetch from '../../hooks/useOptimizationLogFetch';
import { BidCeilingType } from '../../types/BidCeilingType';
import OptimizationLogsPreviewModal from './OptimizationLogsPreviewModal';

const OptimizationLogsTable = () => {
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { t } = useTranslation();
  const { formatDateStringTimeNoSeconds, formatDateStringTimeNoHours, formatPercent, formatCurrency } = useFormatting();

  const gridApiRef = useRef<GridApi<OptimizerJobModel>>();
  const gridContextRef = useRef<LogGridContext>();

  const { fetchLogOnDemand, getHeaderNames } = useOptimizationLogFetch({});

  const {
    data: rowData,
    refetch: refetchJobs,
    isLoading,
  } = useQuery({
    queryKey: ['profileOptimizerJobs', activeProfile?.id],
    queryFn: async () => {
      const result = await optimizationService.getOptimizerJobs();
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading jobs');
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  useEffect(() => {
    if (!isNil(activeTeam) && !isNil(activeProfile)) {
      refetchJobs();
    }
  }, []);

  const [revertConfirmationDialogOpen, setRevertConfirmationDialogOpen] = useState(false);
  const [revertOptimizerJobData, setRevertOptimizerJobData] = useState<OptimizerJobModel>();
  function onRevertOptimizerJobClicked(profileHistoryRowData: OptimizerJobModel) {
    setRevertOptimizerJobData(profileHistoryRowData);
    setRevertConfirmationDialogOpen(true);
  }

  const handleCancelRevert = async () => {
    setRevertConfirmationDialogOpen(false);
    await sleep(200); // wait for the popover close animation to finish, otherwise name will flash 'undefined'
    setRevertOptimizerJobData(undefined);
  };

  const handleConfirmRevertOptimizerJob = () => {
    try {
      if (revertOptimizerJobData?.id) {
        revertOptimization(revertOptimizerJobData.id);
      }
    } catch (error) {
      console.error(error);

      if (error instanceof Error) {
        toastService.error(error.message);
      } else {
        toastService.error('Unable to revert. Please try again later.');
      }
    } finally {
      setRevertConfirmationDialogOpen(false);
      setRevertOptimizerJobData(undefined);
    }
  };

  const { mutate: revertOptimization, isPending: isLoadingRevertOptimization } = useMutation({
    mutationFn: (jobId: number) => optimizationService.revertOptimizerJob(jobId),

    onSuccess: (res) => {
      if (res.isSuccess) {
        refetchJobs();

        if (isArray(res.payload)) {
          const keywords = res.payload.find((e) => e.bidding_entity === BiddingEntity.KEYWORD);
          const productTargets = res.payload.find((e) => e.bidding_entity === BiddingEntity.PRODUCT_TARGET);
          const placements = res.payload.filter(
            (e) => e.bidding_entity != BiddingEntity.PRODUCT_TARGET && e.bidding_entity != BiddingEntity.KEYWORD,
          );

          const placementsUpdateCount = placements.map((biddingEntity) => biddingEntity.update_count).reduce((a, b) => a + b, 0);

          const totalUpdateCount = res.payload.map((biddingEntity) => biddingEntity.update_count).reduce((a, b) => a + b, 0);
          const totalErrorCount = res.payload.map((biddingEntity) => biddingEntity.error_count).reduce((a, b) => a + b, 0);

          let message = `Reverted ${totalUpdateCount} entities: `;

          if (productTargets && productTargets.update_count > 0) {
            message += ` ${productTargets?.update_count} product ${productTargets.update_count > 1 ? 'targets' : 'target'};`;
          }

          if (keywords && keywords.update_count > 0) {
            message += ` ${keywords?.update_count} keyword ${keywords.update_count > 1 ? 'bids' : 'bid'};`;
          }

          if (placementsUpdateCount > 0) {
            message += ` ${placementsUpdateCount} ${placementsUpdateCount > 1 ? 'placements' : 'placement'};`;
          }

          if (totalErrorCount > 0) {
            message += ` Total Errors: ${totalErrorCount}`;
          }

          toastService.success(message);
        } else {
          toastService.error(`Something went wrong: ${JSON.stringify(res.payload)}`);
        }
      } else {
        toastService.error(`Did not receive a response from server: ${res.message}`);
      }
    },
  });

  const columnDefs: ColDefOrGroup<OptimizerJobModel>[] = [
    {
      colId: ColumnId.REVERT_JOB,
      headerName: 'Revert Job',
      cellRenderer: ButtonCallbackCellRenderer,
      minWidth: 130,
      cellRendererParams: (): IButtonCallbackCellRendererParams<OptimizerJobModel> => {
        return {
          buttonText: 'Revert',
          callback: onRevertOptimizerJobClicked,
        };
      },
    },
    {
      colId: ColumnId.JOB_ID,
      headerName: 'ID',
      field: 'id',
    },
    {
      colId: ColumnId.USERNAME,
      headerName: 'User',
      field: 'userName',
    },
    {
      colId: ColumnId.REVERTED_JOB_ID,
      headerName: 'Reverted ID',
      field: 'revertedJobId',
    },
    {
      colId: ColumnId.CREATED_AT,
      headerName: 'Created At',
      field: 'createdAt',
      filter: 'agDateColumnFilter',
      width: 120,
      valueFormatter: (params: ValueFormatterParams<OptimizerJobModel>) => formatDateStringTimeNoSeconds(params.value),
    },
    {
      colId: ColumnId.APPLIED_ENTITY_COUNT,
      headerName: 'Applied',
      field: 'appliedEntityCount',
    },
    {
      colId: ColumnId.SUCCESS_ENTITY_COUNT,
      headerName: 'Success Entity Count',
      field: 'successEntityCount',
    },
    {
      colId: ColumnId.FAILED_ENTITY_COUNT,
      headerName: 'Failed',
      field: 'failedEntityCount',
    },
    {
      colId: ColumnId.START_DATE,
      headerName: 'Opt. Range Start',
      field: 'startDate',
      filter: 'agDateColumnFilter',
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams<OptimizerJobModel>) =>
        params.data?.isRevertJob ? '' : formatDateStringTimeNoHours(params.value),
    },
    {
      colId: ColumnId.END_DATE,
      headerName: 'Opt. Range End',
      field: 'endDate',
      filter: 'agDateColumnFilter',
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams<OptimizerJobModel>) =>
        params.data?.isRevertJob ? '' : formatDateStringTimeNoHours(params.value),
    },
    {
      colId: ColumnId.TARGET_ACOS,
      headerName: 'Target ACOS',
      field: 'tacos',
      valueFormatter: (params: ValueFormatterParams<OptimizerJobModel>) => (params.data?.isRevertJob ? '' : formatPercent(params.value)),
    },
    {
      colId: ColumnId.SENTIMENT,
      headerName: 'Prioritization',
      field: 'sentiment',
      cellRenderer: ChipArrayCellRenderer,
      cellRendererParams: (params: ICellRendererParams<OptimizerJobModel>): IChipArrayCellRendererParams => {
        if (params.data?.isRevertJob) {
          return {
            chipArrayChips: [],
          };
        }

        let color: MuiColorVariant;

        switch (params.data?.sentiment) {
          case OptimizationPreset.BALANCED:
            color = MuiColorVariant.Success;
            break;
          case OptimizationPreset.INCREASE_SALES:
            color = MuiColorVariant.Primary;
            break;
          case OptimizationPreset.REDUCE_ACOS:
            color = MuiColorVariant.Warning;
            break;
          default:
            color = MuiColorVariant.Success;
        }

        return {
          chipArrayChips: params.data?.sentiment ? [{ color, value: t(`enums.optimization_presets.${params.data?.sentiment}`) }] : [],
        };
      },
    },
    {
      colId: ColumnId.BID_CEILING,
      headerName: 'Bid Ceiling',
      field: 'bidCeiling',

      valueGetter: (params: ValueGetterParams<OptimizerJobModel>) => {
        if (params.data?.isRevertJob) {
          return '';
        }

        if (params.data && params.data.bidCeiling > 0) {
          if (params.data.bidCeilingType == BidCeilingType.SMART) {
            return `Smart ${params.data.bidCeiling}x`;
          } else {
            return formatCurrency(params.data.bidCeiling, { customCurrencyCode: params.context?.activeProfileCurrencyCode });
          }
        } else if (params.data && params.data.bidCeiling == 0 && params.data.bidCeilingType != BidCeilingType.OFF) {
          return 'Smart'; // Legacy
        } else {
          return 'Off';
        }
      },
    },
    {
      colId: ColumnId.BID_FLOOR,
      headerName: 'Bid Floor',
      field: 'bidFloor',

      valueFormatter: (params: ValueFormatterParams<OptimizerJobModel>) => {
        if (params.data?.isRevertJob) {
          return '';
        }

        return params.value > 0 ? formatCurrency(params.value, { customCurrencyCode: params.context?.activeProfileCurrencyCode }) : 'Off';
      },
    },
    {
      colId: ColumnId.ACTIONS,
      headerName: 'Actions',
      width: 255,
      pinned: 'right',
      cellClass: 'border-none outline-none',
      cellRenderer: ButtonGroupCellRenderer,
      cellRendererParams: (params: ICellRendererParams<OptimizerJobModel, unknown, LogGridContext>): IButtonGroupCellRendererParams => {
        const isLoading = params.data && params.context?.idsBeingLoaded.has(params.data.id?.toString() ?? '');

        return {
          buttons: [
            <RowActionButton
              color={'default'}
              key="preview"
              text={'Preview'}
              tooltipText={'Preview the downloadable table'}
              onClick={() => onPreviewClicked(params)}
              icon={<PageviewIcon />}
              isDisabled={false}
              isLoading={false}
              isLoadingText="Loading..."
            ></RowActionButton>,
            <RowActionButton
              key="download"
              text="Download"
              color={'default'}
              isLoadingText="Loading..."
              isDisabled={isLoading}
              tooltipText={'Download detailed table for this event'}
              isLoading={isLoading}
              onClick={() => onDownloadClicked(params)}
              icon={<FileDownloadOutlinedIcon />}
            ></RowActionButton>,
          ],
        };
      },
    },
  ];

  // Action buttons
  const onPreviewClicked = useCallback((params: ICellRendererParams<OptimizerJobModel, unknown, LogGridContext>) => {
    const { id, createdAt } = params.data ?? {};
    const title = `Log: Optimization on ${formatDateStringTimeNoSeconds(createdAt)}`;
    const filename = createFilename(createdAt ?? '', id?.toString() ?? '');

    setPreviewModalDetails({ jobId: id?.toString() ?? '', actionId: '', title, filename });
    setIsPreviewModalOpen(true);
  }, []);

  function setIdLoadingState(id: string, isLoading: boolean) {
    if (!gridApiRef.current || !gridContextRef.current) return;

    if (isLoading) {
      gridContextRef.current.idsBeingLoaded.add(id);
    } else {
      gridContextRef.current.idsBeingLoaded.delete(id);
    }

    gridApiRef.current?.refreshCells({
      force: true,
      columns: [ColumnId.ACTIONS],
    });
  }

  const createFilename = useCallback((createdAt: string, id: string) => {
    return sanitizeFilename(`${createdAt}-${id}-adlabs_optimization_log`);
  }, []);

  const onDownloadClicked = async (params: ICellRendererParams<OptimizerJobModel, unknown, LogGridContext>) => {
    setIdLoadingState(params.data?.id?.toString() ?? '', true);

    const data = await fetchLogOnDemand(params.data?.id);

    if (!data || isEmpty(data)) {
      // User feedback is handled in function
      setIdLoadingState(params.data?.id?.toString() ?? '', false);
      toastService.info('No data to download');
      return;
    }

    try {
      const fileName = createFilename(params.data?.createdAt ?? '', params.data?.id.toString() ?? '');
      downloadObjectArrayAsCsv(data, fileName, getHeaderNames(data));
    } catch (e) {
      console.error(e);
      toastService.error(`Failed to download data: ${e}`);
    }

    setIdLoadingState(params.data?.id?.toString() ?? '', false);
  };

  // Data preview modal
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewModalDetails, setPreviewModalDetails] = useState<LogPreviewModalDetails | undefined>(undefined);

  function onPreviewModalClose() {
    setIsPreviewModalOpen(false);
    setPreviewModalDetails(undefined);
  }

  function onGridReady(params: GridReadyEvent) {
    gridApiRef.current = params.api;
    gridContextRef.current = params.context;
  }

  const customGridOptions: GridOptions<OptimizerJobModel> = useMemo(() => {
    const defaultGridContext: LogGridContext = {
      idsBeingLoaded: new Set(),
    };

    return {
      ...DEFAULT_GRID_OPTIONS,
      getRowId: (params) => params.data.id.toString(),
      context: defaultGridContext,
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 100,
        headerComponent: DefaultHeaderRenderer,
        sortingOrder: GRID_DEFAULT_SORTING_ORDER,
      },
    };
  }, []);

  return (
    <div className="flex flex-col flex-grow">
      <LoadingOverlay isVisible={isLoadingRevertOptimization} message="Sending data to Amazon..." />

      <AlGrid
        colDefs={columnDefs}
        rowData={rowData}
        gridOptions={customGridOptions}
        isLoading={isLoading}
        onGridReadyCallback={onGridReady}
      />

      <Dialog maxWidth="xs" open={revertConfirmationDialogOpen}>
        <DialogTitle>Revert changes</DialogTitle>
        <DialogContent>{`Are you sure you want to revert Job ${revertOptimizerJobData?.id}?`}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelRevert} variant="text">
            Cancel
          </Button>
          <Button onClick={handleConfirmRevertOptimizerJob}>Ok</Button>
        </DialogActions>
      </Dialog>

      <OptimizationLogsPreviewModal isOpen={isPreviewModalOpen} onClose={onPreviewModalClose} details={previewModalDetails} />
    </div>
  );
};

export default OptimizationLogsTable;
