import FilterBarCard from '@/components/cards/FilterBarCard';
import FilterControls from '@/components/filter-builder/FilterControls';
import { SearchBox } from '@/components/filter-builder/SearchBox';
import { TargetingFilterModel, createNegativeTargetingFilters } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import { ContextKey } from '@/types/context-shared';
import { FunctionComponent } from 'react';
import { useNegativeTargetingContext } from '../contexts/NegativeTargetsContext';

const MAX_FILTER_CHIPS = 6;

const NegativeTargetingFilterBar: FunctionComponent = () => {
  const { filters, setFilters, setFilterValue } = useNegativeTargetingContext();

  const NEGATIVE_TARGETS_FILTERS = createNegativeTargetingFilters();

  return (
    <FilterBarCard>
      <SearchBox
        filterKey={FilterKey.TARGETING}
        newFilterModel={() => new TargetingFilterModel()}
        placeholder="Search Neg. Target"
        filters={filters}
        setFilterValue={setFilterValue}
        setFilters={setFilters}
      />

      <FilterControls
        contextKey={ContextKey.NEGATIVE_TARGETS}
        filters={filters}
        setFilters={setFilters}
        availableFilters={NEGATIVE_TARGETS_FILTERS}
        defaultFilterKey={FilterKey.CAMPAIGN_NAME}
        maxFilterChips={MAX_FILTER_CHIPS}
      />
    </FilterBarCard>
  );
};

export default NegativeTargetingFilterBar;
