import { useCallback } from 'react';

const useUtils = () => {
  const extractPlainText = useCallback((html: string): string => {
    const div = document.createElement('div');
    div.innerHTML = html;
    const text = div.textContent || div.innerText || '';
    div.remove();
    return text;
  }, []);

  return {
    extractPlainText,
  };
};

export default useUtils;
