import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { OptimizerJobDTO } from '../api/profile.contracts';
import { BidCeilingType } from '@/modules/optimizer/types/BidCeilingType';
import { BidLimitChangeUnitType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';

interface OptimizerJobArguments {
  id: number;
  revertedJobId: number;
  optimizerVersion: string;
  userId: number;
  userName: string;
  teamId: number;
  campaignCount: number;
  previewCampaignCount: number;
  previewEntityCount: number;
  appliedEntityCount: number;
  successEntityCount: number;
  failedEntityCount: number;
  startDate: string;
  endDate: string;
  compareStartDate: string;
  compareEndDate: string;
  tacos: number;
  sentiment: OptimizationPreset;
  bidCeiling: number;
  bidCeilingType: BidCeilingType;
  bidFloor: number;
  enabledKeywordGroups: string;
  excludeNoImpressions: false;
  state: string;
  createdAt: string;
}

// Setting Class
export class OptimizerJobModel {
  id: number;
  revertedJobId: number;
  optimizerVersion: string;
  userId: number;
  userName: string;
  teamId: number;
  campaignCount: number;
  previewCampaignCount: number;
  previewEntityCount: number;
  appliedEntityCount: number;
  successEntityCount: number;
  failedEntityCount: number;
  startDate: string;
  endDate: string;
  compareStartDate: string;
  compareEndDate: string;
  tacos: number;
  sentiment: OptimizationPreset;
  bidCeiling: number;
  bidCeilingType: BidCeilingType;
  bidFloor: number;
  enabledKeywordGroups: string;
  excludeNoImpressions: false;
  state: string;
  createdAt: string;

  constructor(args: OptimizerJobArguments) {
    this.id = args.id;
    this.revertedJobId = args.revertedJobId;
    this.optimizerVersion = args.optimizerVersion;
    this.userId = args.userId;
    this.userName = args.userName;
    this.teamId = args.teamId;
    this.campaignCount = args.campaignCount;
    this.previewCampaignCount = args.previewCampaignCount;
    this.previewEntityCount = args.previewEntityCount;
    this.appliedEntityCount = args.appliedEntityCount;
    this.successEntityCount = args.successEntityCount;
    this.failedEntityCount = args.failedEntityCount;
    this.startDate = args.startDate;
    this.endDate = args.endDate;
    this.compareStartDate = args.compareStartDate;
    this.compareEndDate = args.compareEndDate;
    this.tacos = args.tacos;
    this.sentiment = args.sentiment;
    this.bidCeiling = args.bidCeiling;
    this.bidCeilingType = args.bidCeilingType;
    this.bidFloor = args.bidFloor;
    this.enabledKeywordGroups = args.enabledKeywordGroups;
    this.excludeNoImpressions = args.excludeNoImpressions;
    this.state = args.state;
    this.createdAt = args.createdAt;
  }

  get isRevertJob() {
    return this.revertedJobId > 0;
  }

  static fromDTO(dto: OptimizerJobDTO): OptimizerJobModel {
    return new OptimizerJobModel({
      id: dto.id,
      revertedJobId: dto.reverted_job_id,
      optimizerVersion: dto.optimizer_version,
      userId: dto.user_id,
      userName: dto.user_name,
      teamId: dto.team_id,
      campaignCount: dto.campaign_count,
      previewCampaignCount: dto.preview_campaign_count,
      previewEntityCount: dto.preview_entity_count,
      appliedEntityCount: dto.applied_entity_count,
      successEntityCount: dto.success_entity_count,
      failedEntityCount: dto.failed_entity_count,
      startDate: dto.start_date,
      endDate: dto.end_date,
      compareStartDate: dto.compare_start_date,
      compareEndDate: dto.compare_end_date,
      tacos: dto.tacos,
      sentiment: dto.sentiment,
      bidCeiling: dto.bid_ceiling,
      bidCeilingType:
        dto.bid_ceiling_type == BidLimitChangeUnitType.TIMES_CPC || dto.bid_ceiling_type == ''
          ? BidCeilingType.SMART
          : dto.bid_ceiling_type == BidLimitChangeUnitType.CURRENCY
            ? BidCeilingType.MANUAL
            : BidCeilingType.OFF,
      bidFloor: dto.bid_floor,
      enabledKeywordGroups: dto.enabled_keyword_groups,
      excludeNoImpressions: dto.exclude_no_impressions,
      state: dto.state,
      createdAt: dto.created_at,
    });
  }
}
