import useFormatting, { CurrencyPosition } from '@/hooks/useFormatting';
import { CurrencyCode } from '@/modules/amazon-constants/types/CurrencyCode';
import { InputAdornment, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { ChangeEvent, FunctionComponent, ReactNode } from 'react';
import AlSelect from './AlSelect';
import { BudgetUpdateType } from '@/modules/amazon-constants/types/BudgetUpdateType';
import { assertUnhandledCase } from '@/modules/application/utils';
import useBudgetLimits from '@/modules/amazon-constants/hooks/useBudgetLimits';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';

interface BudgetEditRowProps {
  title: ReactNode;
  budgetUpdateType: BudgetUpdateType;
  handleBudgetUpdateTypeChange: (event: SelectChangeEvent<BudgetUpdateType>) => void;
  budgetUpdateValue: string;
  handleBudgetUpdateValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isBudgetEditAllowed: boolean;
}

const BudgetEditRow: FunctionComponent<BudgetEditRowProps> = ({
  title,
  budgetUpdateType,
  handleBudgetUpdateTypeChange,
  budgetUpdateValue,
  handleBudgetUpdateValueChange,
  isBudgetEditAllowed,
}) => {
  const { getCurrencySymbol, getCurrencySymbolPosition } = useFormatting();
  const { activeProfile } = useActiveTeamContext();
  const { getBudgetUpdateOptions } = useBudgetLimits();

  const currency = activeProfile?.currencyCode ?? CurrencyCode.USD;
  const currencySymbol = getCurrencySymbol(currency);
  const currencyPosition = getCurrencySymbolPosition(currency);

  function isCurrencyUpdateType(budgetUpdateType: BudgetUpdateType): boolean {
    switch (budgetUpdateType) {
      case BudgetUpdateType.SET_BUDGET_TO_AMOUNT:
      case BudgetUpdateType.INCREASE_BUDGET_BY_AMOUNT:
      case BudgetUpdateType.DECREASE_BUDGET_BY_AMOUNT:
        return true;
      case BudgetUpdateType.DECREASE_BUDGET_BY_PERCENTAGE:
      case BudgetUpdateType.INCREASE_BUDGET_BY_PERCENTAGE:
      case BudgetUpdateType.NO_CHANGE:
        return false;
      default:
        // Make sure all cases are covered
        return assertUnhandledCase(budgetUpdateType);
    }
  }

  function getLabel(budgetUpdateType: BudgetUpdateType): string {
    switch (budgetUpdateType) {
      case BudgetUpdateType.SET_BUDGET_TO_AMOUNT:
        return `New Budget (${currencySymbol})`;
      case BudgetUpdateType.INCREASE_BUDGET_BY_AMOUNT:
      case BudgetUpdateType.DECREASE_BUDGET_BY_AMOUNT:
        return `Budget adjustment (${currencySymbol})`;
      case BudgetUpdateType.DECREASE_BUDGET_BY_PERCENTAGE:
      case BudgetUpdateType.INCREASE_BUDGET_BY_PERCENTAGE:
        return 'Budget adjustment (%) points';
      case BudgetUpdateType.NO_CHANGE:
        // Should not get here because with no change the field should not be visible
        return '';
      default:
        // Make sure all cases are covered
        return assertUnhandledCase(budgetUpdateType);
    }
  }

  function getEndAdornmentSymbol(budgetUpdateType: BudgetUpdateType): string {
    switch (budgetUpdateType) {
      case BudgetUpdateType.SET_BUDGET_TO_AMOUNT:
      case BudgetUpdateType.INCREASE_BUDGET_BY_AMOUNT:
      case BudgetUpdateType.DECREASE_BUDGET_BY_AMOUNT:
        return currencySymbol;
      case BudgetUpdateType.DECREASE_BUDGET_BY_PERCENTAGE:
      case BudgetUpdateType.INCREASE_BUDGET_BY_PERCENTAGE:
        return '%';
      case BudgetUpdateType.NO_CHANGE:
        // Should not get here because with no change the field should not be visible
        return '';
      default:
        // Make sure all cases are covered
        return assertUnhandledCase(budgetUpdateType);
    }
  }

  const numberInputLabel = getLabel(budgetUpdateType);
  const adornmentSymbol = getEndAdornmentSymbol(budgetUpdateType);

  const putAdornmentAtStart = currencyPosition === CurrencyPosition.LEFT && isCurrencyUpdateType(budgetUpdateType);
  const adornmentData = putAdornmentAtStart
    ? { startAdornment: <InputAdornment position="start">{adornmentSymbol}</InputAdornment> }
    : { endAdornment: <InputAdornment position="end">{adornmentSymbol}</InputAdornment> };

  return (
    <div>
      <Typography variant="subtitle2">{title}</Typography>
      <div className="flex gap-x-4">
        <AlSelect
          label={'Update action'}
          value={budgetUpdateType}
          options={getBudgetUpdateOptions(activeProfile?.currencyCode ?? CurrencyCode.USD)}
          onChange={handleBudgetUpdateTypeChange}
          renderOption={(item) => item.label}
          valueExtractor={(item) => item.value}
          disabled={!isBudgetEditAllowed}
        />
        {budgetUpdateType !== BudgetUpdateType.NO_CHANGE && (
          <>
            <TextField
              fullWidth
              label={numberInputLabel}
              value={budgetUpdateValue}
              onChange={handleBudgetUpdateValueChange}
              slotProps={{
                input: {
                  ...adornmentData,
                },

                htmlInput: { pattern: '[0-9]*' },
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BudgetEditRow;
