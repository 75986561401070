import AlSelect from '@/components/form/AlSelect';
import { SelectChangeEvent } from '@mui/material';
import { FunctionComponent } from 'react';
import { EntityType } from '../../types/EntityType';

interface EntityTypeSelectProps {
  value: EntityType | '';
  onChange: (value: EntityType | '') => void;
  helperText?: string;
  disabledEntityTypes?: EntityType[];
}

const EntityTypeSelect: FunctionComponent<EntityTypeSelectProps> = ({ value, onChange, helperText, disabledEntityTypes }) => {
  const handleChange = (event: SelectChangeEvent<EntityType | ''>) => {
    onChange(event.target.value as EntityType | '');
  };

  const options: { label: string; value: EntityType }[] = [
    { label: 'Target', value: EntityType.TARGET },
    { label: 'Campaign', value: EntityType.CAMPAIGN },
    { label: 'Profile', value: EntityType.PROFILE },
    { label: 'Product', value: EntityType.PRODUCT },
    { label: 'Search Term', value: EntityType.SEARCH_TERM },
    { label: 'Placement', value: EntityType.PLACEMENT },
  ];

  return (
    <AlSelect
      label="Entity"
      value={value}
      options={options.filter((option) => !disabledEntityTypes?.includes(option.value))}
      onChange={handleChange}
      renderOption={(v) => v.label}
      valueExtractor={(v) => v.value}
      helperText={helperText}
    />
  );
};

export default EntityTypeSelect;
