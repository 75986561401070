import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

export const DATE_FORMAT_YYYY_MM_DD = 'yyyy-MM-dd';
export const SYNC_DATE_FORMAT = 'dd, MMM d, yyyy HH:mm';
export const TIMELINE_DATE_FORMAT = 'MMM dd';
export const FULL_WEEEK_DAY_FORMAT = 'EEEE';
export const DAY_WITH_MONTH_FORMAT = 'd MMM';
export const YEAR_FORMAT = 'yyyy';
export const DISPLAY_DATE_FORMAT = 'd MMM, yyyy';
export const MONTH_FORMAT = 'MMM';
export const MONTH_YEAR_FORMAT = `MMM yy`;
export const HOURS_MINUTES = 'HH:mm';

export type AlDateRawType = DateTime;

export class AlDate {
  public static adapter = AdapterLuxon;
  private dateTime: DateTime;
  constructor(input?: string | DateTime, format: string = DATE_FORMAT_YYYY_MM_DD) {
    if (input === undefined) {
      this.dateTime = DateTime.now();
    } else if (input === null) {
      this.dateTime = DateTime.invalid('Invalid date time');
      console.error(`Invalid date time`, new Error().stack);
    } else if (input instanceof DateTime) {
      this.dateTime = input;
    } else if (AlDate.isISOFormat(input)) {
      this.dateTime = DateTime.fromISO(input);
    } else {
      this.dateTime = DateTime.fromFormat(input, format);
    }
  }

  static isISOFormat(dateString: string): boolean {
    const dt = DateTime.fromISO(dateString);
    return dt.isValid;
  }

  static fromISO(isoString: string): AlDate | null {
    const dateTime = DateTime.fromISO(isoString);
    return AlDate.fromRaw(dateTime);
  }

  static parse(input: string, format: string = DATE_FORMAT_YYYY_MM_DD): AlDate {
    return new AlDate(input, format);
  }

  static now(): AlDate {
    return new AlDate();
  }

  static parseWithTimezone(input: string, format: string, timezone: string): AlDate {
    const dateTime = DateTime.fromFormat(input, format, { zone: timezone });
    return new AlDate(dateTime);
  }

  static nowWithTimezone(timezone: string): AlDate {
    const dateTime = DateTime.now().setZone(timezone);
    return new AlDate(dateTime);
  }

  toRaw(): DateTime {
    return this.dateTime;
  }

  static fromRaw(dateTime: DateTime | null): AlDate | null {
    return dateTime === null ? null : new AlDate(dateTime);
  }

  static getRawType(): typeof DateTime {
    return DateTime;
  }

  static daysInMonth(year: number, month: number): number | undefined {
    return DateTime.local(year, month).daysInMonth;
  }

  isValid(): boolean {
    return this.dateTime.isValid;
  }

  toISO(): string | null {
    return this.dateTime.toISO();
  }

  diff(other: AlDate, unit: 'months' | 'days' | 'hours' | 'minutes' | 'seconds'): number {
    return Math.abs(this.dateTime.diff(other.dateTime, unit).as(unit));
  }

  minus(duration: { years?: number; months?: number; weeks?: number; days?: number; hours?: number; minutes?: number }): AlDate {
    const newDateTime = this.dateTime.minus(duration);
    return new AlDate(newDateTime);
  }

  subtractDays(days: number): AlDate {
    const newDateTime = this.dateTime.minus({ days });
    return new AlDate(newDateTime);
  }

  plus(duration: { days?: number; hours?: number; minutes?: number }): AlDate {
    const newDateTime = this.dateTime.plus(duration);
    return new AlDate(newDateTime);
  }

  startOf(unit: 'day' | 'month' | 'year' | 'week'): AlDate {
    let newDateTime;

    // Extra logic for week to start from Sunday (like in date picker), not Monday
    if (unit === 'week') {
      // Luxon: Monday=1, …, Sunday=7.
      // To get Sunday, subtract (weekday mod 7) days.
      const daysToSubtract = this.dateTime.weekday % 7;
      newDateTime = this.dateTime.minus({ days: daysToSubtract }).startOf('day');
    } else {
      newDateTime = this.dateTime.startOf(unit);
    }
    return new AlDate(newDateTime);
  }

  endOf(unit: 'day' | 'month' | 'year' | 'week'): AlDate {
    let newDateTime;

    // Extra logic for week to start from Sunday (like in date picker), not Monday
    if (unit === 'week') {
      // First get the Sunday that marks the start of the week.
      const daysToSubtract = this.dateTime.weekday % 7;
      const weekStart = this.dateTime.minus({ days: daysToSubtract }).startOf('day');
      // The week ends on Saturday (6 days after Sunday).
      newDateTime = weekStart.plus({ days: 6 }).endOf('day');
    } else {
      newDateTime = this.dateTime.endOf(unit);
    }
    return new AlDate(newDateTime);
  }

  toFormat(format: string): string {
    return this.dateTime.toFormat(format);
  }

  toDefaultFormat(): string {
    return this.toFormat(DATE_FORMAT_YYYY_MM_DD);
  }

  get day(): number {
    return this.dateTime.day;
  }

  get month(): number {
    return this.dateTime.month;
  }

  get year(): number {
    return this.dateTime.year;
  }

  get hour(): number {
    return this.dateTime.hour;
  }

  get minute(): number {
    return this.dateTime.minute;
  }

  get second(): number {
    return this.dateTime.second;
  }

  get millisecond(): number {
    return this.dateTime.millisecond;
  }

  get weekday(): number {
    return this.dateTime.weekday;
  }

  get weekdayShort(): string {
    return this.dateTime.toFormat('ccc');
  }

  isAfter(other: AlDate): boolean {
    return this.dateTime > other.dateTime;
  }

  isBefore(other: AlDate): boolean {
    return this.dateTime < other.dateTime;
  }

  isSame(other: AlDate): boolean {
    return this.dateTime.equals(other.dateTime);
  }

  isSameYear(other: AlDate): boolean {
    return this.year === other.year;
  }

  isSameDate(other: AlDate): boolean {
    return this.year === other.year && this.month === other.month && this.day === other.day;
  }

  isFirstDayOfMonth(): boolean {
    return this.dateTime.day === 1;
  }

  isFirstDayOfYear(): boolean {
    return this.dateTime.ordinal === 1;
  }

  setHour(hour: number): AlDate {
    this.dateTime = this.dateTime.set({ hour });
    return this;
  }

  setMinute(minute: number): AlDate {
    this.dateTime = this.dateTime.set({ minute });
    return this;
  }

  setSecond(second: number): AlDate {
    this.dateTime = this.dateTime.set({ second });
    return this;
  }

  setMillisecond(millisecond: number): AlDate {
    this.dateTime = this.dateTime.set({ millisecond });
    return this;
  }

  hasSame(other: AlDate, unit: 'year'): boolean {
    switch (unit) {
      case 'year':
        return this.dateTime.year === other.dateTime.year;
      default:
        throw new Error(`Unsupported unit: ${unit}`);
    }
  }
}
