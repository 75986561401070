import * as Sentry from '@sentry/react';
import { QueryObserverResult, RefetchOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import Gleap from 'gleap';
import { isNil } from 'lodash-es';
import { createContext, FunctionComponent, MutableRefObject, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { userService } from '../api/users/users.service';
import { UserModel } from '../types/UserModel';

const UPDATE_USER_REFETCH_INTERVAL = 10 * 60 * 1000;
interface UserContextProps {
  user: UserModel | undefined;
  setUser: (user: UserModel | undefined) => void;
  refetchUser: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<UserModel | undefined, Error>>;
  isLoading: boolean;
  isAdminModeActive: boolean;
  toggleAdminMode: () => void;
  firebaseId: string | undefined;
  hasSellerProfiles: boolean;
  hasVendorProfiles: boolean;
  isProfileSeller: (profileId: string) => boolean;
  isProfileVendor: (profileId: string) => boolean;
  updateGleapIdentity: () => void;
  userHasAtLeastOneProLicense: boolean;
  isSellingPartnerConnected: (profileId: string) => boolean;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps extends PropsWithChildren {
  initialUserRef: MutableRefObject<UserModel | undefined>;
}

export const UserProvider: FunctionComponent<UserProviderProps> = ({ children, initialUserRef }) => {
  const queryClient = useQueryClient();
  const [isAdminModeActive, setIsAdminModeActive] = useState<boolean>(false);
  const [isAutoUpdateUserEnabled, setIsAutoUpdateUserEnabled] = useState(false);
  const [userHasAtLeastOneProLicense, setUserHasAtLeastOneProLicense] = useState<boolean>(false);

  const USER_QUERY_KEY = ['user']; // we do not need unique key as one user is logged in at the same time, this provider is destroyed while loading in App.tsx
  const cachedUserData = queryClient.getQueryData<UserModel>(USER_QUERY_KEY);
  const {
    data: user,
    refetch: refetchUser,
    isFetching: isLoading,
  } = useQuery({
    queryKey: USER_QUERY_KEY,
    queryFn: async () => {
      const userId = (cachedUserData?.id ?? initialUserRef.current?.id) as number;

      if (userId) {
        const userResponse = await userService.getUserById(userId);
        if (userResponse.isSuccess) {
          return userResponse.payload;
        }
      } else {
        Sentry.captureMessage(`User ID is undefined`, 'info');
      }

      return undefined;
    },
    initialData: initialUserRef.current,
    enabled: isAutoUpdateUserEnabled,
    refetchInterval: UPDATE_USER_REFETCH_INTERVAL,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAutoUpdateUserEnabled(true); // Enable the query after the initial delay
    }, UPDATE_USER_REFETCH_INTERVAL);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  function setUser(user: UserModel | undefined) {
    if (!isNil(user)) {
      queryClient.setQueryData<UserModel>(USER_QUERY_KEY, (oldUser) => {
        if (!isNil(user)) {
          return user;
        }
        return oldUser;
      });
    }
  }

  useEffect(() => {
    if (!isNil(user)) {
      setGleapIdentity(user);

      // Check if user has at least one pro license
      const hasProLicense = user.teams.some((team) => team.hasProPlan);
      setUserHasAtLeastOneProLicense(hasProLicense);
    }
  }, [user]);

  async function setGleapIdentity(user: UserModel | undefined, foreceUpdate = false) {
    try {
      if (user && user.hash) {
        // Create a map of teams to send to gleap
        const teamsData = user.teams.reduce<{ [key: string]: string }>((acc, team, index) => {
          acc[`team${index + 1}name`] = team.name;
          acc[`team${index + 1}plan`] = team.adlabsPlan;
          return acc;
        }, {});

        // fetch user data from the server
        const userGleapData = await userService.getUserGleapData(user.id, foreceUpdate);
        const gleapDataObject = userGleapData.isSuccess && userGleapData.httpResponseCode !== 204 ? userGleapData.payload : {};

        Gleap.identify(
          user.firebaseId,
          {
            email: user.email,
            name: user.name,
            customData: {
              userFirebaseId: user.firebaseId,
              ...gleapDataObject,
              ...teamsData,
            },
          },
          user.hash,
        );
      } else {
        Gleap.clearIdentity();
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          user,
        },
      });
    }
  }

  function updateGleapIdentity() {
    if (user) {
      setGleapIdentity(user, true);
    }
  }

  function toggleAdminMode() {
    setIsAdminModeActive(!isAdminModeActive);
  }

  const hasSellerProfiles = useMemo(() => {
    return user ? user?.teams.some((team) => team.profiles.some((profile) => profile.isSeller)) : false;
  }, [user?.teams]);

  const hasVendorProfiles = useMemo(() => {
    return user ? user?.teams.some((team) => team.profiles.some((profile) => profile.isVendor)) : false;
  }, [user?.teams]);

  function isProfileSeller(profileId: string) {
    return user?.teams.some((team) => team.profiles.some((profile) => profile.id === profileId && profile.isSeller)) ?? false;
  }
  function isProfileVendor(profileId: string) {
    return user?.teams.some((team) => team.profiles.some((profile) => profile.id === profileId && profile.isVendor)) ?? false;
  }

  function isSellingPartnerConnected(profileId: string) {
    return (
      user?.teams.some((team) => team.profiles.some((profile) => profile.id === profileId && (profile.isSeller || profile.isVendor))) ?? false
    );
  }
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        refetchUser,
        isLoading,
        firebaseId: user?.firebaseId,
        isAdminModeActive,
        toggleAdminMode,
        hasSellerProfiles,
        hasVendorProfiles,
        isProfileSeller,
        isProfileVendor,
        updateGleapIdentity,
        userHasAtLeastOneProLicense,
        isSellingPartnerConnected,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
// eslint-disable-next-line react-refresh/only-export-components
export function useUserContext(): UserContextProps {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }

  return context;
}
