import MetricTimelineChart from '@/components/chart/MetricTimelineChart';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import { MetricSelectionColorIndex } from '@/components/metrics/MetricsConfig';
import useCurrency from '@/modules/currency/hooks/useCurrency';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { CurrencyCode } from '@/modules/amazon-constants/types/CurrencyCode';
import { FunctionComponent, useMemo } from 'react';
import { ProfileWithMetricsModel } from '../models/ProfileWithMetricsModel';
import { vendorFieldToSellerFieldMap } from '@/components/metrics/types/MetricField';

interface ProfilesStatsTimelineChartProps {
  profilesWithMetrics: ProfileWithMetricsModel[] | undefined;
  visibleMetrics: MetricSelectionColorIndex[];
  selectedCurrency: CurrencyCode;
}

function getVendorTimeline(timeline: TimelineModel): TimelineModel {
  timeline.yAxisData.forEach(({ key, values }) => {
    const sellerKey = vendorFieldToSellerFieldMap[key];
    if (sellerKey) {
      const sellerEntry = timeline.yAxisData.find(({ key }) => key === sellerKey);
      if (sellerEntry) {
        sellerEntry.values = values;
      }
    }
  });

  return timeline;
}

const ProfilesStatsTimelineChart: FunctionComponent<ProfilesStatsTimelineChartProps> = ({
  visibleMetrics,
  profilesWithMetrics,
  selectedCurrency,
}) => {
  const { conversionRatesModel } = useCurrency();
  const { getCalculatedMetricAggDataForArrays } = useColDefsFunctions();

  const aggregatedTimeline = useMemo(() => {
    if (!profilesWithMetrics || !conversionRatesModel) return undefined;

    const timelinesWithCurrencies = profilesWithMetrics.map(({ timeline, currencyCode, isVendor }) => ({
      timeline: isVendor ? getVendorTimeline(timeline) : timeline,
      currency: currencyCode,
    }));

    return TimelineModel.createAggregatedTimeline(
      timelinesWithCurrencies,
      selectedCurrency,
      conversionRatesModel,
      getCalculatedMetricAggDataForArrays,
    );
  }, [profilesWithMetrics, selectedCurrency, conversionRatesModel]);

  return (
    <MetricTimelineChart
      visibleMetrics={visibleMetrics}
      timelineData={aggregatedTimeline}
      isLoading={!profilesWithMetrics || !conversionRatesModel}
      error={null}
      isError={false}
      selectedCurrency={selectedCurrency}
    />
  );
};

export default ProfilesStatsTimelineChart;
