import { Typography } from '@mui/material';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent } from 'react';

const MultiRowCellRenderer: FunctionComponent<ICellRendererParams> = ({ value }) => {
  if (isNil(value)) return null;

  const [mainValue, ...secondaryValues] = value.split('\n');

  return (
    <div>
      <Typography variant="body2">{mainValue}</Typography>
      {secondaryValues.map((secondary: string, index: number) => (
        <Typography key={index} variant="body2" className="text-slate-400 dark:text-slate-400 text-xs">
          {secondary}
        </Typography>
      ))}
    </div>
  );
};

export default MultiRowCellRenderer;
