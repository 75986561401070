import { AlMultiSelectOptionModel } from '@/components/filter-builder/models/AlMultiSelectOptionModel';
import { AlMultiSelect } from '@/components/form/AlMultiSelect';
import { useConfirmationModal } from '@/components/modals/confirmation-modal/useConfirmationModal';
import { useNavigationConfirmationModal } from '@/components/modals/confirmation-modal/useNavigationConfirmationModal';
import { sleep } from '@/lib/api/api-utils';
import { userService, useUserContext } from '@/modules/users';
import { SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY } from '@/modules/users/api/users/users.contracts';
import { toastService } from '@/services/toast.service';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Button, DialogContentText, IconButton, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import { SellingPartnerGetParamsModel } from '../types/SellingPartnerGetParamsModel';

interface VendorProfileSelectorDialogProps {
  open: boolean;
  onClose: () => void;
  getParams: SellingPartnerGetParamsModel;
}

export function VendorProfileSelectorDialog({ onClose, open, getParams }: VendorProfileSelectorDialogProps) {
  const { refetchUser } = useUserContext();
  const { activeTeam } = useActiveTeamContext();
  const theme = useTheme();

  const { spapiOauthCode: code, state, sellingPartnerId } = getParams;

  const market = localStorage.getItem(SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY);
  const profiles = activeTeam?.profiles.filter((profile) => profile.countryCode === market && !profile.isSeller);
  const options = profiles?.map((profile) => new AlMultiSelectOptionModel(profile.name, profile.id));
  const [selectedProfileIds, setSelectedProfileIds] = useState<string[]>([]);

  const onAuthVendorCentralClicked = () => {
    if (market && activeTeam && code && state && sellingPartnerId) {
      authorizeVendorCentral({
        teamId: activeTeam.id,
        code,
        state,
        sellingPartnerId,
        profileIds: selectedProfileIds,
      });
    }
  };

  const handleClose = () => {
    onClose();
  };

  const { mutate: authorizeVendorCentral, isPending } = useMutation({
    mutationFn: ({
      teamId,
      code,
      state,
      sellingPartnerId,
      profileIds,
    }: {
      teamId: number;
      code: string;
      state: string;
      sellingPartnerId: string;
      profileIds: string[];
    }) => userService.authorizeVendorCentral(teamId, sellingPartnerId, profileIds, { code, state }),

    onSuccess: async (res) => {
      if (res.isSuccess) {
        toastService.success(`Amazon seller central authorization successful`);
      } else {
        toastService.error(`Amazon seller central authorization failed: ${res.message}`);
      }

      setBlock(false);

      await sleep(100);
      await refetchUser();

      onClose();
      localStorage.removeItem(SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY);
    },
  });

  const { ModalComponent: CancelMappingConfirmationModal, handleOpenModal: openCancelMappingConfirmationModal } = useConfirmationModal({
    questionText: 'Are you sure you want to cancel mapping vendor to ad(s) profile(s)?',
    onConfirm: handleClose,
    questionTitle: 'Cancel Mapping',
    confirmButtonText: 'Leave',
    cancelButtonText: 'Stay',
  });

  const { ModalComponent: BlockNavigationModal, setBlock } = useNavigationConfirmationModal({
    questionText: 'Are you sure you want to cancel mapping vendor to ad(s) profile(s)?',
  });

  useEffect(() => {
    if (open) {
      setBlock(true);
    } else {
      setBlock(false);
    }
  }, [open]);

  return (
    <>
      <Dialog onClose={() => openCancelMappingConfirmationModal()} open={open} maxWidth={'md'}>
        <DialogTitle>
          <div className="flex flex-row">
            Map Ad Profiles to Vendor account
            <div className="flex flex-grow"></div>
            <IconButton edge="start" color="inherit" onClick={() => openCancelMappingConfirmationModal()} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="max-w-2xl">
            {profiles && (
              <AlMultiSelect
                options={options}
                selectedOptionIds={selectedProfileIds}
                setSelectedOptionIds={setSelectedProfileIds}
                label="Profiles"
                placeholderText="Select Profiles"
              />
            )}

            <DialogContentText className="flex items-center font-small">
              <InfoIcon fontSize="small" style={{ marginRight: '6px', color: theme.palette.info.light }} />
              To correct inaccurate account mapping, you have the option to reauthorize
            </DialogContentText>

            <Button loading={isPending} variant="contained" className="mt-4" onClick={onAuthVendorCentralClicked}>
              Select profiles
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {CancelMappingConfirmationModal}
      {BlockNavigationModal}
    </>
  );
}
