import { ColumnId } from '@/components/grid/columns/columns.enum';
import type { ColumnState } from 'ag-grid-enterprise';

export function generateProductsTableColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.CHECKBOX, hide: false, pinned: 'left' },
    { colId: ColumnId.ID, hide: true, pinned: 'left' },
    { colId: ColumnId.TITLE, hide: false, width: 440, pinned: 'left' },
    { colId: ColumnId.ASIN, hide: false, width: 90 },
    { colId: ColumnId.SKU, hide: false, width: 90 },
    { colId: ColumnId.AVAILABILITY, hide: false, width: 110 },
    { colId: ColumnId.PRICE_TO_PAY, hide: false, width: 90 },
    { colId: ColumnId.BEST_SELLER_RANK, hide: false, width: 64 },
    { colId: ColumnId.RANK_CHANGE, hide: false },
    { colId: ColumnId.CATEGORY, hide: false, width: 175 },
    { colId: ColumnId.BRAND, hide: false, width: 110 },
    { colId: ColumnId.IMPRESSIONS, hide: false },
    { colId: ColumnId.CLICKS, hide: false },
    { colId: ColumnId.ORDERS, hide: false },
    { colId: ColumnId.CTR, hide: false },
    { colId: ColumnId.CVR, hide: false },
    { colId: ColumnId.CPC, hide: false },
    { colId: ColumnId.SPEND, hide: false },
    { colId: ColumnId.SALES, hide: false },
    { colId: ColumnId.SALES_PREVIOUS_DAYS, hide: false },
    { colId: ColumnId.SPEND_PREVIOUS_DAYS, hide: false },
    { colId: ColumnId.ACOS, hide: false },
    { colId: ColumnId.ACTC, hide: true },
    { colId: ColumnId.ROAS, hide: false },
    { colId: ColumnId.RPC, hide: false },
    { colId: ColumnId.CPA, hide: false },
    { colId: ColumnId.AOV, hide: false },
    { colId: ColumnId.CPM, hide: false },
    { colId: ColumnId.SAME_SKU_ORDERS, hide: false },
    { colId: ColumnId.SAME_SKU_SALES, hide: false },
    { colId: ColumnId.OTHER_SKU_SALES, hide: false },

    // Seller metrics
    { colId: ColumnId.ACOTS, hide: true, pinned: false },
    { colId: ColumnId.ASP, hide: true, pinned: false },
    { colId: ColumnId.ORGANIC_SALES, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_UNITS, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_SALES, hide: true, pinned: false },
    { colId: ColumnId.AD_SALES_OF_TOTAL, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_ROAS, hide: true, pinned: false },
    { colId: ColumnId.UNIT_VIEW, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_VIEWS, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_CVR, hide: true, pinned: false },
    { colId: ColumnId.ORGANIC_TRAFFIC, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_CPA, hide: true, pinned: false },
    { colId: ColumnId.UPS, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_CLICKS, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_ORDERS, hide: true, pinned: false },
    { colId: ColumnId.TOTAL_AOV, hide: true, pinned: false },
    { colId: ColumnId.PARENT_ASIN, hide: true, pinned: false },

    // Vendor metrics
    { colId: ColumnId.VENDOR_ACOTS, hide: true, pinned: false },
    { colId: ColumnId.VENDOR_ASP, hide: true, pinned: false },
    { colId: ColumnId.VENDOR_ORGANIC_SALES, hide: true, pinned: false },
    { colId: ColumnId.VENDOR_TOTAL_UNITS, hide: true, pinned: false },
    { colId: ColumnId.VENDOR_TOTAL_SALES, hide: true, pinned: false },
    { colId: ColumnId.VENDOR_AD_SALES_OF_TOTAL, hide: true, pinned: false },
    { colId: ColumnId.VENDOR_TOTAL_ROAS, hide: true, pinned: false },
    { colId: ColumnId.VENDOR_UNIT_VIEW, hide: true, pinned: false },
    { colId: ColumnId.VENDOR_TOTAL_VIEWS, hide: true, pinned: false },
    { colId: ColumnId.CUSTOMER_RETURNS, hide: true, pinned: false },
    { colId: ColumnId.SHIPPED_COGS, hide: false, pinned: false },
    { colId: ColumnId.SHIPPED_REVENUE, hide: true, pinned: false },
    { colId: ColumnId.SHIPPED_UNITS, hide: false, pinned: false },
    { colId: ColumnId.RETURN_RATE, hide: false, pinned: false },
  ];
}
