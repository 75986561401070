import { filtersService } from '@/components/filter-builder/api/filters-service';
import useFilters from '@/components/filter-builder/hooks/useFilters';
import { getDefaultCampaignGroupsFilters } from '@/components/filter-builder/models/AlFilterModel';
import { GridToggles } from '@/components/grid/types';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import {
  ContextKey,
  ContextWithFilters,
  ContextWithGridToggles,
  DEFAULT_GRID_TOGGLES,
  FiltersPropsWithChildren,
  defaultFiltersContext,
  defaultTableTogglesContext,
} from '@/types/context-shared';
import type { GridApi } from 'ag-grid-enterprise';
import { isEqual } from 'lodash-es';
import { Dispatch, FunctionComponent, MutableRefObject, SetStateAction, createContext, useContext, useEffect, useRef, useState } from 'react';

interface CampaignGroupsContext extends ContextWithFilters, ContextWithGridToggles {
  selectedCampaignGroups: CampaignGroupModel[];
  setSelectedCampaignGroups: Dispatch<SetStateAction<CampaignGroupModel[]>>;
  totalCampaignGroupsCount: number;
  gridApiRef: MutableRefObject<GridApi<CampaignGroupModel> | undefined>;
}

const CampaignGroupsContext = createContext<CampaignGroupsContext>({
  ...defaultFiltersContext,
  ...defaultTableTogglesContext,
  selectedCampaignGroups: [],
  setSelectedCampaignGroups: () => {},
  totalCampaignGroupsCount: 0,
  gridApiRef: { current: undefined },
});

interface CampaignGroupsProviderProps extends FiltersPropsWithChildren {
  selectedCampaignGroups: CampaignGroupModel[];
  setSelectedCampaignGroups: Dispatch<SetStateAction<CampaignGroupModel[]>>;
  totalCampaignGroupsCount: number;
}

export const CampaignGroupsProvider: FunctionComponent<CampaignGroupsProviderProps> = ({
  filters,
  setFilters,
  selectedCampaignGroups,
  setSelectedCampaignGroups,
  totalCampaignGroupsCount,
  children,
}) => {
  const { activeProfileIdChange, activeProfile } = useActiveTeamContext();
  const { fetchCache } = useAlFetchCache();

  // TODO: add sorting toggle button
  const [gridToggles, setGridToggles] = useState<GridToggles>(DEFAULT_GRID_TOGGLES);
  const [isMounted, setIsMounted] = useState(false);
  const { setFilterValue, setFilterValues } = useFilters({ filters, setFilters });

  useEffect(() => {
    if (!isMounted) return;
    if (!activeProfile) return;

    filtersService.saveProfileFilters(ContextKey.CAMPAIGN_GROUPS, activeProfile.id, filters);
  }, [filters]);

  useEffect(() => {
    if (activeProfileIdChange && activeProfileIdChange.hasChanged && activeProfile?.id) {
      const profileFilters = filtersService.loadProfileFilters(
        ContextKey.CAMPAIGN_GROUPS,
        activeProfile?.id,
        getDefaultCampaignGroupsFilters(),
        fetchCache,
      );

      if (!isEqual(profileFilters, filters)) {
        setFilters(profileFilters);
      }
    }
  }, [activeProfileIdChange]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // GRID
  const gridApiRef = useRef<GridApi<CampaignGroupModel>>();

  return (
    <CampaignGroupsContext.Provider
      value={{
        filters,
        setFilters,
        setFilterValue,
        setFilterValues,
        selectedCampaignGroups,
        setSelectedCampaignGroups,
        totalCampaignGroupsCount,
        gridApiRef,
        gridToggles,
        setGridToggles,
      }}
    >
      {children}
    </CampaignGroupsContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useCampaignGroupsContext = (): CampaignGroupsContext => {
  const context = useContext(CampaignGroupsContext);
  if (!context) {
    throw new Error('useCampaignGroupsContext must be used within a CampaignGroupsProvider');
  }
  return context;
};
