import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Divider, Menu, MenuItem, ToggleButton, Tooltip, Typography } from '@mui/material';
import type { GridApi } from 'ag-grid-enterprise';
import { MutableRefObject, useState, MouseEvent as ReactMouseEvent } from 'react';

interface ExportGridButtonProps<T> {
  gridApiRef: MutableRefObject<GridApi<T> | null | undefined>;
}

const ExportGridButton = <T,>({ gridApiRef }: ExportGridButtonProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: ReactMouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportData = (allColumns: boolean, onlyVisibleRows: boolean) => {
    if (!gridApiRef.current) return;

    const gridApi = gridApiRef.current;

    gridApi.exportDataAsExcel({
      allColumns,
      onlySelected: false,
      shouldRowBeSkipped: (params) => {
        if (onlyVisibleRows) {
          return !params.node.displayed;
        }
        return false;
      },
    });

    handleClose();
  };

  return (
    <div>
      <Tooltip title={'Export Table'} placement="left">
        <ToggleButton value={''} onClick={handleClick} className="pt-[7px] pb-[8px] bg-white border-slate-300">
          <FileDownloadOutlinedIcon sx={{ fontSize: 15 }} />
        </ToggleButton>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {/* Explanation Text */}
        <MenuItem disabled className="opacity-100">
          <Typography variant="caption" color="textPrimary" className=" max-w-96 text-gray-600  text-wrap flex items-start">
            Export data to an Excel file. You can choose to export all columns or only the ones visible. You can also export all rows or only
            the ones visible (collapsed rows are not exported).
          </Typography>
        </MenuItem>
        <Divider className="my-2" />

        {/* Export Options */}
        <MenuItem onClick={() => exportData(true, false)}>All Columns, All Rows</MenuItem>
        <MenuItem onClick={() => exportData(true, true)}>All Columns, Visible Rows</MenuItem>
        <MenuItem onClick={() => exportData(false, false)}>Visible Columns, All Rows</MenuItem>
        <MenuItem onClick={() => exportData(false, true)}>Visible Columns, Visible Rows</MenuItem>
      </Menu>
    </div>
  );
};

export default ExportGridButton;
