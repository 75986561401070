import { ColumnId, SELLER_VENDOR_COLUMNS } from '@/components/grid/columns/columns.enum';
import { SentimentDirection } from '@/types/SentimentDirection';
import { isNil } from 'lodash-es';
import { FilterKey } from '../filter-builder/types/FilterKey';
import { calculateMetricDataHelper } from '../grid/helpers';
import { CommonMetricField, MetricField, ProfileStatsSellerMetricField, SellerMetricField, VendorMetricField } from './types/MetricField';

export const METRIC_COLORS: string[] = ['#399fcc', '#2EC598', '#586182', '#E97B20'];
export const MAX_METRICS_CAN_SELECT = METRIC_COLORS.length;

export interface MetricColor {
  key: MetricField;
  color: string;
}

export enum UnitType {
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
  NONE = 'NONE',
}

interface MetricConfiguration {
  key: MetricField;
  title: string;
  sentimentDirection: SentimentDirection;
  chartSeriesType: 'bar' | 'line';
  colId: ColumnId;
  unitType: UnitType;
  filterKey: FilterKey;
  aggFunc: ((aggData: Record<string, number[]>) => number[]) | undefined;
  aggDependencies?: MetricField[];
}

export function getConfigForMetric(metricKey: MetricField) {
  return METRICS[metricKey];
}

export function getSentimentByMetric(metricKey: MetricField) {
  return METRICS[metricKey].sentimentDirection;
}

export function getMetricConfigByColId(colId: ColumnId) {
  return Object.values(METRICS).find((metric) => metric.colId === colId);
}

export function getMetricColumns() {
  return Object.values(METRICS).map((metric) => metric.colId);
}

export function getMetricConfigByFilterKey(filterKey: FilterKey) {
  return Object.values(METRICS).find((metric) => metric.filterKey === filterKey);
}

export function getSellerMetricColIds() {
  return Object.values(SELLER_METRICS).map((metric) => metric.colId);
}

export function getVendorMetricColIds() {
  return Object.values(VENDOR_METRICS).map((metric) => metric.colId);
}

export function getSellerVendorSharedMetricColIds() {
  return SELLER_VENDOR_COLUMNS;
}

export function isMetricColumn(colId: ColumnId) {
  return Object.values(METRICS).some((metric) => metric.colId === colId);
}

export function isCalculatedMetric(metricField: MetricField) {
  const metric = METRICS[metricField];
  return !isNil(metric.aggFunc);
}

export function getMetricAggDependencies(metricField: MetricField): MetricField[] {
  const metric = METRICS[metricField];
  return metric.aggDependencies || [];
}

export const COMMON_METRICS: { [K in CommonMetricField]: MetricConfiguration } = {
  [CommonMetricField.IMPRESSIONS]: {
    key: CommonMetricField.IMPRESSIONS,
    filterKey: FilterKey.IMPRESSIONS,
    title: 'Impressions',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.IMPRESSIONS,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [CommonMetricField.CLICKS]: {
    key: CommonMetricField.CLICKS,
    filterKey: FilterKey.CLICKS,
    title: 'Clicks',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.CLICKS,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [CommonMetricField.ORDERS]: {
    key: CommonMetricField.ORDERS,
    filterKey: FilterKey.ORDERS,
    title: 'Orders',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.ORDERS,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [CommonMetricField.UNITS]: {
    key: CommonMetricField.UNITS,
    filterKey: FilterKey.UNITS,
    title: 'Units',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.UNITS,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [CommonMetricField.CTR]: {
    key: CommonMetricField.CTR,
    filterKey: FilterKey.CTR,
    title: 'CTR',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.CTR,
    unitType: UnitType.PERCENTAGE,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, CommonMetricField.CLICKS, CommonMetricField.IMPRESSIONS),
    aggDependencies: [CommonMetricField.CLICKS, CommonMetricField.IMPRESSIONS],
  },
  [CommonMetricField.CVR]: {
    key: CommonMetricField.CVR,
    filterKey: FilterKey.CVR,
    title: 'CVR',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.CVR,
    unitType: UnitType.PERCENTAGE,
    aggFunc: (aggData: Record<string, number[]>) => calculateMetricDataHelper(aggData, CommonMetricField.ORDERS, CommonMetricField.CLICKS),
    aggDependencies: [CommonMetricField.ORDERS, CommonMetricField.CLICKS],
  },
  [CommonMetricField.CPC]: {
    key: CommonMetricField.CPC,
    filterKey: FilterKey.CPC,
    title: 'CPC',
    sentimentDirection: SentimentDirection.NEUTRAL,
    chartSeriesType: 'line',
    colId: ColumnId.CPC,
    unitType: UnitType.CURRENCY,
    aggFunc: (aggData: Record<string, number[]>) => calculateMetricDataHelper(aggData, CommonMetricField.SPEND, CommonMetricField.CLICKS),
    aggDependencies: [CommonMetricField.SPEND, CommonMetricField.CLICKS],
  },
  [CommonMetricField.SPEND]: {
    key: CommonMetricField.SPEND,
    filterKey: FilterKey.SPEND,
    title: 'Spend',
    sentimentDirection: SentimentDirection.NEUTRAL,
    chartSeriesType: 'bar',
    colId: ColumnId.SPEND,
    unitType: UnitType.CURRENCY,
    aggFunc: undefined,
  },
  [CommonMetricField.SALES]: {
    key: CommonMetricField.SALES,
    filterKey: FilterKey.SALES,
    title: 'Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.SALES,
    unitType: UnitType.CURRENCY,
    aggFunc: undefined,
  },
  [CommonMetricField.SAME_SKU_ORDERS]: {
    key: CommonMetricField.SAME_SKU_ORDERS,
    filterKey: FilterKey.SAME_SKU_ORDERS,
    title: 'sSKU Orders',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.SAME_SKU_ORDERS,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [CommonMetricField.SAME_SKU_SALES]: {
    key: CommonMetricField.SAME_SKU_SALES,
    filterKey: FilterKey.SAME_SKU_SALES,
    title: 'sSKU Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.SAME_SKU_SALES,
    unitType: UnitType.CURRENCY,
    aggFunc: undefined,
  },
  [CommonMetricField.OTHER_SKU_SALES]: {
    key: CommonMetricField.OTHER_SKU_SALES,
    filterKey: FilterKey.OTHER_SKU_SALES,
    title: 'oSKU Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.OTHER_SKU_SALES,
    unitType: UnitType.CURRENCY,
    aggFunc: undefined,
  },
  [CommonMetricField.ACOS]: {
    key: CommonMetricField.ACOS,
    filterKey: FilterKey.ACOS,
    title: 'ACOS',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.ACOS,
    unitType: UnitType.PERCENTAGE,
    aggFunc: (aggData: Record<string, number[]>) => calculateMetricDataHelper(aggData, CommonMetricField.SPEND, CommonMetricField.SALES),
    aggDependencies: [CommonMetricField.SPEND, CommonMetricField.SALES],
  },
  [CommonMetricField.ACTC]: {
    key: CommonMetricField.ACTC,
    filterKey: FilterKey.ACTC,
    title: 'aCTC',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.ACTC,
    unitType: UnitType.NONE,
    aggFunc: (aggData: Record<string, number[]>) => calculateMetricDataHelper(aggData, CommonMetricField.CLICKS, CommonMetricField.ORDERS),
    aggDependencies: [CommonMetricField.CLICKS, CommonMetricField.ORDERS],
  },
  [CommonMetricField.ROAS]: {
    key: CommonMetricField.ROAS,
    filterKey: FilterKey.ROAS,
    title: 'ROAS',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.ROAS,
    unitType: UnitType.NONE,
    aggFunc: (aggData: Record<string, number[]>) => calculateMetricDataHelper(aggData, CommonMetricField.SALES, CommonMetricField.SPEND),
    aggDependencies: [CommonMetricField.SALES, CommonMetricField.SPEND],
  },
  [CommonMetricField.RPC]: {
    key: CommonMetricField.RPC,
    filterKey: FilterKey.RPC,
    title: 'RPC',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.RPC,
    unitType: UnitType.CURRENCY,
    aggFunc: (aggData: Record<string, number[]>) => calculateMetricDataHelper(aggData, CommonMetricField.SALES, CommonMetricField.CLICKS),
    aggDependencies: [CommonMetricField.SALES, CommonMetricField.CLICKS],
  },
  [CommonMetricField.CPA]: {
    key: CommonMetricField.CPA,
    filterKey: FilterKey.CPA,
    title: 'CPA',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.CPA,
    unitType: UnitType.CURRENCY,
    aggFunc: (aggData: Record<string, number[]>) => calculateMetricDataHelper(aggData, CommonMetricField.SPEND, CommonMetricField.ORDERS),
    aggDependencies: [CommonMetricField.SPEND, CommonMetricField.ORDERS],
  },
  [CommonMetricField.AOV]: {
    key: CommonMetricField.AOV,
    filterKey: FilterKey.AOV,
    title: 'AOV',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.AOV,
    unitType: UnitType.CURRENCY,
    aggFunc: (aggData: Record<string, number[]>) => calculateMetricDataHelper(aggData, CommonMetricField.SALES, CommonMetricField.ORDERS),
    aggDependencies: [CommonMetricField.SALES, CommonMetricField.ORDERS],
  },
  [CommonMetricField.CPM]: {
    key: CommonMetricField.CPM,
    filterKey: FilterKey.CPM,
    title: 'CPM',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.CPM,
    unitType: UnitType.CURRENCY,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, CommonMetricField.SPEND, CommonMetricField.IMPRESSIONS, 1000),
    aggDependencies: [CommonMetricField.SPEND, CommonMetricField.IMPRESSIONS],
  },
};

const SELLER_METRICS: { [K in SellerMetricField]: MetricConfiguration } = {
  [SellerMetricField.SELLER_ACOS]: {
    key: SellerMetricField.SELLER_ACOS,
    filterKey: FilterKey.ACOTS,
    title: 'TACOS', // Total Ad Cost of Sales was Ad Cost of Total Sales (ACOTS)
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.ACOTS,
    unitType: UnitType.PERCENTAGE,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, CommonMetricField.SPEND, SellerMetricField.SELLER_SALES),
    aggDependencies: [CommonMetricField.SPEND, SellerMetricField.SELLER_SALES],
  },
  // [SellerMetricField.SELLER_ACTC]: {
  //   key: SellerMetricField.SELLER_ACTC,
  //   filterKey: FilterKey.TOTAL_ACTC,
  //   title: 'Total aCTC',
  //   sentimentDirection: SentimentDirection.REVERSED,
  //   chartSeriesType: 'line',
  //   colId: ColumnId.TOTAL_ACTC,
  //   unitType: UnitType.NONE,
  //   aggFunc: (aggData: Record<string, number[]>) =>
  //     calculateMetricDataHelper(aggData, SellerMetricField.SELLER_CLICKS, SellerMetricField.SELLER_ORDERS),
  //  aggDependencies: [SellerMetricField.SELLER_CLICKS, SellerMetricField.SELLER_ORDERS],
  // },
  [SellerMetricField.SELLER_AD_SALES_OF_TOTAL]: {
    key: SellerMetricField.SELLER_AD_SALES_OF_TOTAL,
    filterKey: FilterKey.AD_SALES_OF_TOTAL,
    title: 'Ad Sales % of Total',
    sentimentDirection: SentimentDirection.NEUTRAL,
    chartSeriesType: 'line',
    colId: ColumnId.AD_SALES_OF_TOTAL,
    unitType: UnitType.PERCENTAGE,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, CommonMetricField.SALES, SellerMetricField.SELLER_SALES),
    aggDependencies: [CommonMetricField.SALES, SellerMetricField.SELLER_SALES],
  },
  [SellerMetricField.SELLER_AOV]: {
    key: SellerMetricField.SELLER_AOV,
    filterKey: FilterKey.TAOV,
    title: 'Total AOV',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.TOTAL_AOV,
    unitType: UnitType.CURRENCY,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, SellerMetricField.SELLER_SALES, SellerMetricField.SELLER_ORDERS),
    aggDependencies: [SellerMetricField.SELLER_SALES, SellerMetricField.SELLER_ORDERS],
  },
  [SellerMetricField.SELLER_ASP]: {
    key: SellerMetricField.SELLER_ASP,
    filterKey: FilterKey.ASP,
    title: 'ASP',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.ASP,
    unitType: UnitType.CURRENCY,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, SellerMetricField.SELLER_SALES, SellerMetricField.SELLER_UNITS),
    aggDependencies: [SellerMetricField.SELLER_SALES, SellerMetricField.SELLER_UNITS],
  },
  [SellerMetricField.SELLER_CLICKS]: {
    key: SellerMetricField.SELLER_CLICKS,
    filterKey: FilterKey.TOTAL_CLICKS,
    title: 'Total Sessions',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_CLICKS,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [SellerMetricField.SELLER_CPA]: {
    key: SellerMetricField.SELLER_CPA,
    filterKey: FilterKey.TCPA,
    title: 'Total CPA',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.TOTAL_CPA,
    unitType: UnitType.CURRENCY,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, CommonMetricField.SPEND, SellerMetricField.SELLER_ORDERS),
    aggDependencies: [CommonMetricField.SPEND, SellerMetricField.SELLER_ORDERS],
  },
  [SellerMetricField.SELLER_CVR]: {
    key: SellerMetricField.SELLER_CVR,
    filterKey: FilterKey.TCVR,
    title: 'Total CVR',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.TOTAL_CVR,
    unitType: UnitType.PERCENTAGE,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, SellerMetricField.SELLER_ORDERS, SellerMetricField.SELLER_CLICKS),
    aggDependencies: [SellerMetricField.SELLER_ORDERS, SellerMetricField.SELLER_CLICKS],
  },
  [SellerMetricField.SELLER_ORDERS]: {
    key: SellerMetricField.SELLER_ORDERS,
    filterKey: FilterKey.TOTAL_ORDERS,
    title: 'Total Orders',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_ORDERS,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [SellerMetricField.SELLER_ORG_SALES]: {
    key: SellerMetricField.SELLER_ORG_SALES,
    filterKey: FilterKey.ORGANIC_SALES,
    title: 'Organic Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.ORGANIC_SALES,
    unitType: UnitType.CURRENCY,
    aggFunc: undefined,
  },
  [SellerMetricField.SELLER_ORG_TRAFFIC]: {
    key: SellerMetricField.SELLER_ORG_TRAFFIC,
    filterKey: FilterKey.ORGANIC_TRAFFIC,
    title: 'Organic Traffic',
    sentimentDirection: SentimentDirection.NEUTRAL,
    chartSeriesType: 'bar',
    colId: ColumnId.ORGANIC_TRAFFIC,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [SellerMetricField.SELLER_ROAS]: {
    key: SellerMetricField.SELLER_ROAS,
    filterKey: FilterKey.TROAS,
    title: 'Total ROAS',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.TOTAL_ROAS,
    unitType: UnitType.NONE,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, SellerMetricField.SELLER_SALES, CommonMetricField.SPEND),
    aggDependencies: [SellerMetricField.SELLER_SALES, CommonMetricField.SPEND],
  },
  [SellerMetricField.SELLER_SALES]: {
    key: SellerMetricField.SELLER_SALES,
    filterKey: FilterKey.TOTAL_SALES,
    title: 'Total Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_SALES,
    unitType: UnitType.CURRENCY,
    aggFunc: undefined,
  },
  [SellerMetricField.SELLER_UNIT_SESS]: {
    key: SellerMetricField.SELLER_UNIT_SESS,
    filterKey: FilterKey.UPS,
    title: 'Units Per Session',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.UPS,
    unitType: UnitType.PERCENTAGE,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, SellerMetricField.SELLER_UNITS, SellerMetricField.SELLER_CLICKS),
    aggDependencies: [SellerMetricField.SELLER_UNITS, SellerMetricField.SELLER_CLICKS],
  },
  [SellerMetricField.SELLER_UNIT_VIEW]: {
    key: SellerMetricField.SELLER_UNIT_VIEW,
    filterKey: FilterKey.UPPW,
    title: 'Unit Pageview %',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.UNIT_VIEW,
    unitType: UnitType.PERCENTAGE,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, SellerMetricField.SELLER_UNITS, SellerMetricField.SELLER_VIEWS),
    aggDependencies: [SellerMetricField.SELLER_UNITS, SellerMetricField.SELLER_VIEWS],
  },
  [SellerMetricField.SELLER_UNITS]: {
    key: SellerMetricField.SELLER_UNITS,
    filterKey: FilterKey.TOTAL_UNITS,
    title: 'Total Units',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_UNITS,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [SellerMetricField.SELLER_VIEWS]: {
    key: SellerMetricField.SELLER_VIEWS,
    filterKey: FilterKey.TOTAL_VIEWS,
    title: 'Total Views',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.TOTAL_VIEWS,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
};

const VENDOR_METRICS: { [K in VendorMetricField]: MetricConfiguration } = {
  [VendorMetricField.VENDOR_ACOS]: {
    key: VendorMetricField.VENDOR_ACOS,
    filterKey: FilterKey.ACOTS,
    title: 'TACOS', // Total Ad Cost of Sales was Ad Cost of Total Sales (ACOTS)
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.VENDOR_ACOTS,
    unitType: UnitType.PERCENTAGE,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, CommonMetricField.SPEND, VendorMetricField.VENDOR_SALES),
    aggDependencies: [CommonMetricField.SPEND, VendorMetricField.VENDOR_SALES],
  },
  [VendorMetricField.VENDOR_AD_SALES_OF_TOTAL]: {
    key: VendorMetricField.VENDOR_AD_SALES_OF_TOTAL,
    filterKey: FilterKey.AD_SALES_OF_TOTAL,
    title: 'Ad Sales % of Total',
    sentimentDirection: SentimentDirection.NEUTRAL,
    chartSeriesType: 'line',
    colId: ColumnId.VENDOR_AD_SALES_OF_TOTAL,
    unitType: UnitType.PERCENTAGE,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, CommonMetricField.SALES, VendorMetricField.VENDOR_SALES),
    aggDependencies: [CommonMetricField.SALES, VendorMetricField.VENDOR_SALES],
  },
  [VendorMetricField.VENDOR_ASP]: {
    key: VendorMetricField.VENDOR_ASP,
    filterKey: FilterKey.ASP,
    title: 'ASP',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.VENDOR_ASP,
    unitType: UnitType.CURRENCY,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, VendorMetricField.VENDOR_SALES, VendorMetricField.VENDOR_UNITS),
    aggDependencies: [VendorMetricField.VENDOR_SALES, VendorMetricField.VENDOR_UNITS],
  },
  [VendorMetricField.VENDOR_CUSTOMER_RETURNS]: {
    key: VendorMetricField.VENDOR_CUSTOMER_RETURNS,
    filterKey: FilterKey.CUSTOMER_RETURNS,
    title: 'Units Refunded',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'bar',
    colId: ColumnId.CUSTOMER_RETURNS,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [VendorMetricField.VENDOR_VIEWS]: {
    key: VendorMetricField.VENDOR_VIEWS,
    filterKey: FilterKey.GLANCE_VIEWS,
    title: 'Total Views',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.VENDOR_TOTAL_VIEWS,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [VendorMetricField.VENDOR_SALES]: {
    key: VendorMetricField.VENDOR_SALES,
    filterKey: FilterKey.ORDERED_REVENUE,
    title: 'Total Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.VENDOR_TOTAL_SALES,
    unitType: UnitType.CURRENCY,
    aggFunc: undefined,
  },
  [VendorMetricField.VENDOR_UNITS]: {
    key: VendorMetricField.VENDOR_UNITS,
    filterKey: FilterKey.ORDERED_UNITS,
    title: 'Total Units',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.VENDOR_TOTAL_UNITS,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [VendorMetricField.VENDOR_ORG_SALES]: {
    key: VendorMetricField.VENDOR_ORG_SALES,
    filterKey: FilterKey.ORGANIC_SALES,
    title: 'Organic Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.VENDOR_ORGANIC_SALES,
    unitType: UnitType.CURRENCY,
    aggFunc: undefined,
  },
  [VendorMetricField.VENDOR_RETURN_RATE]: {
    key: VendorMetricField.VENDOR_RETURN_RATE,
    filterKey: FilterKey.RETURN_RATE,
    title: 'Refund Rate',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.RETURN_RATE,
    unitType: UnitType.PERCENTAGE,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, VendorMetricField.VENDOR_CUSTOMER_RETURNS, VendorMetricField.VENDOR_UNITS),
    aggDependencies: [VendorMetricField.VENDOR_CUSTOMER_RETURNS, VendorMetricField.VENDOR_UNITS],
  },
  [VendorMetricField.VENDOR_ROAS]: {
    key: VendorMetricField.VENDOR_ROAS,
    filterKey: FilterKey.TROAS,
    title: 'Total ROAS',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.VENDOR_TOTAL_ROAS,
    unitType: UnitType.NONE,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, VendorMetricField.VENDOR_SALES, CommonMetricField.SPEND),
    aggDependencies: [VendorMetricField.VENDOR_SALES, CommonMetricField.SPEND],
  },
  [VendorMetricField.VENDOR_SHIPPED_COGS]: {
    key: VendorMetricField.VENDOR_SHIPPED_COGS,
    filterKey: FilterKey.SHIPPED_COGS,
    title: 'Shipped COGS',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.SHIPPED_COGS,
    unitType: UnitType.CURRENCY,
    aggFunc: undefined,
  },
  [VendorMetricField.VENDOR_SHIPPED_REVENUE]: {
    key: VendorMetricField.VENDOR_SHIPPED_REVENUE,
    filterKey: FilterKey.SHIPPED_REVENUE,
    title: 'Shipped Revenue',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.SHIPPED_REVENUE,
    unitType: UnitType.CURRENCY,
    aggFunc: undefined,
  },
  [VendorMetricField.VENDOR_SHIPPED_UNITS]: {
    key: VendorMetricField.VENDOR_SHIPPED_UNITS,
    filterKey: FilterKey.SHIPPED_UNITS,
    title: 'Shipped Units',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'bar',
    colId: ColumnId.SHIPPED_UNITS,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [VendorMetricField.VENDOR_UNIT_VIEW]: {
    key: VendorMetricField.VENDOR_UNIT_VIEW,
    filterKey: FilterKey.UPPW,
    title: 'Unit Pageview %',
    sentimentDirection: SentimentDirection.SYNCED,
    chartSeriesType: 'line',
    colId: ColumnId.VENDOR_UNIT_VIEW,
    unitType: UnitType.PERCENTAGE,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, VendorMetricField.VENDOR_UNITS, VendorMetricField.VENDOR_VIEWS),
    aggDependencies: [VendorMetricField.VENDOR_UNITS, VendorMetricField.VENDOR_VIEWS],
  },
};

const PROFILE_STATS_SELLER_METRICS: { [K in ProfileStatsSellerMetricField]: MetricConfiguration } = {
  [ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED]: {
    key: ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED,
    filterKey: FilterKey.TOTAL_UNITS_REFUNDED,
    title: 'Units Refunded',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'bar',
    colId: ColumnId.UNITS_REFUNDED,
    unitType: UnitType.NONE,
    aggFunc: undefined,
  },
  [ProfileStatsSellerMetricField.SELLER_UNITS_REFUND_RATE]: {
    key: ProfileStatsSellerMetricField.SELLER_UNITS_REFUND_RATE,
    filterKey: FilterKey.TOTAL_UNITS_REFUNDED_RATE,
    title: 'Refund Rate',
    sentimentDirection: SentimentDirection.REVERSED,
    chartSeriesType: 'line',
    colId: ColumnId.UNITS_REFUND_RATE,
    unitType: UnitType.PERCENTAGE,
    aggFunc: (aggData: Record<string, number[]>) =>
      calculateMetricDataHelper(aggData, ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED, SellerMetricField.SELLER_UNITS),
    aggDependencies: [ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED, SellerMetricField.SELLER_UNITS],
  },
};

// const COMMON_WITH_SELLER_METRICS: { [K in CommonWithSellerAndVendorMetricField]: MetricConfiguration } = {
//   ...COMMON_METRICS,
//   ...SELLER_METRICS,
//   ...VENDOR_METRICS,
// };

export const METRICS: { [K in MetricField]: MetricConfiguration } = {
  ...COMMON_METRICS,
  ...SELLER_METRICS,
  ...VENDOR_METRICS,
  ...PROFILE_STATS_SELLER_METRICS,
};

export type MetricSelectionColorIndex = {
  key: MetricField;
  isSelected: boolean;
  colorIndex: number;
};

export const DEFAULT_VISIBLE_METRICS: MetricSelectionColorIndex[] = [
  {
    key: CommonMetricField.IMPRESSIONS,
    isSelected: false,
    colorIndex: -1,
  },
  {
    key: CommonMetricField.CLICKS,
    isSelected: false,
    colorIndex: -1,
  },
  {
    key: CommonMetricField.ORDERS,
    isSelected: false,
    colorIndex: -1,
  },
  {
    key: CommonMetricField.SPEND,
    isSelected: false,
    colorIndex: -1,
  },
  {
    key: CommonMetricField.SALES,
    isSelected: true,
    colorIndex: 1,
  },
  {
    key: CommonMetricField.ACOS,
    isSelected: true,
    colorIndex: 0,
  },
  {
    key: CommonMetricField.CTR,
    isSelected: false,
    colorIndex: -1,
  },
  {
    key: CommonMetricField.CVR,
    isSelected: false,
    colorIndex: -1,
  },
];

export function getSelectedMetricsCount(metrics: MetricSelectionColorIndex[]): number {
  return metrics.filter((metric) => metric.isSelected).length;
}

export function canSelectMoreMetrics(metrics: MetricSelectionColorIndex[]): boolean {
  return getSelectedMetricsCount(metrics) < MAX_METRICS_CAN_SELECT;
}

function updateMetricSelectionState(metrics: MetricSelectionColorIndex[], metricKey: MetricField, isSelected: boolean) {
  if (!canSelectMoreMetrics(metrics) && isSelected) {
    console.error('Tried to select more metrics than allowed');
    return metrics;
  }

  const newColorIndex = isSelected ? getNewColorIndex(metrics) : -1;
  return metrics.map((metric) => {
    if (metric.key === metricKey) {
      return {
        ...metric,
        isSelected,
        colorIndex: newColorIndex,
      };
    }
    return metric;
  });
}

function getNewColorIndex(metrics: MetricSelectionColorIndex[]): number {
  const usedIndexes = metrics.filter((metric) => metric.isSelected).map((metric) => metric.colorIndex);

  // Get next unused index in the span of MAX_METRICS_CAN_SELECT
  for (let i = 0; i < MAX_METRICS_CAN_SELECT; i++) {
    if (!usedIndexes.includes(i)) {
      return i;
    }
  }

  console.error('Could not find new color index. Returning -1');
  return -1;
}

export function toggleMetricSelectionStatus(metrics: MetricSelectionColorIndex[], metricKeyToToggleSelect: MetricField) {
  const foundMetric = metrics.find((metric) => metric.key === metricKeyToToggleSelect);
  if (!foundMetric) {
    console.error(`Could not find metric ${metricKeyToToggleSelect} to toggle selection status`);
    return updateMetricSelectionState(metrics, metricKeyToToggleSelect, false);
  }
  return updateMetricSelectionState(metrics, metricKeyToToggleSelect, !foundMetric.isSelected);
}

export function updateMetricVisibilityStatus(metrics: MetricSelectionColorIndex[], newVisibleMetrics: MetricField[]) {
  return newVisibleMetrics.map((metric) => {
    const newMetric = metrics.find((m) => m.key === metric);
    return newMetric ? newMetric : { key: metric, isSelected: false, colorIndex: -1 };
  });
}

export function getColorByIndex(index: number): string {
  return METRIC_COLORS[index % METRIC_COLORS.length];
}
