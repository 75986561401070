import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ButtonGroupCellRenderer, { IButtonGroupCellRendererParams } from '@/components/grid/cells/ButtonGroupCellRenderer';
import { ITextCellRendererParams, TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import RowActionButton from '@/components/grid/components/RowActionButton';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import { AlColDef } from '@/components/grid/types';
import { TailwindColorVariant } from '@/config/theme/color.type';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useTranslation } from '@/lib';
import { downloadObjectArrayAsCsv, sanitizeFilename } from '@/modules/application/utils';
import { UserSettingKey } from '@/modules/users';
import { LogEntityTypeColors } from '@/types/colors.enum';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PageviewIcon from '@mui/icons-material/Pageview';
import { Card } from '@mui/material';
import type {
  CellClickedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toastService } from '@/services/toast.service';
import { FlowType } from '../api/logs-contracts';
import useLogFetch from '../hooks/useLogFetch';
import { JobActionModel } from '../models/JobActionModel';
import LogPreviewModal, { LogPreviewModalDetails } from './LogPreviewModal';
import { generateLogsTableColumnState } from './logs-table.default-column-state';

export interface LogGridContext {
  idsBeingLoaded: Set<string>;
}

interface LogsTableProps {
  rowData: JobActionModel[] | undefined;
  isLoading: boolean;
  isLoadingError: boolean;
  loadingErrorMessage: string;
}

const LogsTable: FunctionComponent<LogsTableProps> = ({ rowData, isLoading, isLoadingError, loadingErrorMessage }) => {
  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.LOGS_TABLE_COLUMN_STATE,
    generateLogsTableColumnState(),
  );

  const { checkboxColumnType } = useColumnTypes();
  const { t } = useTranslation();
  const gridApiRef = useRef<GridApi<JobActionModel>>();
  const gridContextRef = useRef<LogGridContext>();

  const { fetchLogOnDemand } = useLogFetch({});
  const { formatDateStringTimeNoSeconds, formatWithThousandsSeparator } = useFormatting();

  const columnDefs: ColDef<JobActionModel>[] = useMemo(() => {
    const colDefs: AlColDef<JobActionModel>[] = [
      //   {
      //     colId: ColumnId.CHECKBOX,
      //     pinned: 'left',
      //     lockPosition: 'left',
      //     type: 'checkboxColumnType',
      //   },
      {
        colId: ColumnId.FLOW_TYPE,
        headerName: 'Flow',
        field: 'flowType',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`enums.flow_type.${key}`),
            valueToColor: (key: string) => LogEntityTypeColors[key as FlowType] ?? TailwindColorVariant.GREEN,
          };
        },
      },
      {
        colId: ColumnId.ACTION,
        headerName: 'Action',
        field: 'action',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`enums.log_action_type.${key}`),
          };
        },
      },

      {
        colId: ColumnId.ACTION_ENTITY_TYPE,
        headerName: 'Entity',
        field: 'entityType',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`enums.action_entity_type.${key}`),
            valueToColor: (key: string) => LogEntityTypeColors[key as FlowType] ?? TailwindColorVariant.GREEN,
          };
        },
      },
      {
        colId: ColumnId.APPLIED_ENTITY_COUNT,
        headerName: 'Total',
        field: 'total',
        valueFormatter: (params) => formatWithThousandsSeparator(params.value),
        width: 65,
      },
      {
        colId: ColumnId.SUCCESS_ENTITY_COUNT,
        headerName: 'Success',
        field: 'success',
        valueFormatter: (params) => formatWithThousandsSeparator(params.value),
        width: 81,
      },
      {
        colId: ColumnId.FAILED_ENTITY_COUNT,
        headerName: 'Failed',
        field: 'failed',
        valueFormatter: (params) => formatWithThousandsSeparator(params.value),
        width: 64,
      },
      {
        colId: ColumnId.CREATED_AT,
        headerName: 'Created Date',
        field: 'createdAt',
        width: 180,
        valueFormatter: (params: ValueFormatterParams<JobActionModel>) => formatDateStringTimeNoSeconds(params.value),
      },
      {
        colId: ColumnId.USERNAME,
        headerName: 'Created By',
        field: 'createdBy',
      },
      {
        colId: ColumnId.ACTIONS,
        headerName: 'Actions',
        width: 255,
        pinned: 'right',
        cellClass: 'border-none outline-none',
        cellRenderer: ButtonGroupCellRenderer,
        cellRendererParams: (params: ICellRendererParams<JobActionModel, unknown, LogGridContext>): IButtonGroupCellRendererParams => {
          const isLoading = params.data && params.context?.idsBeingLoaded.has(params.data.id?.toString() ?? '');

          return {
            buttons: [
              <RowActionButton
                color={'default'}
                key="preview"
                text={'Preview'}
                tooltipText={'Preview the downloadable table'}
                onClick={() => onPreviewClicked(params)}
                icon={<PageviewIcon />}
                isDisabled={false}
                isLoading={false}
                isLoadingText="Loading..."
              ></RowActionButton>,
              <RowActionButton
                key="download"
                text="Download"
                color={'default'}
                isLoadingText="Loading..."
                isDisabled={isLoading}
                tooltipText={'Download detailed table for this event'}
                isLoading={isLoading}
                onClick={() => onDownloadClicked(params)}
                icon={<FileDownloadOutlinedIcon />}
              ></RowActionButton>,
            ],
          };
        },
      },
    ];

    applyStateToDefinitions(colDefs);
    return colDefs;
  }, []);

  const onCellClicked = useCallback((params: CellClickedEvent<JobActionModel>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const customGridOptions: GridOptions<JobActionModel> = useMemo(() => {
    // While values might be undefined, default values are needed so grid knows which fields to expect
    const defaultGridContext: LogGridContext = {
      idsBeingLoaded: new Set(),
    };
    return {
      ...DEFAULT_GRID_OPTIONS,
      getRowId: (params) => params.data.id.toString(),
      onCellClicked: onCellClicked,
      context: defaultGridContext,
      maintainColumnOrder: true,
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,
      columnTypes: { checkboxColumnType },
    };
  }, []);

  function onGridReady(params: GridReadyEvent) {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    gridContextRef.current = params.context;
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  // Action buttons
  const onPreviewClicked = useCallback((params: ICellRendererParams<JobActionModel, unknown, LogGridContext>) => {
    const { jobId, flowType, id: actionId, action, createdAt } = params.data ?? {};
    const title = `Log: ${t(`enums.flow_type.${flowType}`)} - ${t(`enums.log_action_type.${action}`)} on ${formatDateStringTimeNoSeconds(createdAt)}`;
    const filename = createFilename(createdAt ?? '', jobId ?? '', flowType ?? '', actionId ?? '');

    setPreviewModalDetails({ jobId: jobId ?? '', actionId: actionId ?? '', title, filename });
    setIsPreviewModalOpen(true);
  }, []);

  function setIdLoadingState(id: string, isLoading: boolean) {
    if (!gridApiRef.current || !gridContextRef.current) return;

    if (isLoading) {
      gridContextRef.current.idsBeingLoaded.add(id);
    } else {
      gridContextRef.current.idsBeingLoaded.delete(id);
    }

    gridApiRef.current?.refreshCells({
      force: true,
      columns: [ColumnId.ACTIONS],
    });
  }

  const createFilename = useCallback((createdAt: string, id: string, entity: string, action: string) => {
    return sanitizeFilename(`${createdAt}-${id}-${entity}-${action}-adlabs_log`);
  }, []);

  const onDownloadClicked = async (params: ICellRendererParams<JobActionModel, unknown, LogGridContext>) => {
    setIdLoadingState(params.data?.id?.toString() ?? '', true);

    const data = await fetchLogOnDemand(params.data?.id);

    if (!data || isEmpty(data)) {
      // User feedback is handled in function
      setIdLoadingState(params.data?.id?.toString() ?? '', false);
      toastService.info('No data to download');
      return;
    }

    try {
      const fileName = createFilename(
        params.data?.createdAt ?? '',
        params.data?.id ?? '',
        params.data?.flowType ?? '',
        params.data?.action ?? '',
      );

      downloadObjectArrayAsCsv(data, fileName);
    } catch (e) {
      console.error(e);
      toastService.error(`Failed to download data: ${e}`);
    }

    setIdLoadingState(params.data?.id?.toString() ?? '', false);
  };

  // Data preview modal
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewModalDetails, setPreviewModalDetails] = useState<LogPreviewModalDetails | undefined>(undefined);

  function onPreviewModalClose() {
    setIsPreviewModalOpen(false);
    setPreviewModalDetails(undefined);
  }
  return (
    <>
      {isLoadingError ? (
        <Card className="flex-grow rounded-xl py-0">
          <ErrorLoadingDataAlert details={loadingErrorMessage} />
        </Card>
      ) : (
        <>
          <div className="flex flex-grow">
            <AlGrid
              colDefs={columnDefs}
              rowData={rowData}
              gridOptions={customGridOptions}
              isLoading={isLoading}
              onGridReadyCallback={onGridReady}
              noTopBorderRadius={false}
              fitToResizeEnabled={false}
              addExtraBottomPadding={true}
            />
          </div>
          <LogPreviewModal isOpen={isPreviewModalOpen} onClose={onPreviewModalClose} details={previewModalDetails} />
        </>
      )}
    </>
  );
};

export default LogsTable;
