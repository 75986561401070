import { app } from '@/modules/auth';
import { userService } from '@/modules/users';
import {
  MAX_FIRST_NAME_LENGTH,
  MAX_SURNAME_LENGTH,
  MIN_EMAIL_LENGTH,
  MIN_FIRST_NAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  MIN_SURNAME_LENGTH,
} from '@/modules/users/constants';
import { APP_DEFAULT_PATH } from '@/router/router';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { Alert, Button, TextField } from '@mui/material';
import { FirebaseError } from 'firebase/app';
import { AuthErrorCodes, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, UserCredential } from 'firebase/auth';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import RegistrationWizardPage from '../components/RegistrationWizardPage';
import RegistrationWizardPageTitle from '../components/RegistrationWizardPageTitle';
import { useRegistrationContext } from '../contexts/registration/RegistrationContext';

const RegistrationStepCreateAccountPage: FunctionComponent = () => {
  const { setTeamId, setUser } = useRegistrationContext();
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isGetStartedEnabled, setIsGetStartedEnabled] = useState(false);
  const navigate = useNavigate();
  const [resetPasswordButtonDisabled, setResetPasswordButtonDisabled] = useState(false);
  // const [showWaitListAlert, setShowWaitListAlert] = useState(false);
  const [authErrorCode, setAuthErrorCode] = useState<string | null>(null);

  useEffect(() => {
    if (
      firstName.length >= MIN_FIRST_NAME_LENGTH &&
      firstName.length <= MAX_FIRST_NAME_LENGTH &&
      surname.length >= MIN_SURNAME_LENGTH &&
      surname.length <= MAX_SURNAME_LENGTH &&
      email.length >= MIN_EMAIL_LENGTH &&
      password.length >= MIN_PASSWORD_LENGTH &&
      matchIsValidTel(phoneNumber) &&
      !isSubmitting
    ) {
      setIsGetStartedEnabled(true);
    } else {
      setIsGetStartedEnabled(false);
    }
  }, [firstName, surname, email, password, phoneNumber]);

  async function onGetStaredClicked() {
    setIsSubmitting(true);
    setIsError(false);
    setAuthErrorCode(null);
    // setShowWaitListAlert(false);

    try {
      // const isEmailInWaitList = await userService.isEmailInWaitList(email);
      // if (!isEmailInWaitList) {
      //   setShowWaitListAlert(true);
      //   return;
      // }

      // Try to log in and see if user exists
      let firebaseUser: UserCredential | null = null;
      try {
        const auth = getAuth(app);
        firebaseUser = await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        console.log({ LOGIN_ERROR: error, IsFirebaseError: error instanceof FirebaseError });

        if (error instanceof FirebaseError) {
          switch (error.code) {
            case AuthErrorCodes.USER_DELETED:
              // User is not found (stupid firebase error code)
              setAuthErrorCode(AuthErrorCodes.USER_DELETED);
              break;
            case AuthErrorCodes.INVALID_PASSWORD:
              setAuthErrorCode(AuthErrorCodes.INVALID_PASSWORD);
              return;

            default:
              setAuthErrorCode(error.code);
              return;
          }
        } else {
          setAuthErrorCode(AuthErrorCodes.INTERNAL_ERROR);
          return;
        }
      }

      // If it is an existing user
      if (firebaseUser != null) {
        // Check if user has a team
        const adlabsUserResponse = await userService.getUserByFirebaseId(firebaseUser.user.uid);

        if (!adlabsUserResponse.isSuccess && adlabsUserResponse.httpResponseCode !== 404) {
          // If there was an error, but not a 404, display error message
          setIsError(true);
          setErrorMessage(adlabsUserResponse.message);
          return;
        }

        // If user has a team
        else if (adlabsUserResponse.isSuccess && adlabsUserResponse.payload.teams.length > 0) {
          const user = adlabsUserResponse.payload;
          const teams = user.teams;
          // Find team with at least one profile
          const teamWithAtLeastOneProfile = teams.find((t) => t.profiles.length > 0);

          // If user has a team with at least one profile, navigate to app
          if (teamWithAtLeastOneProfile) {
            // hard navigate to /app page
            // User will be logged in now, and first team and first profile will be loaded
            window.location.href = APP_DEFAULT_PATH;
            navigate(APP_DEFAULT_PATH);
            window.location.reload();
            return;
          } else {
            // User already has a team, but no profiles, navigate to authorize amazon
            setTeamId(teams[0].id);
            const wizardUser = {
              email,
              userId: user.id,
              firebaseId: firebaseUser.user.uid,
              phone: user.phone,
            };
            setUser(wizardUser);
            navigate(Routes.REGISTER_ABOUT_YOU);
            return;
          }
        } else {
          // User has no teams, create a team and user
          // TODO: create team for existing user that doesn't have any teams yet?
          console.error(`User does not have a team ${JSON.stringify(adlabsUserResponse)}`);
        }
      }
      const result = await userService.createUserAndTeam({
        team_name: firstName + "'s Team",
        name: firstName,
        surname,
        phone: phoneNumber,
        email,
        password,
      });

      if (result.isSuccess) {
        setTeamId(result.payload.team_id);
        const user = {
          email,
          userId: result.payload.user_id,
          firebaseId: result.payload.firebase_id,
          phone: phoneNumber,
        };
        setUser(user);
        await signInWithEmailAndPassword(getAuth(app), email, password);

        navigate(Routes.REGISTER_ABOUT_YOU);
        return;
      } else {
        setIsError(true);
        setErrorMessage(result.message);
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
      setErrorMessage('Something went wrong. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  }

  // async function signUpWithGoogleClicked() {
  //   console.log('Sign up with Google Clicked');
  // }

  async function onForgetPasswordClicked() {
    try {
      setResetPasswordButtonDisabled(true);
      await sendPasswordResetEmail(getAuth(app), email);
      toastService.success('Password reset email sent!');

      setTimeout(() => {
        setResetPasswordButtonDisabled(false);
      }, 5000);
    } catch (error) {
      console.log({ error });
      toastService.error('Error sending password reset email');
    }
  }

  return (
    <RegistrationWizardPage>
      <div className="flex flex-col items-center pt-10">
        <RegistrationWizardPageTitle>Join AdLabs Beta!</RegistrationWizardPageTitle>
        <div className="mt-10 w-96">
          <div className="flex flex-col gap-y-3">
            <div className="mt-4 text-center font-semibold text-slate-600">Your Personal Account Details</div>
            <TextField
              id="firstName"
              label="First Name"
              placeholder="First Name"
              value={firstName}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setFirstName(event.target.value);
              }}
              error={firstName.length > MAX_FIRST_NAME_LENGTH}
              helperText={
                firstName.length < MIN_FIRST_NAME_LENGTH
                  ? `Enter at least ${MIN_FIRST_NAME_LENGTH} characters`
                  : firstName.length > MAX_FIRST_NAME_LENGTH && `First name cannot be longer than ${MAX_FIRST_NAME_LENGTH} characters`
              }
            />

            <TextField
              id="surname"
              label="Last Name"
              placeholder="Last Name"
              value={surname}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSurname(event.target.value);
              }}
              error={surname.length > MAX_SURNAME_LENGTH}
              helperText={
                surname.length < MIN_SURNAME_LENGTH
                  ? `Enter at least ${MIN_SURNAME_LENGTH} characters`
                  : surname.length > MAX_SURNAME_LENGTH && `Last name cannot be longer than ${MAX_SURNAME_LENGTH} characters`
              }
            />

            <MuiTelInput
              id="phone"
              label="Phone Number"
              forceCallingCode
              focusOnSelectCountry
              defaultCountry="US"
              value={phoneNumber}
              onChange={(value: string) => {
                setPhoneNumber(value);
              }}
              helperText={phoneNumber.length > 1 && !matchIsValidTel(phoneNumber) ? 'Invalid phone number' : ''}
            />

            <TextField
              id="email"
              label="Email"
              type="email"
              placeholder="Enter your Email"
              value={email}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
            />

            <TextField
              id="password"
              label="Password"
              type="password"
              placeholder="Enter your Password"
              value={password}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
              }}
              helperText={password.length < MIN_PASSWORD_LENGTH && `Enter at least ${MIN_PASSWORD_LENGTH} characters`}
            />
            {/* <TextSplit>or</TextSplit>
            <Button variant="outlined" size="large" onClick={signUpWithGoogleClicked}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" className="mr-2">
                <path
                  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                  fill="#4285F4"
                />
                <path
                  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                  fill="#34A853"
                />
                <path
                  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                  fill="#FBBC05"
                />
                <path
                  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                  fill="#EA4335"
                />
                <path d="M1 1h22v22H1z" fill="none" />
              </svg>
              Sign up with Google
            </Button> */}
            {authErrorCode && authErrorCode === AuthErrorCodes.INVALID_PASSWORD && (
              <Alert severity="error" className="mt-4">
                <div className="flex flex-col">
                  <div className="">
                    It seems you already have an account, but entered an invalid password. Try again, <br /> or reset it (we&rsquo;ll mail you
                    a reset link).
                  </div>
                  <div className="mt-2">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={onForgetPasswordClicked}
                      disabled={resetPasswordButtonDisabled}
                      loading={resetPasswordButtonDisabled}
                    >
                      Reset password
                    </Button>
                  </div>
                </div>
              </Alert>
            )}
            {authErrorCode && authErrorCode !== AuthErrorCodes.INVALID_PASSWORD && authErrorCode !== AuthErrorCodes.USER_DELETED && (
              <Alert severity="error" className="mt-4">
                Something went wrong. Please try again later. The error code was: {authErrorCode}
              </Alert>
            )}

            {isError && (
              <Alert severity="error" className="mt-4">
                Something went wrong. The message was: {errorMessage}
              </Alert>
            )}

            {/* {showWaitListAlert && (
              <Alert severity="warning" className="mt-4">
                Email is not in the waitlist. Please apply to the waitlist at <Link> https://adlabs.app/</Link>
              </Alert>
            )} */}

            <Button
              variant="contained"
              size="large"
              color="primary"
              className="mt-6"
              onClick={onGetStaredClicked}
              disabled={!isGetStartedEnabled}
              loading={isSubmitting}
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </RegistrationWizardPage>
  );
};

export default RegistrationStepCreateAccountPage;
