import useFormatting from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { BidCeilingType } from '@/modules/optimizer/types/BidCeilingType';
import { BidFloorType } from '@/modules/optimizer/types/BidFloorType';
import { SmartBidCeilingType } from '@/modules/optimizer/types/SmartBidCeilingType';
import { toastService } from '@/services/toast.service';
import { zodResolver } from '@hookform/resolvers/zod';
import { Check } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { ChangeCampaignGroupDTO, NewCampaignGroupDTO } from '../../../api/campaign/campaign-contracts';
import { campaignService } from '../../../api/campaign/campaign-service';
import { CampaignGroupModel, getDefaultValues } from '../../../api/campaign/models/CampaignGroupModel';
import { SelectedCampaignDTO } from '../../../api/campaign/models/CampaignModel';
import { BidLimitChangeUnitType } from '../../optimization/api/optimization-contracts';
import { availableOptimizationPresets, OptimizationPreset } from '../../optimization/OptimizerConfig';
import { createCampaignGroupSchema } from '../schemas/campaign-group-schemas';

interface CreateUpdateCampaignGroupProps {
  campaignGroup?: CampaignGroupModel;
  selectedCampaigns?: SelectedCampaignDTO[];
  existingGroups: CampaignGroupModel[];
  onClose: () => void;
  applyChanges?: (changeGroups: ChangeCampaignGroupDTO[], isNewGroup?: boolean) => void;
  isApplyPending?: boolean;
  onSuccess: (campaignGroup?: CampaignGroupModel) => void;
  flowType: FlowType;
}

export const CreateUpdateCampaignGroup: FunctionComponent<CreateUpdateCampaignGroupProps> = ({
  campaignGroup,
  selectedCampaigns,
  existingGroups,
  onClose,
  applyChanges,
  isApplyPending = false,
  onSuccess,
  flowType,
}) => {
  const { t } = useTranslation();

  const { getCurrencySymbol } = useFormatting();
  const currencySymbol = getCurrencySymbol();

  const defaultValues = getDefaultValues(campaignGroup);

  const campaignGroupSchema = createCampaignGroupSchema(existingGroups.filter((group) => group.id != campaignGroup?.id));
  type FormData = z.infer<typeof campaignGroupSchema>;

  // RHF setup
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    defaultValues,
    resolver: zodResolver(campaignGroupSchema),
    mode: 'onChange', // Validation is triggered on every input change
  });

  const handleCreateUpdate = async (data: FormData) => {
    const { groupName, tacos, prioritization, bidCeiling, smartBidCeilingType, bidFloor, bidCeilingType, bidFloorType, optimizationEnabled } =
      data;
    const numberTargetAcos = Number(tacos);

    const bidCeilingValue = !isNaN(Number(bidCeiling)) && bidCeiling != '' ? Number(bidCeiling) : undefined;
    const bidFloorValue = !isNaN(Number(bidFloor)) && bidFloor != '' ? Number(bidFloor) : undefined;

    // validate only if optimize is ON
    if (optimizationEnabled) {
      // TODO: Use marketplace bid limits, see bid-limits.ts and add them to validation schema
      if (bidCeilingType == BidCeilingType.MANUAL && (!bidCeilingValue || (bidCeilingValue && bidCeilingValue < 0.01))) {
        toastService.error('Bid Ceiling cannot be less than 0.01');
        return;
      }

      // TODO: Use marketplace bid limits, see bid-limits.ts
      if (bidFloorType == BidFloorType.MANUAL && (!bidFloorValue || (bidFloorValue && bidFloorValue < 0.01))) {
        toastService.error('Bid Floor cannot be less than 0.01');
        return;
      }

      if (
        bidFloorType == BidFloorType.MANUAL &&
        bidCeilingType == BidCeilingType.MANUAL &&
        bidFloorValue &&
        bidCeilingValue &&
        bidFloorValue >= bidCeilingValue
      ) {
        toastService.error('Bid Floor cannot be larger than Bid Ceiling');
        return;
      }
    }

    // TODO: Add these fields to creation
    // placement_max_increase?: number;
    // placement_max_decrease?: number;
    // bid_max_increase?: MaxChange;
    // bid_max_decrease?: MaxChange;
    // bid_max_increase_off?: boolean;
    // bid_max_decrease_off?: boolean;
    // placement_max_increase_off?: boolean;
    // placement_max_decrease_off?: boolean;

    const groupData: NewCampaignGroupDTO = {
      name: groupName,
      tacos: !isNaN(numberTargetAcos) && tacos != '' ? numberTargetAcos / 100 : undefined, // always in fractions when not displaying
      preset: !isEmpty(prioritization) && prioritization != OptimizationPreset.NOT_SET ? prioritization : undefined,
      no_optimize: !optimizationEnabled,
      bid_floor_off: bidFloorType == BidFloorType.OFF,
      bid_ceiling_off: bidCeilingType == BidCeilingType.OFF,
    };

    if (bidFloorType == BidFloorType.MANUAL) {
      groupData.bid_floor = bidFloorValue;
    }

    if (bidCeilingType == BidCeilingType.MANUAL && bidCeilingValue) {
      groupData.bid_ceiling = {
        unit: BidLimitChangeUnitType.CURRENCY,
        value: bidCeilingValue,
      };
    }

    if (bidCeilingType == BidCeilingType.SMART) {
      let smartBidCeilingValue = 1;
      switch (smartBidCeilingType) {
        case SmartBidCeilingType.TARGET_CPC_1X:
          smartBidCeilingValue = 1;
          break;
        case SmartBidCeilingType.TARGET_CPC_2X:
          smartBidCeilingValue = 2;
          break;
        case SmartBidCeilingType.TARGET_CPC_3X:
          smartBidCeilingValue = 3;
          break;
      }

      groupData.bid_ceiling = {
        unit: BidLimitChangeUnitType.TIMES_CPC,
        value: smartBidCeilingValue,
      };
    }

    if (campaignGroup) {
      // TODO: Refactor
      campaignGroup.name = groupData.name;
      campaignGroup.tacos = groupData.tacos;
      campaignGroup.preset = groupData.preset;
      campaignGroup.optimizationEnabled = !groupData.no_optimize;
      campaignGroup.bidFloorOff = groupData.bid_floor_off;
      campaignGroup.bidCeilingOff = groupData.bid_ceiling_off;
      campaignGroup.bidCeiling = groupData.bid_ceiling;
      campaignGroup.bidFloor = groupData.bid_floor;

      await updateCampaignGroup(campaignGroup);
    } else {
      createGroup(groupData);
    }

    onClose();
  };

  async function updateCampaignGroup(campaignGroupModel: CampaignGroupModel) {
    const res = await campaignService.updateGroups([campaignGroupModel]);
    if (res.isSuccess) {
      toastService.success(`Optimization group updated successfully`);
      onSuccess();
    } else {
      toastService.error(`Did not receive a response from server: ${res.message}`);
    }
  }

  // TODO: Refactor. Remove useMutation
  const { mutate: createGroup, isPending: isLoadingCreateGroup } = useMutation({
    mutationFn: (groupData: NewCampaignGroupDTO) => campaignService.createGroups([groupData], flowType),
    onSuccess: (res) => {
      if (res.isSuccess) {
        const newGroup = res.payload[0];
        if (selectedCampaigns && selectedCampaigns.length > 0 && applyChanges) {
          const changeGroups: ChangeCampaignGroupDTO[] = selectedCampaigns.map((campaign) => ({
            campaign_id: campaign.id,
            group_id: newGroup.id,
          }));

          applyChanges(changeGroups, true);
        } else {
          toastService.success(`Optimization group created successfully`);
        }

        onSuccess(newGroup);
      } else {
        toastService.error(`Did not receive a response from server: ${res.message}`);
      }
    },
  });
  return (
    <>
      <DialogTitle>{campaignGroup ? `Edit ${campaignGroup.name}` : 'Create New Group'}</DialogTitle>
      <DialogContent>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(handleCreateUpdate)}>
          <div className="flex flex-col gap-4">
            <Controller
              name="groupName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="min-w-64"
                  autoFocus
                  fullWidth
                  label="Group Name"
                  error={!!errors.groupName}
                  helperText={errors.groupName?.message}
                />
              )}
            />

            <div className="flex flex-row gap-2">
              <Controller
                name="tacos"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Target ACOS"
                    placeholder={'Not Set'}
                    disabled={!watch('optimizationEnabled')}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      },
                    }}
                    error={!!errors.tacos}
                    helperText={errors.tacos?.message}
                  />
                )}
              />

              <Controller
                name="prioritization"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel>Prioritization</InputLabel>
                    <Select
                      {...field}
                      label="Prioritization"
                      disabled={!watch('optimizationEnabled')}
                      className={`${field.value == OptimizationPreset.NOT_SET ? 'text-gray-400' : ''}`}
                    >
                      {availableOptimizationPresets.map((preset) => (
                        <MenuItem key={preset} value={preset}>
                          {t(`enums.optimization_presets.${preset}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </div>

            <div className="flex flex-row gap-2">
              <Controller
                name="bidFloorType"
                control={control}
                render={({ field }) => (
                  <FormControl className="w-1/2">
                    <InputLabel>Bid Floor</InputLabel>
                    <Select
                      {...field}
                      label="Bid Floor"
                      disabled={!watch('optimizationEnabled')}
                      className={`${field.value == BidFloorType.NOT_SET ? 'text-gray-400' : ''}`}
                    >
                      {Object.values(BidFloorType).map((type) => (
                        <MenuItem key={type} value={type}>
                          {t(`enums.bid_floor_type.${type}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />

              <div className="w-1/2">
                {watch('bidFloorType') == BidFloorType.MANUAL && (
                  <Controller
                    name="bidFloor"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Bid Floor"
                        disabled={!watch('optimizationEnabled')}
                        slotProps={{
                          input: {
                            endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                          },
                        }}
                        error={!!errors.bidFloor}
                        helperText={errors.bidFloor?.message}
                      />
                    )}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <Controller
                name="bidCeilingType"
                control={control}
                render={({ field }) => (
                  <FormControl className="w-1/2">
                    <InputLabel>Bid Ceiling</InputLabel>
                    <Select
                      {...field}
                      label="Bid Ceiling"
                      disabled={!watch('optimizationEnabled')}
                      displayEmpty
                      className={`${watch('bidCeilingType') == BidCeilingType.NOT_SET ? 'text-gray-400' : ''}`}
                    >
                      {Object.values(BidCeilingType).map((type) => (
                        <MenuItem key={type} value={type}>
                          {t(`enums.bid_ceiling_type.${type}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />

              <div className="w-1/2">
                {watch('bidCeilingType') == BidCeilingType.MANUAL && (
                  <Controller
                    name="bidCeiling"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="number"
                        label="Bid Ceiling"
                        disabled={!watch('optimizationEnabled')}
                        slotProps={{
                          input: {
                            endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                          },
                        }}
                        error={!!errors.bidCeiling}
                        helperText={errors.bidCeiling?.message}
                      />
                    )}
                  />
                )}
                {watch('bidCeilingType') == BidCeilingType.SMART && (
                  <Controller
                    name="smartBidCeilingType"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel>Smart Bid Ceiling</InputLabel>
                        <Select {...field} fullWidth displayEmpty label="Smart Bid Ceiling" disabled={!watch('optimizationEnabled')}>
                          {Object.values(SmartBidCeilingType).map((option) => (
                            <MenuItem key={option} value={option}>
                              {t(`enums.smart_bid_ceiling_type.${option}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                )}
              </div>
            </div>

            {/* <div className="flex flex-col">
              <div className="flex mt-2 ml-1">
                <InputLabel>
                  Optimize campaigns in the group
                  <Tooltip title="Disable to avoid optimizing campaigns in this group">
                    <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
                  </Tooltip>
                </InputLabel>
              </div>

              <div className="flex items-center">
                <Controller
                  name="optimizationEnabled"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <Switch
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          setOptimizationEnabled(e.target.checked);
                        }}
                        name="useGroupSettings"
                      />
                      <span className={`ml-2 text-sm font-bold ${value ? 'text-blue-600' : 'text-orange-500'}`}>
                        {value ? 'Optimize campaigns' : "Don't optimize campaigns"}
                      </span>
                    </>
                  )}
                />
              </div>
            </div> */}
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(handleCreateUpdate)}
          loading={isApplyPending || isLoadingCreateGroup}
          loadingPosition="start"
          startIcon={<Check />}
          variant="contained"
          disabled={!isEmpty(errors) || !watch('groupName')}
        >
          {campaignGroup ? (
            'Update'
          ) : (
            <span>
              Create
              {selectedCampaigns && selectedCampaigns.length > 0
                ? ` and Assign to ${selectedCampaigns.length} Campaign` + (selectedCampaigns.length > 1 ? 's' : '')
                : ''}
            </span>
          )}
        </Button>
      </DialogActions>
    </>
  );
};
