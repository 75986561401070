import useFormatting from '@/hooks/useFormatting';
import { AlDate, HOURS_MINUTES, SYNC_DATE_FORMAT } from '@/lib/date/AlDate';
import { BUTTON_WIDTH } from '@/modules/application/types/constants';
import { toastService } from '@/services/toast.service';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Button, InputAdornment, keyframes, TextField, Typography, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { TimePicker } from '@mui/x-date-pickers';
import { isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useState } from 'react';
import { teamService } from '../api/teams.service';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';

interface PreferredTimePickerProps {
  onSuccess: () => void;
  isEmbeddedInTopBar?: boolean;
}

export const PreferredTimePicker: FunctionComponent<PreferredTimePickerProps> = ({ onSuccess, isEmbeddedInTopBar = false }) => {
  const { activeTeam } = useActiveTeamContext();
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
  const { formatDateAsTimeWithTimezoneNoSeconds } = useFormatting();
  const theme = useTheme();

  const setPreferredTimeToUpdate = async (value: AlDate | null) => {
    const isoTime = value?.toISO();
    if (!isNil(value) && !isNil(isoTime)) {
      try {
        const res = await teamService.updatePreferredUpdateTime(isoTime);
        if (res.isSuccess) {
          // value is only time
          const hours = value.hour;
          const minutes = value.minute;
          const seconds = value.second;
          const milliseconds = value.millisecond;
          const newPreferredUpdateTime = AlDate.now().setHour(hours).setMinute(minutes).setSecond(seconds).setMillisecond(milliseconds);

          let nextSync = newPreferredUpdateTime;
          // If selected time is less than 2 hours from last update or time is before now then +1 day
          if (activeTeam?.preferredLastDone) {
            const lastDone = AlDate.parse(activeTeam?.preferredLastDone);
            const diffInSeconds = newPreferredUpdateTime.diff(lastDone, 'seconds');
            if (diffInSeconds < 7200 || newPreferredUpdateTime.isBefore(AlDate.now())) {
              nextSync = newPreferredUpdateTime.plus({ days: 1 });
            }
          }

          toastService.success(
            `Daily data sync set to ${newPreferredUpdateTime.toFormat(HOURS_MINUTES)}. Next sync ${nextSync.toFormat(SYNC_DATE_FORMAT)}`,
          );
          if (onSuccess) {
            onSuccess();
          }
        } else {
          toastService.error('Cannot update daily time to update');
        }
      } catch (error) {
        console.error(error);
        toastService.error('Cannot update daily time to update');
      }
    }
  };

  const [isTimePickerVisible, setIsTimePickerVisible] = useState<boolean>(false);
  useEffect(() => {
    if (activeTeam?.preferredUpdateTime) {
      setIsTimePickerVisible(true);
    } else {
      setIsTimePickerVisible(false);
    }
  }, [activeTeam]);

  const onSetTimeButtonClicked = () => {
    setIsTimePickerOpen(true);
    setIsTimePickerVisible(true);
  };

  const dateStringPieces = formatDateAsTimeWithTimezoneNoSeconds(new Date().toString()).split(' ');
  let timeZoneName = '';
  if (dateStringPieces.length > 1) {
    timeZoneName = dateStringPieces[dateStringPieces.length - 1];
  }

  const rotate = keyframes`
  	0% {
  		transform: rotate(0deg);
      opacity: 0
    }
      
    33% {
      transform: rotate(120deg);
      opacity: 1
    }
        
    66% {
      transform: rotate(210deg);
      opacity: 1
    }
          
    100% {
      transform: rotate(360deg);
      opacity: 0
  	}
  `;

  return (
    <>
      {activeTeam && (
        <Tooltip
          title={
            !isTimePickerOpen
              ? 'Displayed time is your local time. Set your reports to update ~30min before your day starts to ensure you have access to the latest data.'
              : ''
          }
        >
          <div className="flex items-center">
            {isTimePickerVisible && activeTeam ? (
              <TimePicker
                key={`preferred-time-picker-${isTimePickerOpen ? 'open' : 'closed'}`} // fixes flickering issue
                value={!isNil(activeTeam?.preferredUpdateTime) ? AlDate.fromISO(activeTeam.preferredUpdateTime)?.toRaw() : null}
                open={isTimePickerOpen}
                onClose={() => setIsTimePickerOpen(false)}
                onOpen={() => setIsTimePickerOpen(true)}
                onAccept={(newValue) => setPreferredTimeToUpdate(AlDate.fromRaw(newValue))}
                format="HH:mm"
                ampm={false}
                ampmInClock={false}
                slots={{
                  textField: (textFieldProps) => (
                    <TextField
                      {...textFieldProps}
                      onClick={() => {
                        setIsTimePickerOpen(true);
                      }}
                      className="rounded-md"
                      sx={{
                        border: `1px solid ${theme.palette.divider}`,
                        '& .MuiOutlinedInput-notchedOutline': { border: 'red' }, // If using "outlined" variant
                        '& .MuiOutlinedInput-input': {
                          textAlign: 'center',
                        },
                        marginY: 0,
                      }}
                      slotProps={{
                        input: {
                          ...textFieldProps.InputProps,
                          className:
                            'text-gray-800 font-bold bg-white pb-[0.5px] flex items-center justify-center rounded-md hover:cursor-pointer hover:bg-primary-50 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700',
                          style: {
                            width: '200px',
                          },
                          startAdornment: (
                            <InputAdornment position="start" className="mx-0">
                              <AccessTimeIcon className="text-gray-800" fontSize="small" />
                              <Typography variant="body2" className="ml-1 text-gray-800 font-semibold leading-4">
                                Sync
                              </Typography>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end" className="ml-0 mr-0">
                              <Typography variant="body2" className="text-gray-800 font-medium leading-4">
                                {timeZoneName}
                              </Typography>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  ),
                }}
              />
            ) : (
              <Button
                color="primary"
                onClick={onSetTimeButtonClicked}
                style={{ width: BUTTON_WIDTH }}
                sx={
                  isEmbeddedInTopBar
                    ? {
                        '--offset': '2px', // Offset for the inner button area
                        position: 'relative',
                        overflow: 'hidden',
                        zIndex: 0,
                        background: theme.palette.background.default,

                        // Rotating conic gradient for border
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          scale: 5,
                          opacity: 0,
                          background: 'conic-gradient(transparent, transparent, transparent, transparent, #F57335 270deg)',
                          animation: `${rotate} 3s linear 2`,
                          zIndex: -1, // Keep below everything
                          transformOrigin: 'center center',
                        },

                        // Mask for the button's inner content
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          inset: 'var(--offset)',
                          background: theme.palette.background.default, // Matches the theme background dynamically
                          borderRadius: 'inherit', // Ensures masking follows the button's shape
                          zIndex: 0, // Keep below the text but above the gradient
                        },

                        '&:hover::before': {
                          background: theme.palette.action.hover,
                        },

                        '&:hover::after': {
                          background: theme.palette.action.hover,
                        },
                      }
                    : undefined
                }
                className={
                  isEmbeddedInTopBar
                    ? `relative h-9 flex cursor-pointer justify-center rounded-md p-2 font-semibold text-gray-850  hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700`
                    : ''
                }
              >
                <div className="flex flex-row gap-2 items-center relative z-10">
                  <ScheduleIcon fontSize="small" /> Set Daily Sync
                </div>
              </Button>
            )}
          </div>
        </Tooltip>
      )}
    </>
  );
};
