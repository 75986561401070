import { ContextKey } from '@/types/context-shared';
import { DeleteSweep } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Button, DialogActions, Divider, IconButton, Typography } from '@mui/material';
import { cloneDeep, isEmpty, isNil } from 'lodash-es';
import { Dispatch, FunctionComponent, SetStateAction, useRef, useState } from 'react';
import { PendingFiltersContext } from './context/PendingFiltersContext';
import SavedPresetsPanel from './filter-presets/SavedPresetsPanel';
import SaveFiltersButton from './filter-presets/SaveFiltersButton';
import { FilterRow } from './FilterRow';
import useSavedFilterPresets from './hooks/useSavedFilterPresets';
import { AlFilterModel, getUpdatedFiltersValue } from './models/AlFilterModel';
import { FilterPresetModel } from './models/FilterPresetModel';
import { FilterKey } from './types/FilterKey';

interface FilterBuilderProps {
  onSearch: (filters: AlFilterModel[]) => void;
  filtersDefaultValue: AlFilterModel[];
  availableFilters: AlFilterModel[];
  onCancel: () => void;
  onClose: () => void;
  selectedPreset: FilterPresetModel | 'new' | null;
  setSelectedPreset: Dispatch<SetStateAction<'new' | FilterPresetModel | null>>;
  contextKey?: ContextKey;
}

export const FilterBuilder: FunctionComponent<FilterBuilderProps> = ({
  onSearch,
  filtersDefaultValue,
  availableFilters,
  onCancel,
  onClose,
  contextKey,
  selectedPreset: selectedPresetExternal,
  setSelectedPreset: setSelectedPresetExternal,
}) => {
  const [pendingFilters, setPendingFilters] = useState<AlFilterModel[]>(cloneDeep(filtersDefaultValue));
  const previousFilters = useRef<string>(JSON.stringify(pendingFilters));

  const showSavedFilterComponents = !!contextKey;

  const updateFilterByFilterKey = (oldKey: FilterKey, filter: AlFilterModel | null) => {
    setPendingFilters((previousValue) => {
      // Delete filter
      if (filter == null) {
        return previousValue.filter((filterItem) => filterItem.key !== oldKey);
      }
      // Update filter
      const index = previousValue.findIndex((filterItem) => filterItem.key === oldKey);
      // Transfer conditions from old filter to new filter
      // Do not transfer when the old filter has options as it usually doesn't make sense (e.g. selected campaigns to campaign state)
      if (previousValue[index].type === filter.type && isNil(previousValue[index].options)) {
        filter.conditions = cloneDeep(previousValue[index].conditions)?.map((c, ix) => {
          if (filter.isInverseFilter != previousValue[index].isInverseFilter) {
            c.operator = filter.defaultConditionOperators[ix];
          }
          return c;
        });
      }
      previousValue[index] = filter;
      return [...previousValue];
    });
  };

  const setPendingFilterValue = (filter: AlFilterModel) => {
    setPendingFilters((previousValue) => getUpdatedFiltersValue(previousValue, filter));
  };

  const usedFilterKeys = pendingFilters.map((filter) => filter.key);
  const unUsedFilters = availableFilters.filter((f) => !usedFilterKeys.includes(f.key));

  const onAddFilterClicked = () => {
    if (unUsedFilters.length > 0) {
      const newFilterToAdd = availableFilters.find((filter) => filter.key === unUsedFilters[0].key);
      if (newFilterToAdd) {
        setPendingFilters((prevFilters) => [...prevFilters, newFilterToAdd]);
      }
    }
  };

  const onApplyFiltersClicked = () => {
    onSearch(pendingFilters);
    previousFilters.current = JSON.stringify(pendingFilters); // Update the last applied filters
  };

  const onDiscardAllClicked = () => {
    const nonFilterBuilderFilters = pendingFilters.filter((f) => !f.isFilterBuilderFilter);
    setPendingFilters(nonFilterBuilderFilters);
    previousFilters.current = JSON.stringify(nonFilterBuilderFilters); // Update the last applied filters
  };

  const areFiltersUnchanged = () => {
    return JSON.stringify(pendingFilters) === previousFilters.current;
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (areFiltersUnchanged()) {
        onApplyFiltersClicked();
      } else {
        previousFilters.current = JSON.stringify(pendingFilters);
      }
    }
  };

  const nonHiddenFilters = pendingFilters.filter((f) => f.isFilterBuilderFilter);

  // Saved filter presets
  const { selectedPreset, savedPresets, setSelectedPreset, refetchSavedPresets, isLoading, isError, isExistingPresetModified } =
    useSavedFilterPresets({
      filters: pendingFilters,
      setFilters: setPendingFilters,
      contextKey,
      selectedPresetExternal,
      filtersSetOnNew: filtersDefaultValue,
      setSelectedPresetExternal,
    });

  function onFiltersSaved() {
    refetchSavedPresets();
  }

  const presetTitle =
    selectedPreset === 'new' || isNil(selectedPreset) ? (
      'Filter(s)'
    ) : (
      <span className="flex flex-row items-end gap-2">
        {selectedPreset.name}
        {isExistingPresetModified && (
          <Typography gutterBottom variant="caption">
            {' '}
            (modified)
          </Typography>
        )}
      </span>
    );

  return (
    <PendingFiltersContext.Provider value={{ filters: pendingFilters, setFilters: setPendingFilters, setFilterValue: setPendingFilterValue }}>
      {/* min h set to get scrolling in saved filters list */}
      <div className={`flex flex-row ${showSavedFilterComponents ? 'min-h-[40vh]' : 'min-h-[20vh]'} gap-2`}>
        {showSavedFilterComponents && (
          <>
            <SavedPresetsPanel
              setSelectedPreset={setSelectedPreset}
              presets={savedPresets}
              selectedPreset={selectedPreset}
              isLoading={isLoading}
              isError={isError}
              applicableFilterKeys={new Set(availableFilters.map((f) => f.key))}
              contextKey={contextKey}
              refetchSavedPresets={refetchSavedPresets}
            />
            <Divider className="mx-2" orientation="vertical" flexItem />
          </>
        )}
        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-row gap-2">
            <Typography variant="h6" className="justify-center items-center flex">
              {showSavedFilterComponents ? presetTitle : 'Filters'}
            </Typography>
            <div className="flex-grow" />

            {showSavedFilterComponents && (
              <SaveFiltersButton
                filters={pendingFilters}
                existingFilterPresets={savedPresets}
                contextKey={contextKey}
                onFiltersSaved={onFiltersSaved}
                selectedPreset={selectedPreset}
                setSelectedPreset={setSelectedPreset}
              />
            )}
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
          <div className="flex min-w-[28rem] flex-col overflow-y-auto overflow-x-hidden" onKeyUp={handleKeyUp}>
            {pendingFilters
              .filter((f) => f.isFilterBuilderFilter)
              .map((filter, index) => (
                <FilterRow
                  key={!isEmpty(filter.key) ? filter.key : index}
                  filter={filter}
                  unUsedFilters={unUsedFilters}
                  updateFilterByFilterKey={updateFilterByFilterKey}
                />
              ))}
          </div>
          <div className={`flex flex-row ${unUsedFilters.length > 0 ? 'justify-between' : 'justify-end'}`}>
            {unUsedFilters.length > 0 && (
              <Button autoFocus variant="text" startIcon={<AddIcon />} onClick={onAddFilterClicked}>
                Add New Filter
              </Button>
            )}
            {nonHiddenFilters.length > 0 && (
              <Button variant="text" startIcon={<DeleteSweep />} onClick={onDiscardAllClicked}>
                Discard All Filters
              </Button>
            )}
          </div>

          <DialogActions>
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={onApplyFiltersClicked}>
              {nonHiddenFilters.length === 0
                ? 'Apply No Filters'
                : nonHiddenFilters.length === 1
                  ? 'Apply 1 Filter'
                  : `Apply ${nonHiddenFilters.length} Filters`}
            </Button>
          </DialogActions>
        </div>
      </div>
    </PendingFiltersContext.Provider>
  );
};
