import { Edit } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';

export interface IEditableLinkCallbackCellRendererParams<T = unknown> extends ICellRendererParams {
  isEditable: boolean;
  linkTooltip?: string;
  editTooltip?: string;
  hideEditTooltip?: boolean;
  callback: (rowData: T) => void;
}

const EditableLinkCallbackCellRenderer: FunctionComponent<IEditableLinkCallbackCellRendererParams<unknown>> = ({
  api,
  node,
  column,
  data,
  value,
  valueFormatted,
  isEditable = true,
  editTooltip = 'Edit',
  hideEditTooltip,
  linkTooltip,
  callback,
}) => {
  const handleClick = () => {
    if (data) {
      callback(data);
    }
  };

  const startEditing = () => {
    if (!column || node.rowIndex === null) return;
    api.startEditingCell({
      rowIndex: node.rowIndex,
      colKey: column.getId(),
    });
  };

  return (
    <div className={`flex relative w-full`}>
      <div className="flex-1 ">
        <Tooltip title={linkTooltip}>
          <span onClick={handleClick} className="cursor-pointer truncate group underline underline-offset-2 hover:text-primary-700">
            {valueFormatted ?? value}
          </span>
        </Tooltip>
      </div>
      {isEditable && (
        <Tooltip title={hideEditTooltip ? null : editTooltip}>
          <div onClick={startEditing} className="ml-1 mt-0.5 opacity-20 cursor-pointer hover:opacity-100 transition-opacity group">
            <Edit fontSize="inherit" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default EditableLinkCallbackCellRenderer;
