import { assertUnhandledCase } from '@/modules/application/utils';
import type { ColumnPinnedType, GridApi } from 'ag-grid-enterprise';
import { isString } from 'lodash-es';
import { ReactNode } from 'react';
import { ColumnId } from '../columns/columns.enum';
import { isColDef } from '../utils';

export enum ColumnCategoryID {
  ALL = 'ALL',
  ADLABS = 'ADLABS',
  METRICS = 'METRICS',
  GENERAL = 'GENERAL',
  OPTIMIZER = 'OPTIMIZER',
  PRODUCTS = 'PRODUCTS',
  SEARCH_TERMS = 'SEARCH_TERMS',
  PLACEMENTS = 'PLACEMENTS',
  OPT_GROUPS = 'OPT_GROUPS',
}

export enum ColumnGroupID {
  ALL = 'ALL',
  AD_METRICS = 'AD_METRICS',
  SELLER_METRICS = 'SELLER_METRICS',
  ADLABS_COLUMNS = 'ADLABS_COLUMNS',
  SETTINGS = 'SETTINGS',
  OPTIMIZER = 'OPTIMIZER',
  PLACEMENTS = 'PLACEMENTS',
  PRODUCTS = 'PRODUCTS',
  SEARCH_TERMS = 'SEARCH_TERMS',
  OPT_GROUPS = 'OPT_GROUPS',
  OTHER = 'OTHER',
}

export interface ColumnConfiguration {
  title: string;
  isSelected: boolean;
  tooltip?: ReactNode;
  headerClassName?: string;
  headerTooltip?: string;
  pinned?: ColumnPinnedType;
  columnID: ColumnId;
  lockPosition: boolean | 'left' | 'right' | undefined;
  lockPinned: boolean;
  lockVisible: boolean;
}

interface ColumnConfigurationMetadata {
  tooltip?: ReactNode;
}

export interface ColumnConfigurationGroup {
  id: ColumnGroupID;
  title: string;
  columns: ColumnConfiguration[];
}

export interface ColumnConfigurationCategory {
  id: ColumnCategoryID;
  title: string;
  columnGroups: ColumnConfigurationGroup[];
}

const getColumnBaseCategories = (): ColumnConfigurationCategory[] => [
  {
    id: ColumnCategoryID.OPTIMIZER,
    title: '',
    columnGroups: [{ id: ColumnGroupID.OPTIMIZER, title: 'Optimizer', columns: [] }],
  },
  {
    id: ColumnCategoryID.PLACEMENTS,
    title: '',
    columnGroups: [{ id: ColumnGroupID.PLACEMENTS, title: 'Placements', columns: [] }],
  },
  {
    id: ColumnCategoryID.SEARCH_TERMS,
    title: '',
    columnGroups: [{ id: ColumnGroupID.SEARCH_TERMS, title: 'Search Terms', columns: [] }],
  },
  {
    id: ColumnCategoryID.OPT_GROUPS,
    title: '',
    columnGroups: [{ id: ColumnGroupID.OPT_GROUPS, title: 'Optimization Groups', columns: [] }],
  },
  {
    id: ColumnCategoryID.ADLABS,
    title: 'AdLabs',
    columnGroups: [{ id: ColumnGroupID.ADLABS_COLUMNS, title: 'AdLabs', columns: [] }],
  },
  {
    id: ColumnCategoryID.ADLABS,
    title: 'Products',
    columnGroups: [{ id: ColumnGroupID.PRODUCTS, title: 'Products', columns: [] }],
  },
  {
    id: ColumnCategoryID.METRICS,
    title: 'Performance',
    columnGroups: [
      { id: ColumnGroupID.AD_METRICS, title: 'Ad Performance', columns: [] },
      { id: ColumnGroupID.SELLER_METRICS, title: 'Total Performance', columns: [] },
    ],
  },
  {
    id: ColumnCategoryID.GENERAL,
    title: 'General',
    columnGroups: [
      { id: ColumnGroupID.SETTINGS, title: 'Settings', columns: [] },
      { id: ColumnGroupID.OTHER, title: 'Other', columns: [] },
    ],
  },
];

export const organizeColumnsByCategory = (columns: ColumnConfiguration[]): ColumnConfigurationCategory[] => {
  // Clone the base structure to avoid mutating the original
  const categories = getColumnBaseCategories();

  // Map groups by their IDs for quick access
  const groupsMap: Record<ColumnGroupID, ColumnConfigurationGroup | undefined> = categories
    .flatMap((category) => category.columnGroups)
    .reduce(
      (map, group) => {
        map[group.id] = group;
        return map;
      },
      {} as Record<ColumnGroupID, ColumnConfigurationGroup>,
    );

  // Organize columns into groups
  for (const column of columns) {
    switch (column.columnID) {
      case ColumnId.IMPRESSIONS:
      case ColumnId.CLICKS:
      case ColumnId.ORDERS:
      case ColumnId.UNITS:
      case ColumnId.CTR:
      case ColumnId.CVR:
      case ColumnId.CPC:
      case ColumnId.SPEND:
      case ColumnId.SALES:
      case ColumnId.SAME_SKU_ORDERS:
      case ColumnId.SAME_SKU_SALES:
      case ColumnId.OTHER_SKU_SALES:
      case ColumnId.ACOS:
      case ColumnId.ROAS:
      case ColumnId.RPC:
      case ColumnId.CPA:
      case ColumnId.AOV:
      case ColumnId.CPM:
      case ColumnId.SALES_PREVIOUS_DAYS:
      case ColumnId.SPEND_PREVIOUS_DAYS:
      case ColumnId.ACTC:
        groupsMap[ColumnGroupID.AD_METRICS]?.columns.push(column);
        break;

      case ColumnId.AD_SALES_OF_TOTAL:
      case ColumnId.TOTAL_UNITS:
      case ColumnId.AD_SPEND:
      case ColumnId.ESTIMATED_MONTHLY_AD_SPEND:
      case ColumnId.ESTIMATED_COST:
      case ColumnId.ACOTS:
      case ColumnId.TOTAL_AOV:
      case ColumnId.ASP:
      case ColumnId.TOTAL_CLICKS:
      case ColumnId.TOTAL_CPA:
      case ColumnId.TOTAL_CVR:
      case ColumnId.TOTAL_ORDERS:
      case ColumnId.ORGANIC_SALES:
      case ColumnId.ORGANIC_TRAFFIC:
      case ColumnId.TOTAL_ROAS:
      case ColumnId.UPS:
      case ColumnId.UNIT_VIEW:
      case ColumnId.TOTAL_VIEWS:
      case ColumnId.UNITS_REFUNDED:
      case ColumnId.UNITS_REFUND_RATE:
      case ColumnId.VENDOR_ACOTS:
      case ColumnId.VENDOR_AD_SALES_OF_TOTAL:
      case ColumnId.VENDOR_ASP:
      case ColumnId.CUSTOMER_RETURNS:
      case ColumnId.VENDOR_TOTAL_VIEWS:
      case ColumnId.VENDOR_ORGANIC_SALES:
      case ColumnId.VENDOR_UNIT_VIEW:
      case ColumnId.VENDOR_TOTAL_ROAS:
      case ColumnId.SHIPPED_COGS:
      case ColumnId.SHIPPED_REVENUE:
      case ColumnId.SHIPPED_UNITS:
      case ColumnId.RETURN_RATE:
      case ColumnId.TOTAL_SALES:
      case ColumnId.VENDOR_TOTAL_SALES:
      case ColumnId.VENDOR_TOTAL_UNITS:
        groupsMap[ColumnGroupID.SELLER_METRICS]?.columns.push(column);
        break;

      case ColumnId.GROUP_NAME:
      case ColumnId.CAMPAIGN_LAST_OPTIMIZED:
      case ColumnId.LAST_OPTIMIZED:
      case ColumnId.PERFORMANCE_AD_SPEND:
      case ColumnId.PERFORMANCE_AD_SALES:
      case ColumnId.PERFORMANCE_ACOS:
      case ColumnId.PERFORMANCE_TOTAL_SALES:
      case ColumnId.PERFORMANCE_ACOTS:
      case ColumnId.TARGET_ACOS:
      case ColumnId.DATA_ITEMS:
        groupsMap[ColumnGroupID.ADLABS_COLUMNS]?.columns.push(column);
        break;

      case ColumnId.ID:
      case ColumnId.CAMPAIGN_ID:
      case ColumnId.ENTITY_ID:
      case ColumnId.BID:
      case ColumnId.CAMPAIGN_NAME:
      case ColumnId.CAMPAIGN_STATE:
      case ColumnId.NAME:
      case ColumnId.STATE:
      case ColumnId.TARGETING:
      case ColumnId.ENTITY_TYPE:
      case ColumnId.MATCH:
      case ColumnId.AD_GROUP:
      case ColumnId.AD_GROUP_STATE:
      case ColumnId.CREATIVE_TYPE:
      case ColumnId.COST_TYPE:
      case ColumnId.BID_OPTIMIZATION:
      case ColumnId.START_DATE:
      case ColumnId.END_DATE:
      case ColumnId.TARGETING_TYPE:
      case ColumnId.BUDGET_TYPE:
      case ColumnId.BUDGET_AMOUNT:
      case ColumnId.BID_STRATEGY:
      case ColumnId.MULTI_AD_GROUPS_ENABLED:
      case ColumnId.SERVING_STATUS:
      case ColumnId.CREATED_AT:
      case ColumnId.UPDATED_AT:
      case ColumnId.PORTFOLIO_NAME:
      case ColumnId.CAMPAIGN_AD_TYPE:
        groupsMap[ColumnGroupID.SETTINGS]?.columns.push(column);
        break;

      case ColumnId.BIDDING_ENTITY:
      case ColumnId.OLD_VALUE:
      case ColumnId.NEW_VALUE:
      case ColumnId.DELTA:
      case ColumnId.REASONS:
        groupsMap[ColumnGroupID.OPTIMIZER]?.columns.push(column);
        break;

      case ColumnId.PLACEMENT_TYPE:
      case ColumnId.PLACEMENT_PERCENTAGE:
        groupsMap[ColumnGroupID.PLACEMENTS]?.columns.push(column);
        break;

      case ColumnId.SEARCH_TERM:
      case ColumnId.WORD_COUNT:
      case ColumnId.HARVESTED:
      case ColumnId.NEGATIVES:
        groupsMap[ColumnGroupID.SEARCH_TERMS]?.columns.push(column);
        break;

      case ColumnId.TITLE:
      case ColumnId.AVAILABILITY:
      case ColumnId.BASIS_PRICE:
      case ColumnId.PRICE_TO_PAY:
      case ColumnId.BEST_SELLER_RANK:
      case ColumnId.RANK_CHANGE:
      case ColumnId.ASIN:
      case ColumnId.PARENT_ASIN:
      case ColumnId.SKU:
      case ColumnId.CATEGORY:
      case ColumnId.BRAND:
        groupsMap[ColumnGroupID.PRODUCTS]?.columns.push(column);
        break;

      case ColumnId.GROUP_ID:
      case ColumnId.GROUP_DESCRIPTION:
      case ColumnId.GROUP_TACOS:
      case ColumnId.GROUP_PRESET:
      case ColumnId.GROUP_BID_CEILING:
      case ColumnId.GROUP_BID_FLOOR:
      case ColumnId.GROUP_OPTIMIZATION_ENABLED:
      case ColumnId.CAMPAIGN_GROUP_COUNT:
        groupsMap[ColumnGroupID.OPT_GROUPS]?.columns.push(column);
        break;

      // Add all other columns to "Other"

      case ColumnId.CHECKBOX:
      case ColumnId.CAMPAIGN_NAME_DESTINATION:
      case ColumnId.AD_GROUP_DESTINATION:
      case ColumnId.CAMPAIGN_AD_TYPE_DESTINATION:
      case ColumnId.TAGS:
      case ColumnId.REPORTED_AT:
      case ColumnId.PROFILE_NAME:
      case ColumnId.PROFILE_ID:
      case ColumnId.ACCOUNT_ID:
      case ColumnId.BILLING_STATUS:
      case ColumnId.MARKET:
      case ColumnId.CURRENCY:
      case ColumnId.TIMEZONE:
      case ColumnId.TYPE:
      case ColumnId.ONBOARD_DATE:
      case ColumnId.TEAM_ACCESS:
      case ColumnId.DEBUG:
      case ColumnId.REPORT_INFO:
      case ColumnId.DATA_STATUS:
      case ColumnId.SELLING_PARTNER_DATA_STATUS:
      case ColumnId.PROCESS_LAST:
      case ColumnId.MANUAL_UPDATE:
      case ColumnId.REMOVE_PROFILE:
      case ColumnId.JOB_ID:
      case ColumnId.REVERTED_JOB_ID:
      case ColumnId.VERSION:
      case ColumnId.APPLIED_ENTITY_COUNT:
      case ColumnId.SUCCESS_ENTITY_COUNT:
      case ColumnId.FAILED_ENTITY_COUNT:
      case ColumnId.SENTIMENT:
      case ColumnId.BID_CEILING:
      case ColumnId.REVERT_JOB:
      case ColumnId.RESTART_INITIAL_REPORTS:
      case ColumnId.DELETE_ARCHIVED_TARGETS:
      case ColumnId.CAMPAIGN_GROUP_DELETE:
      case ColumnId.ACTIONS:
      case ColumnId.ACTION:
      case ColumnId.SYNC_STV:
      case ColumnId.TEAM_NAME:
      case ColumnId.TEAM_PLAN:
      case ColumnId.TEAM_ROLE:
      case ColumnId.TEAM_DELETE:
      case ColumnId.TEAM_MEMBERS_COUNT:
      case ColumnId.TEAM_IS_AUTHORIZED:
      case ColumnId.TEAM_PROFILES_COUNT:
      case ColumnId.TEAM_OWNER:
      case ColumnId.TEAM_MEMBERS:
      case ColumnId.TEAM_PREFERRED_UPDATE_TIME:
      case ColumnId.FLOW_TYPE:
      case ColumnId.ACTION_ENTITY_TYPE:
      case ColumnId.ENTITY_TYPE_DESTINATION:
      case ColumnId.NEGATIVE_MATCH_TYPE:
      case ColumnId.NEGATIVE_CAMPAIGN:
      case ColumnId.NEGATIVE_AD_GROUP:
      case ColumnId.BID_METHOD:
      case ColumnId.BID_METHOD_VALUE:
      case ColumnId.BID_FLOOR:
      case ColumnId.USERNAME:
      case ColumnId.SOURCE_GROUP:
      case ColumnId.DESTINATION_GROUP:
      case ColumnId.STARTING_BID_GROUP:
      case ColumnId.MATCH_TYPE_GROUP:
      case ColumnId.SELLER_CENTRAL_AUTHORIZED:
      case ColumnId.WIDGETS:
      case ColumnId.PROFILES:
      case ColumnId.COLLABORATORS:
      case ColumnId.OWNER:
      case ColumnId.AMAZON_AUTH:
      case ColumnId.ORGANIZATION_NAME:
        groupsMap[ColumnGroupID.OTHER]?.columns.push(column);
        break;

      default:
        try {
          if ((column.columnID as string).startsWith('data_group_')) {
            groupsMap[ColumnGroupID.ADLABS_COLUMNS]?.columns.push(column);
          } else {
            assertUnhandledCase(column.columnID);
          }
        } catch (e) {
          console.error(e);
        }
    }
  }

  // Remove empty groups and categories
  const filteredCategories: ColumnConfigurationCategory[] = categories
    .map((category) => ({
      ...category,
      columnGroups: category.columnGroups.filter((group) => group.columns.length > 0 && group.columns.some((col) => !col.lockVisible)),
    }))
    .filter((category) => category.columnGroups.length > 0);

  // Order the columns within each group, based on the position in the ColumnId enum
  for (const category of filteredCategories) {
    for (const group of category.columnGroups) {
      group.columns.sort((a, b) => {
        const aIndex = Object.values(ColumnId).indexOf(a.columnID);
        const bIndex = Object.values(ColumnId).indexOf(b.columnID);
        return aIndex - bIndex;
      });
    }
  }

  // Add the 'ALL' category, add all columngroups to it
  const allCategory: ColumnConfigurationCategory = {
    id: ColumnCategoryID.ALL,
    title: 'All',
    columnGroups: filteredCategories.flatMap((category) => category.columnGroups),
  };

  return [allCategory, ...filteredCategories];
};

// A record of column IDs and their metadata, nullable for most columns
const COLUMN_CONFIGURATION_METADATA: Record<ColumnId, ColumnConfigurationMetadata | null> = {
  // Settings
  [ColumnId.ID]: null,
  [ColumnId.CHECKBOX]: null,
  [ColumnId.CAMPAIGN_ID]: null,
  [ColumnId.CAMPAIGN_NAME]: null,
  [ColumnId.CAMPAIGN_STATE]: null,
  [ColumnId.TARGETING]: null,
  [ColumnId.BID]: null,
  [ColumnId.NAME]: null,
  [ColumnId.STATE]: null,
  [ColumnId.MATCH]: null,
  [ColumnId.AD_GROUP]: null,
  [ColumnId.AD_GROUP_STATE]: null,
  [ColumnId.CAMPAIGN_AD_TYPE]: null,
  [ColumnId.CREATIVE_TYPE]: null,
  [ColumnId.COST_TYPE]: null,
  [ColumnId.BID_OPTIMIZATION]: null,
  [ColumnId.START_DATE]: null,
  [ColumnId.END_DATE]: null,
  [ColumnId.TARGETING_TYPE]: null,
  [ColumnId.PORTFOLIO_NAME]: null,
  [ColumnId.ENTITY_TYPE]: null,
  [ColumnId.BUDGET_TYPE]: null,
  [ColumnId.BUDGET_AMOUNT]: null,
  [ColumnId.BID_STRATEGY]: null,
  [ColumnId.MULTI_AD_GROUPS_ENABLED]: null,
  [ColumnId.SERVING_STATUS]: null,
  [ColumnId.TAGS]: null,
  [ColumnId.CREATED_AT]: null,
  [ColumnId.UPDATED_AT]: null,

  // Optimizer
  [ColumnId.BIDDING_ENTITY]: null,
  [ColumnId.OLD_VALUE]: null,
  [ColumnId.NEW_VALUE]: null,
  [ColumnId.DELTA]: null,
  [ColumnId.REASONS]: null,

  // Placements
  [ColumnId.PLACEMENT_TYPE]: null,
  [ColumnId.PLACEMENT_PERCENTAGE]: null,

  // Search Terms
  [ColumnId.SEARCH_TERM]: null,
  [ColumnId.WORD_COUNT]: null,
  [ColumnId.HARVESTED]: null,
  [ColumnId.NEGATIVES]: null,

  // AdLabs
  [ColumnId.DATA_ITEMS]: null,
  [ColumnId.LAST_OPTIMIZED]: null,

  // Products
  [ColumnId.TITLE]: null,
  [ColumnId.AVAILABILITY]: null,
  [ColumnId.BASIS_PRICE]: null,
  [ColumnId.PRICE_TO_PAY]: null,
  [ColumnId.BEST_SELLER_RANK]: null,
  [ColumnId.RANK_CHANGE]: null,
  [ColumnId.ASIN]: null,
  [ColumnId.PARENT_ASIN]: null,
  [ColumnId.SKU]: null,
  [ColumnId.CATEGORY]: null,
  [ColumnId.BRAND]: null,

  // Ad Metrics
  [ColumnId.IMPRESSIONS]: null,
  [ColumnId.CLICKS]: null,
  [ColumnId.ORDERS]: null,
  [ColumnId.UNITS]: null,
  [ColumnId.CTR]: null,
  [ColumnId.CVR]: null,
  [ColumnId.CPC]: null,
  [ColumnId.SPEND]: null,
  [ColumnId.SALES]: null,
  [ColumnId.SAME_SKU_ORDERS]: null,
  [ColumnId.SAME_SKU_SALES]: null,
  [ColumnId.OTHER_SKU_SALES]: null,
  [ColumnId.ACOS]: null,
  [ColumnId.ROAS]: null,
  [ColumnId.RPC]: null,
  [ColumnId.CPA]: null,
  [ColumnId.AOV]: null,
  [ColumnId.CPM]: null,
  [ColumnId.SALES_PREVIOUS_DAYS]: null,
  [ColumnId.SPEND_PREVIOUS_DAYS]: null,
  [ColumnId.ACTC]: {
    tooltip: <div>Average Clicks-to-Conversion (Clicks divided by Orders)</div>,
  },

  // Mapping related?
  [ColumnId.CAMPAIGN_NAME_DESTINATION]: null,
  [ColumnId.AD_GROUP_DESTINATION]: null,
  [ColumnId.CAMPAIGN_AD_TYPE_DESTINATION]: null,

  // Other
  [ColumnId.REPORTED_AT]: null,
  [ColumnId.PROFILE_NAME]: null,
  [ColumnId.PROFILE_ID]: null,
  [ColumnId.ACCOUNT_ID]: null,
  [ColumnId.BILLING_STATUS]: null,
  [ColumnId.MARKET]: null,
  [ColumnId.CURRENCY]: null,
  [ColumnId.TIMEZONE]: null,
  [ColumnId.TYPE]: null,
  [ColumnId.ONBOARD_DATE]: null,
  [ColumnId.TEAM_ACCESS]: null,
  [ColumnId.DEBUG]: null,
  [ColumnId.REPORT_INFO]: null,
  [ColumnId.DATA_STATUS]: null,
  [ColumnId.SELLING_PARTNER_DATA_STATUS]: null,
  [ColumnId.PROCESS_LAST]: null,
  [ColumnId.MANUAL_UPDATE]: null,
  [ColumnId.CAMPAIGN_LAST_OPTIMIZED]: null,
  [ColumnId.REMOVE_PROFILE]: null,
  [ColumnId.JOB_ID]: null,
  [ColumnId.REVERTED_JOB_ID]: null,
  [ColumnId.VERSION]: null,
  [ColumnId.APPLIED_ENTITY_COUNT]: null,
  [ColumnId.SUCCESS_ENTITY_COUNT]: null,
  [ColumnId.FAILED_ENTITY_COUNT]: null,
  [ColumnId.TARGET_ACOS]: null,
  [ColumnId.SENTIMENT]: null,
  [ColumnId.BID_CEILING]: null,
  [ColumnId.REVERT_JOB]: null,
  [ColumnId.RESTART_INITIAL_REPORTS]: null,
  [ColumnId.DELETE_ARCHIVED_TARGETS]: null,
  [ColumnId.GROUP_ID]: null,
  [ColumnId.GROUP_NAME]: null,
  [ColumnId.GROUP_DESCRIPTION]: null,
  [ColumnId.GROUP_TACOS]: null,
  [ColumnId.GROUP_PRESET]: null,
  [ColumnId.GROUP_BID_CEILING]: null,
  [ColumnId.GROUP_BID_FLOOR]: null,
  [ColumnId.GROUP_OPTIMIZATION_ENABLED]: null,
  [ColumnId.CAMPAIGN_GROUP_COUNT]: null,
  [ColumnId.CAMPAIGN_GROUP_DELETE]: null,
  [ColumnId.ACTIONS]: null,
  [ColumnId.ACTION]: null,
  [ColumnId.SYNC_STV]: null,
  [ColumnId.TEAM_NAME]: null,
  [ColumnId.TEAM_PLAN]: null,
  [ColumnId.TEAM_ROLE]: null,
  [ColumnId.TEAM_DELETE]: null,
  [ColumnId.TEAM_MEMBERS_COUNT]: null,
  [ColumnId.TEAM_IS_AUTHORIZED]: null,
  [ColumnId.TEAM_PROFILES_COUNT]: null,
  [ColumnId.TEAM_OWNER]: null,
  [ColumnId.TEAM_MEMBERS]: null,
  [ColumnId.TEAM_PREFERRED_UPDATE_TIME]: null,
  [ColumnId.FLOW_TYPE]: null,
  [ColumnId.ACTION_ENTITY_TYPE]: null,
  [ColumnId.ENTITY_TYPE_DESTINATION]: null,
  [ColumnId.ENTITY_ID]: null,
  [ColumnId.NEGATIVE_MATCH_TYPE]: null,
  [ColumnId.NEGATIVE_CAMPAIGN]: null,
  [ColumnId.NEGATIVE_AD_GROUP]: null,
  [ColumnId.BID_METHOD]: null,
  [ColumnId.BID_METHOD_VALUE]: null,
  [ColumnId.AD_SPEND]: null,
  [ColumnId.ESTIMATED_MONTHLY_AD_SPEND]: null,
  [ColumnId.ESTIMATED_COST]: null,
  [ColumnId.BID_FLOOR]: null,
  [ColumnId.USERNAME]: null,
  [ColumnId.SOURCE_GROUP]: null,
  [ColumnId.DESTINATION_GROUP]: null,
  [ColumnId.STARTING_BID_GROUP]: null,
  [ColumnId.MATCH_TYPE_GROUP]: null,
  [ColumnId.SELLER_CENTRAL_AUTHORIZED]: null,
  [ColumnId.WIDGETS]: null,
  [ColumnId.PROFILES]: null,
  [ColumnId.COLLABORATORS]: null,
  [ColumnId.OWNER]: null,
  [ColumnId.AMAZON_AUTH]: null,

  // SELLER CENTRAL
  [ColumnId.ACOTS]: null,
  [ColumnId.AD_SALES_OF_TOTAL]: null,
  [ColumnId.TOTAL_AOV]: null,
  [ColumnId.ASP]: null,
  [ColumnId.TOTAL_CLICKS]: null,
  [ColumnId.TOTAL_CPA]: null,
  [ColumnId.TOTAL_CVR]: null,
  [ColumnId.TOTAL_ORDERS]: null,
  [ColumnId.ORGANIC_SALES]: null,
  [ColumnId.ORGANIC_TRAFFIC]: null,
  [ColumnId.TOTAL_ROAS]: null,
  [ColumnId.TOTAL_SALES]: null,
  [ColumnId.UPS]: null,
  [ColumnId.UNIT_VIEW]: null,
  [ColumnId.TOTAL_UNITS]: null,
  [ColumnId.TOTAL_VIEWS]: null,
  [ColumnId.UNITS_REFUNDED]: null,
  [ColumnId.UNITS_REFUND_RATE]: null,

  // VENDOR CENTRAL
  [ColumnId.VENDOR_ACOTS]: null,
  [ColumnId.VENDOR_AD_SALES_OF_TOTAL]: null,
  [ColumnId.VENDOR_ASP]: null,
  [ColumnId.CUSTOMER_RETURNS]: null,
  [ColumnId.VENDOR_TOTAL_VIEWS]: null,
  [ColumnId.VENDOR_TOTAL_SALES]: null,
  [ColumnId.VENDOR_TOTAL_UNITS]: null,
  [ColumnId.VENDOR_ORGANIC_SALES]: null,
  [ColumnId.VENDOR_UNIT_VIEW]: null,
  [ColumnId.VENDOR_TOTAL_ROAS]: null,
  [ColumnId.SHIPPED_COGS]: null,
  [ColumnId.SHIPPED_REVENUE]: null,
  [ColumnId.SHIPPED_UNITS]: null,
  [ColumnId.RETURN_RATE]: null,

  // Performance
  [ColumnId.PERFORMANCE_ACOS]: {
    tooltip: <div>Your target ACOS compared to the current Month-to-Date ACOS.</div>,
  },
  [ColumnId.PERFORMANCE_ACOTS]: {
    tooltip: <div>Your target TACOS compared to the current Month-to-Date TACOS.</div>,
  },
  [ColumnId.PERFORMANCE_AD_SPEND]: {
    tooltip: (
      <div>
        Ad Spend Monthly Target compared to the current month estimate. Estimate calc: (MTD Ad Spend * Days in Month / MTD Days) / Monthly
        Target.
      </div>
    ),
  },
  [ColumnId.PERFORMANCE_AD_SALES]: {
    tooltip: (
      <div>
        Ad Sales Monthly Target compared to the current month estimate. Estimate calc: (MTD Ad Sales * Days in Month / MTD Days) / Monthly
        Target.
      </div>
    ),
  },
  [ColumnId.PERFORMANCE_TOTAL_SALES]: {
    tooltip: (
      <div>
        Total Sales Monthly Target compared to the current month estimate. Estimate calc: (MTD Total Sales * Days in Month / MTD Days) /
        Monthly Target.
      </div>
    ),
  },

  // Organization
  [ColumnId.ORGANIZATION_NAME]: null,
};

export const getColumnConfigurations = (gridApi: GridApi): ColumnConfiguration[] => {
  const columns = gridApi.getColumnDefs();
  if (columns) {
    return columns.filter(isColDef).map<ColumnConfiguration>((column) => ({
      title: column.headerName || '',
      headerClassName: isString(column.headerClass) ? column.headerClass : '',
      headerTooltip: column.headerTooltip || '',
      pinned: column.pinned,
      isSelected: !column.hide || false,
      columnID: column.colId as ColumnId,
      tooltip: COLUMN_CONFIGURATION_METADATA[column.colId as ColumnId]?.tooltip || undefined,
      lockPinned: column.lockPinned || false,
      lockPosition: column.lockPosition || false,
      lockVisible: column.lockVisible || false,
    }));
  }
  return [];
};

export const applyColumnConfigurations = (newColumnOrderAndVisibility: ColumnConfiguration[], gridApi: GridApi) => {
  const currentCols = gridApi.getColumnState();

  // Add checkbox col separately if exists
  const checkboxCol = currentCols.find((col) => col.colId === ColumnId.CHECKBOX);
  const checkboxColState = checkboxCol ? { colId: checkboxCol.colId, pinned: checkboxCol.pinned, hide: false } : null;

  const newColumns = newColumnOrderAndVisibility.map((newColumn) => {
    const currentCol = currentCols.find((col) => col.colId === newColumn.columnID);

    // Create a new object to prevent mutating the object that's in the grid
    return currentCol
      ? { ...currentCol, pinned: newColumn.pinned, hide: !newColumn.isSelected }
      : { colId: newColumn.columnID, pinned: newColumn.pinned, hide: !newColumn.isSelected };
  });

  const finalState = checkboxColState ? [checkboxColState, ...newColumns] : newColumns;

  gridApi.applyColumnState({ state: finalState, applyOrder: true });
};
