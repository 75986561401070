import { ColumnId } from '@/components/grid/columns/columns.enum';
import type { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { useEffect } from 'react';
import { useReportsContext } from '../../contexts/ReportsContext';
import { CombinedReportStatusType, ProfileTableContext, ProfileTableRow, ReportStatusInfosMapType } from '../../types/ProfilesTableTypes';

interface UseMultipleProfilesReportsInfoProps {
  profileIds: string[];
  gridApiRef: React.MutableRefObject<GridApi<ProfileTableRow> | undefined>;
  gridContextRef: React.MutableRefObject<ProfileTableContext | undefined>;
}

const useMultipleProfilesReportsInfo = ({ profileIds, gridApiRef, gridContextRef }: UseMultipleProfilesReportsInfoProps) => {
  const {
    getReportStatusInfoByProfileId,
    getSellerCentralReportStatusInfoByProfileId,
    getVendorCentralReportStatusInfoByProfileId,
    profileSyncStatus,
    getCombinedUpdatedAtByProfileId,
    isFetchingAllTeamStatuses,
  } = useReportsContext();

  function updateStatuses() {
    if (isNil(gridApiRef.current) || isNil(gridContextRef.current)) return;

    const statusMap = createReportStatusInfosMap();
    if (isEmpty(statusMap)) return;

    gridContextRef.current.reportStatusInfosMap = statusMap;

    gridApiRef.current.refreshCells({
      columns: [ColumnId.DATA_STATUS, ColumnId.SELLING_PARTNER_DATA_STATUS, ColumnId.ACTIONS, ColumnId.UPDATED_AT],
      force: true,
    });
  }

  useEffect(() => {
    updateStatuses();
  }, [isFetchingAllTeamStatuses]);

  function createReportStatusInfosMap(): ReportStatusInfosMapType {
    const reportStatusInfosMap = new Map();

    profileIds.forEach((profileId) => {
      const content: CombinedReportStatusType = {
        syncStatus: profileSyncStatus(profileId),
        ad: getReportStatusInfoByProfileId(profileId),
        seller: getSellerCentralReportStatusInfoByProfileId(profileId),
        vendor: getVendorCentralReportStatusInfoByProfileId(profileId),
        combinedLastUpdated: getCombinedUpdatedAtByProfileId(profileId),
      };

      reportStatusInfosMap.set(profileId, content);
    });

    return reportStatusInfosMap;
  }

  return { createReportStatusInfosMap, updateStatuses };
};

export default useMultipleProfilesReportsInfo;
