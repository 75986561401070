import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import InfoMessage from '@/components/feedback/InfoMessage';
import VideoModal from '@/components/modals/video-modal/VideoModal';
import useTutorialModal from '@/components/modals/video-modal/useVideoModal';
import { VideoUrl } from '@/config/urls';
import { sleep } from '@/lib/api/api-utils';
import { useTranslation } from '@/lib/i18n/useTranslate';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import UpgradeSubscriptionButton from '../../teams/components/UpgradeSubscriptionButton';
import ProfileSyncSelectButton from '../ProfileSyncSelectButton';
import OptimizationLogsTable from '../components/optimization-logs/OptimizationLogsTable';

const OptimizationLogsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeTeam } = useActiveTeamContext();

  // TUTORIAL MODAL
  const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(
    UserSettingKey.OPTIMIZATION_LOGS_TUTORIAL_DISPLAY_COUNT,
  );

  // NAVIGATE TO LOGS
  const navigate = useNavigate();
  async function onNavigateToLogs() {
    await sleep(50); // wait for 50 ms before navigate
    navigate(Routes.LOGS);
  }
  return (
    <PageLayout>
      <PageLayoutTopBar
        header={t('time_machine')}
        actions={
          <div className="flex flex-row items-center gap-2">
            {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
            <TeamSelect />
            <ProfileSyncSelectButton />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <div className="flex flex-row justify-between items-center">
          <Button onClick={() => openTutorialModal()} variant="text" startIcon={<OndemandVideoIcon />}>
            How to Use the Time Machine
          </Button>

          <div className="mb-2">
            <InfoMessage
              content={
                <div className="flex flex-row items-center">
                  This page contains only Bid Optimizer changes, other logs can be found in{' '}
                  <Button className="ml-1" variant="text" onClick={onNavigateToLogs}>
                    Logs
                  </Button>
                </div>
              }
            />
          </div>
        </div>

        <VideoModal
          isOpen={isTutorialModalOpen}
          onClose={onTutorialModalClose}
          url={VideoUrl.TIME_MACHINE}
          title={'How to Use the Time Machine'}
        />

        <AlErrorBoundary>
          <div className="flex flex-grow">
            <OptimizationLogsTable />
          </div>
        </AlErrorBoundary>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default OptimizationLogsPage;
