export enum BudgetUpdateType {
  SET_BUDGET_TO_AMOUNT = 'SET_BUDGET_TO_AMOUNT',
  INCREASE_BUDGET_BY_AMOUNT = 'INCREASE_BUDGET_BY_AMOUNT',
  DECREASE_BUDGET_BY_AMOUNT = 'DECREASE_BUDGET_BY_AMOUNT',
  INCREASE_BUDGET_BY_PERCENTAGE = 'INCREASE_BUDGET_BY_PERCENTAGE',
  DECREASE_BUDGET_BY_PERCENTAGE = 'DECREASE_BUDGET_BY_PERCENTAGE',

  NO_CHANGE = 'NO_CHANGE',
}

export type BudgetUpdateOption = {
  value: BudgetUpdateType;
  label: string;
};
