import { ProfileModel } from '@/modules/profiles/types/ProfileModel';
import { SavedFilterLevel } from '../api/filters-contracts';
import { FilterPresetModel } from '../models/FilterPresetModel';
import { FilterKey } from '../types/FilterKey';
import { ALL_PRESETS, WrappedSavedFilterPreset } from '../types/SavedPresets';

export function splitAndWrapPresets(
  presets: FilterPresetModel[],
  applicableFilterKeys: Set<FilterKey>,
  activeProfile: ProfileModel | undefined,
  selectedLevel: SavedFilterLevel | typeof ALL_PRESETS,
  searchText?: string,
) {
  let filteredPresets = selectedLevel === ALL_PRESETS ? presets : presets.filter((preset) => preset.level === selectedLevel);

  if (searchText) {
    filteredPresets = filteredPresets.filter((preset) => preset.name.toLowerCase().includes(searchText.toLowerCase()));
  }

  const alreadyAssignedFilterPresetIds = new Set<number>();

  // NON-APPLICABLE
  // Contains profile specific filters - not applicable
  const notApplicableDueProfilePresets = filteredPresets
    .filter((preset) => preset.filters.some((f) => f.isProfileSpecific) && preset.profileId !== activeProfile?.id)
    .sort((a, b) => a.name.localeCompare(b.name));
  notApplicableDueProfilePresets.map((preset) => alreadyAssignedFilterPresetIds.add(preset.id));

  // Contains page specific filters - not applicable
  const notApplicableDuePagePresets = filteredPresets
    .filter((preset) => preset.getNonApplicableFilters(applicableFilterKeys).length > 0 && !alreadyAssignedFilterPresetIds.has(preset.id))
    .sort((a, b) => a.name.localeCompare(b.name));
  notApplicableDuePagePresets.map((preset) => alreadyAssignedFilterPresetIds.add(preset.id));

  // APPLICABLE
  // Filters that are current profile specific and applicable on the current page
  const currentProfilePresets = filteredPresets
    .filter(
      (preset) =>
        preset.profileId === activeProfile?.id &&
        preset.filters.some((f) => f.isProfileSpecific) &&
        !alreadyAssignedFilterPresetIds.has(preset.id),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  currentProfilePresets.map((preset) => alreadyAssignedFilterPresetIds.add(preset.id));

  // Filters that are applicable on the current page (all the rest)
  const generalPresets = filteredPresets
    .filter((preset) => !alreadyAssignedFilterPresetIds.has(preset.id))
    .sort((a, b) => a.name.localeCompare(b.name));

  const wrappedProfilePresets: WrappedSavedFilterPreset[] = currentProfilePresets.map((preset) => ({
    preset,
  }));

  const wrappedGeneralPresets: WrappedSavedFilterPreset[] = generalPresets.map((preset) => ({
    preset,
  }));

  const wrappedNotApplicableDueProfilePresets: WrappedSavedFilterPreset[] = notApplicableDueProfilePresets.map((preset) => ({
    preset,
    disabled: true,
    tooltip: (
      <>
        Currently not applicable because it contains filters specific for an other profile:
        <ul style={{ listStyle: 'disc', marginLeft: '1em' }}>
          {preset.filters
            .filter((f) => f.isProfileSpecific)
            .map((f) => (
              <li key={f.key}>{f.shortName}</li>
            ))}
        </ul>
      </>
    ),
  }));

  const wrappedNotApplicableDuePagePresets: WrappedSavedFilterPreset[] = notApplicableDuePagePresets.map((preset) => {
    const nonApplicableFilters = preset.getNonApplicableFilters(applicableFilterKeys);

    return {
      preset,
      disabled: true,
      tooltip: (
        <>
          Currently not applicable because it contains filters specific for another view:
          <ul style={{ listStyle: 'disc' }}>
            {nonApplicableFilters.map(({ key, shortName }) => (
              <li key={key}>{shortName}</li>
            ))}
          </ul>
        </>
      ),
    };
  });

  return {
    currentProfilePresets: wrappedProfilePresets,
    generalPresets: wrappedGeneralPresets,
    notApplicablePresets: [...wrappedNotApplicableDueProfilePresets, ...wrappedNotApplicableDuePagePresets],
  };
}
