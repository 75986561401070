import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import * as Sentry from '@sentry/react';
import { MinMaxLimit } from '../types/MinMaxLimit';
import { BudgetUpdateOption, BudgetUpdateType } from '../types/BudgetUpdateType';
import { BudgetLimits, DEFAULT_BUDGET_DECIMALS, marketplaceBudgetLimits } from '../types/budget-limits';
import useFormatting from '@/hooks/useFormatting';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useMemo } from 'react';
import { round } from 'lodash-es';
import { ProfileType } from '@/modules/users/types/ProfileType';
import { adTypeMappingForLimitWarnings } from '../types/AdTypeMapping';

function useBudgetLimits() {
  const { formatCurrency } = useFormatting();

  const { activeProfile } = useActiveTeamContext();
  const currentProfileMarketplaceBudgetLimits: BudgetLimits | undefined = useMemo(() => {
    if (!activeProfile?.countryCode || !marketplaceBudgetLimits[activeProfile?.countryCode]) {
      Sentry.captureMessage(`currentProfileMarketplaceBudgetLimits: unknown country code: ${activeProfile?.countryCode}`, 'info');
      console.log('Unknown country code: ', activeProfile?.countryCode);
      return undefined;
    }

    return marketplaceBudgetLimits[activeProfile?.countryCode];
  }, [activeProfile?.countryCode]);

  const getBudgetLimits = (adType: CampaignAdType, budgetLimits: BudgetLimits, profileType: ProfileType): MinMaxLimit => {
    const minMaxLimitByProfileType = budgetLimits[adType];

    if (profileType == ProfileType.VENDOR) {
      return { min: minMaxLimitByProfileType.vendor.min, max: minMaxLimitByProfileType.vendor.max };
    }

    // Both SELLER and AGENCY use seller limits
    return { min: minMaxLimitByProfileType.seller.min, max: minMaxLimitByProfileType.seller.max };
  };

  const clampBudget = (budgetAmount: number, min: number, max: number, adTypeDescription: string, warnings: Set<string>) => {
    if (budgetAmount < min) {
      const message = `${adTypeDescription} budget is too low. Adjusted to the minimum of ${formatCurrency(min)}`;
      warnings.add(message);
      return min;
    }

    if (budgetAmount > max) {
      const message = `${adTypeDescription} budget is too high. Adjusted to the maximum of ${formatCurrency(max)}`;
      warnings.add(message);
      return max;
    }
    return budgetAmount;
  };

  const getClampedBudgetAmountWithWarnings = (
    budgetAmount: number,
    adType: CampaignAdType,
    profileType: ProfileType,
    warnings: Set<string>,
  ): number => {
    if (!currentProfileMarketplaceBudgetLimits) {
      return budgetAmount;
    }

    const limits = getBudgetLimits(adType, currentProfileMarketplaceBudgetLimits, profileType);

    // For example we don't allow any decimals for Japanese yen
    if (currentProfileMarketplaceBudgetLimits.decimals) {
      budgetAmount = round(budgetAmount, currentProfileMarketplaceBudgetLimits.decimals);
    } else {
      budgetAmount = round(budgetAmount, DEFAULT_BUDGET_DECIMALS);
    }

    if (!limits) {
      return budgetAmount; // Return old value if there are no limits (handles unknown ad type)
    }

    const adTypeDescription = `${'Sponsored ' + adTypeMappingForLimitWarnings[adType] || adType}`;

    return clampBudget(budgetAmount, limits.min, limits.max, adTypeDescription, warnings);
  };

  function getNewBudgetAmountByUpdateType(value: number, updateType: BudgetUpdateType, currentBudget: number): number {
    switch (updateType) {
      case BudgetUpdateType.SET_BUDGET_TO_AMOUNT:
        return value;
      case BudgetUpdateType.INCREASE_BUDGET_BY_AMOUNT:
        return currentBudget + value;
      case BudgetUpdateType.DECREASE_BUDGET_BY_AMOUNT:
        return currentBudget - value;
      case BudgetUpdateType.INCREASE_BUDGET_BY_PERCENTAGE:
        return currentBudget * (1 + value / 100);
      case BudgetUpdateType.DECREASE_BUDGET_BY_PERCENTAGE:
        return currentBudget * (1 - value / 100);
      case BudgetUpdateType.NO_CHANGE:
      default:
        return currentBudget;
    }
  }

  function getBudgetUpdateOptions(currencySymbol: string): BudgetUpdateOption[] {
    return [
      { value: BudgetUpdateType.NO_CHANGE, label: 'No change' },
      { value: BudgetUpdateType.SET_BUDGET_TO_AMOUNT, label: `Set budget to (${currencySymbol})` },
      { value: BudgetUpdateType.INCREASE_BUDGET_BY_AMOUNT, label: `Increase budget by (${currencySymbol})` },
      { value: BudgetUpdateType.DECREASE_BUDGET_BY_AMOUNT, label: `Decrease budget by (${currencySymbol})` },
      { value: BudgetUpdateType.DECREASE_BUDGET_BY_PERCENTAGE, label: 'Decrease budget by (%)' },
      { value: BudgetUpdateType.INCREASE_BUDGET_BY_PERCENTAGE, label: 'Increase budget by (%)' },
    ];
  }

  return {
    getNewBudgetAmountByUpdateType,
    getBudgetUpdateOptions,
    getClampedBudgetAmountWithWarnings,
  };
}

export default useBudgetLimits;
