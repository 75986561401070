import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { PieGraphWidgetConfiguration } from './PieGraphWidgetConfiguration';

import PieGraphWidgetConfigurationForm from './PieGraphWidgetConfigurationForm';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import DashboardWidgetProvider from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DashboardWidgetConfigurationDrawer';

import useFormatting from '@/hooks/useFormatting';
import { ReactECharts } from '@/components/chart/ReactEcharts';
import {
  PieSeriesOption,
  EChartsOption,
  DefaultLabelFormatterCallbackParams,
  TooltipComponentOption,
  TooltipComponentFormatterCallbackParams,
} from 'echarts';
import { METRIC_COLORS, getConfigForMetric } from '@/components/metrics/MetricsConfig';
import { isBaseWidgetConfigurationEqual } from '@/modules/dashboards/lib/areWidgetsEqual';
import { isArray, isEmpty } from 'lodash-es';
import { PieGraphWidgetDataDTO, WidgetDataDTO } from '@/modules/dashboards/api/dashboard/data/dashboard-data.contracts';
import DashboardWidgetFilterIndicator from '../../dashboard-widget/DashboardWidgetFilterIndicator';
import DashboardWidgetTopBar from '../../dashboard-widget/DashboardWidgetTopBar';
import { EntityType } from '@/modules/dashboards/types/EntityType';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { useDashboardTranslations } from '@/modules/dashboards/hooks/useDashboardTranslations';
import DashboardWidgetRichTitle from '../../dashboard-widget/DashboardWidgetRichtTitle';
import useUtils from '@/hooks/useUtils';

interface PieGraphWidgetProps extends IDashboardWidget<PieGraphWidgetConfiguration> {
  data?: WidgetDataDTO;
  isFetchingDataForWidget?: boolean;
  configurationId: string;
}

const PieGraphWidget: FunctionComponent<PieGraphWidgetProps> = ({ configuration, id, data, isFetchingDataForWidget }) => {
  const { getShortFormatterForMetricField, formatPercent } = useFormatting();
  const { getTranslatedLabel } = useDashboardTranslations();
  const dashboardCurrency = useDashboardContextValue((context) => context.dashboard?.dto.currency);
  const { extractPlainText } = useUtils();

  const getEchartsOptions = useCallback((data: PieGraphWidgetDataDTO, widgetConfiguration: PieGraphWidgetConfiguration): EChartsOption => {
    if (!data || isEmpty(data.data)) {
      return {};
    }

    const metricConfig = getConfigForMetric(widgetConfiguration.selectedMetric);
    if (!metricConfig || !data) {
      return {};
    }

    const pieSeries: PieSeriesOption = {
      type: 'pie',
      top: '15px',
      bottom: '15px',
      right: '15px',
      left: '15px',
      radius: ['40%', '70%'],
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2,
      },
      label: {
        alignTo: 'edge',
        formatter: (params: DefaultLabelFormatterCallbackParams) => {
          const labelText = getTranslatedLabel({
            entityType: configuration.entityType,
            campaignGroupByValue: configuration.campaignGroupByValue,
            targetsGroupByValue: configuration.targetsGroupByValue,
            placementGroupByValue: configuration.placementGroupByValue,
            productGroupByValue: configuration.productGroupByValue,
            value: params.name,
          });

          return `{bold|${labelText}}\n${getShortFormatterForMetricField(widgetConfiguration.selectedMetric)(params.value as number, {
            customCurrencyCode: dashboardCurrency,
          })} (${formatPercent((params.percent as number) / 100)})`;
        },
        minMargin: 5,
        edgeDistance: 10,
        lineHeight: 15,
        rich: {
          bold: {
            fontSize: 12,
            color: '#444',
            fontWeight: 'bold',
          },
        },
      },
      labelLine: {
        length: 15,
        length2: 0,
        maxSurfaceAngle: 80,
      },
      // Might want to enable this later on again.
      // labelLayout: (params) => {
      //   try {
      //     if (isNil(echarts) || !echarts.getWidth) {
      //       return {};
      //     }

      //     const isLeft = params.labelRect.x < echarts.getWidth() / 2;
      //     const points = params.labelLinePoints as number[][];
      //     // Update the end point.
      //     points[2][0] = isLeft ? params.labelRect.x : params.labelRect.x + params.labelRect.width;

      //     return {
      //       labelLinePoints: points,
      //     };
      //   } catch (error) {
      //     console.error(error);
      //     return {};
      //   }
      // },
      data: data.data.map((item, index) => {
        const value = item.value;
        const color = METRIC_COLORS[index];
        return {
          value: value,
          name: item.label,
          itemStyle: {
            color: color,
          },
        };
      }),
    };

    const options: EChartsOption = {
      series: pieSeries,
      tooltip: {
        trigger: 'item',
        appendToBody: true,

        axisPointer: {
          type: 'cross',
        },
        formatter: function (params: TooltipComponentFormatterCallbackParams): string {
          if (!isArray(params)) {
            return `<b>${params.name}</b><br/>${params.marker}${getShortFormatterForMetricField(widgetConfiguration.selectedMetric)(
              params.value as number,
              {
                customCurrencyCode: dashboardCurrency,
              },
            )} (${formatPercent((params.percent as number) / 100)})`;
          } else {
            return '';
          }
        },
      } as TooltipComponentOption,
    };

    return options;
  }, []);

  const echartsOptions = useMemo(() => {
    return data ? getEchartsOptions(data as PieGraphWidgetDataDTO, configuration) : {};
  }, [configuration.entityType, data, getEchartsOptions]);

  const hasFilters =
    (!isEmpty(configuration.campaignFilters) &&
      (configuration.entityType === EntityType.CAMPAIGN || configuration.entityType === EntityType.PROFILE)) ||
    (!isEmpty(configuration.targetFilters) && configuration.entityType === EntityType.TARGET);

  const [isMouseOnWidget, setIsMouseOnWidget] = useState(false);

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={isFetchingDataForWidget} isConfigured={configuration.isConfigured}>
      <DashboardWidgetContent onMouseChange={setIsMouseOnWidget}>
        <DashboardWidgetTopBar isMouseOnWidget={isMouseOnWidget}>
          <DashboardWidgetRichTitle configuration={configuration}></DashboardWidgetRichTitle>
          <DashboardWidgetFilterIndicator visible={hasFilters && !isMouseOnWidget} widgetId={id} />
        </DashboardWidgetTopBar>
        <DashboardWidgetConfigurationButton isMouseOnWidget={isMouseOnWidget}></DashboardWidgetConfigurationButton>

        <div className="h-full w-full flex-1 flex  ">
          {data && configuration.selectedMetric && (
            <ReactECharts
              settings={{
                notMerge: true,
              }}
              option={echartsOptions}
            />
          )}
        </div>
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={extractPlainText(configuration.title)}>
        <PieGraphWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default memo(PieGraphWidget, isBaseWidgetConfigurationEqual);
