import FilterBarCard from '@/components/cards/FilterBarCard';
import FilterControls from '@/components/filter-builder/FilterControls';
import { SearchBox } from '@/components/filter-builder/SearchBox';
import { CampaignNameFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import GridTools from '@/components/grid/components/GridTools';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import ExpandToggleButton from '@/modules/application/components/ExpandToggleButton';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import useAvailablePlacementFilters from '@/modules/campaigns/hooks/useAvailablePlacementFilters';
import { MetricsGraphTablePageContext } from '@/types/context-shared';
import type { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, MutableRefObject } from 'react';
import { PlacementsWithTimeline } from '../api/placements-contracts';

interface PlacementsFilterBarProps {
  withTimeline: PlacementsWithTimeline | undefined;
  pageVariables: MetricsGraphTablePageContext;
  gridApiRef: MutableRefObject<GridApi<unknown> | null>;
  onExpandTable: () => void;
  isExpanded: boolean;
}

const PlacementsFilterBar: FunctionComponent<PlacementsFilterBarProps> = ({
  withTimeline,
  pageVariables,
  gridApiRef,
  onExpandTable,
  isExpanded,
}) => {
  const { filters, setFilters, setFilterValue, setFilterValues, gridToggles, setGridToggles } = pageVariables;
  const { getComparisonDataMissingMessage } = useComparisonMissing({});

  const { availablePlacementFilters: availableFilters } = useAvailablePlacementFilters();
  const defaultFilterKey = FilterKey.CAMPAIGN_ID;

  return (
    <FilterBarCard>
      <SearchBox
        filterKey={FilterKey.CAMPAIGN_NAME}
        newFilterModel={() => new CampaignNameFilterModel()}
        placeholder="Search Campaign"
        filters={filters}
        setFilterValue={setFilterValue}
        setFilters={setFilters}
      />

      <FilterControls
        filters={filters}
        setFilters={setFilters}
        availableFilters={availableFilters}
        defaultFilterKey={defaultFilterKey}
        contextKey={pageVariables.contextKey}
      />

      <div className="flex flex-grow"></div>
      <div className="flex h-full justify-center items-center gap-x-3">
        <ExpandToggleButton isExpanded={isExpanded} onExpandToggled={onExpandTable} />

        <DateRangeButton
          title="Range"
          filters={filters}
          setFilterValues={setFilterValues}
          tooltip="Date range used to stats"
          comparisonRangeWarning={getComparisonDataMissingMessage(withTimeline?.isComparisonDataMissing ?? false)}
        />
      </div>
      <GridTools gridToggles={gridToggles} setGridToggles={setGridToggles} gridApiRef={gridApiRef} />
    </FilterBarCard>
  );
};

export default PlacementsFilterBar;
