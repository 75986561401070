import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ChipArrayCellRenderer, { IChipArrayCellRendererParams } from '@/components/grid/cells/ChipArrayCellRenderer';
import EditableCellRenderer from '@/components/grid/cells/EditableCellRenderer';
import EnabledPausedArchivedStateCellRenderer from '@/components/grid/cells/EnabledPausedArchivedStateCellRenderer';
import { ITextCellRendererParams, TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import { StringToCount } from '@/components/grid/helpers';
import useAggregators from '@/components/grid/hooks/useAggregators';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import useDynamicHeight, { EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN } from '@/components/grid/hooks/useDynamicHeight';
import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import useToggles from '@/components/grid/hooks/useToggles';
import { AlColDef, CELL_CLASS_CONTENTS_RIGHT, DEFAULT_GRID_OPTIONS_ROW_GROUPS, ExpandedGridContext } from '@/components/grid/types';
import { MetricModel } from '@/components/metrics/models/MetricModel';
import { AdLabsColorVariant } from '@/config/theme/color.type';
import { useLayoutContext } from '@/contexts/LayoutContext';
import { useDataGroups } from '@/hooks/useDataGroups';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import { AlDate } from '@/lib/date/AlDate';
import { useTranslation } from '@/lib/i18n/useTranslate';
import useBidLimits from '@/modules/amazon-constants/hooks/useBidLimits';
import { isRecord, sortRecordByKeyAndGetColonDelimitedString } from '@/modules/application/utils';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import {
  AmazonBrandsBidOptimization,
  BidStrategyType,
  CampaignAdType,
  CampaignUpdateDTO,
  CostType,
  CreativeType,
  MultiAdGroupsEnabledType,
  PlacementType,
  PlacementUpdate,
} from '@/modules/optimizer/api/campaign/campaign-contracts';
import { campaignService } from '@/modules/optimizer/api/campaign/campaign-service';
import { UserSettingKey } from '@/modules/users';
import { toastService } from '@/services/toast.service';
import { getColorForText } from '@/types/colors.enum';
import { MetricsGraphTablePageContext } from '@/types/context-shared';
import { Card } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import type {
  BodyScrollEvent,
  CellClickedEvent,
  CellValueChangedEvent,
  GridReadyEvent,
  IRowNode,
  ITooltipParams,
  SelectionChangedEvent,
  ValueParserParams,
} from 'ag-grid-community';
import type { ColDef, GridApi, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { PlacementsWithTimeline } from '../../api/placements-contracts';
import { invalidateAll_placementsWithTimelineQueryKeys } from '../../api/placements-service';
import { PlacementModel, SelectedPlacement } from '../../models/PlacementsModel';
import { generatePlacementsTableColumnState } from './placements-table.default-column-state';

interface PlacementsTableProps {
  withTimeline: PlacementsWithTimeline | undefined;
  isLoading: boolean;
  selectedPlacements: SelectedPlacement[];
  setSelectedPlacements: (selectedPlacements: SelectedPlacement[]) => void;
  PlacementsLoadingErrorMessage: string;
  isPlacementsLoadingError: boolean;
  pageVariables: MetricsGraphTablePageContext;
  noTopBorderRadius?: boolean;
  onGridReadyCallback?: (params: GridReadyEvent) => void;
  isExpanded: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface PlacementsGridContext extends ExpandedGridContext {}

const PlacementsTable: FunctionComponent<PlacementsTableProps> = ({
  withTimeline,
  isLoading,
  selectedPlacements,
  setSelectedPlacements,
  PlacementsLoadingErrorMessage,
  isPlacementsLoadingError,
  pageVariables,
  noTopBorderRadius = false,
  onGridReadyCallback,
  isExpanded,
}) => {
  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.PLACEMENTS_TABLE_COLUMN_STATE,
    generatePlacementsTableColumnState(),
  );

  const rowData = withTimeline?.placements ?? [];
  const isComparisonDataMissing = withTimeline?.isComparisonDataMissing ?? false;

  const gridApiRef = useRef<GridApi<PlacementModel>>();
  const gridContextRef = useRef<PlacementsGridContext>();

  const { onSourceScroll } = useLayoutContext();
  const { heightCssValue, onGridReadyCallback: onGridReadyForDynamicHeight } = useDynamicHeight({});
  const { t } = useTranslation();
  const { toastWarnWithSetMessages } = useHelperComponents();

  const pendingPlacementsUpdateCellLocationsRef = useRef<Record<string, IRowNode>>({});

  const {
    metricFieldWithChangePercentage,
    optGroupNameColumnType,
    checkboxColumnType,
    bidStrategyColumnType,
    salesPreviousDaysType,
    spendPreviousDaysType,
    lastOptimizedType,
    campaignLastOptimizedType,
  } = useColumnTypes();
  const queryClient = useQueryClient();
  const { getCampaignAdTypeCellRendererParams, getPlacementTypeCellRendererParams } = useColDefsFunctions();

  // Additional data sources
  const { onGridReadyForMetricColumnAggregates, metricColumnAggregates } = useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates: withTimeline?.metrics ? MetricModel.arrayToMetricAggregates(withTimeline.metrics) : undefined,
  });

  const { onGridReadyForComparisonMissing } = useComparisonMissing({ gridApiRef, gridContextRef, isComparisonDataMissing });
  const { gridToggles } = pageVariables;
  const { gridToggleParams, activeMetricComparator } = useToggles<PlacementModel>({
    gridApiRef,
    gridContextRef,
    gridToggles,
  });

  // Formatters
  const { formatCurrency, formatDateStringTimeNoHours } = useFormatting();

  const {
    enabledPausedArchivedStateAggFunc,
    targetsAggFunc,
    metricDataWithPreviousDaysAggFunc,
    stringToCountAggFunc,
    campaignNameAggFunc,
    portfolioAggFunc,
    metricsDataAggFunc,
    lastOptimizedAggFunc,
  } = useAggregators();

  const { dataGroups, dataItemToInfoMap, groupIdToItemSetMap } = useDataGroups([DataGroupType.CAMPAIGN]);

  function disabledEditingTooltip(params: ITooltipParams) {
    if (params.data?.adType === CampaignAdType.BRANDS) {
      if (params.data?.placementType === PlacementType.PLACEMENT_TOP) {
        return 'Top of Search is not editable for Sponsored Brands';
      } else if (params.data?.creativeType === CreativeType.BRAND_VIDEO || params.data?.creativeType === CreativeType.VIDEO) {
        return 'Amazon does not apply bid adjustments to Video ads';
      } else if (params.data?.bidOptimization == AmazonBrandsBidOptimization.AUTO) {
        return 'Bid Opt. is set to Auto and placements cannot be edited';
      }
    } else if (params.data?.endDate && AlDate.parse(params.data.endDate).isBefore(AlDate.now())) {
      return 'Campaign has ended';
    }
    return undefined;
  }

  // EDITING
  const { getClampedPlacementValueWithWarnings } = useBidLimits();

  const columnDefs: ColDef<PlacementModel>[] = useMemo(() => {
    const colDefs: AlColDef<PlacementModel>[] = [
      {
        colId: ColumnId.CHECKBOX,
        tooltipValueGetter: disabledEditingTooltip,
        pinned: 'left',
        lockPosition: 'left',
        type: 'checkboxColumnType',
      },
      {
        colId: ColumnId.PLACEMENT_TYPE,
        headerName: 'Placement Type',
        field: 'placementType',
        width: 124,
        aggFunc: 'stringToCountAggFunc',
        enableRowGroup: true,
        cellRenderer: TextCellRenderer,
        cellRendererParams: getPlacementTypeCellRendererParams,
        comparator: (valueA: PlacementType | StringToCount, valueB: PlacementType | StringToCount) => {
          // Use translated values for comparison, not the enum values so visually sorting makes sense
          const a = isRecord(valueA)
            ? sortRecordByKeyAndGetColonDelimitedString(valueA).join(',')
            : (t(`enums.bidding_entity.${valueA}`) ?? '');
          const b = isRecord(valueB)
            ? sortRecordByKeyAndGetColonDelimitedString(valueB).join(',')
            : (t(`enums.bidding_entity.${valueB}`) ?? '');
          return a.localeCompare(b);
        },
      },
      {
        colId: ColumnId.PLACEMENT_PERCENTAGE,
        headerName: 'Placement %',
        field: 'percentage',
        cellRenderer: EditableCellRenderer,
        width: 108,
        type: 'numericColumn',
        cellClass: CELL_CLASS_CONTENTS_RIGHT,
        cellRendererParams: (params: ICellRendererParams<PlacementModel>) => {
          return {
            extraTextClass: 'pl-4 flex items-center justify-end min-w-14',
            isEditable: !params.node.group && params.data?.isEditingEnabled,
          };
        },
        valueFormatter: (params) => (!isNil(params.value) && params.data?.isEditingEnabled == true ? params.value + '%' : ''),
        tooltipValueGetter: disabledEditingTooltip,
        editable: (params) => {
          return params.data?.isEditingEnabled === true;
        },
        valueParser: (params: ValueParserParams<PlacementModel>) => {
          // Allow only numeric input with decimals
          const newValue = String(params.newValue).replace(/%/g, '');
          if (!newValue || /^-?[0-9\b]*$/.test(newValue)) {
            const newValueFloat = parseInt(newValue);

            const warnings = new Set<string>();
            const clampedValue = getClampedPlacementValueWithWarnings(newValueFloat, params.data?.adType, warnings);
            if (warnings.size > 0) {
              toastWarnWithSetMessages(warnings);
              return clampedValue;
            }

            return newValueFloat;
          }

          toastService.error('Invalid new placement value');
          return params.oldValue; // Reject the change
        },
      },
      {
        colId: ColumnId.ID,
        headerName: 'Placement ID',
        field: 'id',
        width: 107,
      },
      {
        colId: ColumnId.CAMPAIGN_ID,
        headerName: 'Campaign ID',
        field: 'campaignId',
        width: 148,
      },
      {
        colId: ColumnId.CAMPAIGN_AD_TYPE,
        headerName: 'Campaign Ad Type',
        field: 'adType',
        width: 100,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: getCampaignAdTypeCellRendererParams,
      },
      {
        colId: ColumnId.CAMPAIGN_NAME,
        headerName: 'Campaign',
        field: 'name',
        resizable: true,
        width: 150,
        pinned: 'left',
        enableRowGroup: true,
        aggFunc: 'campaignNameAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PlacementModel>): ITextCellRendererParams => {
          let textColor = undefined;
          let tooltip = '';
          if (params.data?.bidStrategy == BidStrategyType.AUTO_FOR_SALES) {
            textColor = 'orange' as AdLabsColorVariant;
            tooltip +=
              'AdLabs works best with SP Fixed Bids or Dynamic Down only, use bulk Actions to change this setting for best results. ';
          }

          // if (params.data?.bidOptimization == AmazonBrandsBidOptimization.AUTO) {
          //   textColor = 'orange' as AdLabsColorVariant;
          //   tooltip +=
          //     'Adlabs works best with Manual setting for SB Bid Optimization. Auto campaigns do not allow placement editing. Use bulk Actions to change this setting for best results. ';
          // }

          if (params.data?.costType == CostType.VCPM) {
            textColor = 'orange' as AdLabsColorVariant;
            tooltip += 'Bid optimization not supported for vCPM cost types';
          }

          if (params.data?.multiAdGroupsEnabled == MultiAdGroupsEnabledType.FALSE) {
            textColor = 'error' as AdLabsColorVariant;
            tooltip +=
              'Amazon will deprecate SB legacy campaigns (older than Oct 2022). To see stats, duplicate campaign with the Copy function in Amazon Ads Console';
          }

          return {
            textLabel: params.value,
            textColor: textColor,
            tooltip: tooltip,
          };
        },
      },
      {
        colId: ColumnId.STATE,
        headerName: 'State',
        field: 'state',
        cellClass: 'flex flex-row items-center justify-center',
        cellRenderer: EnabledPausedArchivedStateCellRenderer,
        aggFunc: 'enabledPausedArchivedStateAggFunc',
        enableRowGroup: true,
        width: 53,
      },
      {
        colId: ColumnId.PORTFOLIO_NAME,
        headerName: 'Portfolio',
        field: 'portfolioName',
        enableRowGroup: true,
        aggFunc: 'portfolioAggFunc',
        cellRenderer: TextCellRenderer,
        width: 100,
      },
      {
        colId: ColumnId.GROUP_NAME,
        field: 'groupName',
        type: 'optGroupNameColumnType',
        enableRowGroup: true,
      },
      {
        colId: ColumnId.LAST_OPTIMIZED,
        field: 'lastOptimizedAt',
        type: 'lastOptimizedType',
      },
      {
        colId: ColumnId.CAMPAIGN_LAST_OPTIMIZED,
        field: 'campaignLastOptimizedAt',
        type: 'campaignLastOptimizedType',
      },
      {
        colId: ColumnId.SALES_PREVIOUS_DAYS,
        field: 'sales',
        type: 'salesPreviousDaysType',
      },
      {
        colId: ColumnId.SPEND_PREVIOUS_DAYS,
        field: 'spend',
        type: 'spendPreviousDaysType',
      },
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Impressions',
        field: 'impressions',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Clicks',
        field: 'clicks',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Orders',
        field: 'orders',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.UNITS,
        headerName: 'Units',
        field: 'units',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CTR,
        headerName: 'CTR',
        field: 'ctr',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CVR,
        headerName: 'CVR',
        field: 'cvr',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CPC,
        headerName: 'CPC',
        field: 'cpc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Spend',
        field: 'spend',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Sales',
        field: 'sales',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ACTC,
        headerName: 'aCTC',
        field: 'actc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'ROAS',
        field: 'roas',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.RPC,
        headerName: 'RPC',
        field: 'rpc',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CPA,
        headerName: 'CPA',
        field: 'cpa',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.AOV,
        headerName: 'AOV',
        field: 'aov',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.CPM,
        headerName: 'CPM',
        field: 'cpm',
        type: 'metricFieldWithChangePercentage',
        comparator: activeMetricComparator,
      },
      {
        colId: ColumnId.START_DATE,
        headerName: 'Start Date',
        field: 'startDate',
        width: 101,
        valueFormatter: (params) => formatDateStringTimeNoHours(params.value),
      },
      {
        colId: ColumnId.END_DATE,
        headerName: 'End Date',
        field: 'endDate',
        width: 101,
        valueFormatter: (params) => formatDateStringTimeNoHours(params.value),
      },
      {
        colId: ColumnId.TARGETING_TYPE,
        headerName: 'Targeting Type',
        field: 'targetingType',
        width: 81,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`enums.targeting_type.${key}`),
          };
        },
      },
      {
        colId: ColumnId.CREATIVE_TYPE,
        headerName: 'Creative Type',
        field: 'creativeType',
        width: 81,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`enums.creative_type.${key}`),
          };
        },
      },
      {
        colId: ColumnId.COST_TYPE,
        headerName: 'Cost Type',
        field: 'costType',
        width: 100,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`enums.cost_type.${key}`),
          };
        },
      },
      {
        colId: ColumnId.BID_OPTIMIZATION,
        headerName: 'SB Bid Opt.',
        field: 'bidOptimization',
        width: 80,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
      },
      {
        colId: ColumnId.BUDGET_AMOUNT,
        headerName: 'Budget',
        field: 'budgetAmount',
        type: 'numericColumn',
        width: 85,
        aggFunc: 'sum',
        valueFormatter: (params) => formatCurrency(params.value, { customCurrencyCode: params.context?.activeProfileCurrencyCode }),
      },
      {
        colId: ColumnId.BID_STRATEGY,
        headerName: 'Bid Strategy',
        field: 'bidStrategy',
        type: 'bidStrategyColumnType',
      },
      {
        colId: ColumnId.MULTI_AD_GROUPS_ENABLED,
        headerName: 'SB Version',
        field: 'multiAdGroupsEnabled',
        width: 95,
        enableRowGroup: true,
        aggFunc: 'stringToCountAggFunc',
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => t(`enums.multi_ad_groups_enabled.${key}`),
          };
        },
      },
    ];

    if (dataGroups) {
      dataGroups.forEach((group) => {
        colDefs.push({
          colId: `data_group_${group.id}`,
          headerName: group.name,
          width: 100,
          headerClass: 'accented-header',
          valueGetter: (params: ValueGetterParams<PlacementModel>) => {
            const itemId = params.data?.dataItemIds.find((itemId) => groupIdToItemSetMap.get(group.id)?.has(itemId));

            if (itemId !== undefined) {
              return dataItemToInfoMap.get(itemId)?.name;
            }

            return undefined;
          },
          enableRowGroup: true,
          cellRendererSelector: (params: ICellRendererParams<PlacementModel>) => {
            if (params.value) {
              return { component: ChipArrayCellRenderer };
            }
            return { component: undefined }; // undefined means default renderer
          },
          cellRendererParams: (params: ICellRendererParams<PlacementModel>): IChipArrayCellRendererParams => {
            return {
              chipArrayChips: [
                {
                  color: getColorForText(params.value),
                  value: params.value,
                },
              ],
            };
          },
        });
      });
    }

    applyStateToDefinitions(colDefs);

    return colDefs;
  }, [groupIdToItemSetMap]);

  // Needed to keep the selection up to date when Tags change on the target and data is refetched
  useEffect(() => {
    if (!gridApiRef.current || !rowData || rowData.length == 0 || selectedPlacements.length == 0) return;
    updateSelectedTargets(gridApiRef.current);
  }, [rowData]);

  const updateSelectedTargets = (api: GridApi<PlacementModel>) => {
    if (!api || api.isDestroyed()) {
      console.warn('[PlacementsTable.tsx] Grid API is not available or has been destroyed.');
      return;
    }

    try {
      const selectedRows = api.getSelectedRows();
      const selectedPlacementsDTO = selectedRows.map(
        (row): SelectedPlacement => ({
          campaignId: row.campaignId,
          state: row.state,
          adType: row.adType,
          placementType: row.placementType,
          percentage: row.percentage,
          bidOptimization: row.bidOptimization,
        }),
      );
      setSelectedPlacements(selectedPlacementsDTO);
    } catch (error) {
      console.error('Error updating selected placements:', error);
    }
  };

  const onCellValueChanged = useCallback((event: CellValueChangedEvent<PlacementModel>) => {
    const changedColumnId = event.column.getColId();
    if (changedColumnId === ColumnId.PLACEMENT_PERCENTAGE) {
      updatePlacementFromEvent(event);
    }
  }, []);

  async function updatePlacementFromEvent(event: CellValueChangedEvent<PlacementModel>) {
    if (event.node.data?.id) {
      // TODO: fix unused expression
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      pendingPlacementsUpdateCellLocationsRef.current[event.node.data.id];
    }

    event.api.refreshCells({ rowNodes: [event.node], columns: [event.column.getColId()], force: true });

    const newPlacementValue = event.newValue;
    const campaignId = event.node.data?.campaignId;
    const adType = event.node.data?.adType;
    const bidOptimization = event.node.data?.bidOptimization;
    let placementType = event.node.data?.placementType;

    if (!placementType || !campaignId || !adType) {
      toastService.error('Error updating placement');
      return;
    }

    placementType = PlacementModel.convertToBackendPlacementType(adType, placementType);

    const placementUpdate: PlacementUpdate = {
      placement: placementType,
      percentage: newPlacementValue,
    };

    const campaignUpdate: CampaignUpdateDTO = {
      campaign_id: campaignId,
      placement_updates: [placementUpdate],
      bid_optimization: bidOptimization,
    };

    const response = await campaignService.updateCampaigns([campaignUpdate], FlowType.PLACEMENT);

    if (response.isSuccess === false) {
      toastService.error(response.message);
      return;
    } else if (response.payload.error_count > 0) {
      let errorMessage = `${response.payload.error_count} placement(s) could not be updated.\n`;
      const errors = response.payload.error_details.map((error) => {
        return error.error;
      });
      errorMessage += [...new Set(errors)].join('\n');
      toastService.error(errorMessage);
      return;
    }

    toastService.success(`Placement successfully changed from ${event.oldValue}% to ${event.newValue}%`);
    invalidateAll_placementsWithTimelineQueryKeys(queryClient);
  }

  const handleRowSelection = (event: SelectionChangedEvent<PlacementModel>) => {
    updateSelectedTargets(event.api);
  };

  const onCellClicked = useCallback((params: CellClickedEvent<PlacementModel>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const customGridOptions: GridOptions<PlacementModel> = useMemo(() => {
    // While values might be undefined, default values are needed so grid knows which fields to expect
    const defaultPlacementsGridContext: PlacementsGridContext = {
      metricColumnAggregates: metricColumnAggregates,
      isComparisonDataMissing: isComparisonDataMissing,
      ...gridToggleParams,
    };
    return {
      ...DEFAULT_GRID_OPTIONS,
      ...DEFAULT_GRID_OPTIONS_ROW_GROUPS,
      sideBar: false,
      getRowId: (params) => params.data.id.toString(),
      onSelectionChanged: handleRowSelection,
      onCellClicked: onCellClicked,
      context: defaultPlacementsGridContext,
      maintainColumnOrder: true,
      onCellValueChanged: onCellValueChanged,
      onBodyScroll: (event: BodyScrollEvent) => onSourceScroll(event.top),
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,
      isRowSelectable: (rowNode) => rowNode.data?.isEditingEnabled == true,
      columnTypes: {
        metricFieldWithChangePercentage,
        optGroupNameColumnType,
        checkboxColumnType,
        bidStrategyColumnType,
        salesPreviousDaysType,
        spendPreviousDaysType,
        lastOptimizedType,
        campaignLastOptimizedType,
      },
      // Defining all aggFuncs here not in colDefs (where using string) so to prevent "stateItem.aggFunc must be a string" warning (col state is stored as a simple JSON)
      aggFuncs: {
        metricsDataAggFunc,
        enabledPausedArchivedStateAggFunc,
        stringToCountAggFunc,
        metricDataWithPreviousDaysAggFunc,
        targetsAggFunc,
        campaignNameAggFunc,
        portfolioAggFunc,
        lastOptimizedAggFunc,
      },
    };
  }, []);

  function onGridReady(params: GridReadyEvent) {
    setColumnStateGridApi(params.api);
    gridApiRef.current = params.api;
    onGridReadyCallback?.(params);
    onGridReadyForDynamicHeight(params);

    // Context
    gridContextRef.current = params.context;

    // Need this because for initial context set the external data sources might have not been set yet
    if (gridContextRef.current) {
      onGridReadyForMetricColumnAggregates();
      onGridReadyForComparisonMissing();
      // Not needed for comparison unit as it isn't fetched and always exists

      gridApiRef.current.refreshHeader();
      gridApiRef.current.refreshCells({ force: true });
    } else {
      // Should not happen as gridApiRef was just set
      Sentry.captureMessage(`PlacementsTable: gridContextRef is undefined though it was just set`, 'info');
    }
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <>
      {isPlacementsLoadingError ? (
        <Card className="flex-grow rounded-xl py-0">
          <ErrorLoadingDataAlert details={PlacementsLoadingErrorMessage} />
        </Card>
      ) : (
        <div style={{ height: isExpanded ? `calc(100vh - ${EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` : heightCssValue }}>
          <AlGrid
            colDefs={!isNil(dataGroups) ? columnDefs : []}
            rowData={rowData}
            gridOptions={customGridOptions}
            isLoading={isLoading}
            onGridReadyCallback={onGridReady}
            noTopBorderRadius={noTopBorderRadius}
            fitToResizeEnabled={false}
            addExtraBottomPadding={true}
          />
        </div>
      )}
    </>
  );
};

export default PlacementsTable;
