import { getSeparatedValuesFromText, truncateString } from '@/modules/application/utils';
import { toastService } from '@/services/toast.service';
import { Autocomplete, AutocompleteInputChangeReason, Chip, TextField, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { ClipboardEvent, FunctionComponent, KeyboardEvent, SyntheticEvent, useContext, useState } from 'react';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { AlFilterModel } from '../models/AlFilterModel';

interface FilterStringExactMatchProps {
  filter: AlFilterModel;
  label: string;
}

export const FilterStringExactMatch: FunctionComponent<FilterStringExactMatchProps> = ({ filter }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);
  const filterKey = filter.key;

  const tags = filter.conditions && Array.isArray(filter.conditions[0].values) ? filter.conditions[0].values.map(String) : [];

  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (event: SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') {
      setInputValue(value);
    }
  };

  function setTags(newTags: string[]) {
    filter.conditions = [
      {
        values: newTags,
        operator: filter.defaultConditionOperators[0],
      },
    ];

    setFilterValue(filter);
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && inputValue) {
      const inputValueTrimmed = inputValue.trim();
      if (isValueValid(inputValueTrimmed)) {
        setTags([...tags, inputValueTrimmed]);
        setInputValue('');
        event.preventDefault(); // Prevent form submission if inside a form
      }
    } else if (event.key === 'Backspace' && !inputValue) {
      setTags(tags.slice(0, -1));
    }
  };

  const handleBlur = () => {
    if (isValueValid(inputValue)) {
      setTags([...tags, inputValue]);
      setInputValue('');
    }
  };

  const isValueValid = (value: string): boolean => {
    if (isEmpty(value)) {
      return false;
    }

    const isTagDuplicate = tags.some((tag) => tag.toLowerCase() === value.toLowerCase());
    if (isTagDuplicate) {
      toastService.error(value + ' is already in the list');
    }

    return !isTagDuplicate;
  };

  const handleDelete = (tagToDelete: string) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent the default paste behavior
    const newTags = getSeparatedValuesFromText(event.clipboardData.getData('Text'), tags);

    setTags([...new Set([...tags, ...newTags])]); // Combine new and existing tags, removing duplicates
    setInputValue(''); // Clear the input field after processing the paste
  };

  return (
    <div className="flex flex-row gap-2 w-full">
      <Autocomplete
        className="w-full flex-1"
        renderInput={(params) => (
          <TextField
            label={'Exact match'}
            {...params}
            placeholder="Type exact phrase and press [ENTER] to add it to the list"
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
        )}
        id={'input-' + filterKey}
        clearIcon={false}
        options={[]}
        freeSolo
        multiple
        value={tags} // Setting value to the existing tags
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        clearOnBlur
        renderTags={(value) =>
          value.map((tag, index) => (
            <Tooltip key={index} title={tag}>
              <Chip className="m-0.5" size="small" label={truncateString(tag)} onDelete={() => handleDelete(tag)} />
            </Tooltip>
          ))
        }
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onPaste={handlePaste}
      />
    </div>
  );
};
