import { getMetricConfigByColId } from '@/components/metrics/MetricsConfig';
import { MetricWithPreviousDaysDTO } from '@/components/metrics/api/metrics-contracts';
import { MetricDataWithPreviousDays } from '@/components/metrics/types/MetricData';
import useFormatting, { FormattingParams } from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { getProductLink } from '@/modules/application/amazon-utils';
import { countDaysInclusive, isRecord, sortRecordByKeyAndGetColonDelimitedString } from '@/modules/application/utils';
import { BidStrategyType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { ProductModel } from '@/modules/products/models/ProductModel';
import { getColorForText } from '@/types/colors.enum';
import type { ICellRendererParams, IHeaderParams, ValueFormatterParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { AlGridContext } from '../AlGrid';
import ChangePercentageCellRenderer from '../cells/ChangePercentageCellRenderer';
import ChipArrayCellRenderer, { ChipArrayChip, IChipArrayCellRendererParams } from '../cells/ChipArrayCellRenderer';
import ExternalLinkCellRenderer, { IExternalLinkCellRendererParams } from '../cells/ExternalLinkCellRenderer';
import SparklineCellRenderer, { IAlSparklineCellRendererParams } from '../cells/SparklineCellRenderer';
import { ITextCellRendererParams, TextCellRenderer } from '../cells/TextCellRenderer';
import { ColumnId } from '../columns/columns.enum';
import ChangePercentageHeaderRenderer, { IChangePercentageHeaderRendererProps } from '../headers/ChangePercentageHeaderRenderer';
import { NONE_LABEL, StringToCount } from '../helpers';
import { FormatterType, WithFiltersGridContext } from '../types';
import useColDefsFunctions from './useColDefsFunctions';

const useColumnTypes = () => {
  const {
    getLongFormatterForMetricField,
    getShortFormatterForMetricField,
    formatPercent,
    formatCurrency,
    formatDateStringTimeNoSeconds,
    formatWithThousandsSeparator,
  } = useFormatting();
  const { metricsDataComparator, getMetricFieldChangePercentageCellRendererParams } = useColDefsFunctions();
  const { t, tWithFallback } = useTranslation();

  const getChangePercentageHeaderRendererParams = (
    params: IHeaderParams,
    formattingParams?: FormattingParams,
  ): IChangePercentageHeaderRendererProps => {
    const colId = params.column.getColId() as ColumnId;
    const metricField = getMetricConfigByColId(colId)?.key;

    // Do not add tooltip when it already exists
    if (!params.column.getColDef().headerTooltip && metricField) {
      const textPath = `enums.metric_description.${metricField}`;
      const text = tWithFallback(textPath, null);

      if (text) {
        // Shows tooltip also after adding the column, not like this: params.column.getColDef().headerTooltip = text;
        params.setTooltip(text);
      }
    }

    let current: number | undefined;
    if (metricField !== undefined) {
      current = params.context?.metricColumnAggregates?.[metricField]?.current;
    }

    let formattedAggValue = current ? formatWithThousandsSeparator(current) : '0';

    if (metricField !== undefined && current !== undefined) {
      if (params.context?.formatterType === FormatterType.SHORT) {
        formattedAggValue = getShortFormatterForMetricField(metricField)(current, formattingParams);
      } else {
        formattedAggValue = getLongFormatterForMetricField(metricField)(current, formattingParams);
      }
    }

    return {
      ...params,
      currentValue: formattedAggValue,
    };
  };

  const metricFieldWithChangePercentage = {
    autoHeight: true,
    width: 92,
    headerComponent: ChangePercentageHeaderRenderer,
    headerComponentParams: (params: IHeaderParams<unknown, AlGridContext>) =>
      getChangePercentageHeaderRendererParams(params, { customCurrencyCode: params.context?.activeProfileCurrencyCode }),
    comparator: metricsDataComparator,
    // TODO: check if function works
    aggFunc: 'metricsDataAggFunc',
    cellRenderer: ChangePercentageCellRenderer,
    cellRendererParams: (params: ICellRendererParams<unknown, unknown, AlGridContext>) =>
      getMetricFieldChangePercentageCellRendererParams(params, {
        customCurrencyCode: params.context?.activeProfileCurrencyCode,
      }),
    valueFormatter: (params: ValueFormatterParams) => {
      if (Array.isArray(params.value) && params.value.length > 0) {
        // Convert to string to avoid 0 values to be discarded and whole array returned on export
        return params.value[0].toString();
      }
      return '';
    },
  };

  const optGroupNameColumnType = {
    headerName: 'Opt Group',
    width: 125,
    enableRowGroup: true,
    aggFunc: 'stringToCountAggFunc',
    cellRenderer: TextCellRenderer,
    cellRendererParams: (params: ICellRendererParams): ITextCellRendererParams => {
      const groupId = params.data?.groupId;
      const contextCampaignGroupIdToCampaignGroupMap = params.context?.campaignGroupIdToCampaignGroupMap;
      const group = groupId && contextCampaignGroupIdToCampaignGroupMap ? contextCampaignGroupIdToCampaignGroupMap[groupId] : null;

      const tooltip = group ? (
        <>
          Target ACOS: {!isNil(group?.tacos) ? formatPercent(group.tacos) : 'Not Set'}
          <br />
          Prioritization: {t(`enums.optimization_presets.${group.preset}`)}
          <br />
          Campaigns In Group: {group.totalCampaigns}
        </>
      ) : null;

      return {
        textLabel: params.value,
        tooltip: tooltip,
        noneLabel: '(Unassigned)',
      };
    },
  };

  const dataGroupColumnType = {
    width: 100,
    hide: true,
    enableRowGroup: true,
    aggFunc: 'dataGroupAggFunc',

    cellRenderer: ChipArrayCellRenderer,
    cellRendererParams: (params: ICellRendererParams): IChipArrayCellRendererParams => {
      const isAutoGroupColumn = params.colDef?.colId?.includes('ag-Grid-AutoColumn') ?? false;

      if (!params.value && isAutoGroupColumn)
        return {
          chipArrayChips: [
            {
              value: NONE_LABEL,
              color: getColorForText(NONE_LABEL),
            },
          ],
        };

      if (params.node.group && !isAutoGroupColumn) {
        // Expecting aggregator output
        const strToCountRecord = params.value as StringToCount;

        const chipArrayChips: ChipArrayChip[] = [];
        for (const [dgValue, count] of Object.entries(strToCountRecord).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))) {
          chipArrayChips.push({
            value: `${dgValue}: ${count}`,
            color: getColorForText(dgValue ?? 'dgValue'),
          });
        }

        return { chipArrayChips };
      }

      return {
        chipArrayChips: params.value ? [{ color: getColorForText(params.value ?? 'dgValue'), value: params.value }] : [],
      };
    },
    comparator: (valueA: string | StringToCount | undefined, valueB: string | StringToCount | undefined) => {
      // string: dg is assigned to row
      // StringToCount: aggregated row
      // undefined: row, but no dg assigned

      // If aggregated value, convert into string first
      const a = isRecord(valueA) ? sortRecordByKeyAndGetColonDelimitedString(valueA).join(',') : (valueA ?? '');
      const b = isRecord(valueB) ? sortRecordByKeyAndGetColonDelimitedString(valueB).join(',') : (valueB ?? '');
      return a.localeCompare(b);
    },
  };

  const checkboxColumnType = {
    headerCheckboxSelection: true,
    checkboxSelection: true,
    resizable: false,
    width: 50,
    minWidth: 30,
    maxWidth: 50,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    lockVisible: true,
    headerClass: 'checkbox-column-header',
    cellClass: 'checkbox-column-cell',
  };

  const matchLongColumnType = {
    aggFunc: 'stringToCountAggFunc',
    cellRenderer: ChipArrayCellRenderer,
    cellRendererParams: (params: ICellRendererParams): IChipArrayCellRendererParams => {
      const isAutoGroupColumn = params.colDef?.colId?.includes('ag-Grid-AutoColumn') ?? false;
      if (params.value == '' && !isAutoGroupColumn) return { chipArrayChips: [] };

      if (params.node.group && !isAutoGroupColumn) {
        // Expecting stringToCountAggFunc output
        const matchToCountRecord = params.value as StringToCount;

        const chipArrayChips: ChipArrayChip[] = [];
        for (const [match, count] of Object.entries(matchToCountRecord).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))) {
          chipArrayChips.push({
            value: `${t(`enums.match_type_long.${match}`)}: ${count}`,
            color: getColorForText(match ?? 'matchType'),
          });
        }

        return { chipArrayChips };
      }
      return {
        chipArrayChips: [
          {
            value: t(`enums.match_type_long.${params.value}`),
            color: getColorForText(params.value ?? 'matchType'),
          },
        ],
      };
    },
  };

  const bidStrategyColumnType = {
    width: 135,
    hide: true,
    enableRowGroup: true,
    aggFunc: 'stringToCountAggFunc',
    cellRenderer: TextCellRenderer,
    cellRendererParams: (params: ICellRendererParams): ITextCellRendererParams => {
      return {
        textColor: params.value == BidStrategyType.AUTO_FOR_SALES ? 'orange' : undefined,
        tooltip:
          params.value == BidStrategyType.AUTO_FOR_SALES
            ? 'AdLabs works best with SP Fixed Bids or Dynamic Down only, use bulk Actions to change this setting for best results.'
            : undefined,

        valueToString: (key: string) => {
          return t(`enums.bid_strategy.${key}`);
        },
        valueToColor: (key: string) =>
          BidStrategyType[key as keyof typeof BidStrategyType] == BidStrategyType.AUTO_FOR_SALES ? 'orange' : undefined,
        valueToTooltip: (key: string) => {
          const type = BidStrategyType[key as keyof typeof BidStrategyType];
          switch (type) {
            case BidStrategyType.AUTO_FOR_SALES:
              return 'AdLabs works best with SP Fixed Bids or Dynamic Down only, use bulk Actions to change this setting for best results';
            default:
              return undefined;
          }
        },
      };
    },
    comparator: (valueA: BidStrategyType | StringToCount, valueB: BidStrategyType | StringToCount) => {
      // Use translated values for comparison, not the enum values so visually sorting makes sense
      const a = isRecord(valueA) ? sortRecordByKeyAndGetColonDelimitedString(valueA).join(',') : (t(`enums.bid_strategy.${valueA}`) ?? '');
      const b = isRecord(valueB) ? sortRecordByKeyAndGetColonDelimitedString(valueB).join(',') : (t(`enums.bid_strategy.${valueB}`) ?? '');
      return a.localeCompare(b);
    },
  };

  const salesPreviousDaysType = {
    headerName: 'Last 30d Sales',
    aggFunc: 'metricDataWithPreviousDaysAggFunc',
    cellRenderer: SparklineCellRenderer,
    cellRendererParams: (params: ICellRendererParams<unknown, MetricWithPreviousDaysDTO, AlGridContext>): IAlSparklineCellRendererParams => {
      return {
        values: params.value?.slice(2) ?? [0],
        valueFormatter: (value: number) => formatCurrency(value, { customCurrencyCode: params.context?.activeProfileCurrencyCode }),
      };
    },
    valueFormatter: (params: ValueFormatterParams) => `${params.value}`,
    comparator: (valueA: MetricDataWithPreviousDays, valueB: MetricDataWithPreviousDays) => {
      // Checking len 2 because of slicing
      const sumA = valueA && valueA.length > 2 ? valueA.slice(2).reduce((sum, current) => sum + current) : 0;
      const sumB = valueB && valueB.length > 2 ? valueB.slice(2).reduce((sum, current) => sum + current) : 0;

      return sumA - sumB;
    },
  };

  const spendPreviousDaysType = {
    headerName: 'Last 30d Spend',
    aggFunc: 'metricDataWithPreviousDaysAggFunc',
    cellRenderer: SparklineCellRenderer,
    cellRendererParams: (params: ICellRendererParams<unknown, MetricWithPreviousDaysDTO, AlGridContext>): IAlSparklineCellRendererParams => {
      return {
        color: 'amber',
        values: params.value?.slice(2) ?? [0],
        valueFormatter: (value: number) => formatCurrency(value, { customCurrencyCode: params.context?.activeProfileCurrencyCode }),
      };
    },
    valueFormatter: (params: ValueFormatterParams) => `$${params.value}`,
    comparator: (valueA: MetricDataWithPreviousDays, valueB: MetricDataWithPreviousDays) => {
      // Checking len 2 because of slicing
      const sumA = valueA && valueA.length > 2 ? valueA.slice(2).reduce((sum, current) => sum + current) : 0;
      const sumB = valueB && valueB.length > 2 ? valueB.slice(2).reduce((sum, current) => sum + current) : 0;

      return sumA - sumB;
    },
  };

  const lastOptimizedType = {
    headerName: 'Last Optimized',
    width: 130,
    aggFunc: 'lastOptimizedAggFunc',
    enableRowGroup: true,
    cellRenderer: TextCellRenderer,
    cellRendererParams: (params: ICellRendererParams): ITextCellRendererParams => {
      return {
        noneLabel: 'Never',
        valueToString: (value: string) => (params.value ? formatDateStringTimeNoSeconds(value) : 'Never'),
        valueToTooltip: (value: string) => (params.value ? formatDateStringTimeNoSeconds(value) : 'Never'),
      };
    },
  };

  const campaignLastOptimizedType = {
    headerName: 'Campaign Last Optimized',
    width: 130,
    aggFunc: 'lastOptimizedAggFunc',
    enableRowGroup: true,
    cellRenderer: TextCellRenderer,
    cellRendererParams: (params: ICellRendererParams): ITextCellRendererParams => {
      return {
        noneLabel: 'Never',
        valueToString: (value: string) => (params.value ? formatDateStringTimeNoSeconds(value) : 'Never'),
        valueToTooltip: (value: string) => (params.value ? formatDateStringTimeNoSeconds(value) : 'Never'),
      };
    },
  };

  const rankChangeType = {
    cellRenderer: SparklineCellRenderer,
    cellRendererParams: (params: ICellRendererParams<unknown, number[], WithFiltersGridContext>): IAlSparklineCellRendererParams => {
      const startEndDate = params.context?.filterCurrentStartEndDate;
      // Multiply by -1 to invert the sparkline so 1 is better than 50
      const values = params.value && startEndDate ? params.value.map((value) => (value ? value * -1 : null)) : null; // if endDate could not be retrieved, opt to not showing the sparkline

      const duration = startEndDate ? countDaysInclusive(startEndDate.startDate, startEndDate.endDate) : null;
      return {
        values,
        endDate: startEndDate?.endDate,
        color: 'blue',
        // Multiply by -1 to switch back to positive numbers visually
        valueFormatter: (value: number) => '#' + formatWithThousandsSeparator(value * -1),
        emptyFillValue: null,
        length: duration,
      };
    },
    valueFormatter: (params: ValueFormatterParams) => `$${params.value}`,
    comparator: (valueA: number[], valueB: number[]) => {
      if (!valueA || valueA.length === 0) return 1;
      if (!valueB || valueB.length === 0) return -1;

      // Comparing the last (latest) value
      const lastA = valueA[valueA.length - 1];
      const lastB = valueB[valueB.length - 1];

      // Perform comparison
      if (lastA < lastB) return -1;
      if (lastA > lastB) return 1;
      return 0; // they are equal
    },
  };

  const asinType = {
    headerName: 'ASIN',
    enableRowGroup: true,
    aggFunc: 'asinAggFunc',
    cellRenderer: ExternalLinkCellRenderer,
    cellRendererParams: (params: ICellRendererParams<ProductModel>): IExternalLinkCellRendererParams => {
      const countryCode = params.context?.countryCode;
      return {
        url: params.data?.asin && countryCode ? getProductLink(params.data.asin, countryCode) : undefined,
        title: params.value,
      };
    },
  };

  const parentAsinType = {
    headerName: 'Parent ASIN',
    aggFunc: 'parentAsinAggFunc',
  };
  return {
    metricFieldWithChangePercentage,
    optGroupNameColumnType,
    checkboxColumnType,
    dataGroupColumnType,
    matchLongColumnType,
    bidStrategyColumnType,
    getChangePercentageHeaderRendererParams,
    salesPreviousDaysType,
    spendPreviousDaysType,
    lastOptimizedType,
    campaignLastOptimizedType,
    rankChangeType,
    asinType,
    parentAsinType,
  };
};

export default useColumnTypes;
