import { AdLabsColorVariant } from '@/config/theme/color.type';
import { Chip, Tooltip } from '@mui/material';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';

export interface ChipArrayChip {
  value: string;
  color: AdLabsColorVariant;
  tooltip?: string;
  link?: string;
}
export interface IChipArrayCellRendererParams {
  chipArrayChips: ChipArrayChip[];
  maxChipsToShow?: number;
}

interface IChipArrayCellInternalRendererParams extends IChipArrayCellRendererParams, ICellRendererParams {}

const ChipArrayCellRenderer: FunctionComponent<IChipArrayCellInternalRendererParams> = ({ chipArrayChips, maxChipsToShow = 5 }) => {
  if (!Array.isArray(chipArrayChips)) {
    return null;
  }

  const [displayedChipArray, additionalChipArray] = [chipArrayChips.slice(0, maxChipsToShow), chipArrayChips.slice(maxChipsToShow)];

  const displayedChips = displayedChipArray.map((chip, index) => {
    return (
      <Tooltip key={index} title={chip.tooltip}>
        <Chip
          variant="outlined"
          size="small"
          color={chip.color}
          label={chip.value}
          className={`mr-2 truncate font-medium ${chip.link ? 'cursor-pointer' : 'cursor-default'}`}
          onClick={() => {
            if (chip.link) {
              window.open(chip.link, '_blank');
            }
          }}
          style={{ cursor: chip.link ? 'pointer' : 'default' }}
        />
      </Tooltip>
    );
  });

  const additionalChipsCount = additionalChipArray.length;

  const additionalChip =
    additionalChipsCount > 0 ? (
      <Tooltip title={additionalChipArray.map((chip) => chip.value).join(', ')}>
        <Chip label={`+${additionalChipsCount}`} />
      </Tooltip>
    ) : null;

  return (
    <div className="flex h-full items-center">
      {displayedChips}
      {additionalChip}
    </div>
  );
};

export default ChipArrayCellRenderer;
