import InfoMessage from '@/components/feedback/InfoMessage';
import { useTranslation } from '@/lib';
import { sleep } from '@/lib/api/api-utils';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { Button } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { logsService } from '../api/logs-service';
import LogsTable from '../components/LogsTable';

const LogsPage: FunctionComponent = () => {
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { t } = useTranslation();

  const {
    data: rowData,
    isLoading: isLogsLoading,
    isFetching: isLogsFetching,
    isError: isLogsLoadingError,
    error: logsLoadingError,
    refetch: refetchLogs,
  } = useQuery({
    queryKey: logsService.createGetJobActionsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await logsService.getJobActions([]);

      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error(`Error loading jobs ${result.message}`);
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  const isLoading = isLogsLoading || isLogsFetching;

  function onRefreshLogsClicked() {
    refetchLogs();
  }

  const navigate = useNavigate();
  async function onNavigateToOptLogs() {
    await sleep(50); // wait for 50 ms before navigate
    navigate(Routes.HISTORY);
  }

  return (
    <PageLayout>
      <PageLayoutTopBar
        header={t('change_logs')}
        actions={
          <div className="flex flex-row items-center gap-2">
            {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
            <TeamSelect />
            <ProfileSyncSelectButton />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <div className="flex flex-grow flex-1 flex-col">
          <div className="flex items-center justify-between">
            <Button variant="outlined" className="whitespace-nowrap" loading={isLoading} onClick={onRefreshLogsClicked} disabled={isLoading}>
              Refresh Logs
            </Button>

            <div className="mb-2">
              <InfoMessage
                content={
                  <div className="flex flex-row items-center">
                    Bid Optimization Logs can be found in the{' '}
                    <Button className="ml-1" variant="text" onClick={onNavigateToOptLogs}>
                      Time Machine
                    </Button>
                  </div>
                }
              />
            </div>
          </div>
          <ErrorBoundary>
            <div className="flex flex-grow ">
              <LogsTable
                rowData={rowData}
                isLoading={isLoading}
                isLoadingError={isLogsLoadingError}
                loadingErrorMessage={logsLoadingError instanceof Error ? logsLoadingError.message : ''}
              />
            </div>
          </ErrorBoundary>
        </div>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default LogsPage;
