import { ColumnId } from '@/components/grid/columns/columns.enum';
import type { ColumnState } from 'ag-grid-enterprise';

export function generateDashboardDefaultColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.NAME, hide: false, pinned: false },
    { colId: ColumnId.WIDGETS, hide: false, pinned: false },
    { colId: ColumnId.CURRENCY, hide: false, pinned: false },
    { colId: ColumnId.PROFILES, hide: false, pinned: false },
    { colId: ColumnId.OWNER, hide: false, pinned: false },
    { colId: ColumnId.COLLABORATORS, hide: false, pinned: false },
    { colId: ColumnId.ACTIONS, hide: false, pinned: 'right' },
  ];
}
