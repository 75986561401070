import { UserModel } from '@/modules/users';
import { RoleKey, TeamMemberDTO } from '../api/teams.contracts';

type TeamMemberUser = Pick<UserModel, 'id' | 'name' | 'email'>;

export class TeamMemberModel {
  public user: TeamMemberUser;
  public role: RoleKey;

  constructor(args: TeamMemberCreateArguments) {
    this.user = args.user;
    this.role = args.role;
  }

  public static fromDTO(dto: TeamMemberDTO): TeamMemberModel {
    return new TeamMemberModel({
      user: {
        id: dto.user.id,
        name: dto.user.name,
        email: dto.user.email,
      },

      role: dto.role,
    });
  }
}

interface TeamMemberCreateArguments {
  user: TeamMemberUser;
  role: RoleKey;
}
