import { UserSettingKey, UserSettingReadDTO, UserSettingType, UserSettingValue } from '../api/user-settings/user-setting.contracts';

// UserSettingArguments Interface
interface UserSettingArguments {
  id: number;
  key: UserSettingKey;
  type: UserSettingType;
  value: UserSettingValue;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

// UserSetting Class
export class UserSettingModel {
  id: number;
  key: UserSettingKey;
  type: UserSettingType;
  value: UserSettingValue;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;

  constructor(args: UserSettingArguments) {
    this.id = args.id;
    this.key = args.key;
    this.type = args.type;
    this.value = args.value;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
    this.deletedAt = args.deletedAt;
  }

  toDTO(): UserSettingReadDTO {
    return {
      id: this.id,
      key: this.key,
      type: this.type,
      value: this.value,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
    };
  }

  static fromDTO(dto: UserSettingReadDTO): UserSettingModel {
    return new UserSettingModel({
      id: dto.id,
      key: dto.key,
      type: dto.type,
      value: dto.value,
      createdAt: dto.createdAt,
      updatedAt: dto.updatedAt,
      deletedAt: dto.deletedAt,
    });
  }
}
