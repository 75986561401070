import { FunctionComponent, useEffect, useState } from 'react';
import { EditorContent, useEditor, Node, AnyExtension } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { TextAlign } from '@tiptap/extension-text-align';
import RichTextEditorMenuBar from './RichTextEditorMenuBar';

interface RichTextEditorProps {
  content: string;
  onContentChange: (content: string) => void;
  onTextChange?: (text: string) => void;
  hideMenuBar?: boolean;
  singleLine?: boolean;
}

const RichTextEditor: FunctionComponent<RichTextEditorProps> = ({ content, onContentChange, onTextChange, hideMenuBar, singleLine }) => {
  const OneLiner = Node.create({
    name: 'oneLiner',
    topNode: true,
    content: 'block',
  });

  const extensions: AnyExtension[] = [
    StarterKit.configure({
      strike: false,
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
  ];

  if (singleLine) {
    extensions.push(OneLiner);
  }

  const editor = useEditor({
    extensions,
    content,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      const text = editor.getText();
      onContentChange(html);
      if (onTextChange) {
        onTextChange(text);
      }
    },
    editorProps: {
      handleKeyDown: (view, event) => {
        if (singleLine && event.key === 'Enter') {
          event.preventDefault();
          return true;
        }
        return false;
      },
    },
  });

  // Keep track of focus state
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    editor?.on('focus', handleFocus);
    editor?.on('blur', handleBlur);

    return () => {
      editor?.off('focus', handleFocus);
      editor?.off('blur', handleBlur);
    };
  }, [editor]);

  if (hideMenuBar) {
    return <EditorContent editor={editor} spellCheck={false} />;
  }

  return (
    <>
      <RichTextEditorMenuBar editor={editor} />
      <div
        className={`border mt-3 p-2 rounded-md ${isFocused ? 'border-primary-500' : 'border-gray-200'}`}
        style={singleLine ? { whiteSpace: 'nowrap', overflow: 'hidden' } : {}}
      >
        <EditorContent editor={editor} />
      </div>
    </>
  );
};

export default RichTextEditor;
