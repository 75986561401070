import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { DTO } from '@/types/dto-wrapper';
import { BiddingMethod, CampaignMappingDTO, CampaignMappingItemDTO } from '../api/campaign-mapping-contracts';
import { CampaignMappingModel } from './CampaignMappingModel';

export class CampaignMappingInnerModel extends DTO<CampaignMappingItemDTO> {
  private campaignMappingDto: CampaignMappingDTO;
  constructor(dto: CampaignMappingItemDTO, campaignMappingDto: CampaignMappingDTO) {
    super(dto);
    this.campaignMappingDto = campaignMappingDto;
  }

  public get id(): string | null {
    return this.campaignMappingDto.sci + this.campaignMappingDto.sai + this.campaignMappingDto.dci + this.campaignMappingDto.dai + this.dto.m;
  }

  // From Inner DTO
  public get matchType(): MatchType {
    return this.dto.m;
  }

  public get biddingMethod(): BiddingMethod {
    return this.dto.bdm;
  }

  public set biddingMethod(value: BiddingMethod) {
    this.dto.bdm = value;
  }

  public get biddingMethodValue(): number | null {
    return this.dto.bdmv;
  }

  public set biddingMethodValue(value: number | null) {
    this.dto.bdmv = value;
  }

  public get bidFloor(): number | null {
    return this.dto.bf;
  }

  public set bidFloor(value: number | null) {
    this.dto.bf = value;
  }

  public get bidCeiling(): number | null {
    return this.dto.bc;
  }

  public set bidCeiling(value: number | null) {
    this.dto.bc = value;
  }

  public get createdAt(): string | null {
    return this.dto.cr;
  }

  public get updatedAt(): string | null {
    return this.dto.up;
  }

  // From parent CampaignMappingDTO
  public get destinationCampaignId(): string {
    return this.campaignMappingDto.dci;
  }

  public get destinationCampaignAdType(): CampaignAdType | null {
    return this.campaignMappingDto.dct;
  }

  public get destinationCampaignBudgetAmount(): number {
    return this.campaignMappingDto.dcb;
  }

  toCampaignMappingModel(): CampaignMappingModel {
    return new CampaignMappingModel(this.campaignMappingDto);
  }
}
