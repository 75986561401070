import { FunctionComponent, useContext } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import { IconButton } from '@mui/material';
import { CopyAll, Delete, SettingsRounded } from '@mui/icons-material';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { useDashboardQueries } from '../../hooks/useDashboardQueries';
import { isEmpty } from 'lodash-es';
import { toastService } from '@/services/toast.service';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';

interface DashboardWidgetConfigurationButtonProps {
  isMouseOnWidget: boolean;
  showDeleteButton?: boolean;
  showDuplicateButton?: boolean;
}

const DashboardWidgetConfigurationButton: FunctionComponent<DashboardWidgetConfigurationButtonProps> = ({
  isMouseOnWidget,
  showDeleteButton = true,
  showDuplicateButton = true,
}) => {
  const { isConfiguring, setIsConfiguring, widgetId } = useContext(DashboardWidgetContext);
  const { setEditingWidgetId, editingWidgetId, duplicateWidget, removeWidget } = useDashboardContextValue((context) => ({
    setEditingWidgetId: context.setEditingWidgetId,
    editingWidgetId: context.editingWidgetId,
    duplicateWidget: context.duplicateWidget,
    removeWidget: context.removeWidget,
  }));

  const isEditMode = isMouseOnWidget || editingWidgetId === widgetId;

  const { refetchForWidgetWithId } = useDashboardQueries();

  async function onDuplicateWidgetClicked() {
    if (widgetId) {
      const duplicatedWidgetID = await duplicateWidget(widgetId);
      if (!isEmpty(duplicatedWidgetID)) {
        refetchForWidgetWithId(duplicatedWidgetID);
        toastService.success('Widget duplicated successfully, added to the bottom of the dashboard. You can now configure it.', {
          autoCloseDelay: 5000,
        });
      } else {
        toastService.error('Failed to duplicate widget');
      }
    }
  }

  function onDeleteWidgetClicked() {
    if (widgetId) {
      removeWidget(widgetId);
      setEditingWidgetId('');
    }
  }

  const { ModalComponent, handleOpenModal } = useDeleteConfirmation({
    questionText: 'Are you sure you want to delete this widget?',
    headerText: 'Delete Widget',
    onDelete: onDeleteWidgetClicked,
    confirmButtonText: 'Delete',
  });

  return (
    isEditMode &&
    editingWidgetId !== widgetId && (
      <div
        className={`absolute flex flex-col items-center -right-px -top-px z-50  opacity-100 rounded-lg bg-white border border-gray-300 shadow-sm`}
      >
        <IconButton
          size="small"
          color="primary"
          onMouseDown={(e) => {
            e.stopPropagation();
            e.stopPropagation();
            e.bubbles = false;

            // If clicked and it's the cogwheel of a different widget
            // Open the drawer for this widget
            if (editingWidgetId !== widgetId || !isConfiguring) {
              setIsConfiguring(true);
              setEditingWidgetId(widgetId ?? '');
            } else {
              setIsConfiguring(false);
              setEditingWidgetId('');
            }
          }}
        >
          <SettingsRounded fontSize="small"></SettingsRounded>
        </IconButton>
        {showDuplicateButton && (
          <IconButton
            size="small"
            color="secondary"
            onMouseDown={(e) => {
              e.stopPropagation();
              e.stopPropagation();
              e.bubbles = false;

              // If clicked and it's the cogwheel of a different widget
              // Open the drawer for this widget
              onDuplicateWidgetClicked();
            }}
          >
            <CopyAll fontSize="small"></CopyAll>
          </IconButton>
        )}
        {showDeleteButton && (
          <IconButton
            size="small"
            color="error"
            onMouseDown={(e) => {
              e.stopPropagation();
              e.stopPropagation();
              e.bubbles = false;

              // If clicked and it's the cogwheel of a different widget
              // Open the drawer for this widget
              handleOpenModal();
            }}
          >
            <Delete fontSize="small"> </Delete>
            {ModalComponent}
          </IconButton>
        )}
      </div>
    )
  );
};

export default DashboardWidgetConfigurationButton;
