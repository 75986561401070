import { CurrencyCode } from '@/modules/amazon-constants/types/CurrencyCode';
import type { GridApi } from 'ag-grid-enterprise';
import { MutableRefObject, useEffect } from 'react';
import { ProfilesStatsGridContext } from '../components/profile-stats-table/ProfilesStatsTable';
import { ProfileWithMetricsModel } from '../models/ProfileWithMetricsModel';

interface useSelectedCurrencyProps {
  gridApiRef: MutableRefObject<GridApi<ProfileWithMetricsModel> | undefined>;
  gridContextRef: MutableRefObject<ProfilesStatsGridContext | undefined>;
  selectedCurrency: CurrencyCode; // Not dynamic as it's not in the context
}

const useSelectedCurrency = ({ gridApiRef, gridContextRef, selectedCurrency }: useSelectedCurrencyProps) => {
  useEffect(() => {
    if (!gridApiRef.current || !gridContextRef.current) return;
    gridContextRef.current.selectedCurrency = selectedCurrency;
    gridApiRef.current.refreshHeader();
  }, [selectedCurrency]);

  const onGridReadyForSelectedCurrency = () => {
    if (!gridApiRef.current || !gridContextRef.current) return;
    Object.assign(gridContextRef.current, {
      selectedCurrency: selectedCurrency,
    });

    // IMPORTANT: cell refreshing needs to be done in table onGridReady to avoid multiple refreshes
  };

  return { onGridReadyForSelectedCurrency };
};

export default useSelectedCurrency;
