import { ColumnId } from '@/components/grid/columns/columns.enum';
import type { ColumnState } from 'ag-grid-enterprise';

export function profilesTableDefaultColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.PROFILE_NAME, hide: false, pinned: false },
    { colId: ColumnId.PROFILE_ID, hide: true, pinned: false },
    { colId: ColumnId.ACCOUNT_ID, hide: true, pinned: false },
    { colId: ColumnId.ACTIONS, hide: false, pinned: false },
    { colId: ColumnId.SYNC_STV, hide: false, pinned: false },
    { colId: ColumnId.SELLER_CENTRAL_AUTHORIZED, hide: false, pinned: false },
    { colId: ColumnId.SELLING_PARTNER_DATA_STATUS, hide: false, pinned: false },
    { colId: ColumnId.DATA_STATUS, hide: false, pinned: false },
    { colId: ColumnId.UPDATED_AT, hide: false, pinned: false },
    { colId: ColumnId.CURRENCY, hide: false, pinned: false },
    { colId: ColumnId.TIMEZONE, hide: false, pinned: false },
    { colId: ColumnId.TYPE, hide: false, pinned: false },
    { colId: ColumnId.ONBOARD_DATE, hide: false, pinned: false },
  ];
}
