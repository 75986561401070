import { Environment } from '@/config/Environment';
import { ContextKey } from '@/types/context-shared';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { Button } from '@mui/material';
import { FunctionComponent, useRef, useState } from 'react';
import { FilterBuilderDialog } from './FilterBuilderDialog';
import { FilterChipRow } from './FilterChipRow';
import { AlFilterModel } from './models/AlFilterModel';
import { FilterKey } from './types/FilterKey';

interface FilterControlsProps {
  filters: AlFilterModel[];
  setFilters: (newFilters: AlFilterModel[] | ((prevFilters: AlFilterModel[]) => AlFilterModel[])) => void;
  availableFilters: AlFilterModel[];
  defaultFilterKey: FilterKey;
  maxFilterChips?: number;
  contextKey?: ContextKey;
}

const FilterControls: FunctionComponent<FilterControlsProps> = ({
  filters,
  setFilters,
  availableFilters,
  defaultFilterKey,
  maxFilterChips,
  contextKey,
}) => {
  const filtersButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false); // state to control the popover

  const onFilterChipClicked = () => {
    setIsFilterDialogOpen((previousValue) => !previousValue);
  };

  const onFilterDeleteButtonClicked = (filter: AlFilterModel) => {
    setFilters((previousFilters) => previousFilters.filter((f) => f.key !== filter.key));
  };

  const onRemoveAllFiltersClicked = () => {
    setFilters((previousFilters) => previousFilters.filter((f) => !f.isFilterBuilderFilter));
  };

  function onFiltersChanged(newFilters: AlFilterModel[]) {
    setFilters(newFilters);
  }

  return (
    <div className="flex flex-row">
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={onFilterChipClicked}
        startIcon={<FilterListRoundedIcon />}
        ref={filtersButtonRef}
        className="mr-2 flex-shrink-0"
      >
        Adjust Filters
      </Button>

      <FilterBuilderDialog
        buttonRef={filtersButtonRef}
        setIsOpen={setIsFilterDialogOpen}
        isOpen={isFilterDialogOpen}
        availableFilters={availableFilters}
        defaultFilterKey={defaultFilterKey}
        appliedFilters={filters}
        onFiltersChanged={onFiltersChanged}
        contextKey={Environment.isDev() ? contextKey : undefined}
      />

      <FilterChipRow
        filters={filters}
        onFilterDeleteButtonClicked={onFilterDeleteButtonClicked}
        onFilterChipClicked={onFilterChipClicked}
        onRemoveAllFiltersClicked={onRemoveAllFiltersClicked}
        maxFilterChips={maxFilterChips}
      />
    </div>
  );
};

export default FilterControls;
