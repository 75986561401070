import { FunctionComponent, JSX } from 'react';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { Tooltip } from '@mui/material';
import { EnabledPausedArchivedState } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { EnumToCount } from '../helpers';
import { GridColors } from '@/types/colors.enum';

const defaultClasses = 'rounded-full bg-opacity-10 flex items-center justify-center w-5 h-5';

const enabledPausedArchivedStateConfig = [
  {
    state: EnabledPausedArchivedState.PAUSED,
    title: 'Paused',
    icon: <PauseCircleOutlineRoundedIcon color={'warning'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-orange-600 bg-orange-600`,
  },
  {
    state: EnabledPausedArchivedState.ARCHIVED,
    title: 'Archived',
    icon: <RemoveCircleOutlineRoundedIcon color={'disabled'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-slate-600 bg-slate-600`,
  },
  {
    state: EnabledPausedArchivedState.ENABLED,
    title: 'Enabled',
    icon: <CheckCircleOutlineRoundedIcon color={'success'} fontSize={'medium'} />,
    styleClasses: `${defaultClasses} border-green-600 bg-green-600`,
  },
];

const StateIcon: FunctionComponent<{ state: EnabledPausedArchivedState }> = ({ state }) => {
  const stateConfig = enabledPausedArchivedStateConfig.find((config) => config.state === state);

  return (
    <div className="flex flex-row mr-0.5 h-full items-center">
      <Tooltip title={stateConfig?.title || ''}>
        <div className={`rounded-full bg-opacity-10 flex items-center justify-center w-5 h-5 ${stateConfig?.styleClasses}`}>
          {stateConfig?.icon}
        </div>
      </Tooltip>
    </div>
  );
};

const EnabledPausedArchivedStateCellRenderer: FunctionComponent<ICellRendererParams> = ({ node, colDef, value }) => {
  if (node.group && value) {
    const isAutoGroupColumn = colDef?.colId?.includes('ag-Grid-AutoColumn') ?? false;

    if (isAutoGroupColumn) {
      return (
        <div className="flex flex-row items-center gap-1">
          <StateIcon state={node.key as EnabledPausedArchivedState} /> <span className={GridColors.GRAY}>{node.allChildrenCount}</span>
        </div>
      );
    } else {
      return <>{createElements(value)}</>;
    }
  }

  return <StateIcon state={value ?? EnabledPausedArchivedState.ENABLED} />;
};

export default EnabledPausedArchivedStateCellRenderer;

function createElements(stateValueAndCount: EnumToCount<typeof EnabledPausedArchivedState>): JSX.Element {
  const elements = Object.entries(stateValueAndCount)
    .filter(([, valueCount]) => valueCount > 0)
    .map(([key, valueCount]) => (
      <div className="flex flex-row items-center mr-2 gap-1" key={key}>
        <StateIcon state={key as EnabledPausedArchivedState} /> <span className={GridColors.GRAY}>{valueCount}</span>
      </div>
    ));

  return <div className="flex flex-row gap-2">{elements}</div>;
}
