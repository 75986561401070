import { useTranslation } from '@/lib';
import { toastService } from '@/services/toast.service';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';
import { isEmpty, isNil } from 'lodash-es';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import { dataGroupsService } from '../api/data-groups-service';
import { DataGroupModel } from '../models/DataGroup';
import { DataItemModel, NewDataItem, getOriginalName, hasAnyDuplicateNames } from '../models/DataItem';
import { DataGroupType } from '../models/data-groups-contracts';
import { EditableDataItemsList } from './EditableDataItemsList';

interface EditDataGroupSelectionModalProps {
  editableDataGroup: DataGroupModel;
  isOpen: boolean;
  onClose: () => void;
  onApplySucceeded: (dataGroupType: DataGroupType) => void;
  existingGroups: DataGroupModel[];
}

const EditDataGroupSelectionModal: FunctionComponent<EditDataGroupSelectionModalProps> = ({
  editableDataGroup,
  isOpen,
  onClose,
  onApplySucceeded,
  existingGroups,
}) => {
  const { t } = useTranslation();

  const [groupName, setGroupName] = useState(editableDataGroup.name);
  const [nameError, setNameError] = useState('');

  // Items
  const [deleteItemsIds, setDeleteItemsIds] = useState<number[]>([]);
  const [items, setItems] = useState<DataItemModel[] | NewDataItem[]>(editableDataGroup.items);
  const hasDuplicatesError = hasAnyDuplicateNames(items);

  const [isApplyInProgress, setIsApplyInProgress] = useState<boolean>(false);
  async function handleApplyChanges() {
    // Split all items, existing non-modified are excluded
    const { modifiedExistingItems, newItems } = splitItems(items, editableDataGroup.items);

    // Update existing group and existing items
    if (modifiedExistingItems.length > 0 || newItems.length > 0 || editableDataGroup.name !== groupName) {
      const applyDataGroup = new DataGroupModel({
        id: editableDataGroup.id,
        name: groupName,
        type: editableDataGroup.type,
        items: modifiedExistingItems,
      });
      const applyResponse = await dataGroupsService.updateGroup(applyDataGroup, newItems);
      setIsApplyInProgress(false);
      if (applyResponse.isSuccess === false) {
        toastService.error(applyResponse.message);
        setIsApplyInProgress(false);
        return;
      }
    }

    // Remove deleted items
    if (deleteItemsIds.length > 0) {
      const applyResponse = await dataGroupsService.deleteItems(deleteItemsIds);
      setIsApplyInProgress(false);
      if (applyResponse.isSuccess === false) {
        toastService.error(applyResponse.message);
        setIsApplyInProgress(false);
        return;
      }
    }

    onApplySucceeded(editableDataGroup.type);
    onCloseClicked();
  }

  const handleGroupNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setGroupName(name);
    setNameError(checkGroupNameExists(name) ? 'This tag already exists' : isEmpty(name) ? "Name can't be empty" : '');
  };

  const checkGroupNameExists = (name: string): boolean => {
    // Comparison with self
    if (name == editableDataGroup.name) {
      return false;
    }
    return (
      !isNil(existingGroups) &&
      existingGroups.filter((g) => g.type === editableDataGroup.type).some((group) => group.name.toLowerCase() === name.toLowerCase())
    );
  };

  const onCloseClicked = () => {
    onClose();
    setDeleteItemsIds([]);
    setItems(editableDataGroup.items);
    setGroupName(editableDataGroup.name);
  };
  return (
    <Dialog open={isOpen} onClose={onCloseClicked} aria-labelledby="edit-selection-modal-title" maxWidth="lg" className="w-full ">
      <DialogTitle id="edit-selection-modal-title">Edit Tag</DialogTitle>
      <DialogContent className="min-w-[500px]">
        <form noValidate autoComplete="off">
          <div className="flex flex-col">
            <div className="flex flex-row gap-2">
              <FormControl className="max-w-32">
                <TextField style={{ margin: 0 }} label="Tag Type" value={t(`enums.data_group_type.${editableDataGroup?.type}`)} disabled />
              </FormControl>

              <TextField
                className="min-w-72"
                autoFocus
                error={!isEmpty(nameError)}
                label="Tag Name"
                value={groupName}
                onChange={handleGroupNameChange}
                helperText={nameError}
              />
            </div>
            <div className="flex flex-col max-w-52">
              <EditableDataItemsList
                items={items}
                setItems={setItems}
                deleteItemIds={deleteItemsIds}
                setDeleteItemIds={setDeleteItemsIds}
                originalItems={editableDataGroup.items}
              />
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCloseClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          loading={isApplyInProgress}
          onClick={handleApplyChanges}
          disabled={!isEmpty(nameError) || hasDuplicatesError}
        >
          Apply Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function splitItems(
  items: (DataItemModel | NewDataItem)[],
  originalItems: (DataItemModel | NewDataItem)[],
): {
  modifiedExistingItems: DataItemModel[];
  newItems: NewDataItem[];
} {
  const modifiedExistingItems: DataItemModel[] = [];
  const newItems: NewDataItem[] = [];

  items.forEach((item) => {
    if ('id' in item && item.id !== undefined) {
      // Item has an id, check if it's modified
      const originalName = getOriginalName(item, originalItems);
      if (originalName !== null && item.name !== originalName) {
        // The item is modified
        modifiedExistingItems.push(item as DataItemModel);
      }
      // If the name is the same as the original, it's not considered here as per the requirement
    } else {
      // Item does not have an id, it's new
      newItems.push(item as NewDataItem);
    }
  });

  return { modifiedExistingItems, newItems };
}

export default EditDataGroupSelectionModal;
