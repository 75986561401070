import { ApiResponse } from '@/lib/api/api-response';
import { simpleHash } from '@/types/colors.enum';
import { QueryClient, QueryKey } from '@tanstack/react-query';

type FetchFunctionResponse<T> = {
  res: ApiResponse<T>;
  cacheHit: boolean;
};

enum FetchCacheDependencyType {
  PROFILE = 'PROFILE',
  TEAM = 'TEAM',
}

export class AlFetchCache {
  private activeProfileId: string | undefined;
  private activeTeamId: number | undefined;
  private getQueryClient: () => QueryClient;
  private pendingFetches: Map<string, Promise<ApiResponse<unknown>>> = new Map();

  constructor(getQueryClient: () => QueryClient, activeProfileId: string | undefined, activeTeamId: number | undefined) {
    this.activeProfileId = activeProfileId;
    this.activeTeamId = activeTeamId;
    this.getQueryClient = getQueryClient;
  }

  getActiveProfileId(): string | undefined {
    return this.activeProfileId;
  }

  getActiveTeamId(): number | undefined {
    return this.activeTeamId;
  }

  setActiveProfileId(activeProfileId: string) {
    this.activeProfileId = activeProfileId;
  }

  setActiveTeamId(activeTeamId: number | undefined) {
    this.activeTeamId = activeTeamId;
  }

  createActiveProfileDepQueryKey<T, A extends unknown[]>(fetchFunction: (...args: A) => Promise<ApiResponse<T>>, args?: unknown[]): QueryKey {
    const functionName = fetchFunction.name || 'anonymous';
    const functionHash = simpleHash(fetchFunction.toString());
    return [this.getActiveProfileId(), `${functionName}_${functionHash}`, JSON.stringify(args)];
  }

  createActiveTeamDepQueryKey<T, A extends unknown[]>(fetchFunction: (...args: A) => Promise<ApiResponse<T>>, args?: unknown[]): QueryKey {
    const functionName = fetchFunction.name || 'anonymous';
    const functionHash = simpleHash(fetchFunction.toString());
    return [this.getActiveTeamId(), `${functionName}_${functionHash}`, JSON.stringify(args)];
  }

  createPendingKey(queryKey: QueryKey): string {
    return JSON.stringify(queryKey);
  }

  getCacheKeyByType<T, A extends unknown[]>(
    fetchFunction: (...args: A) => Promise<ApiResponse<T>>,
    type: FetchCacheDependencyType,
    args?: unknown[],
  ): QueryKey {
    switch (type) {
      case FetchCacheDependencyType.PROFILE:
        return this.createActiveProfileDepQueryKey(fetchFunction, args);

      case FetchCacheDependencyType.TEAM:
        return this.createActiveTeamDepQueryKey(fetchFunction, args);

      default:
        return this.createActiveProfileDepQueryKey(fetchFunction, args);
    }
  }

  async fetchWithFunction<T, A extends unknown[]>({
    fetchFunction,
    args = [] as unknown as A, // Ensure args matches the tuple type
    type = FetchCacheDependencyType.PROFILE,
  }: {
    fetchFunction: (...args: A) => Promise<ApiResponse<T>>; // Ensure args spreads correctly
    args?: A;
    type?: FetchCacheDependencyType;
  }): Promise<FetchFunctionResponse<T>> {
    const cacheKey = this.getCacheKeyByType(fetchFunction, type, args);
    const pendingKey = this.createPendingKey(cacheKey);

    // Check cache
    const cachedData = this.getQueryClient().getQueryData<ApiResponse<T>>(cacheKey);
    if (cachedData) {
      return { res: cachedData, cacheHit: true };
    }

    // Check for pending fetch
    const pendingFetch = this.pendingFetches.get(pendingKey);
    if (pendingFetch) {
      return { res: (await pendingFetch) as ApiResponse<T>, cacheHit: false };
    }

    // Fetch and cache
    const fetchPromise = fetchFunction(...args).then((res) => {
      if (res.isSuccess) {
        this.getQueryClient().setQueryData(cacheKey, res);
      }
      this.pendingFetches.delete(pendingKey); // Clean up pending fetch
      return res;
    });

    // Track the ongoing fetch
    this.pendingFetches.set(pendingKey, fetchPromise);

    return { res: await fetchPromise, cacheHit: false };
  }

  removeFetchFunction<T>({
    fetchFunction,
    args,
    type = FetchCacheDependencyType.PROFILE,
  }: {
    fetchFunction: (...args: unknown[]) => Promise<ApiResponse<T>>;
    args?: unknown[];
    type?: FetchCacheDependencyType;
  }): void {
    const cacheKey = this.getCacheKeyByType(fetchFunction, type, args);
    this.getQueryClient().removeQueries({
      queryKey: cacheKey,
    });
  }

  removeAllForProfile(profileId: string) {
    const key = [profileId];
    this.getQueryClient().removeQueries({
      predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
    });
  }
}
