import useFormatting from '@/hooks/useFormatting';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import * as Sentry from '@sentry/react';
import { round } from 'lodash-es';
import { useMemo } from 'react';
import { BidLimits, DEFAULT_BID_DECIMALS, marketplaceBidLimits, placementsBidLimits } from '../types/bid-limits';
import { BidUpdateOption, BidUpdateType, NewBidUpdateDetails } from '../types/bulk-edit';
import { MinMaxLimit } from '../types/MinMaxLimit';
import { adTypeMappingForLimitWarnings } from '../types/AdTypeMapping';
import { assertUnhandledCase } from '@/modules/application/utils';

function useBidLimits() {
  const { formatCurrency } = useFormatting();

  const { activeProfile } = useActiveTeamContext();
  const currentProfileMarketplaceLimits: BidLimits | undefined = useMemo(() => {
    if (!activeProfile?.countryCode || !marketplaceBidLimits[activeProfile?.countryCode]) {
      Sentry.captureMessage(`currentProfileMarketplaceLimits: unknown country code: ${activeProfile?.countryCode}`, 'info');
      console.log('Unknown country code: ', activeProfile?.countryCode);
      return undefined;
    }

    return marketplaceBidLimits[activeProfile?.countryCode];
  }, [activeProfile?.countryCode]);

  const getBidLimits = (adType: CampaignAdType, limits: BidLimits, isVideo?: boolean): MinMaxLimit | null => {
    switch (adType) {
      case CampaignAdType.PRODUCTS:
        return { min: limits.minBidSP, max: limits.maxBidSP };
      case CampaignAdType.BRANDS:
        return isVideo ? { min: limits.minBidSBVCPC, max: limits.maxBidSBVCPC } : { min: limits.minBidSBCPC, max: limits.maxBidSBCPC };
      case CampaignAdType.DISPLAY:
        return { min: limits.minBidSDCPC, max: limits.maxBidSDCPC };
      default:
        // Handle unknown ad type
        Sentry.captureMessage(`getBidLimits: unknown campaign ad type: ${adType}`, 'info');
        console.log('Unknown campaign ad type: ', adType);
        return null;
    }
  };

  const clampBidByLimitAndBudget = (
    bid: number,
    limits: MinMaxLimit,
    adType: CampaignAdType,
    campaignBudgetAmount: number,
    campaignIsVideo: boolean,
    decimals: number,
    includeAdjustedToMessage: boolean,
    warnings: Set<string>,
  ) => {
    const adTypeDescription = `${campaignIsVideo ? 'Sponsored Brands Video' : 'Sponsored ' + adTypeMappingForLimitWarnings[adType] || adType}`;

    if (bid < limits.min) {
      const message = `${adTypeDescription} bid is too low. ${includeAdjustedToMessage ? '' : 'Adjusted to the minimum of ' + formatCurrency(limits.min)}`;
      warnings.add(message);
      return limits.min;
    } else if (bid > limits.max) {
      const message = `${adTypeDescription} bid is too high. ${includeAdjustedToMessage ? '' : 'Adjusted to the maximum of ' + formatCurrency(limits.max)}`;
      warnings.add(message);
      return limits.max;
    } else {
      // Only check budget limits if non of the limits above applied
      return clampBidByBudget(bid, adType, campaignBudgetAmount, decimals, adTypeDescription, includeAdjustedToMessage, warnings);
    }
  };

  const clampBidByBudget = (
    bid: number,
    adType: CampaignAdType,
    campaignBudgetAmount: number,
    decimals: number,
    adTypeDescription: string,
    includeAdjustedToMessage: boolean,
    warnings: Set<string>,
  ) => {
    // If one of the limits have been applied no point to check budget limits
    if (adType == CampaignAdType.PRODUCTS || adType == CampaignAdType.BRANDS || adType == CampaignAdType.TV) {
      if (bid > campaignBudgetAmount) {
        const message = `${adTypeDescription} bid is larger than campaign budget. ${includeAdjustedToMessage ? '' : 'Adjusted to the campaign budget'}`;
        warnings.add(message);

        return campaignBudgetAmount;
      }
    } else if (adType == CampaignAdType.DISPLAY) {
      if (bid >= campaignBudgetAmount / 2) {
        const message = `${adTypeDescription} bid is larger than 50% of campaign budget. ${includeAdjustedToMessage ? '' : 'Adjusted to the half of campaign budget'}`;
        warnings.add(message);

        const halfBudgetAmount = campaignBudgetAmount / 2;

        // If SD targets bid must be less than half the value of your budget
        const clampedBid = halfBudgetAmount - Math.pow(10, -1 * decimals);

        return round(clampedBid, decimals); // with 2 decimals it is 10^-2 = 0.01 and with 0 decimals 10^-0 = 1 (JPY)
      }
    } else {
      return assertUnhandledCase(adType);
    }

    return bid;
  };

  const getClampedBidWithWarnings = (
    newBid: number,
    adType: CampaignAdType,
    campaignIsVideo: boolean,
    campaignBudgetAmount: number,
    warnings: Set<string>,
    includeAdjustedToMessage = false,
  ): number => {
    if (!currentProfileMarketplaceLimits) {
      return newBid;
    }

    // For example we don't allow any decimals for Japanese yen
    let roundedNewBid;
    if (currentProfileMarketplaceLimits.decimals) {
      roundedNewBid = round(newBid, currentProfileMarketplaceLimits.decimals);
    } else {
      roundedNewBid = round(newBid, DEFAULT_BID_DECIMALS);
    }

    const limits = getBidLimits(adType, currentProfileMarketplaceLimits, campaignIsVideo);

    if (!limits) {
      console.error('Limits not found for ad type: ', adType);
      return roundedNewBid; // Return old value if there are no limits (handles unknown ad type)
    }

    const clampedValue = clampBidByLimitAndBudget(
      roundedNewBid,
      limits,
      adType,
      campaignBudgetAmount,
      campaignIsVideo,
      currentProfileMarketplaceLimits.decimals ?? DEFAULT_BID_DECIMALS,
      includeAdjustedToMessage,
      warnings,
    );

    return clampedValue;
  };

  function getBidByUpdateDetails(newBidUpdateDetails: NewBidUpdateDetails): number {
    switch (newBidUpdateDetails.updateData.bidUpdateType) {
      case BidUpdateType.SET_BID_TO_AMOUNT:
        return newBidUpdateDetails.updateData.newBidValue;
      case BidUpdateType.INCREASE_BID_BY_AMOUNT:
        return newBidUpdateDetails.currentBid + newBidUpdateDetails.updateData.newBidValue;
      case BidUpdateType.DECREASE_BID_BY_AMOUNT:
        return newBidUpdateDetails.currentBid - newBidUpdateDetails.updateData.newBidValue;
      case BidUpdateType.INCREASE_BID_BY_PERCENTAGE:
        return newBidUpdateDetails.currentBid * (1 + newBidUpdateDetails.updateData.newBidValue / 100);
      case BidUpdateType.DECREASE_BID_BY_PERCENTAGE:
        return newBidUpdateDetails.currentBid * (1 - newBidUpdateDetails.updateData.newBidValue / 100);
      case BidUpdateType.SET_CPC_TIMES_X:
        return newBidUpdateDetails.currentCPC > 0
          ? newBidUpdateDetails.updateData.newBidValue * newBidUpdateDetails.currentCPC
          : newBidUpdateDetails.currentBid;
      case BidUpdateType.NO_CHANGE:
        return newBidUpdateDetails.currentBid;
      default:
        assertUnhandledCase(newBidUpdateDetails.updateData.bidUpdateType);
    }
  }

  function getClampedBidWithWarningsByUpdateDetails(newBidUpdateDetails: NewBidUpdateDetails): number {
    const newBid = getBidByUpdateDetails(newBidUpdateDetails);

    const clampedValue = getClampedBidWithWarnings(
      newBid,
      newBidUpdateDetails.campaignAdType,
      newBidUpdateDetails.campaignIsVideo,
      newBidUpdateDetails.campaignBudgetAmount,
      newBidUpdateDetails.warnings,
      newBidUpdateDetails.includeAdjustedToMessage,
    );

    return clampedValue;
  }

  function getBidUpdateOptions(currencySymbol: string): BidUpdateOption[] {
    return [
      { value: BidUpdateType.NO_CHANGE, label: 'No change' },
      { value: BidUpdateType.SET_BID_TO_AMOUNT, label: `Set bid to (${currencySymbol})` },
      { value: BidUpdateType.INCREASE_BID_BY_AMOUNT, label: `Increase bid by (${currencySymbol})` },
      { value: BidUpdateType.DECREASE_BID_BY_AMOUNT, label: `Decrease bid by (${currencySymbol})` },
      { value: BidUpdateType.DECREASE_BID_BY_PERCENTAGE, label: 'Decrease bid by (%)' },
      { value: BidUpdateType.INCREASE_BID_BY_PERCENTAGE, label: 'Increase bid by (%)' },
      { value: BidUpdateType.SET_CPC_TIMES_X, label: `Set CPC * X (${currencySymbol})` },
    ];
  }

  // Placement
  const getClampedPlacementValueWithWarnings = (value: number, adType: CampaignAdType, warnings: Set<string>): number => {
    if (!currentProfileMarketplaceLimits) {
      return value;
    }

    let min: number, max: number;
    switch (adType) {
      case CampaignAdType.PRODUCTS:
        min = placementsBidLimits.minSP;
        max = placementsBidLimits.maxSP;
        break;
      case CampaignAdType.BRANDS:
      case CampaignAdType.DISPLAY:
      case CampaignAdType.TV:
        min = placementsBidLimits.minSB;
        max = placementsBidLimits.maxSB;
        break;
      default:
        assertUnhandledCase(adType);
    }

    if (value < min) {
      const message = `New placement value is too low. Adjusted to the minimum of ${min}%`;
      warnings.add(message);
      return min;
    }
    if (value > max) {
      const message = `New placement value bid is too high. Adjusted to the maximum of ${max}`;
      warnings.add(message);
      return max;
    }

    return value;
  };

  return {
    getClampedBidWithWarningsByUpdateDetails,
    getClampedBidWithWarnings,
    getClampedPlacementValueWithWarnings,
    getBidUpdateOptions,
  };
}

export default useBidLimits;
