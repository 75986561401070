import { useLayoutContext } from '@/contexts/LayoutContext';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { generateCopyName } from '@/modules/application/utils';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { ContextKey } from '@/types/context-shared';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Card, CardContent, CircularProgress, InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { isEmpty, isNil } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CreateOrUpdateFilterDTO, SavedFilterLevel } from '../api/filters-contracts';
import { filtersService } from '../api/filters-service';
import { FilterPresetModel } from '../models/FilterPresetModel';
import { FilterKey } from '../types/FilterKey';
import { ALL_PRESETS, SavedFilterLevelType } from '../types/SavedPresets';
import { splitAndWrapPresets } from './helpers';
import { SavedPresetsListGroup } from './SavedPresetsListGroup';

interface FilterPresetListProps {
  setSelectedPreset: (selectedPreset: FilterPresetModel | 'new') => void;
  selectedPreset: FilterPresetModel | 'new' | null;
  presets: FilterPresetModel[];
  isLoading: boolean;
  isError: boolean;
  applicableFilterKeys: Set<FilterKey>;
  contextKey?: ContextKey;
  refetchSavedPresets: (options?: RefetchOptions) => Promise<QueryObserverResult<FilterPresetModel[], Error>>;
}

const SavedPresetsPanel: React.FunctionComponent<FilterPresetListProps> = ({
  setSelectedPreset,
  selectedPreset,
  presets,
  isLoading,
  isError,
  applicableFilterKeys,
  contextKey,
  refetchSavedPresets,
}) => {
  const { fetchCache } = useAlFetchCache();
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { startGlobalLoading, stopGlobalLoading } = useLayoutContext();

  const [searchText, setSearchText] = useState('');
  const [selectedLevel, setSelectedLevel] = useState<SavedFilterLevelType>(SavedFilterLevel.PERSONAL);

  const handleLevelChange = (event: React.MouseEvent<HTMLElement>, newLevel: SavedFilterLevelType) => {
    setSelectedLevel(newLevel);
  };

  const isNewPresetSelected = selectedPreset === 'new';

  const selectedPresetId: number | 'new' | null = (() => {
    if (!isEmpty(searchText)) {
      return null;
    }

    if (selectedPreset === 'new') {
      return 'new';
    }

    if (selectedPreset) {
      return selectedPreset.id;
    }

    return null;
  })();

  // Switch to corresponding level filter
  useEffect(() => {
    if (selectedPreset === 'new' || isNil(selectedPreset)) return;
    if (selectedLevel === ALL_PRESETS) return;

    if (selectedLevel !== selectedPreset.level) {
      setSelectedLevel(selectedPreset.level);
    }
  }, [selectedPreset]);

  const { currentProfilePresets, generalPresets, notApplicablePresets } = useMemo(
    () => splitAndWrapPresets(presets, applicableFilterKeys, activeProfile, selectedLevel, searchText),
    [presets, applicableFilterKeys, activeProfile, selectedLevel, searchText],
  );

  const handleSelect = (preset: FilterPresetModel) => {
    setSelectedPreset(preset);
    setSearchText(''); // Clear search box when a preset is selected
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  async function onDuplicate(presetId: number) {
    if (!activeTeam) {
      toastService.error('No active team');
      return;
    }

    if (!activeTeam.organization.id) {
      toastService.error('No organization id');
      return;
    }

    if (!activeProfile?.id) {
      toastService.error('No profile id');
      return;
    }

    if (!contextKey) {
      toastService.error('No context key');
      return;
    }

    const preset = presets.find((p) => p.id === presetId);
    if (!preset) {
      toastService.error('Preset not found');
      return;
    }

    const newName = generateCopyName(preset.name, new Set(presets.map((p) => p.name)));

    const loadingKey = 'duplicateFilterPreset' + newName;

    startGlobalLoading(loadingKey);

    const dto: CreateOrUpdateFilterDTO = {
      name: newName,
      team_id: activeTeam.id,
      org_id: activeTeam.organization.id,
      profile_id: activeProfile.id,
      filter: FilterPresetModel.encodeFilters(preset.filters),
      page_id: contextKey,
      level: preset.level,
    };

    const response = await filtersService.saveFilters(dto, fetchCache);
    if (!response.isSuccess) {
      toastService.error(response.message);
      stopGlobalLoading(loadingKey);
      return;
    }

    const newPreset = response.payload;
    setSelectedPreset(newPreset);

    refetchSavedPresets();

    toastService.success(`'${preset.name}' has been successfully duplicated as '${newName}'`);

    stopGlobalLoading(loadingKey);
  }

  async function onDelete(presetId: number) {
    const preset = presets.find((p) => p.id === presetId);
    if (!preset) {
      toastService.error('Preset not found');
      return;
    }

    const loadingKey = 'deleteFilterPreset' + presetId;
    startGlobalLoading(loadingKey);

    const response = await filtersService.deleteSavedFilter(presetId);
    if (!response.isSuccess) {
      toastService.error(response.message);
      stopGlobalLoading(loadingKey);
      return;
    }

    refetchSavedPresets();

    toastService.success(`'${preset.name}' has been successfully deleted"`);

    stopGlobalLoading(loadingKey);
  }

  const displayedPresetCount = currentProfilePresets.length + generalPresets.length + notApplicablePresets.length;

  // If presets change, scroll to make the selected preset visible. Important when name changes
  const cardContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const selectedPresetId = selectedPreset === 'new' ? undefined : selectedPreset?.id;
    const presetElement = document.getElementById('preset-' + selectedPresetId);
    if (presetElement && cardContentRef.current) {
      presetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [presets]);

  return (
    <div className="flex flex-col min-w-[288px]">
      <Typography gutterBottom variant="h6">
        Saved Filters
      </Typography>

      <ToggleButtonGroup
        value={selectedLevel}
        exclusive
        onChange={handleLevelChange}
        orientation="horizontal"
        fullWidth
        size="small"
        sx={{
          '& .MuiToggleButton-root': {
            padding: '2px 8px', // Adjust padding to reduce height
            minHeight: '24px', // Reduce minimum height
          },
          '& .MuiToggleButton-root.Mui-selected': {
            backgroundColor: 'sky.light', // Selected background
            color: 'primary.main', // Selected text color
          },
        }}
      >
        <ToggleButton value={SavedFilterLevel.PERSONAL}>Personal</ToggleButton>
        <ToggleButton value={SavedFilterLevel.TEAM}>Team</ToggleButton>
        <ToggleButton value={ALL_PRESETS}>All</ToggleButton>
      </ToggleButtonGroup>

      {/* Search Field */}
      <TextField
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Search presets"
        fullWidth
        variant="outlined"
        className="mb-2"
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchText && (
              <InputAdornment position="end">
                <ClearIcon onClick={handleClearSearch} sx={{ cursor: 'pointer' }} />
              </InputAdornment>
            ),
          },
        }}
      />

      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
        tabIndex={0} // Make the Card focusable for keyboard events
      >
        <CardContent
          ref={cardContentRef}
          sx={{
            flex: 1, // Makes this section take all available space
            display: 'flex',
            flexDirection: 'column',
            p: 0,
            overflowY: 'auto',
            minHeight: 0,
          }}
        >
          {isLoading && !isError && (
            <div className="flex items-center justify-center flex-grow h-full">
              <CircularProgress />
            </div>
          )}

          {isError && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className={'h-full'}
            >
              Failed to load saved filters
            </Typography>
          )}

          {!isLoading && !isError && displayedPresetCount === 0 && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className={'h-full'}
            >
              {!isEmpty(searchText) ? 'Nothing found' : 'Presets will appear here'}
            </Typography>
          )}

          {!isLoading && !isError && displayedPresetCount > 0 && (
            <div className="flex flex-col">
              {currentProfilePresets.length > 0 && (
                <SavedPresetsListGroup
                  isNewPresetSelected={isNewPresetSelected}
                  selectedPresetId={selectedPresetId}
                  header={activeProfile?.name ?? ''}
                  presets={currentProfilePresets}
                  searchText={searchText}
                  handleSelect={handleSelect}
                  onDuplicate={onDuplicate}
                  onDelete={onDelete}
                />
              )}
              {generalPresets.length > 0 && (
                <SavedPresetsListGroup
                  header="General"
                  presets={generalPresets}
                  isNewPresetSelected={isNewPresetSelected}
                  selectedPresetId={selectedPresetId}
                  searchText={searchText}
                  handleSelect={handleSelect}
                  onDuplicate={onDuplicate}
                  onDelete={onDelete}
                />
              )}
              {notApplicablePresets.length > 0 && (
                <SavedPresetsListGroup
                  header="Not Applicable"
                  presets={notApplicablePresets}
                  isNewPresetSelected={isNewPresetSelected}
                  selectedPresetId={selectedPresetId}
                  searchText={searchText}
                  handleSelect={handleSelect}
                  onDuplicate={onDuplicate}
                  onDelete={onDelete}
                />
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SavedPresetsPanel;
