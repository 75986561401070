import { DateFilterModel, FilterCondition } from '@/components/filter-builder/models/AlFilterModel';
import { Popover } from '@mui/material';
import { Dispatch, FunctionComponent, RefObject, SetStateAction } from 'react';
import DateRangePicker from './DateRangePicker';
import { DateTime } from 'luxon';
import { DayStyleConfig } from '../../types/DayStyleConfig';

interface DateRangePickerPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: (filters: DateFilterModel[]) => void;
  defaultDateRange: FilterCondition[] | undefined;
  defaultComparisonDateRange: FilterCondition[] | undefined;
  title: string;
  disablePaywall?: boolean;
  hideComparison?: boolean;
  minDateString?: string;
  getDayStyleConfig: (day: DateTime<boolean>) => DayStyleConfig;
}

export const DateRangePickerPopover: FunctionComponent<DateRangePickerPopoverProps> = ({
  buttonRef,
  isOpen,
  setIsOpen,
  onSubmit,
  defaultDateRange,
  defaultComparisonDateRange,
  title,
  disablePaywall,
  hideComparison = false,
  minDateString,
  getDayStyleConfig,
}) => {
  const handlePopoverClose = () => {
    setIsOpen((previousValue) => !previousValue);
  };

  return (
    <>
      <Popover
        id={'date-range-popover'}
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <DateRangePicker
          title={title}
          setIsOpen={setIsOpen}
          onSubmit={onSubmit}
          defaultDateRange={defaultDateRange}
          defaultComparisonDateRange={defaultComparisonDateRange}
          disablePaywall={disablePaywall}
          hideComparison={hideComparison}
          minDateString={minDateString}
          getDayStyleConfig={getDayStyleConfig}
        />
      </Popover>
    </>
  );
};
