import { Tooltip } from '@mui/material';
import type { IHeaderParams } from 'ag-grid-enterprise';
import { FunctionComponent, useEffect, useState } from 'react';

export interface IChangePercentageHeaderRendererProps extends IHeaderParams {
  currentValue?: string;
  currentValueDescription?: string;
}

const ChangePercentageHeaderRenderer: FunctionComponent<IChangePercentageHeaderRendererProps> = (props) => {
  const { displayName, currentValue, column, api, currentValueDescription, progressSort, enableSorting, showColumnMenu } = props;

  const handleLabelClick = () => {
    if (!enableSorting) return;

    progressSort();
    setIsSortDescending(column.getSort() === 'desc');
    setIsSortAscending(column.getSort() === 'asc');
  };

  const showColumnMenuLocal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    showColumnMenu(event.currentTarget);
  };

  const [isSortDescending, setIsSortDescending] = useState(column.getSort() === 'desc');
  const [isSortAscending, setIsSortAscending] = useState(column.getSort() === 'asc');

  useEffect(() => {
    const sortChangedHandler = () => {
      setIsSortDescending(column.getSort() === 'desc');
      setIsSortAscending(column.getSort() === 'asc');
    };

    api.addEventListener('sortChanged', sortChangedHandler);

    return () => {
      if (!api.isDestroyed()) api.removeEventListener('sortChanged', sortChangedHandler);
    };
  }, []);

  return (
    <div className="ag-cell-label-container w-full flex flex-row items-center justify-end relative">
      <div className="ag-header-cell-menu-button ag-header-menu-icon flex items-center z-2  absolute left-0">
        <div onClick={showColumnMenuLocal} className="cursor-pointer ag-icon ag-icon-menu-alt"></div>
      </div>
      <div className="flex-col items-end text-right" onClick={handleLabelClick}>
        <div className="flex cursor-pointer items-center justify-end">
          <span className="text-wrap line-clamp-2">{displayName}</span>
          {isSortAscending === true && <span className="ag-icon ag-icon-asc"></span>}
          {isSortDescending === true && <span className="ag-icon ag-icon-desc"></span>}
        </div>

        {currentValue && (
          <Tooltip title={currentValueDescription ?? ''}>
            <span className="cursor-pointer font-medium">{currentValue}</span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ChangePercentageHeaderRenderer;
