import { MetricAggregates } from '@/components/metrics/types/MetricField';
import type { GridApi } from 'ag-grid-enterprise';
import { MutableRefObject, useEffect } from 'react';
import { ExpandedGridContext } from '../types';

interface UseMetricColumnAggregatesProps {
  gridApiRef: MutableRefObject<GridApi | undefined>;
  gridContextRef: MutableRefObject<ExpandedGridContext | undefined>;
  metricColumnAggregates: MetricAggregates | undefined;
}

// General hook to manage metric column aggregations
const useMetricColumnAggregates = ({ gridApiRef, gridContextRef, metricColumnAggregates }: UseMetricColumnAggregatesProps) => {
  useEffect(() => {
    if (!gridApiRef.current || !gridContextRef.current || !metricColumnAggregates) return;

    gridContextRef.current.metricColumnAggregates = metricColumnAggregates;

    gridApiRef.current.refreshHeader();
  }, [metricColumnAggregates]);

  const onGridReadyForMetricColumnAggregates = () => {
    if (!gridApiRef.current || !gridContextRef.current) return;
    Object.assign(gridContextRef.current, {
      metricColumnAggregates: metricColumnAggregates,
    });

    // IMPORTANT: cell refreshing needs to be done in table onGridReady to avoid multiple refreshes
  };
  return { onGridReadyForMetricColumnAggregates, metricColumnAggregates };
};

export default useMetricColumnAggregates;
