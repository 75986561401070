import { useLayoutContext } from '@/contexts/LayoutContext';
import useAlFetchCache from '@/modules/al-fetch-cache/useAlFetchCache';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { ContextKey } from '@/types/context-shared';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import { Box, Button, Tooltip } from '@mui/material';
import { isNil } from 'lodash-es';
import { FunctionComponent, useState } from 'react';
import { CreateOrUpdateFilterDTO, SavedFilterLevel } from '../api/filters-contracts';
import { filtersService } from '../api/filters-service';
import { removeDateRangeFilters, splitFiltersToApplicableAndDiscarded } from '../hooks/useFilters';
import { AlFilterModel } from '../models/AlFilterModel';
import { FilterPresetModel } from '../models/FilterPresetModel';
import SaveFiltersModal from './SaveFiltersModal';

interface SaveFiltersButtonProps {
  filters: AlFilterModel[];
  existingFilterPresets: FilterPresetModel[];
  onFiltersSaved: () => void;
  selectedPreset: FilterPresetModel | 'new' | null;
  setSelectedPreset: (selectedPreset: FilterPresetModel | 'new') => void;
  contextKey?: ContextKey;
}

const SaveFiltersButton: FunctionComponent<SaveFiltersButtonProps> = ({
  filters: pendingFilters,
  existingFilterPresets,
  onFiltersSaved,
  contextKey,
  selectedPreset,
  setSelectedPreset,
}) => {
  const { fetchCache } = useAlFetchCache();

  const filters = removeDateRangeFilters(pendingFilters);

  const { startGlobalLoading, stopGlobalLoading } = useLayoutContext();
  const { activeTeam, activeProfile } = useActiveTeamContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSave = async (name: string, level: SavedFilterLevel, createNew: boolean) => {
    if (!activeTeam) {
      toastService.error('No active team');
      return;
    }

    if (!activeTeam.organization.id) {
      toastService.error('No organization id');
      return;
    }

    if (!activeProfile?.id) {
      toastService.error('No profile id');
      return;
    }

    if (!contextKey) {
      toastService.error('No context key');
      return;
    }

    const isUpdatingExisting = !isNil(selectedPreset) && selectedPreset !== 'new' && !createNew;

    const loadingKey = 'saveFilterPreset' + (isUpdatingExisting ? selectedPreset.name : 'new');
    setIsLoading(true);

    startGlobalLoading(loadingKey);

    const dto: CreateOrUpdateFilterDTO = {
      id: isUpdatingExisting ? selectedPreset.id : undefined,
      name: name,
      team_id: activeTeam.id,
      org_id: activeTeam.organization.id,
      profile_id: activeProfile.id,
      filter: FilterPresetModel.encodeFilters(filters),
      page_id: contextKey,
      level: level,
    };

    const response = isUpdatingExisting
      ? await filtersService.updateSavedFilter(selectedPreset.id, dto, fetchCache)
      : await filtersService.saveFilters(dto, fetchCache);
    if (!response.isSuccess) {
      toastService.error(response.message);
      setIsLoading(false);
      stopGlobalLoading(loadingKey);
      return;
    }

    const newPreset = response.payload;

    if (isUpdatingExisting) {
      toastService.success(`'${name}' updated successfully`);
    } else {
      toastService.success(`'${name}' saved successfully`);
    }

    onFiltersSaved();

    stopGlobalLoading(loadingKey);

    setIsModalOpen(false); // Close modal after saving

    // Wait before setting isLoading to false to not show not disabled button while modal closing animation
    setTimeout(() => {
      setIsLoading(false);
      setSelectedPreset(newPreset);
    }, 200);
  };

  const existingNames = existingFilterPresets.map((preset) => preset.name);

  const { discardedFilters: filtersWithoutValue } = splitFiltersToApplicableAndDiscarded(filters);
  return (
    <>
      <Tooltip
        title={
          filtersWithoutValue.length > 0 ? (
            <>
              Some filters are missing values:
              <ul style={{ listStyle: 'disc', marginLeft: '1em' }}>
                {filtersWithoutValue.map((f) => (
                  <li key={f.key}>{f.shortName}</li>
                ))}
              </ul>
            </>
          ) : (
            ''
          )
        }
      >
        <span className="mr-6">
          <Box display="inline-block">
            <span className="rounded-full border border-purple-500 bg-white bg-opacity-100 px-2 py-0.5 mr-1 text-[10px] font-bold text-purple-500 tracking-wide leading-4">
              New
            </span>
            <Button variant="text" startIcon={<SaveOutlined />} onClick={handleOpenModal} disabled={filtersWithoutValue.length > 0}>
              Save Filter(s)
            </Button>
          </Box>
        </span>
      </Tooltip>

      <SaveFiltersModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSave}
        existingNames={existingNames}
        isLoading={isLoading}
        selectedPreset={selectedPreset}
      />
    </>
  );
};

export default SaveFiltersButton;
