import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FunctionComponent, SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { AlFilterModel } from '../models/AlFilterModel';
import { AlMultiSelectOptionModel } from '../models/AlMultiSelectOptionModel';

interface FilterSelectProps {
  filter: AlFilterModel;
  label: string;
}

export const FilterSelect: FunctionComponent<FilterSelectProps> = ({ filter, label }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);
  const [options, setOptions] = useState<AlMultiSelectOptionModel[]>([]);

  useEffect(() => {
    filter.getOptions().then((options) => {
      setOptions(options);
    });
  }, [filter]);

  const selectedValue = useMemo(() => {
    const selectedOptionId = filter.conditions ? filter.conditions[0].values[0] : null;
    return options.find((o) => o.id == selectedOptionId);
  }, [options, filter]);

  function handleChange(event: SyntheticEvent<Element, Event>, newValue: AlMultiSelectOptionModel | null) {
    const selectOptionId = newValue?.id;

    filter.conditions = [
      {
        values: selectOptionId ? [selectOptionId as string] : [],
        operator: filter.defaultConditionOperators[0],
      },
    ];

    setFilterValue(filter);
  }

  return (
    <div className="flex flex-row gap-2 w-full">
      {options.length > 0 && (
        <Autocomplete
          className="flex-1 w-full"
          id={'select-' + filter.key}
          value={selectedValue}
          getOptionLabel={(option) => option.name} // Do not truncate for accurate searching
          onChange={handleChange}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={label}
              slotProps={{
                inputLabel: { shrink: true },
              }}
            />
          )}
        />
      )}
    </div>
  );
};
