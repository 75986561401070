import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { forwardRef, SyntheticEvent, useEffect, useState } from 'react';

export interface AutoCompleteCellEditorOption {
  id: string;
  label: string;
  canHide?: boolean;
}

interface AutoCompleteCellEditorProps extends CustomCellEditorProps {
  options: AutoCompleteCellEditorOption[];
  currentValue: AutoCompleteCellEditorOption | null;
  placeHolderText: string;
  isDisabled?: boolean;
}

const AutoCompleteCellEditor = (
  { onValueChange, stopEditing, options, currentValue, placeHolderText, isDisabled }: AutoCompleteCellEditorProps,
  _ref: unknown,
) => {
  const [inputValue, setInputValue] = useState<AutoCompleteCellEditorOption | null>(currentValue);
  const [open, setOpen] = useState(true); // State to control the opening of the dropdown

  useEffect(() => {
    setInputValue(currentValue);
  }, [currentValue]);

  const handleOnChange = (event: SyntheticEvent<Element, Event>, newValue: AutoCompleteCellEditorOption | null) => {
    setInputValue(newValue);
    if (newValue) {
      onValueChange(newValue.id); // Update the grid's data model when a new value is selected
    } else {
      onValueChange(null); // Handle potential null values if selection is cleared
    }
    stopEditing(); // Immediately stop editing once selection is made
  };

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} placeholder={placeHolderText} variant="outlined" fullWidth autoFocus />
  );

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={inputValue}
      options={options}
      getOptionLabel={(option) => option.label}
      onChange={handleOnChange}
      renderInput={renderInput}
      disabled={isDisabled}
      fullWidth
      autoHighlight
      style={{ width: '100%' }}
    />
  );
};

AutoCompleteCellEditor.displayName = 'AutoCompleteCellEditor';

export default forwardRef(AutoCompleteCellEditor);
