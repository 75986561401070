import { toastService } from '@/services/toast.service';
import { Autocomplete, Checkbox, FilledInputProps, InputProps, OutlinedInputProps, Paper, TextField } from '@mui/material';
import { ChangeEvent, FunctionComponent, SyntheticEvent, forwardRef, useEffect, useMemo, useRef, useState } from 'react';

export interface AlAutoCompleteOption {
  id: string;
  label: string;
  canHide?: boolean;
}

interface AlAutoCompleteProps {
  textFieldLabel?: string;
  options: AlAutoCompleteOption[];
  currentValue: AlAutoCompleteOption | null;
  updateValueWithOption: (option: AlAutoCompleteOption | null) => void;
  placeHolderText: string;
  hideCheckboxText?: string;
  hideCheckboxText_whenNothingToHide?: string;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
  onBlur?: () => void;
  InputProps?: Partial<FilledInputProps> | Partial<OutlinedInputProps> | Partial<InputProps> | undefined;
}

const AlAutoComplete: FunctionComponent<AlAutoCompleteProps> = ({
  textFieldLabel,
  options,
  currentValue,
  updateValueWithOption,
  placeHolderText,
  hideCheckboxText,
  hideCheckboxText_whenNothingToHide,
  isDisabled,
  error,
  helperText,
  onBlur,
  InputProps,
}) => {
  const [inputValue, setInputValue] = useState<AlAutoCompleteOption | null>(currentValue);

  const [localOptions, setLocalOptions] = useState<AlAutoCompleteOption[]>(options);

  const optionsCanHideRemoved = useMemo(() => {
    return options.filter((option) => !option.canHide);
  }, [options]);

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  useEffect(() => {
    setInputValue(currentValue);
  }, [currentValue]);

  const handleOnChange = (event: SyntheticEvent<Element, Event>, newValue: AlAutoCompleteOption | null) => {
    setInputValue(newValue);
    updateValueWithOption(newValue);
  };

  const listboxRef = useRef<HTMLUListElement>(null);
  const [isHiding, setIsHiding] = useState(false);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsHiding(event.target.checked);

    if (event.target.checked) {
      setLocalOptions(optionsCanHideRemoved);
    } else {
      setLocalOptions(options);
    }
  };

  const ListboxComponent = forwardRef<HTMLUListElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, _ref) {
    return (
      <ul {...props} ref={listboxRef}>
        <Paper className="sticky z-10 -top-2 rounded-none" elevation={1}>
          {optionsCanHideRemoved.length > 0 ? (
            <div className="flex flex-row p-1">
              <Checkbox
                id="show-hide-hidden"
                checked={isHiding}
                onChange={handleCheckboxChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span className="ml-2">{hideCheckboxText}</span>
            </div>
          ) : (
            <span className="ml-2">{hideCheckboxText_whenNothingToHide ?? 'Nothing to hide'}</span>
          )}
        </Paper>
        {props.children}
      </ul>
    );
  });

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('Text');

    tryChangingValueByLabel(paste);
  };

  const tryChangingValueByLabel = (label: string) => {
    const option = localOptions.find((option) => option.label === label);
    if (option) {
      setInputValue(option);
      updateValueWithOption(option);
    } else {
      toastService.error(label + ' not found');
    }
  };

  return (
    <Autocomplete
      className="my-0"
      value={inputValue}
      options={localOptions}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeHolderText}
          variant="outlined"
          onPaste={handlePaste}
          label={textFieldLabel}
          error={error}
          helperText={helperText}
          onBlur={onBlur}
          slotProps={{
            input: {
              ...params.InputProps,
              ...InputProps,
            },

            inputLabel: { shrink: true },
          }}
        />
      )}
      onChange={handleOnChange}
      fullWidth
      disabled={isDisabled}
      slotProps={{
        listbox: {
          component: hideCheckboxText ? ListboxComponent : undefined,
        },
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
    />
  );
};

export default AlAutoComplete;
