import { removeInvalidKeywords } from '@/modules/application/amazon-utils';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { CreateNegativesParams, SelectedSearchTerm } from '../../search-terms/models/SearchTermModel';
import { NegativeMatchType } from '../api/negative-targets-contracts';
import { CreateNegativeGeneratorArguments, CreateNegativeModel } from './CreateNegativeModel';

export class CreateNegativeWithSelectedSearchTermModel {
  public createNegativeModel: CreateNegativeModel;
  private selectedSearchTerm: SelectedSearchTerm;

  constructor(createNegativeModel: CreateNegativeModel, selectedSearchTerm: SelectedSearchTerm) {
    this.createNegativeModel = createNegativeModel;
    this.selectedSearchTerm = selectedSearchTerm;
  }

  public get id(): string {
    return this.selectedSearchTerm.id;
  }

  public get searchTerm(): string {
    return this.selectedSearchTerm.searchTerm;
  }

  public get campaignName(): string {
    return this.selectedSearchTerm.campaignName;
  }

  public get campaignAdType(): CampaignAdType {
    return this.selectedSearchTerm.campaignAdType;
  }

  public get targeting(): string {
    return this.selectedSearchTerm.targeting;
  }

  public get campaignId(): string {
    return this.selectedSearchTerm.campaignId;
  }

  public get adGroupId(): string {
    return this.isCampaign ? '' : this.selectedSearchTerm.adGroupId;
  }

  public get adGroupName(): string {
    return this.isCampaign ? '' : this.selectedSearchTerm.adGroupName;
  }

  public get negativeMatchType(): NegativeMatchType {
    return this.createNegativeModel.negativeMatchType;
  }

  public get entityType(): TargetEntityType {
    return this.selectedSearchTerm.entityType;
  }

  public get keywordOrAsin(): string {
    return this.createNegativeModel.keywordOrAsin;
  }

  public get isCampaign(): boolean {
    return [
      NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT,
      NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE,
      NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET,
    ].includes(this.createNegativeModel.negativeMatchType);
  }

  public get isProductTarget(): boolean {
    return [NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET, NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET].includes(
      this.createNegativeModel.negativeMatchType,
    );
  }

  public get rowSelectWarning(): string | null | undefined {
    if (!this.isProductTarget) {
      const { warnings } = removeInvalidKeywords([this.searchTerm], [this.createNegativeModel.negativeMatchType]);
      if (warnings.size > 0) {
        return warnings.values().next().value;
      }
    }
    return null;
  }

  public static fromSelectedSearchTermArrayAndParams(
    selectedSearchTerms: SelectedSearchTerm[],
    params: CreateNegativesParams,
  ): CreateNegativeWithSelectedSearchTermModel[] {
    const models: CreateNegativeWithSelectedSearchTermModel[] = [];

    for (const searchTerm of selectedSearchTerms) {
      const negArgs: CreateNegativeGeneratorArguments = {
        entityType: searchTerm.entityType,
        targetingType: searchTerm.targetingType,
        adGroupId: searchTerm.adGroupId,
        campaignId: searchTerm.campaignId,
        keywordOrAsin: searchTerm.searchTerm,
        campaignAdType: searchTerm.campaignAdType,
      };

      const createNegativeModels = CreateNegativeModel.generateCreateNegativeModels(negArgs, params);
      for (const createNegativeModel of createNegativeModels) {
        models.push(new CreateNegativeWithSelectedSearchTermModel(createNegativeModel, searchTerm));
      }
    }

    return models;
  }
}
