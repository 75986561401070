export enum DataGroupType {
  CAMPAIGN = 'CAMPAIGN',
  TARGET = 'TARGET',
  SEARCHTERM = 'SEARCHTERM',
  PRODUCT = 'PRODUCT',
}

interface DataItemDTO {
  id: number;
  name: string;
}

export interface UpdatableDataItemDTO {
  id?: number;
  name: string;
}

export interface DataGroupDTO {
  id: number;
  name: string;
  type: DataGroupType;
  items: DataItemDTO[];
}

export enum DataGroupEntityType {
  CAMPAIGN = 'CAMPAIGN',
  KEYWORD = 'KEYWORD',
  TARGET = 'PRODUCT_TARGET',
  SEARCHTERM = 'SEARCHTERM',
  PRODUCT = 'PRODUCT',
}
export interface EntityIdAndType {
  id: string;
  entity_type: DataGroupEntityType;
}

export interface DeletedAssignment {
  entity_id: string;
  entity_type: string;
  item_id: number;
}
