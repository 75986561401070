import { sleep } from '@/lib/api/api-utils';
import { BUTTON_WIDTH } from '@/modules/application/types/constants';
import { userService, useUserContext } from '@/modules/users';
import { SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY } from '@/modules/users/api/users/users.contracts';
import { toastService } from '@/services/toast.service';
import { Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { debounce } from 'lodash-es';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import { SellingPartnerGetParamsModel } from '../types/SellingPartnerGetParamsModel';
import { SellingPartnerSelectionDialog } from './SellingPartnerSelectionDialog';
import { VendorProfileSelectorDialog } from './VendorProfileSelectorDialog';

export const SellingPartnerAuthButton: FunctionComponent = () => {
  const { activeTeam } = useActiveTeamContext();
  const { refetchUser } = useUserContext();
  const navigate = useNavigate();
  const currentPage = useLocation().pathname;

  const [searchParams] = useSearchParams();
  const spGetParams = new SellingPartnerGetParamsModel({
    spapiOauthCode: searchParams.get('spapi_oauth_code'),
    state: searchParams.get('state'),
    sellingPartnerId: searchParams.get('selling_partner_id'),
  });

  const [openAuthorizeSellingPartnerDialog, setOpenAuthorizeSellerVendorCentralDialog] = useState(false);

  function onAuthorizeSellingPartner() {
    setOpenAuthorizeSellerVendorCentralDialog(!openAuthorizeSellingPartnerDialog);
  }

  const [openVendorProfileSelectorCentralDialog, setOpenVendorProfileSelectorCentralDialog] = useState(false);

  function onVendorProfileSelectorClose() {
    setOpenVendorProfileSelectorCentralDialog(false);
  }

  const { mutate: authorizeSellerCentral, isPending: isLoadingAuthSellerCentral } = useMutation({
    mutationFn: ({ teamId, profileId, code, state }: { teamId: number; profileId: string; code: string; state: string }) =>
      userService.authorizeSellerCentral(teamId, profileId, { code, state }),

    onSuccess: async (res) => {
      if (res.isSuccess) {
        toastService.success(`Amazon seller central authorization successful`);
      } else {
        toastService.error(`Amazon seller central authorization failed: ${res.message}`);
      }

      await sleep(300);
      await refetchUser();
    },
  });

  // TODO:can we remove debounce?
  const debouncedAuthorizeSellerCentral = debounce(authorizeSellerCentral, 250);

  const [vendorProfileSelectorGetParams, setVendorProfileSelectorGetParams] = useState<SellingPartnerGetParamsModel>();

  useEffect(() => {
    const isGetParamsEmpty = searchParams.size == 0;
    if (isGetParamsEmpty) return;

    const market = localStorage.getItem(SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY);
    if (!market || !spGetParams.isValid()) {
      // Clear url params
      navigate(currentPage, { replace: true });
      return;
    }

    if (spGetParams.isVendor()) {
      setVendorProfileSelectorGetParams(new SellingPartnerGetParamsModel(spGetParams.clone()));
      setOpenVendorProfileSelectorCentralDialog(true);
    } else {
      if (market && activeTeam) {
        const profile = activeTeam.profiles.find(
          (profile) => profile.accountId === spGetParams.sellingPartnerId && profile.countryCode === market,
        );

        // get profile from
        if (profile && !isLoadingAuthSellerCentral && spGetParams.spapiOauthCode && spGetParams.state) {
          debouncedAuthorizeSellerCentral({
            teamId: activeTeam.id,
            profileId: profile.id,
            code: spGetParams.spapiOauthCode,
            state: spGetParams.state,
          });
          localStorage.removeItem(SELLING_PARTNERS_COUNTRY_CODE_LOCAL_STORAGE_KEY);
        } else {
          toastService.error(
            `No Advertising Profile found for Seller ${spGetParams.sellingPartnerId} ${market} market. Please connect Seller's advertising profile first.`,
          );
        }
      }
    }

    navigate(currentPage, { replace: true });
  }, [searchParams]);

  return (
    <>
      <Button
        variant="contained"
        onClick={onAuthorizeSellingPartner}
        style={{ width: BUTTON_WIDTH }}
        disabled={!activeTeam || (activeTeam && activeTeam.profiles.length == 0)}
      >
        Auth Selling Partner
      </Button>

      <SellingPartnerSelectionDialog open={openAuthorizeSellingPartnerDialog} onClose={onAuthorizeSellingPartner} />

      {vendorProfileSelectorGetParams && (
        <VendorProfileSelectorDialog
          open={openVendorProfileSelectorCentralDialog}
          onClose={onVendorProfileSelectorClose}
          getParams={vendorProfileSelectorGetParams}
        />
      )}
    </>
  );
};
