import OppositeModeOverlayBar from '@/components/OppositeModeOverlayBar';
import useFormatting from '@/hooks/useFormatting';
import BugButton from '@/modules/application/components/BugButton';
import HelpButton from '@/modules/application/components/HelpButton';
import SendIcon from '@mui/icons-material/Send';
import { Button, Checkbox, Divider, Tooltip } from '@mui/material';
import type { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, useState } from 'react';
import { CreateNegativeWithSelectedSearchTermModel } from '../../models/CreateNegativePreviewModel';

interface CreateNegativesPreviewActionsBarProps {
  selection: CreateNegativeWithSelectedSearchTermModel[];
  setSelection: (selection: CreateNegativeWithSelectedSearchTermModel[]) => void;
  visibleRowCount: number;
  onApply: () => void;
  isApplyLoading: boolean;
  createNegativesPreviewTableApi: GridApi<CreateNegativeWithSelectedSearchTermModel> | null;
}

const CreateNegativesPreviewActionsBar: FunctionComponent<CreateNegativesPreviewActionsBarProps> = ({
  selection,
  setSelection,
  visibleRowCount,
  onApply,
  isApplyLoading,
  createNegativesPreviewTableApi,
}) => {
  const { formatWithThousandsSeparator } = useFormatting();

  // HANDLE SELECTION
  function onDeselectAll() {
    setSelection([]);
    if (createNegativesPreviewTableApi) {
      createNegativesPreviewTableApi.deselectAll();
    }
  }

  function onSelectAll() {
    if (createNegativesPreviewTableApi) {
      createNegativesPreviewTableApi.selectAll();
    }
  }

  // CONFIRMATION CHECKBOX
  const [isConfirmationCheckboxChecked, setIsConfirmationCheckboxChecked] = useState(false);
  const handleConfirmationCheckboxChange = () => {
    setIsConfirmationCheckboxChecked((prevState) => !prevState);
  };

  return (
    <>
      <OppositeModeOverlayBar>
        <div className="flex flex-row gap-4 items-center px-1.5">
          <Tooltip title="Deselect all">
            <span onClick={onDeselectAll} className="flex whitespace-nowrap text-sm font-bold items-center hover:cursor-pointer">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(selection.length)} of {formatWithThousandsSeparator(visibleRowCount)}
            </span>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <HelpButton />

          <BugButton />

          <Divider className="my-2" orientation="vertical" flexItem />

          <Button className="whitespace-nowrap" onClick={selection.length == visibleRowCount ? onDeselectAll : onSelectAll} variant="text">
            {selection.length == visibleRowCount ? 'Deselect All' : 'Select All'}
          </Button>

          <span onClick={handleConfirmationCheckboxChange} className="flex whitespace-nowrap text-sm items-center hover:cursor-pointer">
            <Checkbox checked={isConfirmationCheckboxChecked} name="confirmationCheckbox" color="secondary" />I confirm changes
          </span>

          <Tooltip title="Send changes to Amazon">
            <span>
              <Button
                onClick={onApply}
                endIcon={<SendIcon />}
                loading={isApplyLoading}
                loadingPosition="end"
                variant="contained"
                disabled={!isConfirmationCheckboxChecked || selection.length == 0}
                color="primary"
                className="whitespace-nowrap"
              >
                <span>Send Negative Targets to Amazon</span>
              </Button>
            </span>
          </Tooltip>
        </div>
      </OppositeModeOverlayBar>
    </>
  );
};

export default CreateNegativesPreviewActionsBar;
