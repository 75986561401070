import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { AlFetchCache } from '@/modules/al-fetch-cache/AlFetchCache';
import { ContextKey } from '@/types/context-shared';
import { uniqueFiltersByKeys } from '../hooks/useFilters';
import {
  AlFilterModel,
  ComparisonDateFilterModel,
  DateFilterModel,
  extractValidFilters,
  FILTERS_VERSION,
  getDefaultDateFilters,
} from '../models/AlFilterModel';
import { filterFactory } from '../models/FilterFactory';
import { FilterPresetModel } from '../models/FilterPresetModel';
import { FilterKey } from '../types/FilterKey';
import { CreateOrUpdateFilterDTO, FilterDTO, SavedFilterDTO } from './filters-contracts';

const EXPIRY_LIMIT_IN_SECONDS = 3600;

const LOCAL_STORAGE_KEY_PREFIX = 'filters';
function createLocalStorageProfileFiltersKey(contextKey: string, profileId: string) {
  return `${contextKey}_${LOCAL_STORAGE_KEY_PREFIX}_${profileId}`;
}

interface StoredFilters {
  version: number;
  filters: FilterDTO[];
  savedAt: string;
}

class FiltersService {
  public basePath = 'saved-filters';

  // LOCAL STORAGE
  saveDateFilters(filters: AlFilterModel[]) {
    if (filters.length == 0) {
      return;
    }

    const key = `${LOCAL_STORAGE_KEY_PREFIX}_dates`;

    // Make sure only to save date filters
    const dateFilters: AlFilterModel[] = [];
    uniqueFiltersByKeys(filters).forEach((filter) => {
      if (filter instanceof DateFilterModel || filter instanceof ComparisonDateFilterModel) {
        dateFilters.push(filter);
      }
    });

    const storedFilters: StoredFilters = {
      version: FILTERS_VERSION,
      savedAt: new Date().toISOString(),
      filters: dateFilters.map((filter) => filter.toDTO()),
    };

    if (dateFilters.length > 0) {
      localStorage.setItem(key, JSON.stringify(storedFilters));
    }
  }

  loadDateFilters(fetchCache: AlFetchCache): AlFilterModel[] {
    const key = `${LOCAL_STORAGE_KEY_PREFIX}_dates`;
    try {
      const storedArrayString = localStorage.getItem(key);
      const storedFilters: StoredFilters = storedArrayString ? JSON.parse(storedArrayString) : null;

      if (storedFilters && storedFilters.version === FILTERS_VERSION) {
        const savedAt = new Date(storedFilters.savedAt);
        const currentTime = new Date();
        const timeDiffInSeconds = (currentTime.getTime() - savedAt.getTime()) / 1000;

        // Check if the filters were set more than an hour ago
        if (timeDiffInSeconds > EXPIRY_LIMIT_IN_SECONDS) {
          return getDefaultDateFilters();
        }

        const storedFilterModels = storedFilters.filters.map((filter) => filterFactory.createFilterModelFromDTO(filter, fetchCache));
        return extractValidFilters(storedFilterModels);
      }
      return getDefaultDateFilters();
    } catch (error) {
      console.error(`Error parsing JSON from localStorage, key: ${key}`, error);
      return getDefaultDateFilters();
    }
  }

  saveProfileFilters(contextKey: string, profileId: string, filters: AlFilterModel[]) {
    const dateFilters: AlFilterModel[] = [];
    const nonDateFilters: AlFilterModel[] = [];

    uniqueFiltersByKeys(filters).forEach((filter) => {
      if (filter.isFilterBuilderFilter) {
        nonDateFilters.push(filter);
      } else {
        dateFilters.push(filter);
      }
    });

    const key = createLocalStorageProfileFiltersKey(contextKey, profileId);
    const storedFilters: StoredFilters = {
      version: FILTERS_VERSION,
      filters: nonDateFilters.map((filter) => filter.toDTO()),
      savedAt: new Date().toISOString(),
    };
    localStorage.setItem(key, JSON.stringify(storedFilters));

    // Empty filters can happen with intermediate states like profile change
    // Empty to prevent extra api calls by changed filters
    if (dateFilters.length > 0) {
      this.saveDateFilters(dateFilters);
    }
  }

  loadProfileFilters(
    contextKey: ContextKey,
    profileId: string,
    defaultValueOnEmpty: AlFilterModel[],
    fetchCache: AlFetchCache,
  ): AlFilterModel[] {
    const key = createLocalStorageProfileFiltersKey(contextKey, profileId);
    try {
      const storedArrayString = localStorage.getItem(key);
      const nonDateStoredFilters: StoredFilters | null = storedArrayString ? JSON.parse(storedArrayString) : null;

      const dateFilters = this.loadDateFilters(fetchCache);
      if (nonDateStoredFilters && nonDateStoredFilters.version === FILTERS_VERSION) {
        let filters = nonDateStoredFilters.filters
          .map((filter) => filterFactory.createFilterModelFromDTO(filter, fetchCache, { contextKey }))
          .filter((filter) => filter.isFilterBuilderFilter);
        if (defaultValueOnEmpty.find((filter) => filter.key === FilterKey.DATE)) {
          filters = filters.concat(dateFilters);
        }

        return extractValidFilters(filters);
      }

      return this.overwriteDateFiltersIfExist(defaultValueOnEmpty, dateFilters);
    } catch (error) {
      console.error(`Error parsing JSON from localStorage, key: ${key}`, error);
      return defaultValueOnEmpty;
    }
  }

  overwriteDateFiltersIfExist(defaultValue: AlFilterModel[], dateFilters: AlFilterModel[]) {
    const mergedFilters = defaultValue.map((filter) => {
      const dateFilterInstance = dateFilters.find((dateFilter) => filter instanceof dateFilter.constructor);
      return dateFilterInstance || filter;
    });

    return mergedFilters;
  }

  // API
  getSavedFiltersQueryKey() {
    return [this.basePath];
  }

  async getSavedFilters(fetchCache: AlFetchCache) {
    const applicationResponse = await apiClient.get<SavedFilterDTO[]>(`${this.basePath}/`);
    return applicationResponse.processPayload((payload) => FilterPresetModel.fromDTOArray(payload, fetchCache));
  }

  async saveFilters(dto: CreateOrUpdateFilterDTO, fetchCache: AlFetchCache): Promise<ApiResponse<FilterPresetModel>> {
    const applicationResponse = await apiClient.post<SavedFilterDTO>(`${this.basePath}/`, dto);
    return applicationResponse.processPayload((payload) => FilterPresetModel.fromDTO(payload, fetchCache));
  }

  async deleteSavedFilter(id: number) {
    return await apiClient.delete<void>(`${this.basePath}/${id}/`);
  }

  async updateSavedFilter(id: number, dto: CreateOrUpdateFilterDTO, fetchCache: AlFetchCache): Promise<ApiResponse<FilterPresetModel>> {
    const applicationResponse = await apiClient.put<SavedFilterDTO>(`${this.basePath}/${id}/`, dto);
    return applicationResponse.processPayload((payload) => FilterPresetModel.fromDTO(payload, fetchCache));
  }
}

export const filtersService = new FiltersService();
