import { useTranslation } from '@/lib';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash-es';
import { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from 'react';
import { SavedFilterLevel } from '../api/filters-contracts';
import { FilterPresetModel } from '../models/FilterPresetModel';

interface SaveFiltersModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (name: string, level: SavedFilterLevel, createNew: boolean) => void;
  existingNames: string[];
  isLoading: boolean;
  selectedPreset: FilterPresetModel | 'new' | null;
}

const SaveFiltersModal: FunctionComponent<SaveFiltersModalProps> = ({ open, onClose, onSave, existingNames, isLoading, selectedPreset }) => {
  const [name, setName] = useState('');
  const [level, setLevel] = useState<SavedFilterLevel | null>(null);
  const [nameError, setNameError] = useState(false);
  const isUpdatingExisting = !isNil(selectedPreset) && selectedPreset !== 'new';
  const nameInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (!open) return;

    setName(isUpdatingExisting ? selectedPreset.name : '');
    setLevel(isUpdatingExisting ? selectedPreset.level : null);

    setTimeout(() => {
      if (nameInputRef.current) {
        nameInputRef.current.focus();
      }
    }, 100); // Delay ensures the field is mounted before focusing
  }, [open]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputName = e.target.value;
    setName(inputName);

    // Check if the entered name already exists
    const existingUpdateAndUnchangedName = isUpdatingExisting && selectedPreset.name === inputName;
    setNameError(existingNames.includes(inputName) && !existingUpdateAndUnchangedName);
  };

  const onSaveClicked = (createNew: boolean) => {
    if (!nameError && name.trim() && level) {
      onSave(name.trim(), level, createNew);

      // Give time to save and close so wouldn't flash empty
      setTimeout(() => {
        setName('');
        setLevel(null);
      }, 1000);
    }
  };

  const helperText = (() => {
    if (nameError) return 'This filter name already exists';

    if (isUpdatingExisting && name !== selectedPreset.name) return `Name changed from '${selectedPreset.name}'`;
  })();

  const isApplyDisabled = !name.trim() || !level || nameError;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { width: '450px' },
      }}
    >
      <DialogTitle>
        <div className="flex flex-row justify-between">
          <div>{isUpdatingExisting ? 'Update Filter' : 'Save Filter'}</div>

          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="flex flex-col gap-2">
        {/* Name Input */}
        <TextField
          label="Filter Name"
          value={name}
          onChange={handleNameChange}
          placeholder="Enter a unique filter name"
          inputRef={nameInputRef}
          fullWidth
          error={nameError} // Highlight the input in red if there's an error
          helperText={helperText}
        />

        {/* Radio Buttons */}
        <FormControl>
          <Typography variant="body2" className="mb-2">
            Select Saving Level
          </Typography>
          <RadioGroup className="flex flex-col space-y-2" value={level} onChange={(e) => setLevel(e.target.value as SavedFilterLevel)}>
            <FormControlLabel
              value={SavedFilterLevel.PERSONAL}
              control={<Radio />}
              label={
                <div className="flex flex-col space-y-0">
                  <Typography variant="subtitle2">{t(`enums.saved_filter_level.${SavedFilterLevel.PERSONAL}`)}</Typography>
                  <Typography variant="caption">Save this filter for your personal use only.</Typography>
                </div>
              }
            />
            <FormControlLabel
              value={SavedFilterLevel.TEAM}
              control={<Radio />}
              label={
                <div className="flex flex-col space-y-0">
                  <Typography variant="subtitle2">{t(`enums.saved_filter_level.${SavedFilterLevel.TEAM}`)}</Typography>
                  <Typography variant="caption">Share this filter with your team members.</Typography>
                </div>
              }
            />
            {/* <FormControlLabel
              value={SavedFilterLevel.ORG}
              control={<Radio />}
              label={
                <div className="flex flex-col space-y-0">
                  <Typography variant="subtitle2">{t(`enums.saved_filter_level.${SavedFilterLevel.ORG}`)}</Typography>
                  <Typography variant="caption">Make this filter available to everyone in your organization.</Typography>
                </div>
              }
            /> */}
          </RadioGroup>
          {isUpdatingExisting && level && level != selectedPreset.level && (
            <FormHelperText>{`Changed from '${t(`enums.saved_filter_level.${selectedPreset.level}`)}'`}</FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          loading={isLoading}
          onClick={() => onSaveClicked(true)}
          variant={isUpdatingExisting ? 'outlined' : 'contained'}
          disabled={isApplyDisabled}
          color="primary"
        >
          Save as New
        </Button>
        {isUpdatingExisting && (
          <Button loading={isLoading} onClick={() => onSaveClicked(false)} variant="contained" disabled={isApplyDisabled} color="primary">
            Update
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SaveFiltersModal;
