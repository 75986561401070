import { v4 } from 'uuid';
import { IDashboardWidget } from '../types/IDashboardWidget';
import { IDashboardWidgetBaseConfiguration } from '../types/IDashboardWidgetBaseConfiguration';
import { DashboardWidgetType } from '../types/DashboardWidgetType';
import { MetricWidgetConfiguration } from '../components/widgets/metric-widget/MetricWidgetConfiguration';
import { EntityType } from '../types/EntityType';
import { CommonMetricField } from '@/components/metrics/types/MetricField';
import { METRIC_WIDGET_CATALOG_ITEM } from '../components/widgets/metric-widget/MetricWidgetCatalogItem';
import { LineGraphWidgetConfiguration } from '../components/widgets/line-graph-widget/LineGraphWidgetConfiguration';
import { GroupByTime } from '../types/GroupByTime';
import { LINE_GRAPH_WIDGET_CATALOG_ITEM } from '../components/widgets/line-graph-widget/LineGraphWidgetCatalogItem';
import { BarGraphWidgetConfiguration } from '../components/widgets/bar-graph-widget/BarGraphWidgetConfiguration';
import { BAR_GRAPH_WIDGET_CATALOG_ITEM } from '../components/widgets/bar-graph-widget/BarGraphWidgetCatalogItem';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { PieGraphWidgetConfiguration } from '../components/widgets/pie-graph-widget/PieGraphWidgetConfiguration';
import { PIE_GRAPH_WIDGET_CATALOG_ITEM } from '../components/widgets/pie-graph-widget/PieGraphWidgetCatalogItem';
import { TABLE_WIDGET_CATALOG_ITEM } from '../components/widgets/table-widget/TableWidgetCatalogItem';
import { TABLE_METRIC_COLUMNS, TableWidgetConfiguration } from '../components/widgets/table-widget/TableWidgetConfiguration';
import { GroupByCategory } from '../types/GroupByCategory';
import { GroupByField } from '../types/GroupByField';

// Define the DashboardTemplate interface for the template list
interface DashboardTemplate {
  id: string;
  title: string;
  description: string;
  widgets: IDashboardWidget<IDashboardWidgetBaseConfiguration>[];
}

export const DASHBOARD_TEMPLATE_EMPTY: DashboardTemplate = {
  id: 'empty',
  title: 'Empty',
  description: 'Start with a blank canvas',
  widgets: [],
};

const DASHBOARD_TEMPLATE_STANDARD: DashboardTemplate = {
  id: 'standard',
  title: 'Standard Dashboard',
  description: 'A dashboard for small agencies to report important metrics and trends',
  widgets: [
    <IDashboardWidget<MetricWidgetConfiguration>>{
      id: v4(),
      type: DashboardWidgetType.MetricWidget,
      configuration: {
        id: v4(),
        title: 'Impressions',
        campaignFilters: [],
        entityType: EntityType.CAMPAIGN,
        metricField: CommonMetricField.IMPRESSIONS,
        profilesIds: [],
        targetFilters: [],
        placementFilters: [],
        productFilters: [],
        isConfigured: true,
      },
      layout: {
        ...METRIC_WIDGET_CATALOG_ITEM.layout,
        x: 0,
        y: 0,
      },
    },
    // Clicks widget
    <IDashboardWidget<MetricWidgetConfiguration>>{
      id: v4(),
      type: DashboardWidgetType.MetricWidget,
      configuration: {
        id: v4(),
        title: 'Clicks',
        campaignFilters: [],
        entityType: EntityType.CAMPAIGN,
        metricField: CommonMetricField.CLICKS,
        profilesIds: [],
        targetFilters: [],
        placementFilters: [],
        productFilters: [],
        isConfigured: true,
      },
      layout: {
        ...METRIC_WIDGET_CATALOG_ITEM.layout,
        x: METRIC_WIDGET_CATALOG_ITEM.layout.w,
        y: 0,
      },
    },
    // Orders
    <IDashboardWidget<MetricWidgetConfiguration>>{
      id: v4(),
      type: DashboardWidgetType.MetricWidget,
      configuration: {
        id: v4(),
        title: 'Orders',
        campaignFilters: [],
        entityType: EntityType.CAMPAIGN,
        metricField: CommonMetricField.ORDERS,
        profilesIds: [],
        targetFilters: [],
        placementFilters: [],
        productFilters: [],
        isConfigured: true,
      },
      layout: {
        ...METRIC_WIDGET_CATALOG_ITEM.layout,
        x: METRIC_WIDGET_CATALOG_ITEM.layout.w * 2,
        y: 0,
      },
    },
    // Spend
    <IDashboardWidget<MetricWidgetConfiguration>>{
      id: v4(),
      type: DashboardWidgetType.MetricWidget,
      configuration: {
        id: v4(),
        title: 'Spend',
        campaignFilters: [],
        entityType: EntityType.CAMPAIGN,
        metricField: CommonMetricField.SPEND,
        profilesIds: [],
        targetFilters: [],
        placementFilters: [],
        productFilters: [],
        isConfigured: true,
      },
      layout: {
        ...METRIC_WIDGET_CATALOG_ITEM.layout,
        x: METRIC_WIDGET_CATALOG_ITEM.layout.w * 3,
        y: 0,
      },
    },
    // Sales
    <IDashboardWidget<MetricWidgetConfiguration>>{
      id: v4(),
      type: DashboardWidgetType.MetricWidget,
      configuration: {
        id: v4(),
        title: 'Sales',
        campaignFilters: [],
        entityType: EntityType.CAMPAIGN,
        metricField: CommonMetricField.SALES,
        profilesIds: [],
        targetFilters: [],
        placementFilters: [],
        productFilters: [],
        isConfigured: true,
      },
      layout: {
        ...METRIC_WIDGET_CATALOG_ITEM.layout,
        x: METRIC_WIDGET_CATALOG_ITEM.layout.w * 4,
        y: 0,
      },
    },
    // ACoS
    <IDashboardWidget<MetricWidgetConfiguration>>{
      id: v4(),
      type: DashboardWidgetType.MetricWidget,
      configuration: {
        id: v4(),
        title: 'ACOS',
        campaignFilters: [],
        entityType: EntityType.CAMPAIGN,
        metricField: CommonMetricField.ACOS,
        profilesIds: [],
        targetFilters: [],
        placementFilters: [],
        productFilters: [],
        isConfigured: true,
      },
      layout: {
        ...METRIC_WIDGET_CATALOG_ITEM.layout,
        x: METRIC_WIDGET_CATALOG_ITEM.layout.w * 5,
        y: 0,
      },
    },
    // CTR
    <IDashboardWidget<MetricWidgetConfiguration>>{
      id: v4(),
      type: DashboardWidgetType.MetricWidget,
      configuration: {
        id: v4(),
        title: 'CTR',
        campaignFilters: [],
        entityType: EntityType.CAMPAIGN,
        metricField: CommonMetricField.CTR,
        profilesIds: [],
        targetFilters: [],
        placementFilters: [],
        productFilters: [],
        isConfigured: true,
      },
      layout: {
        ...METRIC_WIDGET_CATALOG_ITEM.layout,
        x: METRIC_WIDGET_CATALOG_ITEM.layout.w * 6,
        y: 0,
      },
    },
    // CVR
    <IDashboardWidget<MetricWidgetConfiguration>>{
      id: v4(),
      type: DashboardWidgetType.MetricWidget,
      configuration: {
        id: v4(),
        title: 'CVR',
        campaignFilters: [],
        entityType: EntityType.CAMPAIGN,
        metricField: CommonMetricField.CVR,
        profilesIds: [],
        targetFilters: [],
        placementFilters: [],
        productFilters: [],
        isConfigured: true,
      },
      layout: {
        ...METRIC_WIDGET_CATALOG_ITEM.layout,
        x: METRIC_WIDGET_CATALOG_ITEM.layout.w * 7,
        y: 0,
      },
    },
    // Line Graph widget
    <IDashboardWidget<LineGraphWidgetConfiguration>>{
      id: v4(),
      type: DashboardWidgetType.LineGraphWidget,
      configuration: {
        id: v4(),
        title: 'Line Graph',
        entityType: EntityType.CAMPAIGN,
        campaignFilters: [],
        groupByTime: GroupByTime.DAY,
        selectedMetrics: [CommonMetricField.ACOS],
        profilesIds: [],
        targetFilters: [],
        placementFilters: [],
        productFilters: [],
        isConfigured: true,
      },
      layout: {
        ...LINE_GRAPH_WIDGET_CATALOG_ITEM.layout,
        y: 2,
        h: 8,
        w: METRIC_WIDGET_CATALOG_ITEM.layout.w * 8,
        i: v4(),
      },
    },
    // Bar Graph
    <IDashboardWidget<BarGraphWidgetConfiguration>>{
      id: v4(),
      type: DashboardWidgetType.BarGraphWidget,
      configuration: {
        id: v4(),
        title: 'Bar Graph',
        entityType: EntityType.PROFILE,
        campaignGroupByCategory: GroupByCategory.PROPERTY,
        campaignGroupByValue: GroupByField.AD_TYPE,
        campaignAdType: CampaignAdType.BRANDS,
        targetsGroupByCategory: GroupByCategory.PROPERTY,
        targetsGroupByValue: GroupByField.AD_TYPE,
        selectedMetrics: [CommonMetricField.SALES, CommonMetricField.ACOS, CommonMetricField.SPEND, CommonMetricField.ROAS],
        campaignFilters: [],
        targetFilters: [],
        placementFilters: [],
        productFilters: [],
        placementGroupByCategory: GroupByCategory.PROPERTY,
        placementGroupByValue: GroupByField.PLACEMENT_TYPE,
        productGroupByCategory: GroupByCategory.PROPERTY,
        productGroupByValue: GroupByField.ASIN,
        profilesIds: [],
        isConfigured: true,
      },
      layout: {
        ...BAR_GRAPH_WIDGET_CATALOG_ITEM.layout,
        y: 10,
        h: 8,
        w: METRIC_WIDGET_CATALOG_ITEM.layout.w * 5,
        i: v4(),
      },
    },
    // Pie Graph
    <IDashboardWidget<PieGraphWidgetConfiguration>>{
      id: v4(),
      type: DashboardWidgetType.PieGraphWidget,
      configuration: {
        id: v4(),
        title: 'Pie Graph',
        entityType: EntityType.PROFILE,
        campaignGroupByCategory: GroupByCategory.PROPERTY,
        campaignGroupByValue: GroupByField.AD_TYPE,
        campaignAdType: CampaignAdType.BRANDS,
        targetsGroupByCategory: GroupByCategory.PROPERTY,
        targetsGroupByValue: GroupByField.AD_TYPE,
        selectedMetric: CommonMetricField.SALES,
        campaignFilters: [],
        targetFilters: [],
        placementFilters: [],
        productFilters: [],
        placementGroupByCategory: GroupByCategory.PROPERTY,
        placementGroupByValue: GroupByField.PLACEMENT_TYPE,
        productGroupByCategory: GroupByCategory.PROPERTY,
        productGroupByValue: GroupByField.ASIN,
        profilesIds: [],
        isConfigured: true,
      },
      layout: {
        ...PIE_GRAPH_WIDGET_CATALOG_ITEM.layout,
        y: 10,
        x: METRIC_WIDGET_CATALOG_ITEM.layout.w * 5,
        w: METRIC_WIDGET_CATALOG_ITEM.layout.w * 3,
        h: 8,
        i: v4(),
      },
    },
    // Table widget
    <IDashboardWidget<TableWidgetConfiguration>>{
      id: v4(),
      type: DashboardWidgetType.TableWidget,
      configuration: {
        id: v4(),
        title: 'Table',
        entityType: EntityType.PROFILE,
        campaignGroupByCategory: GroupByCategory.PROPERTY,
        campaignGroupByValue: GroupByField.AD_TYPE,
        campaignAdType: CampaignAdType.BRANDS,
        targetsGroupByCategory: GroupByCategory.PROPERTY,
        targetsGroupByValue: GroupByField.AD_TYPE,
        metricColumns: TABLE_METRIC_COLUMNS.map((column) => ({
          columnId: column.colId,
          isVisible: true,
          label: column.headerName,
        })),
        hasFilters: false,
        campaignFilters: [],
        targetFilters: [],
        placementFilters: [],
        productFilters: [],
        placementGroupByCategory: GroupByCategory.PROPERTY,
        placementGroupByValue: GroupByField.PLACEMENT_TYPE,
        productGroupByCategory: GroupByCategory.PROPERTY,
        productGroupByValue: GroupByField.ASIN,
        profilesIds: [],
        isConfigured: true,
      },
      layout: {
        ...TABLE_WIDGET_CATALOG_ITEM.layout,
        y: 18,
        h: 8,
        w: METRIC_WIDGET_CATALOG_ITEM.layout.w * 8,
        i: v4(),
      },
    },
  ],
};

export const DASHBOARD_TEMPLATES: DashboardTemplate[] = [DASHBOARD_TEMPLATE_EMPTY, DASHBOARD_TEMPLATE_STANDARD];
