import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider, IconButton, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';
import { FunctionComponent, useState } from 'react';
import { FilterPresetModel } from '../models/FilterPresetModel';
import { WrappedSavedFilterPreset } from '../types/SavedPresets';

interface SavedPresetsListGroupProps {
  isNewPresetSelected: boolean;
  selectedPresetId: number | 'new' | null; // TODO: extract type
  header: string;
  presets: WrappedSavedFilterPreset[];
  searchText: string;
  handleSelect: (preset: FilterPresetModel) => void;
  onDuplicate: (presetId: number) => void;
  onDelete: (presetId: number) => void;
}

export const SavedPresetsListGroup: FunctionComponent<SavedPresetsListGroupProps> = ({
  isNewPresetSelected,
  selectedPresetId,
  header,
  presets,
  searchText,
  handleSelect,
  onDuplicate,
  onDelete,
}) => {
  const [hoveredPresetId, setHoveredPresetId] = useState<number | null>(null);

  return (
    <>
      <ListSubheader className="pb-0.5 text-gray-400 leading-none uppercase text-xxs font-bold tracking-wider pt-2">
        {header}
        <Divider className="mt-1" orientation="horizontal" flexItem />
      </ListSubheader>

      {presets.map((wrappedPreset) => (
        <Tooltip title={wrappedPreset.tooltip} key={wrappedPreset.preset.id} placement="right">
          <span>
            <ListItemButton
              id={`preset-${wrappedPreset.preset.id}`}
              selected={!isNewPresetSelected && selectedPresetId === wrappedPreset.preset.id}
              onClick={() => handleSelect(wrappedPreset.preset)}
              className="py-0"
              disabled={wrappedPreset.disabled}
              onMouseEnter={() => setHoveredPresetId(wrappedPreset.preset.id)}
              onMouseLeave={() => setHoveredPresetId(null)}
            >
              {/* Main List Text */}
              <ListItemText
                slotProps={{
                  primary: { className: `text-sm ${wrappedPreset.preset.id === selectedPresetId ? 'text-primary-500 font-semibold' : ''}` },
                }}
                primary={highlightMatch(wrappedPreset.preset.name, searchText)}
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    {t(`enums.saved_filter_level.${wrappedPreset.preset.level}`)}
                  </Typography>
                }
              />

              <ListItemIcon className="flex flex-row items-center justify-end">
                {hoveredPresetId === wrappedPreset.preset.id && (
                  <>
                    {!wrappedPreset.disabled && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDuplicate(wrappedPreset.preset.id);
                        }}
                        color="primary"
                      >
                        <Tooltip title="Duplicate">
                          <ContentCopyIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                    )}
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(wrappedPreset.preset.id);
                      }}
                      color="error"
                    >
                      <Tooltip title="Delete">
                        <DeleteIcon fontSize="small" />
                      </Tooltip>
                    </IconButton>
                  </>
                )}

                {!isNewPresetSelected && selectedPresetId === wrappedPreset.preset.id && <ChevronRightIcon />}
              </ListItemIcon>
            </ListItemButton>
          </span>
        </Tooltip>
      ))}
    </>
  );
};

function highlightMatch(text: string, query: string) {
  if (!query) return text;
  const safeQuery = escapeRegExp(query);
  const parts = text.split(new RegExp(`(${safeQuery})`, 'gi'));
  return (
    <span>
      {parts.map((part, index) => (
        <span
          key={index}
          style={{
            fontWeight: part.toLowerCase() === query.toLowerCase() ? 'bold' : 'normal',
          }}
        >
          {part}
        </span>
      ))}
    </span>
  );
}

function escapeRegExp(text: string): string {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
