import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { CountryCode } from './CountryCode';
import { MinMaxLimitByProfileType } from './MinMaxLimit';

export const DEFAULT_BUDGET_DECIMALS = 2;

export type BudgetLimits = {
  [CampaignAdType.PRODUCTS]: MinMaxLimitByProfileType;
  [CampaignAdType.BRANDS]: MinMaxLimitByProfileType;
  [CampaignAdType.DISPLAY]: MinMaxLimitByProfileType;
  [CampaignAdType.TV]: MinMaxLimitByProfileType;
  decimals?: number; // E.g, Japan has zero decimals
};

// See https://advertising.amazon.com/API/docs/en-us/reference/concepts/limits#budget-constraints-by-marketplace
// CampaignAdType.TV limits are missing from docs. Currently, using SP limits
type MarketplaceBudgetLimits = Record<CountryCode, BudgetLimits>;

export const marketplaceBudgetLimits: MarketplaceBudgetLimits = {
  [CountryCode.US]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 50000 } },
    [CampaignAdType.TV]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
  },
  [CountryCode.CA]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 50000 } },
    [CampaignAdType.TV]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
  },
  [CountryCode.UK]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 50000 } },
    [CampaignAdType.TV]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
  },
  [CountryCode.DE]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 50000 } },
    [CampaignAdType.TV]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
  },
  [CountryCode.FR]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 50000 } },
    [CampaignAdType.TV]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
  },
  [CountryCode.IT]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 50000 } },
    [CampaignAdType.TV]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
  },
  [CountryCode.ES]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 50000 } },
    [CampaignAdType.TV]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
  },
  [CountryCode.IN]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 50, max: 21000000 }, vendor: { min: 50, max: 21000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 100, max: 21000000 }, vendor: { min: 500, max: 21000000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 50, max: 21000000 }, vendor: { min: 50, max: 5000000 } },
    [CampaignAdType.TV]: { seller: { min: 50, max: 21000000 }, vendor: { min: 50, max: 21000000 } },
  },
  [CountryCode.JP]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 100, max: 21000000 }, vendor: { min: 100, max: 21000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 100, max: 21000000 }, vendor: { min: 100, max: 21000000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 100, max: 21000000 }, vendor: { min: 100, max: 5000000 } },
    [CampaignAdType.TV]: { seller: { min: 100, max: 21000000 }, vendor: { min: 100, max: 21000000 } },
    decimals: 0,
  },
  [CountryCode.AU]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 1.4, max: 1500000 }, vendor: { min: 1.4, max: 1500000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 1.4, max: 1500000 }, vendor: { min: 1.4, max: 1500000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1.4, max: 1500000 }, vendor: { min: 1, max: 1500000 } },
    [CampaignAdType.TV]: { seller: { min: 1.4, max: 1500000 }, vendor: { min: 1.4, max: 1500000 } },
  },
  [CountryCode.MX]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 1, max: 21000000 }, vendor: { min: 1, max: 21000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 1, max: 21000000 }, vendor: { min: 1, max: 21000000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1, max: 21000000 }, vendor: { min: 1, max: 21000000 } },
    [CampaignAdType.TV]: { seller: { min: 1, max: 21000000 }, vendor: { min: 1, max: 21000000 } },
  },
  [CountryCode.AE]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 4, max: 3700000 }, vendor: { min: 4, max: 3700000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 4, max: 3700000 }, vendor: { min: 4, max: 3700000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 4, max: 3700000 }, vendor: { min: 4, max: 3700000 } },
    [CampaignAdType.TV]: { seller: { min: 4, max: 3700000 }, vendor: { min: 4, max: 3700000 } },
  },
  [CountryCode.SA]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 4, max: 3700000 }, vendor: { min: 4, max: 3700000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 4, max: 3700000 }, vendor: { min: 4, max: 3700000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 4, max: 3700000 }, vendor: { min: 4, max: 3700000 } },
    [CampaignAdType.TV]: { seller: { min: 4, max: 3700000 }, vendor: { min: 4, max: 3700000 } },
  },
  [CountryCode.BR]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 1.32, max: 5300000 }, vendor: { min: 1.32, max: 5300000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 4, max: 3700000 }, vendor: { min: 4, max: 3700000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1.32, max: 5300000 }, vendor: { min: 1.32, max: 5300000 } },
    [CampaignAdType.TV]: { seller: { min: 1.32, max: 5300000 }, vendor: { min: 1.32, max: 5300000 } },
  },
  [CountryCode.NL]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.TV]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
  },
  [CountryCode.SG]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 1.39, max: 1300000 }, vendor: { min: 1.39, max: 1300000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 4, max: 3700000 }, vendor: { min: 4, max: 3700000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 4, max: 1300000 }, vendor: { min: 4, max: 1300000 } },
    [CampaignAdType.TV]: { seller: { min: 1.39, max: 1300000 }, vendor: { min: 1.39, max: 1300000 } },
  },
  [CountryCode.TR]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 2, max: 2500000 }, vendor: { min: 2, max: 2500000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 2, max: 2700000 }, vendor: { min: 2, max: 2700000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 2, max: 2700000 }, vendor: { min: 2, max: 2700000 } },
    [CampaignAdType.TV]: { seller: { min: 2, max: 2500000 }, vendor: { min: 2, max: 2500000 } },
  },
  [CountryCode.PL]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 2, max: 2000000 }, vendor: { min: 2, max: 2000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 2, max: 2400000 }, vendor: { min: 2, max: 2400000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.TV]: { seller: { min: 2, max: 2000000 }, vendor: { min: 2, max: 2000000 } },
  },
  [CountryCode.SE]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 9, max: 9300000 }, vendor: { min: 9, max: 9300000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 9, max: 9400000 }, vendor: { min: 9, max: 9400000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.TV]: { seller: { min: 9, max: 9300000 }, vendor: { min: 9, max: 9300000 } },
  },
  [CountryCode.EG]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 7, max: 7400000 }, vendor: { min: 7, max: 7400000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 7, max: 7600000 }, vendor: { min: 7, max: 7600000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 7, max: 7600000 }, vendor: { min: 7, max: 7600000 } },
    [CampaignAdType.TV]: { seller: { min: 7, max: 7400000 }, vendor: { min: 7, max: 7400000 } },
  },
  [CountryCode.BE]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 50000 } },
    [CampaignAdType.TV]: { seller: { min: 1, max: 1000000 }, vendor: { min: 1, max: 1000000 } },
  },
  [CountryCode.ZA]: {
    [CampaignAdType.PRODUCTS]: { seller: { min: 20, max: 7000000 }, vendor: { min: 20, max: 7000000 } },
    [CampaignAdType.BRANDS]: { seller: { min: 6, max: 7000000 }, vendor: { min: 6, max: 7000000 } },
    [CampaignAdType.DISPLAY]: { seller: { min: 6, max: 7000000 }, vendor: { min: 6, max: 7000000 } },
    [CampaignAdType.TV]: { seller: { min: 20, max: 7000000 }, vendor: { min: 20, max: 7000000 } },
  },
};
