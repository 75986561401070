import FilterBarCard from '@/components/cards/FilterBarCard';
import FilterControls from '@/components/filter-builder/FilterControls';
import { SearchBox } from '@/components/filter-builder/SearchBox';
import { CampaignGroupNameFilterModel, createCampaignGroupsFilters } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import GridTools from '@/components/grid/components/GridTools';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import { ContextKey } from '@/types/context-shared';
import type { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, MutableRefObject } from 'react';
import { CampaignGroupsWithMetrics } from '../../api/campaign/campaign-contracts';
import { useCampaignGroupsContext } from '../../contexts/CampaignGroupsContext';

interface CampaignGroupsFilterBarProps {
  campaignGroupsWithMetrics: CampaignGroupsWithMetrics | undefined;
  gridApiRef: MutableRefObject<GridApi<unknown> | null>;
}

const CampaignGroupsFilterBar: FunctionComponent<CampaignGroupsFilterBarProps> = ({ campaignGroupsWithMetrics, gridApiRef }) => {
  const { filters, setFilters, setFilterValue, setFilterValues, gridToggles, setGridToggles } = useCampaignGroupsContext();
  const { getComparisonDataMissingMessage } = useComparisonMissing({});

  const availableFilters = createCampaignGroupsFilters();
  const defaultFilterKey = FilterKey.CAMPAIGN_GROUP_NAME;

  return (
    <FilterBarCard>
      <SearchBox
        filterKey={FilterKey.CAMPAIGN_GROUP_NAME}
        newFilterModel={() => new CampaignGroupNameFilterModel()}
        placeholder="Search Opt Group Name"
        filters={filters}
        setFilterValue={setFilterValue}
        setFilters={setFilters}
      />

      <FilterControls
        filters={filters}
        setFilters={setFilters}
        availableFilters={availableFilters}
        defaultFilterKey={defaultFilterKey}
        contextKey={ContextKey.CAMPAIGN_GROUPS}
      />

      <div className="flex flex-grow"></div>
      <div className="flex h-full flex-col justify-center items-center">
        <DateRangeButton
          title="Range"
          filters={filters}
          setFilterValues={setFilterValues}
          tooltip="Date range used to stats"
          comparisonRangeWarning={getComparisonDataMissingMessage(campaignGroupsWithMetrics?.isComparisonDataMissing ?? false)}
        />
      </div>
      <GridTools gridToggles={gridToggles} setGridToggles={setGridToggles} gridApiRef={gridApiRef} overlayColumnGrouping />
    </FilterBarCard>
  );
};

export default CampaignGroupsFilterBar;
