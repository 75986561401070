import InfoMessage from '@/components/feedback/InfoMessage';
import LoadingOverlay from '@/components/feedback/LoadingOverlay';
import { AlMultiSelectOptionModel } from '@/components/filter-builder/models/AlMultiSelectOptionModel';
import { AlMultiSelect } from '@/components/form/AlMultiSelect';
import { AlMultiTextInput } from '@/components/form/AlMultiTextInput';
import { TextLabel } from '@/components/typography/TextLabel';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import { useTranslation } from '@/lib';
import useBidLimits from '@/modules/amazon-constants/hooks/useBidLimits';
import { cleanAsinsReturnErrorMessage, removeInvalidKeywords } from '@/modules/application/amazon-utils';
import { UpdateResponseDTO } from '@/modules/application/components/UpdateResponseModal';
import { isValidPositiveNumberOrEmptyString } from '@/modules/application/utils';
import useCampaignToAdGroups from '@/modules/campaigns/hooks/useCampaignToAdGroups';
import { CampaignToAdGroups } from '@/modules/negative-targets/models/CampaignToAdGroupModel';
import { FieldNameToMatchTypeRecord, MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { TargetEntityTypeColors, TargetingTypeColors } from '@/types/colors.enum';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { ChangeEvent, FunctionComponent, SetStateAction, useEffect, useMemo, useState } from 'react';
import { TargetCreateDTO, TargetEntityType } from '../api/targets-contracts';
import { invalidateProfile_targetingWithTimelineQueryKeys, targetingService } from '../api/targets-service';

interface CreateTargetsModalPreset {
  sourceCampaignIds: string[];
}

interface BulkCreateTargetsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApplied: (applyResponse: SetStateAction<UpdateResponseDTO | undefined>) => void;
  preset?: CreateTargetsModalPreset;
}

interface TargetsCreateInputData {
  selectedCampaignIds: string[];
  selectedAdGroupIds: string[];
  checkedMatchTypes: Set<MatchType>;
  inputKeywords: string[];
  inputAsins: string[];
  bid: number | null;
  rawBid: string;
}

const DEFAULT_INPUT_DATA = {
  selectedCampaignIds: [],
  selectedAdGroupIds: [],
  checkedMatchTypes: new Set<MatchType>(),
  inputKeywords: [],
  inputAsins: [],
  bid: null,
  rawBid: '',
};

const BulkCreateTargetsModal: FunctionComponent<BulkCreateTargetsModalProps> = ({ isOpen, onClose, onApplied, preset }) => {
  const { activeProfile } = useActiveTeamContext();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { getCurrencyInputProps, toastWarnWithSetMessages, createMultilineMessageComponent } = useHelperComponents();
  const { campaignToAdGroupsRecord } = useCampaignToAdGroups();
  const { getClampedBidWithWarnings } = useBidLimits();

  const [inputData, setInputData] = useState<TargetsCreateInputData>(DEFAULT_INPUT_DATA);

  // CAMPAIGN
  useEffect(() => {
    if (!preset?.sourceCampaignIds || !campaignToAdGroupsRecord) return;

    const presetCampaignIdsSet = new Set(preset.sourceCampaignIds);
    setInputData({
      ...inputData,
      selectedCampaignIds: campaignSelectOptions.filter((c) => presetCampaignIdsSet.has(c.id as string)).map((c) => c.id as string),
    });
  }, [preset, campaignToAdGroupsRecord]);

  const campaignSelectOptions = useMemo(() => {
    if (!campaignToAdGroupsRecord) return [];

    const campaignIds = preset?.sourceCampaignIds || Object.keys(campaignToAdGroupsRecord);

    return campaignIds
      .filter((campaignId) => campaignToAdGroupsRecord[campaignId]?.canAddTargets())
      .map((campaignId) => {
        const campaign = campaignToAdGroupsRecord[campaignId];
        const targetingType = t(`enums.targeting_type.${campaign.targetingType}`);
        const color = TargetingTypeColors[campaign.targetingType];
        return new AlMultiSelectOptionModel(campaign.name, campaign.id, undefined, targetingType, color);
      });
  }, [campaignToAdGroupsRecord]);

  const validSelectedCampaignCount = useMemo(() => {
    if (isNil(campaignToAdGroupsRecord)) return 0;

    if (isNil(preset)) {
      return Object.keys(campaignSelectOptions).length;
    }

    return preset.sourceCampaignIds.filter((campaignId) => campaignToAdGroupsRecord[campaignId]?.canAddTargets()).length;
  }, [preset, campaignToAdGroupsRecord]);

  function setSelectedCampaignIds(ids: string[]) {
    setInputData({ ...inputData, selectedCampaignIds: ids });
  }

  // AD GROUPS
  const adGroupSelectOptions = useMemo(() => {
    if (!campaignToAdGroupsRecord || inputData.selectedCampaignIds.length == 0) return [];

    return inputData.selectedCampaignIds.flatMap((campaignId) => {
      const campaign = campaignToAdGroupsRecord[campaignId];
      if (!campaign) return [];

      return Object.keys(campaign.adGroupIdToAdGroup).map((adGroupId) => {
        const adGroup = campaign.adGroupIdToAdGroup[adGroupId];
        const type = adGroup.entityType;
        const color = TargetEntityTypeColors[type];
        return new AlMultiSelectOptionModel(adGroup.name, adGroup.id, undefined, t(`enums.bidding_entity_short.${type}`), color);
      });
    });
  }, [campaignToAdGroupsRecord, inputData.selectedCampaignIds]);

  useEffect(() => {
    // When there's only one ad group, we can select it immediately
    if (adGroupSelectOptions.length != 1) {
      return;
    }
    setInputData((prev) => ({ ...prev, selectedAdGroupIds: [adGroupSelectOptions[0].id as string] }));
  }, [adGroupSelectOptions]);

  // Helper structure to get type
  const adGroupIdToEntityType = useMemo(() => {
    if (!campaignToAdGroupsRecord) return {};

    return Object.values(campaignToAdGroupsRecord).reduce(
      (acc, campaign) => {
        Object.entries(campaign.adGroupIdToAdGroup).forEach(([adGroupId, adGroupModel]) => {
          acc[adGroupId] = adGroupModel.entityType;
        });
        return acc;
      },
      {} as Record<string, TargetEntityType>,
    );
  }, [campaignToAdGroupsRecord]);

  function setSelectedAdGroupIds(ids: string[]) {
    setInputData({ ...inputData, selectedAdGroupIds: ids });
  }

  // HANDLE UNSET TYPE
  const [unsetToType, setUnsetToType] = useState<TargetEntityType | null>(null);

  function onUnsetRadioButtonChange(e: ChangeEvent<HTMLInputElement>) {
    setUnsetToType(e.target.value as TargetEntityType);

    setInputData((prev) => {
      return { ...prev, checkedMatchTypes: new Set(), inputAsins: [], inputKeywords: [] };
    });
  }

  const isSomeSelectedAdGroupsUnsetType = inputData.selectedAdGroupIds
    .map((id) => adGroupIdToEntityType[id])
    .some((type) => type === TargetEntityType.UNSET);

  // CHECKBOXES
  function handleCheckboxValueChanged(event: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    const matchType = FieldNameToMatchTypeRecord[name];

    setInputData((prev) => {
      const updatedSet = new Set(prev.checkedMatchTypes);
      if (checked) {
        updatedSet.add(matchType);
      } else {
        updatedSet.delete(matchType);
      }
      return { ...prev, checkedMatchTypes: updatedSet };
    });
  }

  const isSomeSelectedAdGroupsKeywordType =
    inputData.selectedAdGroupIds.map((id) => adGroupIdToEntityType[id]).some((type) => type === TargetEntityType.KEYWORD) ||
    unsetToType === TargetEntityType.KEYWORD;
  const isSomeSelectedAdGroupsProductTargetType =
    inputData.selectedAdGroupIds.map((id) => adGroupIdToEntityType[id]).some((type) => type === TargetEntityType.PRODUCT_TARGET) ||
    unsetToType === TargetEntityType.PRODUCT_TARGET;

  // KEYWORD
  function setInputKeywords(keywords: string[]) {
    // Remove duplicates and check make valid
    const { validatedKeywords, warnings } = removeInvalidKeywords(Array.from(new Set(keywords)));

    if (warnings.size > 0) {
      const msg =
        keywords.length == 1
          ? `Keyword cannot be added due to amazon constraints`
          : `${keywords.length - validatedKeywords.length} of ${keywords.length} keywords were removed due to amazon constraints`;
      toastService.warn(createMultilineMessageComponent([msg, ...Array.from(warnings)]));
    }
    setInputData({ ...inputData, inputKeywords: validatedKeywords });
  }

  // ASIN
  function setInputAsins(asins: string[]) {
    const { expressions, errorMessage } = cleanAsinsReturnErrorMessage(asins);
    if (errorMessage) {
      toastService.error(errorMessage);
    }

    setInputData({ ...inputData, inputAsins: expressions });
  }

  // BID
  function handleBidChanged(event: ChangeEvent<HTMLInputElement>) {
    const rawBid = event.target.value;

    if (isValidPositiveNumberOrEmptyString(rawBid)) {
      // Do not parseFloat here, otherwise can't enter "2." when trying to input "2.2"
      const bid = rawBid === '' || rawBid === '.' ? null : parseFloat(rawBid);
      setInputData({ ...inputData, bid, rawBid });
    }
  }

  // ACTIONS
  const [isApplying, setIsApplying] = useState(false);

  async function onCreateClicked() {
    setIsApplying(true);

    try {
      const targets: TargetCreateDTO[] = getTargetsToCreate(inputData);
      if (targets.length === 0) {
        toastService.info('No targets to create');
        setIsApplying(false);
        return;
      }

      const response = await targetingService.createTargets(targets);

      if (response.isSuccess) {
        onApplied({ responseErrorMsg: null, payload: response.payload });

        invalidateProfile_targetingWithTimelineQueryKeys(queryClient, activeProfile?.id);
      } else {
        onApplied({ responseErrorMsg: `Did not receive a response from server: ${response.message}`, payload: undefined });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsApplying(false);
    }

    processOnClose();
  }

  function processOnClose() {
    setInputData(DEFAULT_INPUT_DATA);

    setIsApplying(false);
    onClose();
  }

  function getTargetsToCreate(inputData: TargetsCreateInputData): TargetCreateDTO[] {
    const targets: TargetCreateDTO[] = [];

    if (!inputData.bid) {
      toastService.error('Bid cannot be empty');
      return targets;
    }

    const warnings = new Set<string>();
    for (const campaignId of inputData.selectedCampaignIds) {
      const campaign = campaignToAdGroupsRecord?.[campaignId];
      if (!campaign) continue;
      const validBid = getValidBidSetWarnings(inputData.bid, campaign, warnings);
      for (const adGroupId of inputData.selectedAdGroupIds) {
        const adGroup = campaign.adGroupIdToAdGroup[adGroupId];
        if (!adGroup) continue;

        const entityType = adGroup.entityType === TargetEntityType.UNSET ? unsetToType : adGroupIdToEntityType[adGroupId];

        // Should not be null as UI doesn't allow confirm when unset are present and unsetToType is null
        if (entityType === null) continue;

        for (const matchType of inputData.checkedMatchTypes) {
          if (entityType === TargetEntityType.KEYWORD) {
            for (const keyword of inputData.inputKeywords) {
              targets.push({
                campaign_id: campaignId,
                ad_group_id: adGroupId,
                ad_type: campaign.campaignAdType,
                bid: validBid,
                keyword: keyword,
                match_type: matchType,
              });
            }
          }

          if (entityType === TargetEntityType.PRODUCT_TARGET) {
            for (const asin of inputData.inputAsins) {
              targets.push({
                campaign_id: campaignId,
                ad_group_id: adGroupId,
                ad_type: campaign.campaignAdType,
                bid: validBid,
                expression: asin,
                match_type: matchType,
              });
            }
          }
        }
      }
    }

    if (warnings.size > 0) {
      toastWarnWithSetMessages(warnings);
    }

    return targets;
  }

  function getValidBidSetWarnings(bid: number, campaign: CampaignToAdGroups, warnings: Set<string>): number {
    const clampingWarnings = new Set<string>();
    const clampedValue = getClampedBidWithWarnings(
      bid,
      campaign.campaignAdType,
      campaign.isVideo,
      campaign.campaignBudgetAmount,
      clampingWarnings,
    );

    clampingWarnings.forEach((item) => warnings.add(item));
    return clampedValue;
  }

  const isSomeKWMatchSelected =
    inputData.checkedMatchTypes.has(MatchType.EXACT) ||
    inputData.checkedMatchTypes.has(MatchType.BROAD) ||
    inputData.checkedMatchTypes.has(MatchType.PHRASE);
  const isSomePTMatchSelected = inputData.checkedMatchTypes.has(MatchType.INDIVIDUAL) || inputData.checkedMatchTypes.has(MatchType.EXPANDED);

  const isNoneOfTheMatchesSelected = !isSomeKWMatchSelected && !isSomePTMatchSelected;
  const isApplyDisabled =
    !inputData.bid ||
    inputData.selectedCampaignIds.length === 0 ||
    inputData.selectedAdGroupIds.length === 0 ||
    (inputData.inputKeywords.length === 0 && inputData.inputAsins.length === 0 && (isSomeKWMatchSelected || isSomePTMatchSelected)) ||
    (isSomeSelectedAdGroupsUnsetType && isNil(unsetToType)) ||
    isNoneOfTheMatchesSelected;

  const anyValidCampaignSelected = validSelectedCampaignCount > 0;
  const notAllPresetCampaignsAreValid = preset?.sourceCampaignIds && validSelectedCampaignCount < preset.sourceCampaignIds.length;
  const warnings = notAllPresetCampaignsAreValid ? ['Cannot create targets for some of the selected campaigns'] : [];

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle style={{ paddingBottom: '0px' }}>Create Targets</DialogTitle>
      <DialogContent className="flex flex-col gap-y-4 mt-4">
        {anyValidCampaignSelected && (
          <AlMultiSelect
            options={campaignSelectOptions}
            selectedOptionIds={inputData.selectedCampaignIds}
            setSelectedOptionIds={setSelectedCampaignIds}
            label="Campaign(s)"
            placeholderText="Select Campaign(s)"
            isDisabled={!isNil(preset?.sourceCampaignIds)}
          />
        )}

        {inputData.selectedCampaignIds.length > 0 && (
          <AlMultiSelect
            options={adGroupSelectOptions}
            selectedOptionIds={inputData.selectedAdGroupIds}
            setSelectedOptionIds={setSelectedAdGroupIds}
            label="Ad Group(s)"
            placeholderText="Select Ad Group(s)"
          />
        )}

        {isSomeSelectedAdGroupsUnsetType && (
          <FormControl>
            <TextLabel>Target type for empty ad groups</TextLabel>
            <RadioGroup
              row // Makes options appear in a row
              value={unsetToType}
              onChange={onUnsetRadioButtonChange}
              className="flex gap-4"
            >
              <FormControlLabel value={TargetEntityType.KEYWORD} control={<Radio />} label="Keyword" />
              <FormControlLabel value={TargetEntityType.PRODUCT_TARGET} control={<Radio />} label="Product Target" />
            </RadioGroup>
          </FormControl>
        )}

        {inputData.selectedAdGroupIds.length > 0 && (
          <div className="flex flex-col gap-y-0">
            {(isSomeSelectedAdGroupsKeywordType || isSomeSelectedAdGroupsProductTargetType) && <TextLabel>Match</TextLabel>}
            <div className="inline-flex flex-col max-w-fit">
              {isSomeSelectedAdGroupsKeywordType && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={inputData.checkedMatchTypes.has(MatchType.EXACT)}
                        onChange={handleCheckboxValueChanged}
                        name="exactMatch"
                      />
                    }
                    label="Exact Match"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={inputData.checkedMatchTypes.has(MatchType.BROAD)}
                        onChange={handleCheckboxValueChanged}
                        name="broadMatch"
                      />
                    }
                    label="Broad Match"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={inputData.checkedMatchTypes.has(MatchType.PHRASE)}
                        onChange={handleCheckboxValueChanged}
                        name="phraseMatch"
                      />
                    }
                    label="Phrase Match"
                  />
                </>
              )}
              {isSomeSelectedAdGroupsProductTargetType && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={inputData.checkedMatchTypes.has(MatchType.INDIVIDUAL)}
                        onChange={handleCheckboxValueChanged}
                        name="individualProductTarget"
                      />
                    }
                    label="Individual Product Target"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={inputData.checkedMatchTypes.has(MatchType.EXPANDED)}
                        onChange={handleCheckboxValueChanged}
                        name="expandedProductTarget"
                      />
                    }
                    label="Expanded Product Target"
                  />
                </>
              )}
            </div>
          </div>
        )}

        {isSomeSelectedAdGroupsKeywordType && (
          <AlMultiTextInput label="Keyword(s)" tags={inputData.inputKeywords} setTags={setInputKeywords} />
        )}

        {isSomeSelectedAdGroupsProductTargetType && (
          <AlMultiTextInput label="Product ASIN(s)" tags={inputData.inputAsins} setTags={setInputAsins} />
        )}

        {(isSomeSelectedAdGroupsKeywordType || isSomeSelectedAdGroupsProductTargetType) && (
          <div className="flex flex-col gap-y-0">
            <TextLabel>Bid</TextLabel>
            <TextField
              className="w-1/3"
              value={inputData.rawBid}
              type="text"
              onChange={handleBidChanged}
              slotProps={{
                input: getCurrencyInputProps(),
                inputLabel: { shrink: true },
              }}
            />
          </div>
        )}
      </DialogContent>
      {!anyValidCampaignSelected && (
        <div className="flex flex-col px-6">
          <InfoMessage content={'No valid campaigns selected'} />
        </div>
      )}
      {anyValidCampaignSelected && warnings && (
        <div className="flex flex-col px-6">
          {warnings.map((warning, index) => (
            <InfoMessage key={index} content={warning} />
          ))}
        </div>
      )}
      <DialogActions>
        <Box flexGrow={1} />
        <Button onClick={processOnClose} variant="text">
          Cancel
        </Button>
        <Button onClick={onCreateClicked} loading={isApplying} variant="contained" disabled={isApplyDisabled}>
          Create Targets
        </Button>
      </DialogActions>
      <LoadingOverlay isVisible={isApplying} message="Sending new data to Amazon..." />
    </Dialog>
  );
};

export default BulkCreateTargetsModal;
