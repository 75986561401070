import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { AlFilterModel, createTargetingNoStatsFilterSet } from '../filter-builder/models/AlFilterModel';

interface SetNoStatsFilterButtonProps {
  setFilters: (filters: AlFilterModel[]) => void;
}

const SetNoStatsFilterButton: FunctionComponent<SetNoStatsFilterButtonProps> = ({ setFilters }) => {
  const { activeProfile } = useActiveTeamContext();

  function setNoStatsFilters() {
    if (activeProfile) {
      setFilters(createTargetingNoStatsFilterSet(activeProfile));
    }
  }

  return (
    <Button className="ml-2" size="small" onClick={setNoStatsFilters}>
      View Targets
    </Button>
  );
};

export default SetNoStatsFilterButton;
