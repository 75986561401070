import FilterBarCard from '@/components/cards/FilterBarCard';
import FilterControls from '@/components/filter-builder/FilterControls';
import { createCampaignMappingFilters } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import { ContextKey } from '@/types/context-shared';
import { Divider } from '@mui/material';
import { FunctionComponent } from 'react';
import { useCampaignMappingContext } from '../contexts/CampaignMappingContext';
import { CampaignMappingModel } from '../models/CampaignMappingModel';
import DownloadCampaignMappingsButton from './DownloadCampaignMappingsButton';
import DownloadCampaignMappingsTemplateButton from './DownloadCampaignMappingsTemplateButton';
import ImportCampaignMappingsButton from './ImportCampaignMappingsButton';
import OpenNewSingleMappingModalButton from './OpenNewSingleMappingModalButton';

interface CampaignMappingFilterBarProps {
  onlyDownloadButton?: boolean; // true in import view
  isDeleteLoading?: boolean;
  rowData?: CampaignMappingModel[];
}

const CampaignMappingFilterBar: FunctionComponent<CampaignMappingFilterBarProps> = ({ onlyDownloadButton, rowData }) => {
  const { filters, setFilters } = useCampaignMappingContext();

  const availableCampaignMappingFilters = createCampaignMappingFilters();
  const defaultFilterKey = FilterKey.SOURCE_CAMPAIGN_NAME;

  return (
    <FilterBarCard>
      {/* <CampaignMappingSearch /> */}
      <div className="flex flex-row gap-x-4">
        <>
          {onlyDownloadButton ? (
            <DownloadCampaignMappingsTemplateButton />
          ) : (
            <>
              <ImportCampaignMappingsButton />
              <OpenNewSingleMappingModalButton />
            </>
          )}
        </>
        <Divider className="my-2" orientation="vertical" flexItem />
        <FilterControls
          contextKey={ContextKey.CAMPAIGN_MAPPING}
          filters={filters}
          setFilters={setFilters}
          availableFilters={availableCampaignMappingFilters}
          defaultFilterKey={defaultFilterKey}
        />
      </div>
      <div className="flex flex-grow"></div>
      <div className="flex gap-x-4">{rowData && rowData.length > 0 && <DownloadCampaignMappingsButton rowData={rowData} />}</div>
    </FilterBarCard>
  );
};

export default CampaignMappingFilterBar;
