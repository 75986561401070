import { FunctionComponent, useContext, useState } from 'react';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { IDashboardWidgetBaseConfiguration } from '../../types/IDashboardWidgetBaseConfiguration';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import RichTextEditor from '@/components/form/rich-text-editor/RichTextEditor';

const DashboardWidgetRichTitle: FunctionComponent<{ configuration: IDashboardWidgetBaseConfiguration }> = ({ configuration }) => {
  const { widgetId } = useContext(DashboardWidgetContext);
  const updateWidgetConfiguration = useDashboardContextValue((context) => context.updateWidgetConfiguration);

  const [title, setTitle] = useState(configuration.title);

  const handleTitleChange = async (newTitle: string) => {
    if (!widgetId) return;
    setTitle(newTitle);
    configuration.title = newTitle;
    await updateWidgetConfiguration(widgetId, configuration, false);
  };

  return (
    <div className="text-sm mt-2  mx-2 font-bold text-primary-950 opacity-90 tiptap tiptap-single-line overflow-auto w-full">
      <RichTextEditor content={title} onContentChange={handleTitleChange} hideMenuBar singleLine />
    </div>
  );
};

export default DashboardWidgetRichTitle;
