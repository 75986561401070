import { UnitType } from '@/components/metrics/MetricsConfig';
import useFormatting, { CurrencyPosition } from '@/hooks/useFormatting';
import { isValidNumberInput } from '@/modules/application/utils';
import { SelectChangeEvent, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { cloneDeep, isEmpty, isNumber } from 'lodash-es';
import { ChangeEvent, FunctionComponent, useContext } from 'react';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { AlFilterModel, LogicalOperatorType, OperatorType } from '../models/AlFilterModel';
import { BetweenOperatorType } from '../types/Operators';
import { BetweenOperatorSelect } from './BetweenOperatorSelect';

interface FilterBetweenProps {
  filter: AlFilterModel;
  isOperatorDisabled?: boolean;
}

const NONE = 'NONE';

export const FilterBetween: FunctionComponent<FilterBetweenProps> = ({ filter, isOperatorDisabled = false }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);
  const { getCurrencySymbolPosition, getCurrencySymbol } = useFormatting();
  const currencyPosition = getCurrencySymbolPosition();
  const currencySymbol = getCurrencySymbol();

  function formatValue(value: number | string): string[] | number[] {
    return isEmpty(value) ? ['0'] : isNumber(value) ? [value as number] : [value as string];
  }

  const firstCondition: number | string = filter.conditions && filter.conditions.length > 0 ? filter.conditions[0].values[0] : '';
  const firstConditionOperator: OperatorType =
    filter.conditions && filter.conditions.length > 0 ? filter.conditions[0].operator : filter.defaultConditionOperators[0];

  const secondCondition: number | string = filter.conditions && filter.conditions.length > 1 ? filter.conditions[1].values[0] : '';
  const secondConditionOperator: OperatorType =
    filter.conditions && filter.conditions.length > 1 ? filter.conditions[1].operator : filter.defaultConditionOperators[1];

  function updateFilterConditions(
    firstUpdate?: { newCondition?: number | string; newOperator?: OperatorType },
    secondUpdate?: { newCondition?: number | string; newOperator?: OperatorType } | typeof NONE,
    logicalOperator?: LogicalOperatorType | typeof NONE,
  ) {
    const updatedFilter = cloneDeep(filter);
    if (!updatedFilter.conditions) {
      updatedFilter.conditions = [];
    }

    if (logicalOperator === NONE) {
      updatedFilter.logicalOperator = undefined;
    } else if (logicalOperator !== undefined) {
      updatedFilter.logicalOperator = logicalOperator;
    }

    // Update first condition.
    if (firstUpdate) {
      // Ensure the first condition exists.
      if (updatedFilter.conditions.length < 1) {
        updatedFilter.conditions[0] = {
          values: formatValue(firstCondition),
          operator: firstConditionOperator,
        };
      }
      if (firstUpdate.newCondition !== undefined) {
        updatedFilter.conditions[0].values[0] = firstUpdate.newCondition;
      }
      if (firstUpdate.newOperator !== undefined) {
        updatedFilter.conditions[0].operator = firstUpdate.newOperator;
      }
    }

    // Update second condition.
    if (secondUpdate) {
      // If the new value is NONE, remove the second condition if it exists.
      if (secondUpdate === NONE) {
        if (updatedFilter.conditions.length > 1) {
          updatedFilter.conditions.splice(1, 1);
        }
      } else {
        // When updating the second condition, ensure the first exists.
        if (updatedFilter.conditions.length < 1) {
          updatedFilter.conditions[0] = {
            values: formatValue(firstCondition),
            operator: firstConditionOperator,
          };
        }

        // Ensure the second condition exists.
        if (updatedFilter.conditions.length < 2) {
          updatedFilter.conditions[1] = {
            values: formatValue(secondCondition),
            operator: secondConditionOperator,
          };
        }

        if (secondUpdate.newCondition !== undefined) {
          updatedFilter.conditions[1].values[0] = secondUpdate.newCondition;
        }
        if (secondUpdate.newOperator !== undefined) {
          updatedFilter.conditions[1].operator = secondUpdate.newOperator;
        }
      }
    }

    setFilterValue(updatedFilter);
  }

  const handleFirstConditionChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    if (!isValidNumberInput(newValue)) return;

    updateFilterConditions({ newCondition: newValue }, undefined, undefined);
  };

  const handleSecondConditionChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    if (!isValidNumberInput(newValue)) return;

    if (newValue === '') {
      updateFilterConditions(undefined, NONE, undefined);
      return;
    }
    updateFilterConditions(undefined, { newCondition: newValue }, undefined);
  };

  let betweenOperator = BetweenOperatorType.BETWEEN;
  if (filter.conditions) {
    const operator = filter.conditions[0].operator;
    if (filter.conditions.length > 1 || filter.logicalOperator === LogicalOperatorType.AND) {
      betweenOperator = BetweenOperatorType.BETWEEN;
    } else if (operator === OperatorType.LESS_THAN) {
      betweenOperator = BetweenOperatorType.LESS_THAN;
    } else if (operator === OperatorType.LESS_THAN_OR_EQUAL) {
      betweenOperator = BetweenOperatorType.LESS_THAN_OR_EQUAL;
    } else if (operator === OperatorType.EQUAL) {
      betweenOperator = BetweenOperatorType.EQUAL;
    } else if (operator === OperatorType.GREATER_THAN) {
      betweenOperator = BetweenOperatorType.GREATER_THAN;
    } else if (operator === OperatorType.GREATER_THAN_OR_EQUAL) {
      betweenOperator = BetweenOperatorType.GREATER_THAN_OR_EQUAL;
    }
  }

  const handleOperatorChange = (event: SelectChangeEvent) => {
    const operator = event.target.value;

    if (operator === BetweenOperatorType.LESS_THAN) {
      updateFilterConditions({ newOperator: OperatorType.LESS_THAN }, NONE, NONE);
    } else if (operator === BetweenOperatorType.LESS_THAN_OR_EQUAL) {
      updateFilterConditions({ newOperator: OperatorType.LESS_THAN_OR_EQUAL }, NONE, NONE);
    } else if (operator === BetweenOperatorType.GREATER_THAN) {
      updateFilterConditions({ newOperator: OperatorType.GREATER_THAN }, NONE, NONE);
    } else if (operator === BetweenOperatorType.GREATER_THAN_OR_EQUAL) {
      updateFilterConditions({ newOperator: OperatorType.GREATER_THAN_OR_EQUAL }, NONE, NONE);
    } else if (operator === BetweenOperatorType.BETWEEN) {
      updateFilterConditions(
        { newOperator: OperatorType.GREATER_THAN_OR_EQUAL },
        { newOperator: OperatorType.LESS_THAN_OR_EQUAL },
        LogicalOperatorType.AND,
      );
    } else if (operator === BetweenOperatorType.EQUAL) {
      updateFilterConditions({ newOperator: OperatorType.EQUAL }, NONE, NONE);
    }
  };

  const getInputProps = (unitType: UnitType | undefined, currencyPosition: CurrencyPosition, currencySymbol: string) => {
    if (!unitType) {
      return {};
    }

    if (unitType === UnitType.PERCENTAGE) {
      return { endAdornment: <InputAdornment position="end">%</InputAdornment> };
    }

    if (unitType === UnitType.CURRENCY) {
      return currencyPosition === CurrencyPosition.LEFT
        ? { startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment> }
        : { endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment> };
    }

    return {};
  };

  return (
    <div className="flex flex-row gap-2 w-full">
      <div>
        <BetweenOperatorSelect
          label="Operator"
          isDisabled={isOperatorDisabled}
          filterKey={filter.key}
          value={betweenOperator}
          handleChange={handleOperatorChange}
        />
      </div>

      <div className="flex-1">
        <TextField
          id={'first-condition-' + filter.key}
          label={(betweenOperator === BetweenOperatorType.BETWEEN ? 'Min ' : '') + filter.shortName}
          variant="outlined"
          value={firstCondition}
          onChange={handleFirstConditionChange}
          className="flex-1 w-full"
          placeholder="No Value"
          slotProps={{
            input: getInputProps(filter.unitType, currencyPosition, currencySymbol),
          }}
        />
      </div>

      {betweenOperator === BetweenOperatorType.BETWEEN && <div className="mt-3">to</div>}
      {betweenOperator === BetweenOperatorType.BETWEEN && (
        <div className="flex-1">
          <TextField
            id={'second-condition-' + filter.key}
            label={'Max ' + filter.shortName}
            variant="outlined"
            value={secondCondition}
            onChange={handleSecondConditionChange}
            className="flex-1"
            placeholder="No Value"
            slotProps={{
              input: getInputProps(filter.unitType, currencyPosition, currencySymbol),
            }}
          />
        </div>
      )}
    </div>
  );
};
