import InfoMessage from '@/components/feedback/InfoMessage';
import AlSelect from '@/components/form/AlSelect';
import { UpdateResponseDTO, UpdateResponseModal } from '@/modules/application/components/UpdateResponseModal';
import { CampaignAdType, EnabledPausedArchivedState } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Popover, SelectChangeEvent } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FunctionComponent, RefObject, useMemo, useState } from 'react';
import { NegativeTargetsUpdateDTO } from '../api/negative-targets-contracts';
import { invalidateProfile_negativeTargetingQueryKeys, negativeTargetingService } from '../api/negative-targets-service';
import { SelectedNegativeTarget } from '../models/NegativeTargetsModel';

interface NegativeTargetingBulkEditPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  selectedNegativeTargets: SelectedNegativeTarget[];
  isOpen: boolean;
  onClose: () => void;
}

type NoChange = 'NO_CHANGE';
const NO_CHANGE: NoChange = 'NO_CHANGE';

const NegativeTargetingBulkEditPopover: FunctionComponent<NegativeTargetingBulkEditPopoverProps> = ({
  selectedNegativeTargets,
  isOpen,
  onClose,
  buttonRef,
}) => {
  const queryClient = useQueryClient();
  const { activeProfile } = useActiveTeamContext();

  // Post update modal
  const [updateResponseModalOpen, setUpdateResponseModalOpen] = useState(false);
  const [updatedNegativeTargetsResponse, setUpdateNegativeTargetsResponse] = useState<UpdateResponseDTO>();

  const STATE_OPTIONS = [
    { value: NO_CHANGE, label: 'No change' },
    { value: EnabledPausedArchivedState.ENABLED, label: 'Enabled' },
    // { value: EnabledPausedArchivedState.PAUSED, label: 'Paused' }, // Amazon API supports PAUSE, but web does not. So, we also disable it
    { value: EnabledPausedArchivedState.ARCHIVED, label: 'Archived' },
  ];

  const [targetState, setTargetState] = useState<EnabledPausedArchivedState | NoChange>(NO_CHANGE);
  const [isApplyInProgress, setIsApplyInProgress] = useState<boolean>(false);

  async function handleApplyChanges() {
    if (targetState === NO_CHANGE) {
      return;
    }

    setIsApplyInProgress(true);

    const targetUpdates: NegativeTargetsUpdateDTO[] = selectedNegativeTargets.map((target) => {
      const update: NegativeTargetsUpdateDTO = {
        id: target.id,
        ad_type: target.adType,
        entity_type: target.entityType,
        is_campaign: target.isCampaign,
        state: targetState as EnabledPausedArchivedState,
      };

      return update;
    });

    const response = await negativeTargetingService.updateNegativeTargeting(targetUpdates);

    if (response.isSuccess) {
      setUpdateNegativeTargetsResponse({ responseErrorMsg: null, payload: response?.payload });
    } else {
      setIsApplyInProgress(false);
      setUpdateNegativeTargetsResponse({
        responseErrorMsg: `Did not receive a response from server: ${response.message}`,
        payload: undefined,
      });
    }
    setUpdateResponseModalOpen(true);

    // Reset all fields
    setTargetState(NO_CHANGE);

    invalidateProfile_negativeTargetingQueryKeys(queryClient, activeProfile?.id);
    onClose();
    setIsApplyInProgress(false);
  }

  function handleNegativeTargetStateChange(event: SelectChangeEvent<EnabledPausedArchivedState | NoChange>) {
    const value = event.target.value;

    if (value === NO_CHANGE) {
      setTargetState(value);
    } else {
      setTargetState(value as EnabledPausedArchivedState);
    }
  }

  const numberOfDuplicatesInSelection = useMemo<number>(() => {
    const duplicateCount: { [key: string]: number } = {};
    selectedNegativeTargets.forEach((target) => {
      const key = `${target.targeting}${target.adGroupName}${target.campaignName}${target.entityType}`;
      if (duplicateCount[key]) {
        duplicateCount[key]++;
      } else {
        duplicateCount[key] = 1;
      }
    });

    return Object.values(duplicateCount).filter((count) => count > 1).length;
  }, [selectedNegativeTargets]);

  const onlySBSelected = useMemo<boolean>(() => {
    return selectedNegativeTargets.every((target) => target.adType === CampaignAdType.BRANDS);
  }, [selectedNegativeTargets]);

  const someSBSelected = useMemo<boolean>(() => {
    return selectedNegativeTargets.some((target) => target.adType === CampaignAdType.BRANDS);
  }, [selectedNegativeTargets]);
  return (
    <>
      <Popover
        id={'negative-targets-bulk-edit-popover'}
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disablePortal={true} // Allow scrolling bg scrolling when popover is open
        slotProps={{ paper: { style: { width: 300 } } }}
      >
        <DialogTitle id="edit-selection-modal-title">Edit Selected Negative Targets</DialogTitle>

        <DialogContent className="space-y-8">
          <span>
            You have selected {selectedNegativeTargets.length} Negative Target{selectedNegativeTargets.length == 1 ? '' : 's'} to edit.
          </span>
          <AlSelect
            label={'Negative Target State'}
            value={targetState}
            options={STATE_OPTIONS}
            onChange={handleNegativeTargetStateChange}
            renderOption={(item) => item.label}
            valueExtractor={(item) => item.value}
          />
        </DialogContent>
        <Divider />
        {numberOfDuplicatesInSelection > 0 && (
          <div className="flex flex-col mx-5">
            <InfoMessage
              content={`Selection contains ${numberOfDuplicatesInSelection} duplicate${numberOfDuplicatesInSelection > 1 ? 's' : ''}. Bulk editing will result in an error and only one duplicate will be updated.`}
            />
          </div>
        )}
        {someSBSelected && targetState === EnabledPausedArchivedState.PAUSED && (
          <div className="flex flex-col mx-5">
            <InfoMessage content={`Sponsored Brands cannot be paused and will be ignored when applying changes.`} />
          </div>
        )}
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            loading={isApplyInProgress}
            onClick={handleApplyChanges}
            disabled={targetState === NO_CHANGE || (onlySBSelected && targetState === EnabledPausedArchivedState.PAUSED)}
          >
            Apply Changes
          </Button>
        </DialogActions>
      </Popover>

      <UpdateResponseModal
        isOpen={updateResponseModalOpen}
        onClose={() => setUpdateResponseModalOpen(false)}
        updateResponse={updatedNegativeTargetsResponse}
      />
    </>
  );
};

export default NegativeTargetingBulkEditPopover;
