import { Environment } from '@/config/Environment';
import { sleep } from '@/lib/api/api-utils';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { invalidateProfile_placementsWithTimelineQueryKeys } from '@/modules/placements/api/placements-service';
import { invalidateProfile_targetingWithTimelineQueryKeys } from '@/modules/targeting/api/targets-service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { CircularProgress, Paper } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { Dispatch, FunctionComponent, RefObject, SetStateAction, useState } from 'react';
import { toastService } from '@/services/toast.service';
import { ChangeCampaignGroupDTO } from '../../../api/campaign/campaign-contracts';
import {
  campaignService,
  createCampaignGroupsQueryKey,
  invalidateProfile_campaignGroupsQueryKey,
  invalidateProfile_campaignsWithTimelineQueryKeys,
} from '../../../api/campaign/campaign-service';
import { CampaignGroupModel } from '../../../api/campaign/models/CampaignGroupModel';
import { SelectedCampaignDTO } from '../../../api/campaign/models/CampaignModel';
import { CreateUpdateCampaignGroup } from './CreateUpdateCampaignGroup';
import { UpdateCampaignGroups } from './UpdateCampaignGroups';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import PopoverLikePopper from '@/components/PopoverLikePopper';

interface CampaignGroupsPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedCampaigns: SelectedCampaignDTO[];
  setSelectedCampaigns: Dispatch<SetStateAction<SelectedCampaignDTO[]>>;
}

export const CampaignGroupsPopover: FunctionComponent<CampaignGroupsPopoverProps> = ({
  buttonRef,
  isOpen,
  setIsOpen,
  selectedCampaigns,
  setSelectedCampaigns,
}) => {
  const [isInCreateMode, setIsInCreateMode] = useState(false);
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const [isApplyingGroupChange, setIsApplyingGroupChange] = useState(false);
  const queryClient = useQueryClient();

  const handlePopoverClose = async () => {
    setIsOpen((previousValue) => !previousValue);
    await sleep(200); // So that the create -> update isn't seen in the closing animation
    setIsInCreateMode(false);
  };

  const {
    data: campaignGroups,
    isLoading,
    refetch: refetchCampaignGroups,
  } = useQuery({
    queryKey: createCampaignGroupsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignService.getGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error('Error loading groups');
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  async function applyChanges(changeGroups: ChangeCampaignGroupDTO[], isNewGroup = false): Promise<void> {
    setIsApplyingGroupChange(true);

    const response = await campaignService.changeGroups(changeGroups);
    setIsApplyingGroupChange(false);

    if (response.isSuccess === false) {
      toastService.error(response.message);
      return;
    }

    refetchCampaignGroups();

    // Invalidate the metrics query so it's fetched again when going to opt groups page
    invalidateProfile_campaignGroupsQueryKey(queryClient, activeProfile?.id);

    updateCampaigns(changeGroups);
    if (isNewGroup) {
      toastService.success(
        `Optimization group created successfully and ${changeGroups.length} ${
          changeGroups.length == 1 ? 'campaign' : 'campaigns'
        } assigned to the group successfully`,
      );
    } else {
      toastService.success(`Optimization group changed successfully`);
    }
  }

  const updateCampaigns = (updatedGroups: ChangeCampaignGroupDTO[]) => {
    const updatedGroupMap = new Map<string, number>();
    updatedGroups.forEach(({ campaign_id, group_id }) => {
      updatedGroupMap.set(campaign_id, group_id);
    });

    invalidateProfile_campaignsWithTimelineQueryKeys(queryClient, activeProfile?.id);
    invalidateProfile_targetingWithTimelineQueryKeys(queryClient, activeProfile?.id);
    invalidateProfile_placementsWithTimelineQueryKeys(queryClient, activeProfile?.id);

    const newSelectedCampaigns: SelectedCampaignDTO[] = selectedCampaigns.map((campaign): SelectedCampaignDTO => {
      const updatedGroupId = updatedGroupMap.get(campaign.id);
      return {
        id: campaign.id,
        name: campaign.name,
        groupId: updatedGroupId !== undefined ? updatedGroupId : campaign.groupId,
        adType: campaign.adType,
        state: campaign.state,
        costType: campaign.costType,
        bidStrategy: campaign.bidStrategy,
        bidOptimization: campaign.bidOptimization,
        multiAdGroupsEnabled: campaign.multiAdGroupsEnabled,
        hasOptRule: campaign.hasOptRule,
        endDate: campaign.endDate,
        lastOptimizedAt: campaign.lastOptimizedAt,
        dataGroupType: DataGroupType.CAMPAIGN,
        dataItemIds: campaign.dataItemIds,
        budgetAmount: campaign.budgetAmount,
      };
    });
    setSelectedCampaigns(newSelectedCampaigns);
  };

  const onCreateConfirmed = (campaignGroup?: CampaignGroupModel) => {
    if (Environment.isDev()) {
      console.log('Created', campaignGroup);
    }

    invalidateProfile_campaignGroupsQueryKey(queryClient, activeProfile?.id);

    refetchCampaignGroups();
  };

  return (
    <PopoverLikePopper open={isOpen} anchorEl={buttonRef.current} onClose={handlePopoverClose}>
      <Paper style={{ width: '500px' }}>
        {isLoading ? (
          <CircularProgress />
        ) : isInCreateMode || !campaignGroups || (campaignGroups && campaignGroups.length === 0) ? (
          <CreateUpdateCampaignGroup
            existingGroups={campaignGroups ?? []}
            onClose={handlePopoverClose}
            applyChanges={applyChanges}
            isApplyPending={false}
            selectedCampaigns={selectedCampaigns}
            onSuccess={onCreateConfirmed}
            flowType={FlowType.CAMPAIGN_OPTIMIZER}
          />
        ) : (
          <UpdateCampaignGroups
            existingGroups={campaignGroups.filter((group) => group.id !== 0) ?? []}
            selectedCampaigns={selectedCampaigns}
            onClose={handlePopoverClose}
            onCreateClicked={() => setIsInCreateMode(true)}
            applyChanges={applyChanges}
            isApplyPending={isApplyingGroupChange}
          />
        )}
      </Paper>
    </PopoverLikePopper>
  );
};
