import { ColumnId } from '@/components/grid/columns/columns.enum';
import { Environment } from '@/config/Environment';
import useFormatting from '@/hooks/useFormatting';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import useBidLimits from '@/modules/amazon-constants/hooks/useBidLimits';
import { PlacementUpdateData, PlacementUpdateType, TotalUpdateData } from '@/modules/amazon-constants/types/bulk-edit';
import BugButton from '@/modules/application/components/BugButton';
import HelpButton from '@/modules/application/components/HelpButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SendIcon from '@mui/icons-material/Send';
import { Button, Checkbox, Divider, Tooltip } from '@mui/material';
import type { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, MutableRefObject, useRef, useState } from 'react';
import OppositeModeOverlayBar from '../../../components/OppositeModeOverlayBar';
import { BiddingEntity, OptimizationModel } from './optimization/models/OptimizationModel';
import { PreviewDataRow } from './optimization/models/PreviewDataRow';
import OptimizationEditSelectionPopover from './optimization/OptimizationEditSelectionPopover';
import { OptimizationApplyData } from './optimization/OptimizerConfig';

interface OptimizerPreviewActionsBarProps {
  optimizationPreviewSelection: OptimizationApplyData[];
  visibleRowCount: number;
  onApply: () => void;
  isApplyLoading: boolean;
  optimizationResults: OptimizationModel;
  optimizationTableApiRef: MutableRefObject<GridApi<PreviewDataRow> | null>;
  setOptimizationPreviewSelection: (optimizationApplyData: OptimizationApplyData[]) => void;
}

const OptimizerPreviewActionsBar: FunctionComponent<OptimizerPreviewActionsBarProps> = ({
  optimizationPreviewSelection,
  visibleRowCount,
  onApply,
  isApplyLoading,
  optimizationResults,
  optimizationTableApiRef,
  setOptimizationPreviewSelection,
}) => {
  const { formatWithThousandsSeparator } = useFormatting();
  const { toastWarnWithSetMessages } = useHelperComponents();

  // CONFIRMATION CHECKBOX
  const [isConfirmationCheckboxChecked, setIsConfirmationCheckboxChecked] = useState(false);
  const handleConfirmationCheckboxChange = () => {
    setIsConfirmationCheckboxChecked((prevState) => !prevState);
  };

  // BULK EDIT
  const { getClampedBidWithWarningsByUpdateDetails } = useBidLimits();
  const bulkEditButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isEditSelectionModalOpen, setIsEditSelectionModalOpen] = useState(false);
  function onEditSelectionClicked() {
    setIsEditSelectionModalOpen(true);
  }

  function onApplySelectionEdit(updateData: TotalUpdateData) {
    // Apply updates to preview data rows
    optimizationResults.previewData = applyUpdatesToPreviewDataRows(
      optimizationResults.previewData,
      updateData,
      optimizationPreviewSelection,
    );

    // Refresh edited cells
    if (optimizationTableApiRef.current) {
      optimizationTableApiRef.current.refreshCells({
        columns: [ColumnId.NEW_VALUE, ColumnId.DELTA],
      });
    }

    // Update the selection
    setOptimizationPreviewSelection(
      optimizationPreviewSelection.map((optimizationApplyData) => {
        const adjustedPreviewData = optimizationResults.previewData.find(
          (pd) => optimizationApplyData.id === pd.id && optimizationApplyData.bidding_entity === pd.biddingEntity,
        );
        return {
          ...optimizationApplyData,
          new_value: adjustedPreviewData?.newValue ?? optimizationApplyData.new_value,
        };
      }),
    );
  }

  function applyUpdatesToPreviewDataRows(
    previewDataRows: PreviewDataRow[],
    updateData: TotalUpdateData,
    selectedRows: OptimizationApplyData[],
  ): PreviewDataRow[] {
    if (Environment.isDev()) {
      console.log({
        previewDataRows,
        updateData,
        selectedRows,
      });
    }

    const warnings = new Set<string>();

    const modifiedRows = previewDataRows.map((row) => {
      if (selectedRows.some((selectedRow) => selectedRow.id === row.id && selectedRow.bidding_entity === row.biddingEntity)) {
        switch (row.biddingEntity) {
          case BiddingEntity.KEYWORD:
          case BiddingEntity.PRODUCT_TARGET:
            row.newValue = getClampedBidWithWarningsByUpdateDetails({
              currentBid: row.oldValue,
              updateData: updateData.bidUpdateData,
              campaignAdType: row.campaignAdType,
              campaignIsVideo: row.campaignIsVideo,
              campaignBudgetAmount: row.campaignBudgetAmount,
              currentCPC: row.cpc?.[0] ?? 0,
              warnings,
            });
            break;
          default:
            applyPlacementUpdate(row, updateData.placementUpdateData);
            break;
        }
      }
      return row;
    });

    if (warnings.size > 0) {
      toastWarnWithSetMessages(warnings);
    }

    return modifiedRows;
  }

  // TODO: move away from here
  function applyPlacementUpdate(row: PreviewDataRow, updateData: PlacementUpdateData) {
    switch (updateData.placementUpdateType) {
      case PlacementUpdateType.SET_PLACEMENT_TO_PERCENTAGE:
        row.newValue = updateData.newPlacementValue / 100;
        break;
      case PlacementUpdateType.INCREASE_PLACEMENT_BY_PERCENTAGE_POINTS:
        row.newValue = row.oldValue + updateData.newPlacementValue / 100;
        break;
      case PlacementUpdateType.DECREASE_PLACEMENT_BY_PERCENTAGE_POINTS:
        row.newValue = row.oldValue - updateData.newPlacementValue / 100;
        break;
      // No need for NO_CHANGE as it does nothing
    }
  }

  // HANDLE SELECTION
  function onDeselectAll() {
    setOptimizationPreviewSelection([]);
    if (optimizationTableApiRef.current) {
      optimizationTableApiRef.current.deselectAll();
    }
  }

  function onSelectAll() {
    if (optimizationTableApiRef.current) {
      optimizationTableApiRef.current.selectAll();
    }
  }
  // TODO: move gleap button to own component
  return (
    <>
      <OppositeModeOverlayBar>
        <div className="flex flex-row gap-4 items-center px-1.5">
          <Tooltip title="Deselect all">
            <span onClick={onDeselectAll} className="flex whitespace-nowrap text-sm font-bold items-center hover:cursor-pointer">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(optimizationPreviewSelection.length)} of {formatWithThousandsSeparator(visibleRowCount)}
            </span>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <Button
            variant={isEditSelectionModalOpen ? 'contained' : 'outlined'}
            ref={bulkEditButtonRef}
            onClick={onEditSelectionClicked}
            startIcon={<EditRoundedIcon />}
            disabled={optimizationPreviewSelection.length === 0}
            className="mr-2 flex-shrink-0"
          >
            {optimizationPreviewSelection.length > 0
              ? `Edit ${formatWithThousandsSeparator(optimizationPreviewSelection.length)} item${optimizationPreviewSelection.length > 1 ? 's' : ''}`
              : 'Edit Selection'}
          </Button>

          <Divider className="my-2" orientation="vertical" flexItem />

          <HelpButton />

          <BugButton />

          <Divider className="my-2" orientation="vertical" flexItem />

          <Button
            className="whitespace-nowrap"
            onClick={optimizationPreviewSelection.length == visibleRowCount ? onDeselectAll : onSelectAll}
            variant="text"
          >
            {optimizationPreviewSelection.length == visibleRowCount ? 'Deselect All' : 'Select All'}
          </Button>

          <span onClick={handleConfirmationCheckboxChange} className="flex whitespace-nowrap text-sm items-center hover:cursor-pointer">
            <Checkbox checked={isConfirmationCheckboxChecked} name="confirmationCheckbox" color="secondary" />I confirm changes
          </span>

          <Tooltip title="Send changes to Amazon">
            <span>
              <Button
                onClick={onApply}
                endIcon={<SendIcon />}
                loading={isApplyLoading}
                loadingPosition="end"
                variant="contained"
                disabled={!isConfirmationCheckboxChecked || optimizationPreviewSelection.length == 0}
                color="primary"
                className="whitespace-nowrap"
              >
                <span>Apply Changes</span>
              </Button>
            </span>
          </Tooltip>
        </div>
      </OppositeModeOverlayBar>

      <OptimizationEditSelectionPopover
        selectedItems={optimizationPreviewSelection}
        isOpen={isEditSelectionModalOpen}
        onClose={() => setIsEditSelectionModalOpen(false)}
        onApply={onApplySelectionEdit}
        buttonRef={bulkEditButtonRef}
      />
    </>
  );
};

export default OptimizerPreviewActionsBar;
