import { Switch, Tooltip } from '@mui/material';
import type { ICellRendererParams } from 'ag-grid-community';
import { ChangeEvent, FunctionComponent } from 'react';

export interface IToggleCellRendererParams extends ICellRendererParams {
  tooltip?: string;
}

const ToggleCellRenderer: FunctionComponent<IToggleCellRendererParams> = ({ tooltip, value, api, node, colDef }) => {
  if (!api || !node || !colDef?.field) return null;

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    if (colDef.colId) {
      node.setDataValue(colDef.colId, newValue);
    }
  };

  return (
    <Tooltip title={tooltip}>
      <Switch checked={value} onChange={handleToggle} inputProps={{ 'aria-label': 'toggle-switch' }} />
    </Tooltip>
  );
};

export default ToggleCellRenderer;
