import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import { dashboardService } from '../../api/dashboard/dashboard.service';
import { DashboardModel } from '../../types/DashboardModel';

interface RenameDashboardDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDashboardRenamed: () => void;
  dashboard: DashboardModel;
}

const RenameDashboardDialog: FunctionComponent<RenameDashboardDialogProps> = ({ isOpen, onClose, onDashboardRenamed, dashboard }) => {
  const [dashboardName, setDashboardName] = useState(dashboard.dto.name || '');
  const [isRenamingDashboard, setIsRenamingDashboard] = useState(false);

  function onDashboardNameChange(e: ChangeEvent<HTMLInputElement>) {
    setDashboardName(e.target.value);
  }

  async function onRenameDashboardClicked() {
    setIsRenamingDashboard(true);
    const renamedDashboard = await dashboardService.update(dashboard.dto.id, { name: dashboardName });
    if (renamedDashboard.isSuccess) {
      dashboard.dto.name = dashboardName;
      onDashboardRenamed();
    }
    setIsRenamingDashboard(false);
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Rename Dashboard</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the new name for the Dashboard:</DialogContentText>
        <TextField
          className="mt-4"
          autoFocus
          margin="dense"
          id="name"
          label="New Name"
          type="text"
          fullWidth
          variant="standard"
          helperText={'At least 3 characters'}
          value={dashboardName}
          onChange={onDashboardNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button loading={isRenamingDashboard} variant="contained" onClick={onRenameDashboardClicked} disabled={dashboardName.length < 3}>
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenameDashboardDialog;
