import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import AlGrid, { AlGridContext, DEFAULT_GRID_OPTIONS, GRID_DEFAULT_SORTING_ORDER } from '@/components/grid/AlGrid';
import EditableCellRenderer, { IEditableCellRendererParams } from '@/components/grid/cells/EditableCellRenderer';
import { ErrorWarningWrapperCellRenderer, IErrorWarningCellRendererParams } from '@/components/grid/cells/ErrorWarningWrapperCellRenderer';
import MultipleChoiceCellRenderer, {
  MultipleChoiceCellRendererOption,
  MultipleChoiceUpdatedValue,
} from '@/components/grid/cells/MultipleChoiceCellRendererOptions';
import { ITextCellRendererParams, TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import ChangePercentageHeaderRenderer from '@/components/grid/headers/ChangePercentageHeaderRenderer';
import DefaultHeaderRenderer from '@/components/grid/headers/DefaultHeaderRenderer';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import { ComparisonUnit, ExpandedGridContext } from '@/components/grid/types';
import { getMetricConfigByColId, isCalculatedMetric } from '@/components/metrics/MetricsConfig';
import { MetricData } from '@/components/metrics/types/MetricData';
import { CommonMetricField, MetricAggregates } from '@/components/metrics/types/MetricField';
import { TailwindColorVariant } from '@/config/theme/color.type';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import { useTranslation } from '@/lib/i18n/useTranslate';
import useBidLimits from '@/modules/amazon-constants/hooks/useBidLimits';
import { getErrorMessage } from '@/modules/application/utils';
import {
  BiddingMethod,
  biddingMethodsWith_currencyValues,
  biddingMethodsWith_noUnit,
} from '@/modules/campaign-mapping/api/campaign-mapping-contracts';
import useCampaignToAdGroupsMappingData from '@/modules/campaign-mapping/hooks/useCampaignToAdGroupsMappingData';
import {
  CampaignMappingModel,
  CampaignToCampaignDataWithCampaignMappingAdGroupDataType,
} from '@/modules/campaign-mapping/models/CampaignMappingModel';
import { TargetEntityExtendedType, TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { UserSettingKey } from '@/modules/users';
import { toastService } from '@/services/toast.service';
import { getErrorWarningCellClass, TargetEntityTypeColors } from '@/types/colors.enum';
import { Tooltip } from '@mui/material';
import type {
  CellClassParams,
  CellClickedEvent,
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IHeaderParams,
  IRowNode,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
} from 'ag-grid-community';
import { isNil, round } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { KeywordHarvestingModel } from '../../models/KeywordHarvestingModel';
import { KeywordHarvestingPreviewDataRow } from '../../models/KeywordHarvestingPreviewDataRow';
import { generateKeywordHarvestingTableColumnState } from './keyword-harvesting-table.default-column-state';

const VISIBLE_ABOVE_PX_ON_SCROLL_DOWN = 60;

interface KeywordHarvestingTableGridContext extends ExpandedGridContext {
  comparisonUnit: ComparisonUnit;
  metricColumnAggregates: MetricAggregates | undefined;
  campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType;
}

interface KeywordHarvestingTableProps {
  harvestingKeywordData: KeywordHarvestingModel | null;
  setKeywordHarvestingSelection: (keywordHarvestingSelection: KeywordHarvestingPreviewDataRow[]) => void;
  externalFilters: AlFilterModel[];
  setVisibleRowCount: (visibleRowCount: number) => void;
  onInternalGridReady?: (gridApi: GridApi<KeywordHarvestingPreviewDataRow>) => void;
}

const KeywordHarvestingTable: FunctionComponent<KeywordHarvestingTableProps> = ({
  harvestingKeywordData,
  setKeywordHarvestingSelection,
  externalFilters,
  setVisibleRowCount,
  onInternalGridReady,
}) => {
  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.KEYWORD_HARVESTING_PREVIEW_COLUMN_STATE,
    generateKeywordHarvestingTableColumnState(),
  );

  const { t } = useTranslation();
  const { getCampaignAdTypeCellRendererParams, getCalculatedMetricAggDataForMetricData, getCheckboxWithConditionalWarningColDefProperties } =
    useColDefsFunctions();

  const gridApiRef = useRef<GridApi<KeywordHarvestingPreviewDataRow>>();
  const gridContextRef = useRef<KeywordHarvestingTableGridContext>();

  const { toastWarnWithSetMessages } = useHelperComponents();
  const { matchLongColumnType, checkboxColumnType, getChangePercentageHeaderRendererParams } = useColumnTypes();

  const { campaignToAdGroupsMap } = useCampaignToAdGroupsMappingData();
  const [metricColumnAggregates, setMetricColumnAggregates] = useState<MetricAggregates | undefined>(undefined);
  useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates,
  });

  const { formatWithThousandsSeparator, formatCurrency, getLongFormatterForMetricField } = useFormatting();
  const { getClampedBidWithWarnings } = useBidLimits();

  const getColumnAggregates = (gridApi: GridApi<KeywordHarvestingPreviewDataRow>): MetricAggregates => {
    type AggDataType = Record<CommonMetricField, MetricData>;
    const sums: AggDataType = Object.values(CommonMetricField).reduce(
      (acc, field) => ({
        ...acc,
        [field]: [0, 0],
      }),
      {} as AggDataType,
    );

    const notCalculatedMetricFields = Object.values(CommonMetricField).filter((metricField) => !isCalculatedMetric(metricField));

    gridApi.forEachNodeAfterFilter((node) => {
      const rowData = node.data;

      if (rowData) {
        for (const field of notCalculatedMetricFields) {
          const value = rowData[field];
          if (typeof value === 'number') {
            sums[field][0] += value;
          }
        }
      }
    });

    // Aggregate data for calculated metrics
    const calculatedMetricFields = Object.values(CommonMetricField).filter((metricField) => isCalculatedMetric(metricField));

    calculatedMetricFields.forEach((field) => {
      const metricData = getCalculatedMetricAggDataForMetricData(field, sums);
      sums[field][0] = metricData[0]; // Assuming the first element is 'current'
    });

    const output: MetricAggregates = {} as MetricAggregates;

    Object.entries(sums).forEach(([key, [current, _]]) => {
      // Only extract the 'current' part and ignore the 'previous'
      output[key as CommonMetricField] = { current };
    });

    return output;
  };

  function updateWithMultipleChoiceValues(
    params: ICellRendererParams<KeywordHarvestingPreviewDataRow>,
    updatedValues: MultipleChoiceUpdatedValue<KeywordHarvestingPreviewDataRow>[],
  ) {
    if (!gridApiRef.current || !params.node.data) return;

    const updatedData = params.node.data as KeywordHarvestingPreviewDataRow & Record<string, unknown>;
    for (const updatedValue of updatedValues) {
      if (updatedValue.id in updatedData) {
        updatedData[updatedValue.id as keyof typeof updatedData] = updatedValue.selected;
      }
    }

    params.api.applyTransaction({
      update: [updatedData], // setData doesn't trigger event that can be listened, but applyTransaction needs cell refresh
    });

    updateOptimizationPreviewSelection(params.api);
    gridApiRef.current.refreshCells({ rowNodes: [params.node], force: true });
  }

  function statsValueFormatter(params: ValueFormatterParams<KeywordHarvestingPreviewDataRow>) {
    const columnId = params.colDef?.colId as ColumnId;
    const metricField = columnId ? (getMetricConfigByColId(columnId)?.key ?? CommonMetricField.IMPRESSIONS) : CommonMetricField.IMPRESSIONS;

    const formatter = params.colDef ? getLongFormatterForMetricField(metricField) : formatWithThousandsSeparator;
    return formatter(params.value);
  }

  const columnDefs: ColDef<KeywordHarvestingPreviewDataRow>[] = useMemo(() => {
    const colDefsToReturn: ColDef<KeywordHarvestingPreviewDataRow>[] = [
      {
        colId: ColumnId.CHECKBOX,
        type: 'checkboxColumnType',
        ...getCheckboxWithConditionalWarningColDefProperties<KeywordHarvestingPreviewDataRow>('rowSelectWarning'),
      },
      {
        colId: ColumnId.SEARCH_TERM,
        headerName: 'Search Term',
        field: 'searchTerm',
        editable: true,
        cellClass: (params: CellClassParams<KeywordHarvestingPreviewDataRow>) => {
          const isError = !isNil(params.data?.rowSelectWarning);
          const isWarning = false;
          return getErrorWarningCellClass(isError, isWarning);
        },
        cellRenderer: ErrorWarningWrapperCellRenderer,
        cellRendererParams: (params: IErrorWarningCellRendererParams<KeywordHarvestingPreviewDataRow>) => {
          return {
            CellRenderer: EditableCellRenderer,
            cellRendererParams: params,
            errorMessage: params.data?.rowSelectWarning ?? '',
            //warningMessage: '',
          };
        },
      },
      {
        colId: ColumnId.MATCH,
        headerName: 'Match Type',
        field: 'match',
        filter: 'agNumberColumnFilter',
        cellRenderer: TextCellRenderer,
        width: 100,
        type: 'matchLongColumnType',
      },
      {
        colId: ColumnId.BID_METHOD,
        headerName: 'Bid Method',
        valueGetter: (params: ValueGetterParams<KeywordHarvestingPreviewDataRow>) => {
          if (!params.data?.biddingMethod) {
            return undefined;
          }

          const value = params.data.biddingMethod == BiddingMethod.ADLABS ? '' : params.data.biddingMethodValue;
          let formattedValue = value;
          if (biddingMethodsWith_currencyValues.includes(params.data.biddingMethod) && value != '') {
            formattedValue = formatCurrency(value);
          } else if (biddingMethodsWith_noUnit.includes(params.data.biddingMethod)) {
            formattedValue = value;
          }
          return t(`enums.bidding_method_before_value.${params.data.biddingMethod}`) + formattedValue;
        },
        width: 100,
      },
      {
        colId: ColumnId.BID_FLOOR,
        headerName: 'Bid Floor',
        field: 'bidFloor',
        valueFormatter: (params: ValueFormatterParams<KeywordHarvestingPreviewDataRow>) => {
          return params.value ? formatCurrency(params.value) : '';
        },
        width: 100,
      },
      {
        colId: ColumnId.BID_CEILING,
        headerName: 'Bid Ceiling',
        field: 'bidCeiling',
        valueFormatter: (params: ValueFormatterParams<KeywordHarvestingPreviewDataRow>) => {
          return params.value ? formatCurrency(params.value) : '';
        },
        width: 100,
      },
      {
        colId: ColumnId.BID,
        headerName: 'Bid',
        field: 'bid',
        editable: true,
        singleClickEdit: true,
        width: 100,
        cellRenderer: EditableCellRenderer,
        cellRendererParams: (params: ICellRendererParams<KeywordHarvestingPreviewDataRow>): IEditableCellRendererParams => {
          const originalBid = params.data?.originalBid;
          const isValueChanged = params.data?.bid != params.data?.originalBid;
          return {
            isEditable: true,
            hideTooltip: !isValueChanged,
            tooltip: isValueChanged ? `Changed from: ${formatCurrency(originalBid)}` : 'Edit',
            isChanged: isValueChanged,
          };
        },
        valueFormatter: (params: ValueFormatterParams<KeywordHarvestingPreviewDataRow>) => {
          return params.value ? formatCurrency(params.value) : '-';
        },
        valueParser: (params: ValueParserParams<KeywordHarvestingPreviewDataRow>) => {
          if (params.newValue == '') {
            return 0;
          }

          try {
            const newValue = round(parseFloat(params.newValue), 2);

            if (isNaN(newValue) || newValue <= 0) {
              toastService.error('Invalid new value entered: ' + params.newValue);
              return params.oldValue;
            }

            if (params.data.bidCeiling != 0 && newValue > params.data.bidCeiling) {
              toastService.error('New value must be less than or equal to Bid Ceiling');
              return params.oldValue;
            }

            const warnings = new Set<string>();
            const clampedValue = getClampedBidWithWarnings(
              newValue,
              params.data?.destinationCampaignAdType,
              params.data?.destinationCampaignIsVideo,
              params.data?.destinationCampaignBudgetAmount,
              warnings,
            );

            if (warnings.size > 0) {
              toastWarnWithSetMessages(warnings);
              return clampedValue;
            }

            return newValue;
          } catch (e: unknown) {
            toastService.error(getErrorMessage(e));
          }

          // Revert to old when invalid
          return params.oldValue;
        },
      },
      {
        colId: ColumnId.ENTITY_TYPE,
        headerName: 'Entity Type',
        field: 'destinationAdGroupEntityType',
        minWidth: 40,
        width: 50,
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString: (key: string) => (key ? t(`enums.bidding_entity_short.${key}`) : '-'),
            valueToColor: (key: string) => TargetEntityTypeColors[key as TargetEntityExtendedType] ?? TailwindColorVariant.GREEN,
            valueToTooltip: (key: string) => t(`enums.bidding_entity.${key}`),
          };
        },
      },
      {
        colId: ColumnId.CAMPAIGN_AD_TYPE,
        headerName: 'Source Campaign Ad Type',
        field: 'sourceCampaignAdType',
        width: 100,
        cellRenderer: TextCellRenderer,
        cellRendererParams: getCampaignAdTypeCellRendererParams,
      },
      {
        colId: ColumnId.CAMPAIGN_NAME,
        headerName: 'Source Campaign',
        field: 'sourceCampaignName',
        resizable: true,
        cellRenderer: TextCellRenderer,
        width: 150,
      },
      {
        colId: ColumnId.AD_GROUP,
        headerName: 'Source Ad Group',
        field: 'sourceAdGroupName',
        resizable: true,
        cellRenderer: TextCellRenderer,
        width: 150,
      },
      {
        colId: ColumnId.CAMPAIGN_AD_TYPE_DESTINATION,
        headerName: 'Destination Campaign Ad Type',
        field: 'destinationCampaignAdType',
        width: 100,
        cellRenderer: TextCellRenderer,
        cellRendererParams: getCampaignAdTypeCellRendererParams,
      },
      {
        colId: ColumnId.CAMPAIGN_NAME_DESTINATION,
        headerName: 'Destination Campaign',
        field: 'destinationCampaignName',
        resizable: true,
        cellRenderer: TextCellRenderer,
        width: 150,
      },
      {
        colId: ColumnId.AD_GROUP_DESTINATION,
        headerName: 'Destination Ad Group',
        field: 'destinationAdGroupName',
        resizable: true,
        cellRenderer: TextCellRenderer,
        width: 150,
      },
      {
        colId: ColumnId.NEGATIVE_CAMPAIGN,
        headerName: 'Neg Src Campaign',
        cellClass: 'border-none outline-none',
        pinned: 'left',
        width: 130,
        cellRendererSelector: (params: ICellRendererParams<KeywordHarvestingPreviewDataRow>) => {
          if (!params.data) {
            return;
          }

          const campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType = params.context?.campaignToAdGroupsMap;

          const campaignMapping = CampaignMappingModel.fromKeywordHarvestingPreviewDataRow(params.data);
          const warningText = campaignMapping.createNegativeCampaignWarning(campaignToAdGroupsMap);
          if (!isNil(warningText)) {
            return {
              component: () => (
                <div className="h-full flex items-center pb-1">
                  <Tooltip title={warningText}>
                    <span>–</span>
                  </Tooltip>
                </div>
              ),
            };
          }

          const keywordOptions: MultipleChoiceCellRendererOption<KeywordHarvestingPreviewDataRow>[] = [
            { id: 'campaignNegativeExact', label: 'E', selected: params.data.campaignNegativeExact, tooltip: 'Campaign Negative Exact' },
            {
              id: 'campaignNegativePhrase',
              label: 'P',
              selected: params.data.campaignNegativePhrase,
              tooltip: 'Campaign Negative Phrase',
            },
          ];

          const productTargetOptions: MultipleChoiceCellRendererOption<KeywordHarvestingPreviewDataRow>[] = [
            {
              id: 'campaignNegativeProductTarget',
              label: 'PT',
              selected: params.data.campaignNegativeProductTarget,
              tooltip: 'Campaign Negative Product Target',
            },
          ];

          const options = params.data.destinationAdGroupEntityType === TargetEntityType.KEYWORD ? keywordOptions : productTargetOptions;

          function updateValues(values: MultipleChoiceUpdatedValue<KeywordHarvestingPreviewDataRow>[]) {
            updateWithMultipleChoiceValues(params, values);
          }
          return {
            component: () => <MultipleChoiceCellRenderer options={options} updateValues={updateValues} />,
          };
        },
      },
      {
        colId: ColumnId.NEGATIVE_AD_GROUP,
        headerName: 'Neg Src Ad Group',
        cellClass: 'border-none outline-none',
        pinned: 'left',
        width: 130,
        cellRendererSelector: (params: ICellRendererParams<KeywordHarvestingPreviewDataRow>) => {
          if (!params.data) {
            return;
          }

          const campaignToAdGroupsMap: CampaignToCampaignDataWithCampaignMappingAdGroupDataType = params.context?.campaignToAdGroupsMap;

          const campaignMapping = CampaignMappingModel.fromKeywordHarvestingPreviewDataRow(params.data);
          const warningText = campaignMapping.createNegativeAdGroupsWarning(campaignToAdGroupsMap);
          if (!isNil(warningText)) {
            return {
              component: () => (
                <div className="h-full flex items-center pb-1">
                  <Tooltip title={warningText}>
                    <span>–</span>
                  </Tooltip>
                </div>
              ),
            };
          }

          const keywordOptions: MultipleChoiceCellRendererOption<KeywordHarvestingPreviewDataRow>[] = [
            { id: 'adGroupNegativeExact', label: 'E', selected: params.data.adGroupNegativeExact, tooltip: 'Ad Group Negative Exact' },
            { id: 'adGroupNegativePhrase', label: 'P', selected: params.data.adGroupNegativePhrase, tooltip: 'Ad Group Negative Phrase' },
          ];

          const productTargetOptions: MultipleChoiceCellRendererOption<KeywordHarvestingPreviewDataRow>[] = [
            {
              id: 'adGroupNegativeProductTarget',
              label: 'PT',
              selected: params.data.adGroupNegativeProductTarget,
              tooltip: 'Ad Group Negative Product Target',
            },
          ];

          const options = params.data.destinationAdGroupEntityType === TargetEntityType.KEYWORD ? keywordOptions : productTargetOptions;

          function updateValues(values: MultipleChoiceUpdatedValue<KeywordHarvestingPreviewDataRow>[]) {
            updateWithMultipleChoiceValues(params, values);
          }
          return {
            component: () => <MultipleChoiceCellRenderer options={options} updateValues={updateValues} />,
          };
        },
      },

      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Impressions',
        field: 'impressions',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Clicks',
        field: 'clicks',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Orders',
        field: 'orders',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.UNITS,
        headerName: 'Units',
        field: 'units',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.CTR,
        headerName: 'CTR',
        field: 'ctr',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.CVR,
        headerName: 'CVR',
        field: 'cvr',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.CPC,
        headerName: 'CPC',
        field: 'cpc',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Spend',
        field: 'spend',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Sales',
        field: 'sales',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.ACTC,
        headerName: 'aCTC',
        field: 'actc',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'ROAS',
        field: 'roas',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.RPC,
        headerName: 'RPC',
        field: 'rpc',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.CPA,
        headerName: 'CPA',
        field: 'cpa',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.AOV,
        headerName: 'AOV',
        field: 'aov',
        type: 'metricColumnType',
      },
      {
        colId: ColumnId.CPM,
        headerName: 'CPM',
        field: 'cpm',
        type: 'metricColumnType',
      },
    ];

    applyStateToDefinitions(colDefsToReturn);
    return colDefsToReturn;
  }, []);

  const metricColumnType = useMemo(() => {
    return {
      width: 50,
      valueFormatter: statsValueFormatter,
      headerComponent: ChangePercentageHeaderRenderer,
      headerComponentParams: (params: IHeaderParams<unknown, AlGridContext>) =>
        getChangePercentageHeaderRendererParams(params, { customCurrencyCode: params.context?.activeProfileCurrencyCode }),
      cellClass: 'justify-end',
    };
  }, []);

  // Pass selected fields values to parent for checked rows
  const handleRowSelection = useCallback((event: SelectionChangedEvent<KeywordHarvestingPreviewDataRow>) => {
    updateOptimizationPreviewSelection(event.api);
  }, []);

  const onCellValueChanged = useCallback((event: CellValueChangedEvent<KeywordHarvestingPreviewDataRow>) => {
    updateOptimizationPreviewSelection(event.api);
  }, []);

  const updateOptimizationPreviewSelection = (api: GridApi<KeywordHarvestingPreviewDataRow>) => {
    if (!api || api.isDestroyed()) {
      console.warn('[KeywordHarvestingTable.tsx] Grid API is not available or has been destroyed.');
      return;
    }

    try {
      const selectedRows = api.getSelectedRows();
      const selectedKeywordHarvestingDTOs = selectedRows.map(
        (row): KeywordHarvestingPreviewDataRow => new KeywordHarvestingPreviewDataRow(row.dto),
      );
      setKeywordHarvestingSelection(selectedKeywordHarvestingDTOs);
    } catch (error) {
      console.error('Error updating selected keyword harvesting rows:', error);
    }
  };

  const applyExternalFilters = (filters: AlFilterModel[]) => {
    if (!gridApiRef.current) return;

    gridApiRef.current.setGridOption('isExternalFilterPresent', () => true);
    gridApiRef.current.setGridOption('doesExternalFilterPass', (node: IRowNode<KeywordHarvestingPreviewDataRow>) => {
      if (!node || !node.data || !filters || filters.length === 0) {
        return true;
      }

      return !filters.some((filter) => {
        if (!node.data) {
          return true;
        }
        return !filter.doesFilterPass(node.data);
      });
    });
    gridApiRef.current.onFilterChanged();
    setVisibleRowCount(gridApiRef.current.getDisplayedRowCount());
    gridApiRef.current.deselectAll();
  };

  useEffect(() => {
    setKeywordHarvestingSelection([]);
    applyExternalFilters(externalFilters);

    if (gridApiRef.current) {
      setMetricColumnAggregates(getColumnAggregates(gridApiRef.current));
    }
  }, [externalFilters]);

  const onGridReady = (params: GridReadyEvent<KeywordHarvestingPreviewDataRow>) => {
    gridApiRef.current = params.api;
    gridContextRef.current = params.context;
    applyExternalFilters(externalFilters);
    setVisibleRowCount(params.api.getDisplayedRowCount());
    setColumnStateGridApi(params.api);

    if (!metricColumnAggregates) {
      setMetricColumnAggregates(getColumnAggregates(params.api));
    }

    if (onInternalGridReady) {
      onInternalGridReady(params.api);
    }
  };

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  const onCellClicked = useCallback((params: CellClickedEvent<KeywordHarvestingPreviewDataRow>) => {
    if (params.column.getColId() === ColumnId.CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const customGridOptions: GridOptions<KeywordHarvestingPreviewDataRow> = useMemo(() => {
    const defaultGridContext: KeywordHarvestingTableGridContext = {
      comparisonUnit: 'nominal', // placeholder, not used
      metricColumnAggregates: metricColumnAggregates,
      campaignToAdGroupsMap,
      isComparisonDataMissing: false,
    };

    return {
      ...DEFAULT_GRID_OPTIONS,
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 40,
        headerComponent: DefaultHeaderRenderer,
        menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        sortingOrder: GRID_DEFAULT_SORTING_ORDER,
      },
      context: defaultGridContext,
      getRowId: (params) => params.data.id.toString(),
      maintainColumnOrder: true,
      onSelectionChanged: handleRowSelection,
      onCellClicked: onCellClicked,
      onCellValueChanged: onCellValueChanged,

      // Column state management
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnRowGroupChanged: handleColumnStateChange,
      onSortChanged: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,

      columnTypes: { matchLongColumnType, metricColumnType, checkboxColumnType },

      isRowSelectable: (rowNode) => rowNode.data?.rowSelectWarning == null,
    };
  }, [handleRowSelection]);

  return (
    <div style={{ height: `calc(100vh - ${VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)` }}>
      <AlGrid
        gridOptions={customGridOptions}
        colDefs={columnDefs}
        rowData={harvestingKeywordData?.keywordHarvestingPreviewData ?? []}
        onGridReadyCallback={onGridReady}
        fitToResizeEnabled={false}
        addExtraBottomPadding={true}
      />
    </div>
  );
};

export default KeywordHarvestingTable;
