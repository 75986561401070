import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { Typography } from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import EntityTypeSelect from '../../forms/EntityTypeSelect';
import GroupBySelect, { TableWidgetGroupByOptionSelectOption } from '../../forms/GroupBySelect';
import { PieGraphWidgetConfiguration } from './PieGraphWidgetConfiguration';
import { CommonMetricField, MetricField, SellerMetricField } from '@/components/metrics/types/MetricField';
import { cloneDeep } from 'lodash-es';
import { DashboardWidgetContext } from '../../dashboard-widget/DashboardWidgetContextProvider';
import PieGraphWidgetFilters from './PieGraphWidgetFilters';
import ProfileSelect from '../../forms/ProfileSelect';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import SingleMetricSelect from '@/components/form/SingleMetricSelect';
import { useTranslation } from '@/lib';
import DashboardWidgetRichTextTitleEditor from '../../dashboard-widget/DashboardWidgetRichTextTitleEditor';
import { EntityType } from '@/modules/dashboards/types/EntityType';

interface PieGraphWidgetConfigurationFormProps {
  configuration: PieGraphWidgetConfiguration;
  id: string;
}

const PieGraphWidgetConfigurationForm: FunctionComponent<PieGraphWidgetConfigurationFormProps> = ({ configuration }) => {
  const { setModifiedConfiguration } = useContext(DashboardWidgetContext);
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);
  const { t } = useTranslation();
  const [localConfiguration, setLocalConfiguration] = useState<PieGraphWidgetConfiguration>(cloneDeep(configuration));

  // Update the modified configuration in the context whenever localConfiguration changes
  useEffect(() => {
    localConfiguration.isConfigured = true;
    setModifiedConfiguration(cloneDeep(localConfiguration));
  }, [localConfiguration, setModifiedConfiguration]);

  const handleTitleChange = (html: string) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.title = html || '';
      return newConfig;
    });
  };

  const handleEntityTypeChange = (entityType: EntityType | '') => {
    if (entityType === '') return;
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.entityType = entityType;
      return newConfig;
    });
  };

  const handleCampaignGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.campaignGroupByValue = groupByOption.value;
      newConfig.campaignGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handlePlacementGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.placementGroupByValue = groupByOption.value;
      newConfig.placementGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handleTargetsGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.targetsGroupByValue = groupByOption.value;
      newConfig.targetsGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handleProductGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.productGroupByValue = groupByOption.value;
      newConfig.productGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handleSelectedMetricChange = (selectedMetric: MetricField) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.selectedMetric = selectedMetric;
      return newConfig;
    });
  };

  // TODO: move to context. SellerMetricField depends if profile is a seller or vendor
  const availableMetrics = new Map<string, MetricField[]>([
    [t('ad_metrics'), Object.values(CommonMetricField)],
    [t('total_metrics'), Object.values(SellerMetricField)],
  ]);

  const onSelectedProfilesChanged = (selectedProfileIds: string[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.profilesIds = selectedProfileIds;
      return newConfig;
    });
  };

  return (
    <>
      <DashboardWidgetRichTextTitleEditor content={localConfiguration.title} onContentChange={handleTitleChange} />
      {dashboardProfiles && (
        <ProfileSelect
          profiles={dashboardProfiles}
          selectedProfileIds={localConfiguration.profilesIds}
          setSelectedProfileIds={onSelectedProfilesChanged}
        />
      )}

      <EntityTypeSelect
        value={localConfiguration.entityType}
        onChange={handleEntityTypeChange}
        helperText={'Select an Entity Type to filter on.'}
        disabledEntityTypes={[EntityType.SEARCH_TERM]}
      />

      {localConfiguration.entityType === EntityType.CAMPAIGN && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.campaignGroupByValue}
          onChange={handleCampaignGroupByChange}
          dataGroupType={DataGroupType.CAMPAIGN}
        />
      )}

      {localConfiguration.entityType === EntityType.TARGET && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.targetsGroupByValue}
          onChange={handleTargetsGroupByChange}
          dataGroupType={DataGroupType.TARGET}
        />
      )}

      {localConfiguration.entityType === EntityType.PLACEMENT && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.placementGroupByValue}
          onChange={handlePlacementGroupByChange}
          dataGroupType={DataGroupType.CAMPAIGN}
        />
      )}

      {localConfiguration.entityType === EntityType.PRODUCT && (
        <GroupBySelect
          entityType={localConfiguration.entityType}
          value={localConfiguration.productGroupByValue}
          dataGroupType={DataGroupType.PRODUCT}
          onChange={handleProductGroupByChange}
        />
      )}

      <div>
        <div className="text-sm">Metric</div>
        <Typography variant="caption"> Select the metric to display</Typography>
        <SingleMetricSelect
          availableMetrics={availableMetrics}
          value={localConfiguration.selectedMetric}
          onChange={handleSelectedMetricChange}
        />
      </div>

      <PieGraphWidgetFilters configuration={localConfiguration} setConfiguration={setLocalConfiguration} />
    </>
  );
};

export default PieGraphWidgetConfigurationForm;
