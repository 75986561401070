import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import { userService, useUserContext } from '@/modules/users';
import { Button } from '@mui/material';
import { BUTTON_WIDTH } from '@/modules/application/types/constants';
import { ProfileSelectorDialog } from './ProfileSelectorDialog';
import { toastService } from '@/services/toast.service';
import { Routes } from '@/router/router-paths';
import { useMutation } from '@tanstack/react-query';
import { debounce, isEmpty, isNil } from 'lodash-es';
import AuthorizeAmazonDialog from './AuthorizeAmazonDialog';

export const ManageProfilesButton: FunctionComponent = () => {
  const { activeTeam } = useActiveTeamContext();
  const { user, refetchUser } = useUserContext();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  const [openAuthorizeAmazonDialog, setOpenAuthorizeAmazonDialog] = useState(false);

  // If GET params are present, authorize user
  useEffect(() => {
    if (!isNil(code) && !isEmpty(code) && !isNil(state) && !isEmpty(state) && activeTeam && user && user.id != 0) {
      debouncedAuthorizeUser({ teamId: activeTeam.id, userId: user.id, code, state });
    }
  }, [code, state, activeTeam, user]);

  function onManageProfilesClicked() {
    if (!activeTeam?.amazonAccount?.isAuthorized) {
      setOpenAuthorizeAmazonDialog(true);
    } else {
      setOpenProfileSelectorDialog(true);
    }
  }

  const [openProfileSelectorDialog, setOpenProfileSelectorDialog] = useState(false);
  const handleClose = () => {
    setOpenProfileSelectorDialog(false);
  };

  // Auth amazon ads
  const { mutate: authorizeUser } = useMutation({
    mutationFn: ({ teamId, userId, code, state }: { teamId: number; userId: number; code: string; state: string }) =>
      userService.authorizeUser(teamId, userId, { code, state, is_registration: false }),

    onSuccess: async (res) => {
      if (res.isSuccess) {
        toastService.success(`Amazon authorization successful`);
        await refetchUser();
        navigate(Routes.PROFILES, { replace: true });
        setOpenProfileSelectorDialog(true);
      } else if (!activeTeam?.amazonAccount?.isAuthorized) {
        toastService.error(`Amazon authorization failed: ${res.message}`);
      }
    },
  });

  const debouncedAuthorizeUser = debounce(authorizeUser, 250);

  return (
    <>
      <Button variant="contained" onClick={onManageProfilesClicked} style={{ width: BUTTON_WIDTH }}>
        Manage Profiles
      </Button>

      <AuthorizeAmazonDialog isOpen={openAuthorizeAmazonDialog} setIsOpen={setOpenAuthorizeAmazonDialog} />

      <ProfileSelectorDialog open={openProfileSelectorDialog} onClose={handleClose} />
    </>
  );
};
