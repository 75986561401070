import { useContext, createContext, FunctionComponent, PropsWithChildren } from 'react';
import { useRegistration } from './useRegistration';

const RegistrationContext = createContext<ReturnType<typeof useRegistration> | undefined>(undefined);

interface RegistrationProviderProps extends PropsWithChildren {
  registration: ReturnType<typeof useRegistration>;
}

export const RegistrationProvider: FunctionComponent<RegistrationProviderProps> = ({ children, registration }) => {
  return <RegistrationContext.Provider value={registration}>{children}</RegistrationContext.Provider>;
};
// eslint-disable-next-line react-refresh/only-export-components
export const useRegistrationContext = () => {
  const context = useContext(RegistrationContext);
  if (!context) {
    throw new Error('useRegistrationContext must be used within a RegistrationProvider');
  }
  return context;
};
