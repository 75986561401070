import type { ICellRendererParams } from 'ag-grid-enterprise';
import { FunctionComponent, ReactNode } from 'react';

export interface IButtonGroupCellRendererParams {
  buttons: ReactNode[];
}

interface IButtonGroupCellRendererProps extends ICellRendererParams, IButtonGroupCellRendererParams {}

const ButtonGroupCellRenderer: FunctionComponent<IButtonGroupCellRendererProps> = ({ buttons }) => {
  return <div className="flex flex-row items-center h-full gap-x-2">{buttons}</div>;
};

export default ButtonGroupCellRenderer;
