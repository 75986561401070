import { Autocomplete, TextField } from '@mui/material';
import { FunctionComponent, SyntheticEvent } from 'react';
import { GroupByTime } from '../../types/GroupByTime';

interface GroupByTimeSelectProps {
  value: number | GroupByTime;
  onChange: (value: GroupByTimeSelectOption) => void;
  helperText?: string;
}

export interface GroupByTimeSelectOption {
  label: string;
  value: GroupByTime;
}

const GroupByTimeSelect: FunctionComponent<GroupByTimeSelectProps> = ({ value, onChange, helperText }) => {
  const options: GroupByTimeSelectOption[] = [
    { label: 'Day', value: GroupByTime.DAY },
    { label: 'Day of Week', value: GroupByTime.DAY_OF_WEEK },
    { label: 'Week', value: GroupByTime.WEEK },
  ];

  function onSelectionChanged(event: SyntheticEvent<Element, Event>, value: GroupByTimeSelectOption) {
    onChange(value);
  }

  return (
    <Autocomplete
      id="group-by-time-select"
      disableClearable
      value={options.find((option) => option.value === value)}
      options={options}
      getOptionLabel={(option) => option.label}
      getOptionKey={(option) => `${option.value}`}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={onSelectionChanged}
      renderInput={(params) => <TextField {...params} label="Group By" helperText={helperText} />}
    />
  );
};

export default GroupByTimeSelect;
