import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ButtonGroupCellRenderer, { IButtonGroupCellRendererParams } from '@/components/grid/cells/ButtonGroupCellRenderer';
import LinkCallbackCellRenderer, { ILinkCallbackCellRendererParams } from '@/components/grid/cells/LinkCallbackCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import RowActionButton from '@/components/grid/components/RowActionButton';
import useAggregators from '@/components/grid/hooks/useAggregators';
import useColumnTypes from '@/components/grid/hooks/useColumnTypes';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import useToggles from '@/components/grid/hooks/useToggles';
import { DEFAULT_GRID_OPTIONS_ROW_GROUPS, ExpandedGridContext } from '@/components/grid/types';
import { MetricModel } from '@/components/metrics/models/MetricModel';
import useFormatting from '@/hooks/useFormatting';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useTranslation } from '@/lib';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { campaignService, invalidateProfile_campaignGroupsQueryKey } from '@/modules/optimizer/api/campaign/campaign-service';
import { CampaignGroupMetricsModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupMetricsModel';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { toastService } from '@/services/toast.service';
import { Edit } from '@mui/icons-material';
import * as Sentry from '@sentry/react';
import { QueryObserverResult, RefetchOptions, useQueryClient } from '@tanstack/react-query';
import type { CellValueChangedEvent, GridReadyEvent } from 'ag-grid-community';
import type { GridOptions, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';
import { isNumber, round } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { generateCampaignGroupDefaultColumnState } from '../../../profiles/configuration/campaign-group.default-column-state';
import { CampaignGroupsWithMetrics } from '../../api/campaign/campaign-contracts';
import { useCampaignGroupsContext } from '../../contexts/CampaignGroupsContext';
import { useEditCampaignGroup } from '../../hooks/useEditCampaignGroupModal';
import { BidCeilingType } from '../../types/BidCeilingType';
import { BidFloorType } from '../../types/BidFloorType';
import { SmartBidCeilingType } from '../../types/SmartBidCeilingType';
import { BidLimitChangeUnitType } from '../optimization/api/optimization-contracts';
import useCampaignGroupsNavigation from './hooks/useCampaignGroupsNavigation';

interface CampaignGroupsTableProps {
  withMetricsResponse: CampaignGroupsWithMetrics | undefined;
  isLoading: boolean;
  onGridReadyCallback: (params: GridReadyEvent) => void;
  refetch: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<CampaignGroupsWithMetrics | undefined, Error>>;
  noTopBorderRadius?: boolean;
}

const CampaignGroupsTable: FunctionComponent<CampaignGroupsTableProps> = ({
  withMetricsResponse,
  isLoading,
  refetch,
  noTopBorderRadius,
  onGridReadyCallback,
}) => {
  const NO_GROUPS_ASSIGNED_GROUP_ID = 0;

  const rowData = withMetricsResponse?.campaignGroups ?? [];
  const isComparisonDataMissing = withMetricsResponse?.isComparisonDataMissing ?? false;

  const { activeProfile } = useActiveTeamContext();
  const { t } = useTranslation();
  const { checkboxColumnType, metricFieldWithChangePercentage } = useColumnTypes();
  const { gridToggles, setSelectedCampaignGroups, gridApiRef } = useCampaignGroupsContext();
  const { navigateToPageWithCampaignGroupFilter } = useCampaignGroupsNavigation();

  const { formatCurrency } = useFormatting();

  const queryClient = useQueryClient();

  const { stringToCountAggFunc, metricsDataAggFunc } = useAggregators();

  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.CAMPAIGN_GROUP_TABLE_COLUMN_STATE,
    generateCampaignGroupDefaultColumnState(),
  );

  const gridContextRef = useRef<ExpandedGridContext>();

  const { onGridReadyForComparisonMissing } = useComparisonMissing({
    gridApiRef,
    gridContextRef,
    isComparisonDataMissing: isComparisonDataMissing,
  });

  // TODO: use activeMetricComparator
  const { gridToggleParams } = useToggles<CampaignGroupModel>({
    gridApiRef,
    gridContextRef,
    gridToggles,
  });

  // METRIC AGGREGATES TO HEADERS
  const { onGridReadyForMetricColumnAggregates, metricColumnAggregates } = useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates: withMetricsResponse?.metrics ? MetricModel.arrayToMetricAggregates(withMetricsResponse.metrics) : undefined,
  });

  // UPDATE GROUP
  const updateGroup = async (groups: CampaignGroupModel[]) => {
    const response = await campaignService.updateGroups(groups);

    if (response.isSuccess === false) {
      toastService.error(response.message);
      return;
    }

    toastService.success(`Opt group '${groups[0].name}' updated successfully`);

    refetch();

    invalidateProfile_campaignGroupsQueryKey(queryClient, activeProfile?.id);

    // No need to update campaign
  };

  const onCellValueChanged = useCallback((event: CellValueChangedEvent<CampaignGroupMetricsModel>) => {
    updateGroup([CampaignGroupModel.fromCampaignGroupMetricsModel(event.data)]);
  }, []);

  const { ModalComponent: EditCampaignGroupModal, handleOpenModal: openEditCampaignGroupModal } = useEditCampaignGroup(rowData, refetch);
  const onRowEditClicked = (group: CampaignGroupMetricsModel | undefined) => {
    if (group) {
      openEditCampaignGroupModal(group);
    }
  };

  const onProfileNameClicked = (data: CampaignGroupMetricsModel | undefined) => {
    if (!data) return;

    navigateToPageWithCampaignGroupFilter(Routes.OPTIMIZER, data.id);
  };

  const columnDefs: ColDefOrGroup<CampaignGroupMetricsModel>[] = useMemo(() => {
    const colDefs: ColDefOrGroup<CampaignGroupMetricsModel>[] = [
      {
        colId: ColumnId.CHECKBOX,
        type: 'checkboxColumnType',
        pinned: 'left',
        lockPosition: true,
        lockPinned: true,
        lockVisible: true,
      },
      {
        colId: ColumnId.GROUP_NAME,
        headerName: 'Name',
        field: 'name',
        pinned: 'left',
        lockPosition: true,
        lockPinned: true,
        lockVisible: true,
        cellRenderer: LinkCallbackCellRenderer,
        cellRendererParams: (
          params: ICellRendererParams<CampaignGroupMetricsModel>,
        ): ILinkCallbackCellRendererParams<CampaignGroupMetricsModel> => {
          return {
            buttonText: params.value || '',
            tooltip: 'See campaigns in this group',
            callback: onProfileNameClicked,
          };
        },
      },
      {
        colId: ColumnId.ACTIONS,
        headerName: 'Actions',
        cellRenderer: ButtonGroupCellRenderer,
        width: 95,
        pinned: 'left',
        lockPosition: true,
        lockPinned: true,
        lockVisible: true,
        cellRendererParams: (params: ICellRendererParams<CampaignGroupMetricsModel>): IButtonGroupCellRendererParams => {
          const buttons = [
            <RowActionButton
              key="edit"
              text="Edit"
              isLoadingText="Updating..."
              color="default"
              tooltipText={`Edit this opt group`}
              onClick={() => onRowEditClicked(params.data)}
              icon={<Edit />}
            ></RowActionButton>,
          ];

          return {
            buttons,
          };
        },
      },
      {
        colId: ColumnId.GROUP_TACOS,
        headerName: 'Target ACOS',
        field: 'tacos',
        width: 130,
        cellClass: (params) => {
          if (!params.data?.optimizationEnabled || params.value == 0 || !params.value || params.value == '') {
            return 'text-gray-400';
          } else {
            return '';
          }
        },

        valueFormatter: (params: ValueFormatterParams<CampaignGroupMetricsModel>) => {
          if (params.data?.id === NO_GROUPS_ASSIGNED_GROUP_ID) return '-';
          if (params.value == null) return 'Not Set';
          // valueGetter already multiples by 100, so only need to show %
          return params.value ? params.value + '%' : 'Not Set';
        },
        // Get the value for editing, as a whole number percentage
        valueGetter: (params: ValueGetterParams<CampaignGroupMetricsModel>) => {
          // Under the hood the value is in fraction, but for display and edit display we want to show %
          return params.data && isNumber(params.data.tacos) ? round(params.data.tacos * 100) : '';
        },
      },
      {
        colId: ColumnId.GROUP_PRESET,
        headerName: 'Prioritization',
        field: 'preset',
        cellEditor: 'agSelectCellEditor',
        width: 145,
        cellClass: (params) => {
          if (!params.data?.optimizationEnabled || params.value == OptimizationPreset.NOT_SET) {
            return 'text-gray-400';
          } else {
            return '';
          }
        },

        valueFormatter: (params) => {
          if (params.data?.id === NO_GROUPS_ASSIGNED_GROUP_ID) return '-';
          return t(`enums.optimization_presets.${params.value}`);
        },
      },
      {
        colId: ColumnId.GROUP_BID_CEILING,
        headerName: 'Bid Ceiling',
        field: 'bidCeiling',

        width: 130,
        cellClass: (params: ValueFormatterParams<CampaignGroupMetricsModel>) => {
          if (!params.data?.optimizationEnabled || (!params.data?.bidCeilingOff && params.data?.bidCeiling == undefined)) {
            return 'text-gray-400';
          } else {
            return '';
          }
        },

        valueFormatter: (params: ValueFormatterParams<CampaignGroupMetricsModel>) => {
          if (params.data?.id === NO_GROUPS_ASSIGNED_GROUP_ID) return '-';

          if (params.data) {
            if (params.data.bidCeilingOff) {
              return t(`enums.bid_ceiling_type.${BidCeilingType.OFF}`);
            } else if (params.data.bidCeiling) {
              if (params.data.bidCeiling.unit == BidLimitChangeUnitType.CURRENCY) {
                return formatCurrency(params.data.bidCeiling.value);
              } else if (params.data.bidCeiling.unit == BidLimitChangeUnitType.PERCENT) {
                return params.data.bidCeiling.value * 100 + '%';
              } else if (params.data.bidCeiling.unit == BidLimitChangeUnitType.TIMES_CPC) {
                if (params.data.bidCeiling.value == 1) {
                  return t(`enums.smart_bid_ceiling_type.${SmartBidCeilingType.TARGET_CPC_1X}`);
                } else if (params.data.bidCeiling.value == 2) {
                  return t(`enums.smart_bid_ceiling_type.${SmartBidCeilingType.TARGET_CPC_2X}`);
                } else {
                  return t(`enums.smart_bid_ceiling_type.${SmartBidCeilingType.TARGET_CPC_3X}`);
                }
              }
            }
          }

          return t(`enums.bid_ceiling_type.${BidCeilingType.NOT_SET}`);
        },
      },
      {
        colId: ColumnId.GROUP_BID_FLOOR,
        headerName: 'Bid Floor',
        field: 'bidFloor',
        width: 100,
        cellClass: (params: ValueFormatterParams<CampaignGroupMetricsModel>) => {
          if (!params.data?.optimizationEnabled || (!params.data?.bidFloorOff && params.data?.bidFloor == undefined)) {
            return 'text-gray-400';
          } else {
            return '';
          }
        },

        valueFormatter: (params: ValueFormatterParams<CampaignGroupMetricsModel>) => {
          if (params.data?.id === NO_GROUPS_ASSIGNED_GROUP_ID) return '-';

          if (params.data) {
            if (params.data.bidFloorOff) {
              return t(`enums.bid_floor_type.${BidFloorType.OFF}`);
            } else if (params.data.bidFloor) {
              return formatCurrency(params.data.bidFloor);
            }
          }

          return t(`enums.bid_floor_type.${BidFloorType.NOT_SET}`);
        },
      },
      // {
      //   colId: ColumnId.GROUP_OPTIMIZATION_ENABLED,
      //   headerName: 'Optimization Enabled',
      //   field: 'optimizationEnabled',
      //   cellClass: 'flex flex-row items-center justify-center',
      //   cellRenderer: BooleanCellRenderer,
      //   width: 82,
      // },
      {
        colId: ColumnId.CAMPAIGN_GROUP_COUNT,
        headerName: '# of Campaigns',
        field: 'totalCampaigns',
        type: 'numericColumn',
        width: 145,
      },
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Impressions',
        field: 'impressions',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Clicks',
        field: 'clicks',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Orders',
        field: 'orders',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.UNITS,
        headerName: 'Units',
        field: 'units',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CTR,
        headerName: 'CTR',
        field: 'ctr',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CVR,
        headerName: 'CVR',
        field: 'cvr',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CPC,
        headerName: 'CPC',
        field: 'cpc',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Spend',
        field: 'spend',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Sales',
        field: 'sales',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ACTC,
        headerName: 'aCTC',
        field: 'actc',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.ROAS,
        headerName: 'ROAS',
        field: 'roas',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.RPC,
        headerName: 'RPC',
        field: 'rpc',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CPA,
        headerName: 'CPA',
        field: 'cpa',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.AOV,
        headerName: 'AOV',
        field: 'aov',
        type: 'metricFieldWithChangePercentage',
      },
      {
        colId: ColumnId.CPM,
        headerName: 'CPM',
        field: 'cpm',
        type: 'metricFieldWithChangePercentage',
      },
    ];
    applyStateToDefinitions(colDefs);

    return colDefs;
  }, []);

  const defaultCampaignGridContext: ExpandedGridContext = {
    metricColumnAggregates,
    isComparisonDataMissing,
    ...gridToggleParams,
  };

  const customGridOptions: GridOptions<CampaignGroupMetricsModel> = {
    ...DEFAULT_GRID_OPTIONS,
    ...DEFAULT_GRID_OPTIONS_ROW_GROUPS,
    sideBar: false,
    getRowId: (params) => params.data.id.toString(),
    context: defaultCampaignGridContext,
    isRowSelectable: (params) => params.data?.id !== NO_GROUPS_ASSIGNED_GROUP_ID,
    onSelectionChanged: (params) => {
      if (params.api && !params.api.isDestroyed()) {
        setSelectedCampaignGroups(params.api.getSelectedRows());
      }
    },
    columnTypes: { checkboxColumnType, metricFieldWithChangePercentage },
    onColumnMoved: handleColumnStateChange,
    onColumnVisible: handleColumnStateChange,
    onColumnResized: handleColumnStateChange,
    onColumnRowGroupChanged: handleColumnStateChange,
    onSortChanged: handleColumnStateChange,
    onColumnPinned: handleColumnStateChange,
    aggFuncs: {
      stringToCountAggFunc,
      metricsDataAggFunc,
    },
    onRowDoubleClicked: (params) => {
      onRowEditClicked(params.data);
    },
  };

  const onGridReady = (params: GridReadyEvent<CampaignGroupModel>) => {
    setColumnStateGridApi(params.api);

    gridApiRef.current = params.api;

    // Context
    gridContextRef.current = params.context;
    if (gridContextRef.current) {
      onGridReadyForMetricColumnAggregates();
      onGridReadyForComparisonMissing();
      onGridReadyCallback(params);
      gridApiRef.current.refreshHeader();
    } else {
      // Should not happen as gridApiRef was just set
      Sentry.captureMessage(`CampaignGroupsTable: gridContextRef is undefined though it was just set`, 'info');
    }
  };

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <>
      <div className="flex flex-col flex-grow">
        <AlGrid
          colDefs={columnDefs}
          rowData={rowData}
          gridOptions={customGridOptions}
          isLoading={isLoading}
          onGridReadyCallback={onGridReady}
          onCellValueChanged={onCellValueChanged}
          noTopBorderRadius={noTopBorderRadius}
          fitToResizeEnabled={false}
        />
      </div>

      {EditCampaignGroupModal}
    </>
  );
};

export default CampaignGroupsTable;
