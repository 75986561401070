import PopoverLikePopper from '@/components/PopoverLikePopper';
import InfoMessage from '@/components/feedback/InfoMessage';
import AlSelect from '@/components/form/AlSelect';
import BidEditRow from '@/components/form/BidEditRow';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import { useLayoutContext } from '@/contexts/LayoutContext';
import useBidLimits from '@/modules/amazon-constants/hooks/useBidLimits';
import { CurrencyCode } from '@/modules/amazon-constants/types/CurrencyCode';
import { BidUpdateData, BidUpdateType } from '@/modules/amazon-constants/types/bulk-edit';
import { UpdateResponseDTO, UpdateResponseModal } from '@/modules/application/components/UpdateResponseModal';
import { CampaignAdType, EnabledPausedArchivedState } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { MetricsGraphTablePageContext } from '@/types/context-shared';
import { Button, DialogActions, DialogContent, DialogTitle, Divider, SelectChangeEvent, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, FunctionComponent, RefObject, useEffect, useState } from 'react';
import { TargetUpdateDTO } from '../api/targets-contracts';
import { createTargetingWithTimelineQueryKey, targetingService } from '../api/targets-service';
import useTargetingWarnings from '../hooks/useTargetingWarnings';
import { TargetModel } from '../models/TargetsModel';

const ARCHIVE_COUNT_TO_SHOW_WARNING = 1;

interface TargetingBulkActionsPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  selectedTargets: TargetModel[];
  isOpen: boolean;
  onClose: () => void;
  pageVariables: MetricsGraphTablePageContext;
}

type NoChange = 'NO_CHANGE';
const NO_CHANGE: NoChange = 'NO_CHANGE';

const TargetingBulkEditPopover: FunctionComponent<TargetingBulkActionsPopoverProps> = ({
  selectedTargets,
  isOpen,
  onClose,
  buttonRef,
  pageVariables,
}) => {
  const { startGlobalLoading, stopGlobalLoading } = useLayoutContext();

  // STATE UPDATE
  // TODO: move to common types as it's also used elsewhere
  const STATE_OPTIONS = [
    { value: NO_CHANGE, label: 'No change' },
    { value: EnabledPausedArchivedState.ENABLED, label: 'Enabled' },
    { value: EnabledPausedArchivedState.PAUSED, label: 'Paused' },
    { value: EnabledPausedArchivedState.ARCHIVED, label: 'Archived' },
  ];

  // Post update modal
  const [updateResponseModalOpen, setUpdateResponseModalOpen] = useState(false);
  const [updatedTargetsResponse, setUpdateTargetResponse] = useState<UpdateResponseDTO>();

  const [targetState, setCampaignState] = useState<EnabledPausedArchivedState | NoChange>(NO_CHANGE);
  const [isApplyInProgress, setIsApplyInProgress] = useState<boolean>(false);

  const { activeProfile } = useActiveTeamContext();
  const { getClampedBidWithWarningsByUpdateDetails, getBidUpdateOptions } = useBidLimits();

  function handleTargetStateChange(event: SelectChangeEvent<EnabledPausedArchivedState | NoChange>) {
    const value = event.target.value;

    if (value === NO_CHANGE) {
      setCampaignState(value);
    } else {
      setCampaignState(value as EnabledPausedArchivedState);
    }
  }

  // BID UPDATE
  const [bidUpdateData, setBidUpdateData] = useState<BidUpdateData>({ bidUpdateType: BidUpdateType.NO_CHANGE, newBidValue: 0 });

  const [bidLimitWarnings, setBidLimitWarnings] = useState<string[]>([]);
  const [newBidValue, setNewBidValue] = useState<string>('');
  const onNewBidValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Allow only numeric input with decimals
    if (!value || /^\d*\.?\d*$/.test(value)) {
      setNewBidValue(value);
      setBidUpdateData({ ...bidUpdateData, newBidValue: parseFloat(value) });
    }
  };

  // I need to check warnings when entered bid value changes or selected targets change
  useEffect(() => {
    if (bidUpdateData.bidUpdateType === BidUpdateType.NO_CHANGE || newBidValue == '' || bidUpdateData.newBidValue === 0) {
      setBidLimitWarnings([]);
      return;
    }

    const warningsWithCount = new Map<string, number>();

    for (const target of selectedTargets) {
      const warnings = new Set<string>();

      getClampedBidWithWarningsByUpdateDetails({
        currentBid: target.bid,
        updateData: bidUpdateData,
        campaignAdType: target.campaignAdType,
        campaignIsVideo: target.campaignIsVideo,
        campaignBudgetAmount: target.campaignBudgetAmount,
        currentCPC: target.cpc?.[0] ?? 0,
        includeAdjustedToMessage: true,
        warnings,
      });

      for (const warning of warnings) {
        if (warningsWithCount.has(warning)) {
          const currentCount = warningsWithCount.get(warning) ?? 0;
          warningsWithCount.set(warning, currentCount + 1);
        } else {
          warningsWithCount.set(warning, 1);
        }
      }
    }

    const limitWarnings = [];
    for (const [warning, count] of warningsWithCount) {
      if (count >= ARCHIVE_COUNT_TO_SHOW_WARNING) {
        limitWarnings.push(`${count} ${warning}`);
      }
    }

    setBidLimitWarnings(limitWarnings);
  }, [selectedTargets.length, bidUpdateData]);

  const handleBidUpdateTypeChange = (event: SelectChangeEvent<BidUpdateType>) => {
    setBidUpdateData({ ...bidUpdateData, bidUpdateType: event.target.value as BidUpdateType });
  };

  // PROCESS UPDATE
  const queryClient = useQueryClient();
  const targetsWithTimelineQueryKey = createTargetingWithTimelineQueryKey(activeProfile?.id, pageVariables.filters);
  const [pendingTargetUpdates, setPendingTargetUpdates] = useState<TargetUpdateDTO[]>([]);
  async function handleApplyChanges() {
    const warnings = new Set<string>();

    const filteredTargets = selectedTargets.filter(
      (target) =>
        target.state !== EnabledPausedArchivedState.ARCHIVED &&
        target.campaignAdType !== CampaignAdType.TV &&
        target.campaignState !== EnabledPausedArchivedState.ARCHIVED,
    );

    const targetUpdates: TargetUpdateDTO[] = filteredTargets.reduce((updates, target) => {
      const update: TargetUpdateDTO = {
        id: target.id,
        ad_type: target.campaignAdType,
        entity_type: target.targetEntityType,
        match_type: target.matchType,
      };

      let isUpdated = false; // Flag to check if the target has been updated

      if (targetState !== NO_CHANGE) {
        update.state = targetState as EnabledPausedArchivedState;
        isUpdated = true; // Mark as updated
      }

      if (bidUpdateData.bidUpdateType !== BidUpdateType.NO_CHANGE && target.isBidChangeEnabled) {
        update.bid = getClampedBidWithWarningsByUpdateDetails({
          currentBid: target.bid,
          updateData: bidUpdateData,
          campaignAdType: target.campaignAdType,
          campaignIsVideo: target.campaignIsVideo,
          campaignBudgetAmount: target.campaignBudgetAmount,
          currentCPC: target.cpc?.[0] ?? 0,
          warnings,
        });

        isUpdated = true; // Mark as updated
      }

      // Only add the update to the array if the target was actually updated
      if (isUpdated) {
        updates.push(update);
      }

      return updates;
    }, [] as TargetUpdateDTO[]); // Initialize with an empty array

    if (warnings.size > 0) {
      const warningList = Array.from(warnings);
      warningList.forEach((w) =>
        toastService.warn(w, {
          autoCloseDelay: 5000,
        }),
      );
    }

    // If user tries to archive more than ARCHIVE_COUNT_TO_SHOW_WARNING then display conformation dialog to client
    if (targetState === EnabledPausedArchivedState.ARCHIVED && targetUpdates.length >= ARCHIVE_COUNT_TO_SHOW_WARNING) {
      handleArchivingManyTargetsModalHandleOpen();
      setPendingTargetUpdates(targetUpdates);
    } else {
      await applyTargetUpdates(targetUpdates);
    }

    onClose();
    setIsApplyInProgress(false);
  }

  async function applyTargetUpdates(targetUpdates: TargetUpdateDTO[]) {
    const loadingKey = 'applyTargetUpdates';
    startGlobalLoading(loadingKey);
    setIsApplyInProgress(true);
    const response = await targetingService.updateTargets(targetUpdates);

    if (response.isSuccess) {
      setUpdateTargetResponse({ responseErrorMsg: null, payload: response?.payload });
    } else {
      setIsApplyInProgress(false);
      stopGlobalLoading(loadingKey);
      setUpdateTargetResponse({ responseErrorMsg: `Did not receive a response from server: ${response.message}`, payload: undefined });
    }
    setUpdateResponseModalOpen(true);

    // Reset all fields
    setCampaignState(NO_CHANGE);

    queryClient.invalidateQueries({
      queryKey: targetsWithTimelineQueryKey,
    });
    onClose();
    setIsApplyInProgress(false);
    stopGlobalLoading(loadingKey);
  }

  const { ModalComponent: archivingManyTargetsModalComponent, handleOpenModal: handleArchivingManyTargetsModalHandleOpen } =
    useDeleteConfirmation({
      questionText: `We want to double confirm, are you sure you want to archive ${pendingTargetUpdates.length} ${pendingTargetUpdates.length == 1 ? 'target' : 'targets'}? This action cannot be undone.`,
      headerText: 'Archiving Targets',
      onDelete: () => {
        applyTargetUpdates(pendingTargetUpdates);
      },
      confirmButtonText: `Confirm Archiving ${pendingTargetUpdates.length} Targets`,
    });

  const {
    warnings,
    selectedLegacyTargetsCount,
    selectedArchivedTargetsCount,
    selectedActiveTargetsWithArchivedCampaignsCount,
    selectedTargetsWithOptRulesCount,
    selectedAutoTargetCount,
    selectedTvTargetsCount,
  } = useTargetingWarnings({
    selectedTargets,
  });

  const isStateEditAllowed =
    selectedTargets.length > selectedLegacyTargetsCount + selectedTvTargetsCount &&
    selectedTargets.length > selectedArchivedTargetsCount &&
    selectedTargets.length > selectedActiveTargetsWithArchivedCampaignsCount;

  const isBidEditAllowed =
    selectedTargets.length > selectedLegacyTargetsCount + selectedTargetsWithOptRulesCount &&
    selectedTargets.length > selectedTvTargetsCount &&
    selectedTargets.length > selectedArchivedTargetsCount &&
    selectedTargets.length > selectedActiveTargetsWithArchivedCampaignsCount;

  const onlyAutoArchive = targetState == EnabledPausedArchivedState.ARCHIVED && selectedTargets.length == selectedAutoTargetCount;

  const canClickApply =
    (targetState !== NO_CHANGE || bidUpdateData.bidUpdateType !== BidUpdateType.NO_CHANGE) &&
    !onlyAutoArchive &&
    selectedTargets.length != selectedArchivedTargetsCount;

  return (
    <>
      <PopoverLikePopper open={isOpen} anchorEl={buttonRef.current} onClose={onClose}>
        <DialogTitle id="edit-selection-modal-title">Edit Selected Targets</DialogTitle>

        <DialogContent className="space-y-4">
          <span>
            You have selected {selectedTargets.length} Target{selectedTargets.length == 1 ? '' : 's'} to edit.
          </span>
          <div>
            <Typography variant="subtitle2">Bulk Edit State</Typography>
            <AlSelect
              label={'Target State'}
              value={targetState}
              options={STATE_OPTIONS}
              onChange={handleTargetStateChange}
              renderOption={(item) => item.label}
              valueExtractor={(item) => item.value}
              disabled={!isStateEditAllowed}
            />
          </div>

          <BidEditRow
            title={'Bulk Edit Bids'}
            options={getBidUpdateOptions(activeProfile?.currencyCode ?? CurrencyCode.USD)}
            bidUpdateData={bidUpdateData}
            handleBidUpdateTypeChange={handleBidUpdateTypeChange}
            isBidEditAllowed={isBidEditAllowed}
            newBidValue={newBidValue}
            onNewBidValueChange={onNewBidValueChange}
            currency={activeProfile?.currencyCode ?? CurrencyCode.USD}
          />
        </DialogContent>
        <Divider />
        {warnings.length > 0 && (
          <div className="flex flex-col mx-5">
            {warnings.map((warning, index) => (
              <InfoMessage key={index} content={warning} />
            ))}
          </div>
        )}

        {bidLimitWarnings.length > 0 && (
          <div className="flex flex-col mx-5">
            {bidLimitWarnings.map((warning, index) => (
              <InfoMessage key={index} content={warning} />
            ))}
          </div>
        )}

        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" loading={isApplyInProgress} onClick={handleApplyChanges} disabled={!canClickApply}>
            Apply Changes
          </Button>
        </DialogActions>
      </PopoverLikePopper>

      <UpdateResponseModal
        isOpen={updateResponseModalOpen}
        onClose={() => setUpdateResponseModalOpen(false)}
        updateResponse={updatedTargetsResponse}
      />

      {archivingManyTargetsModalComponent}
    </>
  );
};

export default TargetingBulkEditPopover;
