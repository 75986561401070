export enum FilterKey {
  DATE = 'DATE',
  STATE = 'STATE',
  COMPARE_DATE = 'COMPARE_DATE', // Used only in metrics endpoint
  PORTFOLIO_ID = 'PORTFOLIO_ID', // A collection of Amazon Ads campaigns to manage and organize them.
  PORTFOLIO_NAME = 'PORTFOLIO_NAME',
  CAMPAIGN_AD_TYPE = 'CAMPAIGN_AD_TYPE',
  CAMPAIGN_ID = 'CAMPAIGN_ID',
  CAMPAIGN_NAME = 'CAMPAIGN_NAME',
  CAMPAIGN_NAME_NOT = 'CAMPAIGN_NAME_NOT',
  AD_GROUP_ID = 'AD_GROUP_ID', // is not available for filtering campaigns
  AD_GROUP_NAME = 'AD_GROUP_NAME',
  AD_GROUP_NAME_NOT = 'AD_GROUP_NAME_NOT',
  AD_GROUP_STATE = 'AD_GROUP_STATE',
  CAMPAIGN_STATE = 'CAMPAIGN_STATE', // The current state of the campaign (e.g., Active, Paused, Archived).
  TARGETING_TYPE = 'TARGETING_TYPE', // MANUAL, AUTO, AUDIENCES, CONTEXTUAL
  CAMPAIGN_START_DATE = 'CAMPAIGN_START_DATE', // The date the campaign starts.
  CAMPAIGN_END_DATE = 'CAMPAIGN_END_DATE', // The date the campaign ends.
  CAMPAIGN_LAST_OPTIMIZED_AT = 'CAMPAIGN_LAST_OPTIMIZED_AT',
  BUDGET = 'BUDGET', // The maximum amount set to spend for a campaign.
  IMPRESSIONS = 'IMPRESSIONS', // The number of times an ad was displayed.
  CLICKS = 'CLICKS', // The number of times an ad was clicked on.
  ORDERS = 'ORDERS', // The number of orders placed after clicking the ad.
  UNITS = 'UNITS',
  CTR = 'CTR', // Click-Through Rate: The percentage of times the ad was clicked after being seen.
  CVR = 'CVR', // Conversion Rate: The percentage of clicks that resulted in a sale.
  CPC = 'CPC', // Cost Per Click: The average cost paid for each click.
  SPEND = 'SPEND', // The total amount spent on the campaign.
  SALES = 'SALES', // The total revenue generated from the ads.
  ACOS = 'ACOS', // Advertising Cost of Sale: The percentage of ad spend against sales.
  ACTC = 'ACTC',
  ROAS = 'ROAS', // Return on Ad Spend: Revenue generated for every dollar spent on advertising.
  RPC = 'RPC', // Revenue Per Click: Average revenue generated per click.
  CPA = 'CPA', // Cost Per Acquisition: Cost to acquire a customer.
  AOV = 'AOV', // Average Order Value: Average value of each order.
  CPM = 'CPM', // Cost Per Mille: Cost of 1,000 ad impressions.
  SAME_SKU_ORDERS = 'SAME_SKU_ORDERS',
  SAME_SKU_SALES = 'SAME_SKU_SALES',
  OTHER_SKU_SALES = 'OTHER_SKU_SALES',
  CAMPAIGN_GROUP = 'CAMPAIGN_GROUP',
  CAMPAIGN_GROUP_NAME = 'CAMPAIGN_GROUP_NAME',
  CAMPAIGN_GROUP_NAME_NOT = 'CAMPAIGN_GROUP_NAME_NOT',
  CREATIVE_TYPE = 'CREATIVE_TYPE',
  COST_TYPE = 'COST_TYPE',
  PLACEMENT_TYPE = 'PLACEMENT_TYPE',
  BID_ADJUSTMENT = 'BID_ADJUSTMENT',
  BID_OPTIMIZATION = 'BID_OPTIMIZATION',
  BID_ADJUSTMENT_LAST_OPTIMIZED_AT = 'BID_ADJUSTMENT_LAST_OPTIMIZED_AT',
  MULTI_AD_GROUPS_ENABLED = 'MULTI_AD_GROUPS_ENABLED',
  TARGET_TYPE = 'TARGET_TYPE', // keyword or product target
  TARGET_STATE = 'TARGET_STATE',
  CAMPAIGN_DATA_GROUP_ITEM = 'CAMPAIGN_DATA_GROUP_ITEM',
  TARGET_DATA_GROUP_ITEM = 'TARGET_DATA_GROUP_ITEM',
  SEARCH_TERM_DATA_GROUP_ITEM = 'SEARCH_TERM_DATA_GROUP_ITEM',
  SEARCH_TERM = 'SEARCH_TERM',
  SEARCH_TERM_NOT = 'SEARCH_TERM_NOT',
  SEARCH_TERM_EXACT_MATCH = 'SEARCH_TERM_EXACT_MATCH',
  SEARCH_TERM_NEGATED = 'SEARCH_TERM_NEGATED',
  SEARCH_TERM_HARVESTED = 'SEARCH_TERM_HARVESTED',
  BID_STRATEGY = 'BID_STRATEGY',
  BIDDING_METHOD = 'BIDDING_METHOD',
  TARGET_LAST_OPTIMIZED_AT = 'TARGET_LAST_OPTIMIZED_AT',

  SOURCE_CAMPAIGN_NAME = 'SOURCE_CAMPAIGN_NAME',
  SOURCE_CAMPAIGN_NAME_NOT = 'SOURCE_CAMPAIGN_NAME_NOT',
  DESTINATION_CAMPAIGN_NAME = 'DESTINATION_CAMPAIGN_NAME',
  DESTINATION_CAMPAIGN_NAME_NOT = 'DESTINATION_CAMPAIGN_NAME_NOT',
  SOURCE_AD_GROUP_NAME = 'SOURCE_AD_GROUP_NAME',
  SOURCE_AD_GROUP_NAME_NOT = 'SOURCE_AD_GROUP_NAME_NOT',
  DESTINATION_AD_GROUP_NAME = 'DESTINATION_AD_GROUP_NAME',
  DESTINATION_AD_GROUP_NAME_NOT = 'DESTINATION_AD_GROUP_NAME_NOT',
  SOURCE_CAMPAIGN_AD_TYPE = 'SOURCE_CAMPAIGN_AD_TYPE',
  DESTINATION_CAMPAIGN_AD_TYPE = 'DESTINATION_CAMPAIGN_AD_TYPE',

  // Negative targeting
  REPORTED_AT = 'REPORTED_AT',
  CREATED_AT = 'CREATED_AT',
  NEGATIVE_KEYWORD_MATCH_TYPE = 'NEGATIVE_KEYWORD_MATCH_TYPE',
  NEGATIVE_TARGETING_LEVEL = 'NEGATIVE_TARGETING_LEVEL',
  NEGATIVE_TARGETING = 'NEGATIVE_TARGETING',
  NEGATIVE_TARGETING_NOT = 'NEGATIVE_TARGETING_NOT',
  NEGATIVE_TARGETING_EXACT_MATCH = 'NEGATIVE_TARGETING_EXACT_MATCH',

  // Products
  PRODUCT_TITLE = 'PRODUCT_TITLE',
  PRODUCT_TITLE_NOT = 'PRODUCT_TITLE_NOT',
  PRODUCT_ASIN = 'PRODUCT_ASIN',
  PRODUCT_PARENT_ASIN = 'PRODUCT_PARENT_ASIN',
  PRODUCT_SKU = 'PRODUCT_SKU',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  PRODUCT_BRAND = 'PRODUCT_BRAND',
  PRODUCT_DATA_GROUP_ITEM = 'PRODUCT_DATA_GROUP_ITEM',
  PRODUCT_AVAILABILITY = 'PRODUCT_AVAILABILITY',

  // Seller and Vendor
  ACOTS = 'ACOTS',
  TOTAL_ACTC = 'TACTC',
  ASP = 'TASP', // Total ASP
  TCPA = 'TCPA', // Total CPA
  TROAS = 'TROAS', // Total ROAS
  TAOV = 'TAOV', // Total AOV
  ORGANIC_SALES = 'ORGANIC_SALES',
  ORGANIC_TRAFFIC = 'ORGANIC_TRAFFIC',
  TOTAL_VIEWS = 'TOTAL_VIEWS',
  TOTAL_UNITS = 'TOTAL_UNITS',
  TOTAL_SALES = 'TOTAL_SALES',
  UPS = 'UPS', // Unit Per Session
  TCVR = 'TCVR', // Total CVR
  TOTAL_CLICKS = 'TOTAL_CLICKS',
  TOTAL_ORDERS = 'TOTAL_ORDERS',
  AD_SALES_OF_TOTAL = 'AD_SALES_OF_TOTAL',
  UPPW = 'UPPW',

  GLANCE_VIEWS = 'GLANCE_VIEWS',
  CUSTOMER_RETURNS = 'CUSTOMER_RETURNS',
  ORDERED_REVENUE = 'ORDERED_REVENUE',
  ORDERED_UNITS = 'ORDERED_UNITS',
  SHIPPED_COGS = 'SHIPPED_COGS',
  SHIPPED_REVENUE = 'SHIPPED_REVENUE',
  SHIPPED_UNITS = 'SHIPPED_UNITS',
  RETURN_RATE = 'RETURN_RATE',

  // Currently supported only by the preview table
  DELTA = 'DELTA',
  REASON = 'REASON',
  ENTITY_TYPE = 'ENTITY_TYPE',
  MATCH_TYPE = 'MATCH_TYPE',
  TARGETING = 'TARGETING',
  TARGETING_NOT = 'TARGETING_NOT',
  TARGETING_EXACT_MATCH = 'TARGETING_EXACT_MATCH',
  OLD_VALUE = 'OLD_VALUE',
  NEW_VALUE = 'NEW_VALUE',
  BID = 'BID',
  LAST_OPTIMIZED_AT = 'LAST_OPTIMIZED_AT',

  // Currently supported only by the profile stats table (front end filters)
  TEAM = 'TEAM',
  PROFILE = 'PROFILE',
  TOTAL_UNITS_REFUNDED = 'TOTAL_UNITS_REFUNDED',
  TOTAL_UNITS_REFUNDED_RATE = 'TOTAL_UNITS_REFUNDED_RATE',
}

export enum FilterType {
  BETWEEN = 'BETWEEN',
  DATE_SELECT = 'DATE_SELECT',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  STRING_COMPARISON = 'STRING_COMPARISON',
  STRING_EXACT_MATCH = 'STRING_EXACT_MATCH',
}
