import ErrorMessage from '@/components/feedback/ErrorMessage';
import InfoMessage from '@/components/feedback/InfoMessage';
import AlAutoComplete, { AlAutoCompleteOption } from '@/components/form/AlAutoComplete';
import AlSelect from '@/components/form/AlSelect';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import useFormatting, { CurrencyPosition } from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import useBidLimits from '@/modules/amazon-constants/hooks/useBidLimits';
import { emptyCampaignMappingImportRow } from '@/modules/data-management/importers/campaign-mapping/CampaignMappingImportRow';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MatchType } from '@/modules/optimizer/components/optimization/api/optimization-contracts';
import { TargetEntityExtendedType, TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { TargetEntityTypeColors, getCampaignAdTypeColor } from '@/types/colors.enum';
import Close from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { isEmpty, isNil } from 'lodash-es';
import { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BiddingMethod } from '../api/campaign-mapping-contracts';
import { campaignMappingService, invalidateProfile_campaignMappingQueryKeys } from '../api/campaign-mapping-service';
import useCampaignMapping from '../hooks/useCampaignMapping';
import useCampaignToAdGroupsMappingData from '../hooks/useCampaignToAdGroupsMappingData';
import { CampaignMappingModel, NewCampaignMappingPreset } from '../models/CampaignMappingModel';
import { CampaignMappingModelWithValidation } from '../models/CampaignMappingModelWithValidation';

interface NewSingleMappingModalProps {
  isOpen: boolean;
  onClose: () => void;
  newCampaignMappingPreset?: NewCampaignMappingPreset;
}

export const NewSingleMappingModal: FunctionComponent<NewSingleMappingModalProps> = ({ isOpen, onClose, newCampaignMappingPreset }) => {
  const isAddingNewMatch = !isNil(newCampaignMappingPreset?.existingMatches);
  const { campaignToAdGroupsMap, getCampaignNameToCampaignIdMap, getCampaignMappingCampaignOptions } = useCampaignToAdGroupsMappingData();
  const { existingMappingKeys } = useCampaignMapping();
  const { t } = useTranslation();

  const { getCurrencySymbolPosition, getCurrencySymbol } = useFormatting();
  const currencyPosition = getCurrencySymbolPosition();
  const currencySymbol = getCurrencySymbol();

  const queryClient = useQueryClient();
  const { activeProfile } = useActiveTeamContext();
  const { getClampedBidWithWarnings } = useBidLimits();

  const [newCampaignMapping, setNewCampaignMapping] = useState<CampaignMappingModelWithValidation>(() => {
    const model = new CampaignMappingModelWithValidation({
      rowId: '1',
      importRow: emptyCampaignMappingImportRow,
      campaignNameToCampaignIdsMap: getCampaignNameToCampaignIdMap(),
      campaignToAdGroupsMap,
      getClampedBidWithWarnings,
      existingMappingKeys,
    });

    if (newCampaignMappingPreset) {
      model.sourceCampaignId = newCampaignMappingPreset.sourceCampaignId;
      model.sourceAdGroupId = newCampaignMappingPreset.sourceAdGroupId;
      model.destinationCampaignId = newCampaignMappingPreset.destinationCampaignId;
      model.destinationAdGroupId = newCampaignMappingPreset.destinationAdGroupId;
      model.setNegativesFromNegativeMatchBools(newCampaignMappingPreset.existingNegativeMatches);
    }

    model.biddingMethod = BiddingMethod.ADLABS;
    return model;
  });

  useEffect(() => {
    if (newCampaignMappingPreset) {
      const clone = newCampaignMapping.clone();

      clone.sourceCampaignId = newCampaignMappingPreset.sourceCampaignId;
      clone.sourceAdGroupId = newCampaignMappingPreset.sourceAdGroupId;
      clone.destinationCampaignId = newCampaignMappingPreset.destinationCampaignId;
      clone.destinationAdGroupId = newCampaignMappingPreset.destinationAdGroupId;
      clone.setNegativesFromNegativeMatchBools(newCampaignMappingPreset.existingNegativeMatches);

      setNewCampaignMapping(clone);
    }
  }, [newCampaignMappingPreset]);

  // Utility function for updating
  const updateNewMappingFields = (updates: Partial<CampaignMappingModelWithValidation>) => {
    const clone = newCampaignMapping.clone();
    Object.keys(updates).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (clone as any)[key] = updates[key as keyof CampaignMappingModelWithValidation];
    });
    setNewCampaignMapping(clone);
  };

  // Maps are pulled async so might not exist when setting state
  useEffect(() => {
    updateNewMappingFields({
      campaignNameToCampaignIdsMap: getCampaignNameToCampaignIdMap(),
      campaignToAdGroupsMap: campaignToAdGroupsMap,
      existingMappingKeys: existingMappingKeys,
    });
  }, [campaignToAdGroupsMap, existingMappingKeys]);

  const [isLoading, setIsLoading] = useState(false);

  const onCreateClicked = async () => {
    setIsLoading(true);

    try {
      const data = CampaignMappingModel.fromCampaignMappingModelWithValidation(newCampaignMapping);
      const response = await campaignMappingService.createCampaignMapping(data);

      if (!response.isSuccess) {
        toastService.error(response.message);
      } else {
        toastService.success('Created new campaign mapping');
        setTimeout(() => {
          invalidateProfile_campaignMappingQueryKeys(queryClient, activeProfile?.id);
        }, 2000);
        onClose();
      }
    } catch (error) {
      console.error(error);
      toastService.error('Failed to create campaign mapping: ' + error);
    }

    setIsLoading(false);
  };

  function handleAutoCompleteValueChanged(option: AlAutoCompleteOption | null, optionIdField: keyof CampaignMappingModelWithValidation) {
    if (['sourceCampaignId', 'sourceAdGroupId', 'destinationCampaignId', 'destinationAdGroupId'].includes(optionIdField)) {
      updateNewMappingFields({
        [optionIdField]: option?.id ?? null,
        exactMatch: false,
        broadMatch: false,
        phraseMatch: false,
        individualProductTarget: false,
        expandedProductTarget: false,
        campaignNegativeExact: false,
        campaignNegativePhrase: false,
        campaignNegativeProductTarget: false,
        adGroupNegativeExact: false,
        adGroupNegativePhrase: false,
        adGroupNegativeProductTarget: false,
      });
    } else {
      updateNewMappingFields({
        [optionIdField]: option?.id ?? null,
      });
    }
  }

  const handleTextFieldValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    updateNewMappingFields({
      [name]: value == '' ? null : (Number(value) ?? null),
    });

    validateField(name as keyof CampaignMappingModelWithValidation);
  };

  function handleCheckboxValueChanged(event: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    updateNewMappingFields({
      [name]: checked,
    });
  }

  // Field values
  const campaignOptions = getCampaignMappingCampaignOptions(campaignToAdGroupsMap);

  const availableSourceAdGroups = newCampaignMapping.sourceCampaignId
    ? (campaignToAdGroupsMap[newCampaignMapping.sourceCampaignId]?.adGroups ?? [])
    : [];
  const sourceAdGroupOptions = availableSourceAdGroups
    .map((adGroup) => ({
      id: adGroup.id,
      label: adGroup.isNameDuplicate ? CampaignMappingModelWithValidation.joinIDAndName(adGroup.id, adGroup.name) : adGroup.name,
      canHide: adGroup.mapCount > 0,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const availableDestinationAdGroups = newCampaignMapping.destinationCampaignId
    ? (campaignToAdGroupsMap[newCampaignMapping.destinationCampaignId]?.adGroups ?? [])
    : [];
  const destinationAdGroupOptions = availableDestinationAdGroups
    .map((adGroup) => ({
      id: adGroup.id,
      label: adGroup.isNameDuplicate ? CampaignMappingModelWithValidation.joinIDAndName(adGroup.id, adGroup.name) : adGroup.name,
      canHide: adGroup.mapCount > 0,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const getCampaignAdTypeComponent = (campaignAdType: CampaignAdType | null | string) => {
    if (!campaignAdType) return <>-</>;
    const text = t(`enums.ad_type.${campaignAdType}`);
    const color = getCampaignAdTypeColor(campaignAdType as CampaignAdType);
    return <Typography color={color}>{text}</Typography>;
  };

  const getAdGroupEntityTypeComponent = (entityType: TargetEntityExtendedType | null | string) => {
    if (!entityType) return undefined;
    const text = t(`enums.bidding_entity_short.${entityType}`);
    const color = TargetEntityTypeColors[entityType as TargetEntityExtendedType];
    return <Typography color={color}>{text}</Typography>;
  };

  function getCurrencyInputProps(currencyPosition: CurrencyPosition, currencySymbol: string) {
    return currencyPosition === CurrencyPosition.LEFT
      ? { startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment> }
      : { endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment> };
  }

  // Bidding Method
  const BIDDING_METHOD_OPTIONS = useMemo(
    () =>
      [BiddingMethod.ADLABS, BiddingMethod.CPC_PLUS, BiddingMethod.CPC_MINUS, BiddingMethod.CPC_TIMES, BiddingMethod.CUSTOM].map(
        (method) => ({ value: method, label: t(`enums.bidding_method.${method}`) }),
      ),
    [],
  );

  function handleBiddingMethodChange(event: SelectChangeEvent<BiddingMethod>) {
    const value = event.target.value;

    updateNewMappingFields({
      ['biddingMethod']: value ?? null,
      ['biddingMethodValue']: null,
    });

    validateField('biddingMethodValue');
  }

  function getBiddingMethodInputProps(currencyPosition: CurrencyPosition, currencySymbol: string) {
    if (newCampaignMapping.biddingMethod === BiddingMethod.ADLABS || newCampaignMapping.biddingMethod === BiddingMethod.CPC_TIMES) return {};

    // CPC_PLUS, CPC_MINUS, CUSTOM
    return currencyPosition === CurrencyPosition.LEFT
      ? { startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment> }
      : { endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment> };
  }

  const [validationErrors, setValidationErrors] = useState<Partial<Record<keyof CampaignMappingModelWithValidation, string | null>>>({
    sourceCampaignId: null,
    destinationCampaignId: null,
    sourceAdGroupId: null,
    destinationAdGroupId: null,
    biddingMethod: null,
    biddingMethodValue: null,
    bidFloor: null,
    bidCeiling: null,
    exactMatch: null,
    broadMatch: null,
    phraseMatch: null,
  });

  function validateField(field: keyof CampaignMappingModelWithValidation) {
    // Actual validation done in CampaignMappingModelWithValidation. Not using newCampaignMapping.validationErrors directly because want to show error only after onBlur
    setValidationErrors((prev) => ({ ...prev, [field]: newCampaignMapping.validationErrors?.[field] }));
  }

  const isAdGroupsSet = !isNil(newCampaignMapping.sourceAdGroupId) && !isNil(newCampaignMapping.destinationAdGroupId);

  const existingMatchTypes = useMemo(() => {
    const existingMatches = newCampaignMappingPreset?.existingMatches || [];

    return Object.fromEntries(Object.values(MatchType).map((type) => [type, existingMatches.includes(type)]));
  }, [newCampaignMappingPreset?.existingMatches]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>
        <div className="flex flex-row">
          {isAddingNewMatch ? 'Add New Match' : 'Create New Campaign Mapping'}
          <div className="flex flex-grow"></div>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="flex flex-col">
        <Typography variant="button">Source</Typography>
        <AlAutoComplete
          textFieldLabel={'Source Campaign'}
          options={campaignOptions}
          currentValue={campaignOptions.find((option) => option.id === newCampaignMapping.sourceCampaignId) ?? null}
          placeHolderText={'Select source campaign'}
          updateValueWithOption={(option: AlAutoCompleteOption | null) => handleAutoCompleteValueChanged(option, 'sourceCampaignId')}
          error={!isNil(validationErrors.sourceCampaignId)}
          helperText={validationErrors.sourceCampaignId ?? ''}
          onBlur={() => validateField('sourceCampaignId')}
          isDisabled={isAddingNewMatch}
        />
        <AlAutoComplete
          textFieldLabel={'Source Ad Group'}
          options={sourceAdGroupOptions}
          currentValue={sourceAdGroupOptions.find((option) => option.id === newCampaignMapping.sourceAdGroupId) ?? null}
          placeHolderText={'Select source ad group'}
          updateValueWithOption={(option: AlAutoCompleteOption | null) => handleAutoCompleteValueChanged(option, 'sourceAdGroupId')}
          error={!isNil(validationErrors.sourceAdGroupId)}
          helperText={validationErrors.sourceAdGroupId ?? ''}
          onBlur={() => validateField('sourceAdGroupId')}
          isDisabled={isAddingNewMatch}
        />

        <Typography variant="button" className="mt-2">
          Destination
        </Typography>
        <AlAutoComplete
          textFieldLabel={'Destination Campaign'}
          options={campaignOptions}
          currentValue={campaignOptions.find((option) => option.id === newCampaignMapping.destinationCampaignId) ?? null}
          placeHolderText={'Select source campaign'}
          updateValueWithOption={(option: AlAutoCompleteOption | null) => handleAutoCompleteValueChanged(option, 'destinationCampaignId')}
          error={!isNil(validationErrors.destinationCampaignId)}
          helperText={validationErrors.destinationCampaignId ?? ''}
          onBlur={() => validateField('destinationCampaignId')}
          InputProps={{
            endAdornment: <>{getCampaignAdTypeComponent(newCampaignMapping.destinationCampaignAdType)}</>,
          }}
          isDisabled={isAddingNewMatch}
        />
        <AlAutoComplete
          textFieldLabel={'Destination Ad Group'}
          options={destinationAdGroupOptions}
          currentValue={destinationAdGroupOptions.find((option) => option.id === newCampaignMapping.destinationAdGroupId) ?? null}
          placeHolderText={'Select source ad group'}
          updateValueWithOption={(option: AlAutoCompleteOption | null) => handleAutoCompleteValueChanged(option, 'destinationAdGroupId')}
          error={!isNil(validationErrors.destinationAdGroupId)}
          helperText={validationErrors.destinationAdGroupId ?? ''}
          onBlur={() => validateField('destinationAdGroupId')}
          InputProps={{
            endAdornment: <>{getAdGroupEntityTypeComponent(newCampaignMapping.destinationAdGroupEntityType)}</>,
          }}
          isDisabled={isAddingNewMatch}
        />

        <Typography variant="button" className="mt-2">
          Starting Bid
        </Typography>

        <div className="flex flex-row gap-x-2">
          <AlSelect
            label={'Bidding Method'}
            value={newCampaignMapping.biddingMethod as BiddingMethod}
            options={BIDDING_METHOD_OPTIONS}
            onChange={handleBiddingMethodChange}
            renderOption={(item) => item.label}
            valueExtractor={(item) => item.value}
          />
          <TextField
            defaultValue={newCampaignMapping.biddingMethodValue}
            type="number"
            onChange={handleTextFieldValueChange}
            name="biddingMethodValue"
            label={'Custom Value'}
            error={!isNil(newCampaignMapping.validationErrors.biddingMethodValue)}
            helperText={newCampaignMapping.validationErrors.biddingMethodValue ?? ''}
            disabled={newCampaignMapping.biddingMethod === BiddingMethod.ADLABS}
            fullWidth
            slotProps={{
              input: getBiddingMethodInputProps(currencyPosition, currencySymbol),
              inputLabel: { shrink: true },
            }}
          />
        </div>
        <div className="flex flex-row gap-x-2">
          <TextField
            defaultValue={newCampaignMapping.bidFloor}
            type="number"
            onChange={handleTextFieldValueChange}
            name="bidFloor"
            label={'Bid Floor (Optional)'}
            error={!isNil(newCampaignMapping.validationErrors.bidFloor)}
            helperText={newCampaignMapping.validationErrors.bidFloor ?? ''}
            fullWidth
            slotProps={{
              input: getCurrencyInputProps(currencyPosition, currencySymbol),
              inputLabel: { shrink: true },
            }}
          />

          <TextField
            defaultValue={newCampaignMapping.bidCeiling}
            type="number"
            onChange={handleTextFieldValueChange}
            name="bidCeiling"
            label={'Bid Ceiling (Optional)'}
            error={!isNil(newCampaignMapping.validationErrors.bidCeiling)}
            helperText={newCampaignMapping.validationErrors.bidCeiling ?? ''}
            fullWidth
            slotProps={{
              input: getCurrencyInputProps(currencyPosition, currencySymbol),
              inputLabel: { shrink: true },
            }}
          />
        </div>
        {newCampaignMapping.destinationAdGroupEntityType && (
          <Typography variant="button" className="mt-2">
            Match Type
          </Typography>
        )}
        {newCampaignMapping.destinationAdGroupEntityType == TargetEntityType.KEYWORD && (
          <div className="mb-2">
            <div className="inline-flex flex-col max-w-fit">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newCampaignMapping.exactMatch || existingMatchTypes[MatchType.EXACT]}
                    disabled={existingMatchTypes[MatchType.EXACT]}
                    onChange={handleCheckboxValueChanged}
                    name="exactMatch"
                  />
                }
                label="Exact Match"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newCampaignMapping.broadMatch || existingMatchTypes[MatchType.BROAD]}
                    disabled={existingMatchTypes[MatchType.BROAD]}
                    onChange={handleCheckboxValueChanged}
                    name="broadMatch"
                  />
                }
                label="Broad Match"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newCampaignMapping.phraseMatch || existingMatchTypes[MatchType.PHRASE]}
                    disabled={existingMatchTypes[MatchType.PHRASE]}
                    onChange={handleCheckboxValueChanged}
                    name="phraseMatch"
                  />
                }
                label="Phrase Match"
              />
            </div>
          </div>
        )}
        {newCampaignMapping.destinationAdGroupEntityType == TargetEntityType.PRODUCT_TARGET && (
          <div>
            <div className="inline-flex flex-col max-w-fit">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newCampaignMapping.individualProductTarget || existingMatchTypes[MatchType.INDIVIDUAL]}
                    disabled={existingMatchTypes[MatchType.INDIVIDUAL]}
                    onChange={handleCheckboxValueChanged}
                    name="individualProductTarget"
                  />
                }
                label="Individual Product Target"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newCampaignMapping.expandedProductTarget || existingMatchTypes[MatchType.EXPANDED]}
                    disabled={existingMatchTypes[MatchType.EXPANDED]}
                    onChange={handleCheckboxValueChanged}
                    name="expandedProductTarget"
                  />
                }
                label="Expanded Product Target"
              />
            </div>
          </div>
        )}

        {!isAddingNewMatch && (
          <div className="flex mt-2">
            {!newCampaignMapping.isCellDisabledMap[ColumnId.NEGATIVE_AD_GROUP] && (
              <div className="w-1/2">
                {newCampaignMapping.destinationAdGroupEntityType && (
                  <Typography variant="button" className="mt-2">
                    Negate from Source Ad Group?
                  </Typography>
                )}
                {newCampaignMapping.destinationAdGroupEntityType == TargetEntityType.KEYWORD && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newCampaignMapping.adGroupNegativeExact}
                          onChange={handleCheckboxValueChanged}
                          name="adGroupNegativeExact"
                        />
                      }
                      label="Ad Group Negative Exact"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newCampaignMapping.adGroupNegativePhrase}
                          onChange={handleCheckboxValueChanged}
                          name="adGroupNegativePhrase"
                        />
                      }
                      label="Ad Group Negative Phrase"
                    />
                  </FormGroup>
                )}
                {newCampaignMapping.destinationAdGroupEntityType == TargetEntityType.PRODUCT_TARGET && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newCampaignMapping.adGroupNegativeProductTarget}
                          onChange={handleCheckboxValueChanged}
                          name="adGroupNegativeProductTarget"
                        />
                      }
                      label="Ad Group Negative Product Target"
                    />
                  </FormGroup>
                )}
              </div>
            )}

            {!newCampaignMapping.isCellDisabledMap[ColumnId.NEGATIVE_CAMPAIGN] && (
              <div className="w-1/2">
                {newCampaignMapping.destinationAdGroupEntityType && (
                  <Typography variant="button" className="mt-2">
                    Negate from Source Campaign?
                  </Typography>
                )}
                {newCampaignMapping.destinationAdGroupEntityType == TargetEntityType.KEYWORD && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newCampaignMapping.campaignNegativeExact}
                          onChange={handleCheckboxValueChanged}
                          name="campaignNegativeExact"
                        />
                      }
                      label="Campaign Negative Exact"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newCampaignMapping.campaignNegativePhrase}
                          onChange={handleCheckboxValueChanged}
                          name="campaignNegativePhrase"
                        />
                      }
                      label="Campaign Negative Phrase"
                    />
                  </FormGroup>
                )}
                {newCampaignMapping.destinationAdGroupEntityType == TargetEntityType.PRODUCT_TARGET && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newCampaignMapping.campaignNegativeProductTarget}
                          onChange={handleCheckboxValueChanged}
                          name="campaignNegativeProductTarget"
                        />
                      }
                      label="Campaign Negative Product Target"
                    />
                  </FormGroup>
                )}
              </div>
            )}
          </div>
        )}
      </DialogContent>

      {!isEmpty(newCampaignMapping.validationErrors) && isAdGroupsSet && (
        <div className="flex flex-col mx-5">
          {Array.from(new Set(Object.values(newCampaignMapping.validationErrors).filter(Boolean))).map((error, index) => (
            <ErrorMessage key={index} text={error} />
          ))}
        </div>
      )}
      {Object.keys(newCampaignMapping.validationWarnings).length > 0 && (
        <div className="flex flex-col mx-5">
          {Object.entries(newCampaignMapping.validationWarnings).map(
            ([key, warning]) => warning && <InfoMessage key={key} content={warning} />,
          )}
        </div>
      )}

      <DialogActions>
        <div className="flex flex-col items-end">
          <div className="flex flex-row">
            <Button onClick={onClose} variant="text">
              Cancel
            </Button>
            <Tooltip
              title={
                Object.keys(newCampaignMapping.validationErrors).length > 0 ? (
                  <div className="flex flex-col mx-5">
                    {Array.from(new Set(Object.values(newCampaignMapping.validationErrors).filter(Boolean))).map((error, index) => (
                      <span key={index}>❗️ {error}</span>
                    ))}
                  </div>
                ) : undefined
              }
            >
              <span>
                <Button
                  variant="contained"
                  loading={isLoading}
                  onClick={onCreateClicked}
                  disabled={isLoading || Object.keys(newCampaignMapping.validationErrors).length > 0}
                  endIcon={<SendIcon />}
                >
                  Create New Mapping
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};
