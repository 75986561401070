import type { IAggFuncParams, IRowNode } from 'ag-grid-enterprise';
import { MetricField } from '../metrics/types/MetricField';

export type EnumToCount<E extends Record<string, string | number>, K extends keyof E = keyof E> = Partial<Record<K, number>>;

export type StringToCount = Record<string, number>;

export type BooleanCountsType = {
  trueCount: number;
  falseCount: number;
};

export function isStringToCount(obj: unknown): obj is StringToCount {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  const recordObj = obj as Record<string, unknown>;

  for (const key in recordObj) {
    if (!Object.prototype.hasOwnProperty.call(recordObj, key) || typeof recordObj[key] !== 'number') {
      return false;
    }

    // Check only first
    return true;
  }

  return true;
}

export const NONE_LABEL = '(None)';

export function countEnumValues<T extends Record<string, string | number>>(enumValues: T, states: T[keyof T][]): EnumToCount<T> {
  // Initialize counts for all enum values
  const initialState: EnumToCount<T> = Object.values(enumValues).reduce((acc, value) => {
    acc[value as keyof EnumToCount<T>] = 0;
    return acc;
  }, {} as EnumToCount<T>);

  // Count occurrences of each enum value
  return states.reduce((acc, state) => {
    if ((state as keyof EnumToCount<T>) in acc) {
      const key = state as keyof EnumToCount<T>;
      acc[key] = (acc[key] || 0) + 1;
    }
    return acc;
  }, initialState);
}

export function collectLeafNodeValues<T>(params: IAggFuncParams): T[] {
  const leafNodeValues: T[] = [];
  const field = params.colDef.field;

  // Function to recursively collect leaf node values
  function collectLeafNodeValues_recursive(rowNode: IRowNode) {
    if (rowNode.group && rowNode.childrenAfterGroup) {
      // Iterate over child nodes if it's a group node and children are not null
      rowNode.childrenAfterGroup.forEach((childNode) => collectLeafNodeValues_recursive(childNode));
    } else {
      // Collect the value for leaf nodes
      if (field && rowNode.data && rowNode.data[field] !== undefined) {
        leafNodeValues.push(rowNode.data[field]);
      }
    }
  }

  // Start collecting values from the current group node
  collectLeafNodeValues_recursive(params.rowNode);

  return leafNodeValues;
}

function safeDivide(numerator: number, denominator: number): number {
  return denominator === 0 ? 0 : numerator / denominator;
}

export function calculateMetricDataHelper(
  aggData: Record<string, number[]>,
  numeratorKey: MetricField,
  denominatorKey: MetricField,
  multiplier = 1,
): number[] {
  const numerator = aggData[numeratorKey] || [];
  const denominator = aggData[denominatorKey] || [];

  // Ensure we calculate for the length of the larger array
  const length = Math.max(numerator.length, denominator.length);

  return Array.from({ length }, (_, i) => safeDivide(numerator[i] || 0, denominator[i] || 0) * multiplier);
}
