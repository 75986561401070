import { IDashboardWidgetCatalogItem } from '../../../types/IDashboardWidgetCatalogItem';
import TextIcon from '@mui/icons-material/TextFields';
import { v4 } from 'uuid';
import { DashboardWidgetType } from '@/modules/dashboards/types/DashboardWidgetType';
import { TextWidgetConfiguration } from './TextWidgetConfiguration';

export const TEXT_WIDGET_CATALOG_ITEM: IDashboardWidgetCatalogItem<TextWidgetConfiguration> = {
  title: 'Text Widget',
  description: 'A blank widget for adding text to your dashboard',
  icon: <TextIcon />,
  id: v4(),
  type: DashboardWidgetType.TextWidget,
  configuration: new TextWidgetConfiguration(),
  layout: {
    h: 2,
    w: 4,
    i: v4(),
    x: 0,
    y: 0,
    maxH: 30,
    maxW: 60,
    minH: 1,
    minW: 1,
  },
};
