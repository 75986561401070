import { AlDate, DATE_FORMAT_YYYY_MM_DD } from '@/lib/date/AlDate';
import { CSSProperties } from 'react';

export interface DayStyleConfig {
  tooltip: string;
  style: CSSProperties;
}

interface DayStyleConfigWithDates extends DayStyleConfig {
  dates: Set<string>;
}

const DEFAULT_STYLE = {
  color: 'orange',
  fontWeight: 'bold',
};

// If selling partner is earlier than ads, mark all those dates in orange and say "ad data missing for period"
export const ADS_DATA_MISSING_CONFIG: DayStyleConfig = {
  tooltip: 'Ads data missing for period',
  style: DEFAULT_STYLE,
};

//If ads data is earlier than selling partner, mark all those dates in orange and say "vendor/seller data missing for period"
export const VENDOR_DATA_MISSING_CONFIG: DayStyleConfig = {
  tooltip: 'Vendor data missing for period',
  style: DEFAULT_STYLE,
};

export const SELLER_DATA_MISSING_CONFIG: DayStyleConfig = {
  tooltip: 'Seller data missing for period',
  style: DEFAULT_STYLE,
};

const now = AlDate.now();

// ads data might be incomplete for today. Make today "Orange" in the date picker and hover message: "Ads data is incomplete for 24h"
export const ADS_DATA_INCOMPLETE_CONFIG: DayStyleConfigWithDates = {
  dates: new Set([now.toFormat(DATE_FORMAT_YYYY_MM_DD)]),
  tooltip: 'Ads data is incomplete for 24h',
  style: DEFAULT_STYLE,
};

// If Vendor is connected, then make last 3 days "Orange" Hover: "Vendor data is incomplete for 72h"
export const VENDOR_DATA_INCOMPLETE_CONFIG: DayStyleConfigWithDates = {
  dates: new Set([
    now.subtractDays(2).toFormat(DATE_FORMAT_YYYY_MM_DD),
    now.subtractDays(1).toFormat(DATE_FORMAT_YYYY_MM_DD),
    now.toFormat(DATE_FORMAT_YYYY_MM_DD),
  ]),
  tooltip: 'Vendor data is incomplete for 72h',
  style: DEFAULT_STYLE,
};

// If Seller is connected. Then make last 2 days "Orange" in the date picker and hover message: "Seller data is incomplete for 48h"
export const SELLER_DATA_INCOMPLETE_CONFIG: DayStyleConfigWithDates = {
  dates: new Set([now.subtractDays(1).toFormat(DATE_FORMAT_YYYY_MM_DD), now.toFormat(DATE_FORMAT_YYYY_MM_DD)]),
  tooltip: 'Seller data is incomplete for 48h',
  style: DEFAULT_STYLE,
};
