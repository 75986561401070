import { getMetricConfigByFilterKey, UnitType } from '@/components/metrics/MetricsConfig';

import { AlDate } from '@/lib/date/AlDate';
import { AlFetchCache } from '@/modules/al-fetch-cache/AlFetchCache';
import { BiddingMethod } from '@/modules/campaign-mapping/api/campaign-mapping-contracts';
import { CampaignMappingModelWithValidation } from '@/modules/campaign-mapping/models/CampaignMappingModelWithValidation';
import { TeamProfile } from '@/modules/dashboards/types/TeamProfile';
import { dataGroupsService } from '@/modules/data-groups/api/data-groups-service';
import { DataGroupModel } from '@/modules/data-groups/models/DataGroup';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { NegativeKeywordMatchType, NegativeTargetingLevel } from '@/modules/negative-targets/api/negative-targets-contracts';
import {
  AmazonBrandsBidOptimization,
  BidStrategyType,
  CampaignAdType,
  CostType,
  CreativeType,
  EnabledPausedArchivedState,
  IdNameDTO,
  MultiAdGroupsEnabledType,
  PlacementType,
} from '@/modules/optimizer/api/campaign/campaign-contracts';
import { campaignService } from '@/modules/optimizer/api/campaign/campaign-service';
import { CampaignGroupModel, emptyUnAssignedCampaignGroup } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { ProductAvailability } from '@/modules/products/api/products-contracts';
import { productsService } from '@/modules/products/api/products-service';
import { ProfileWithMetricsModel } from '@/modules/profiles-stats/models/ProfileWithMetricsModel';
import { ProfileModel } from '@/modules/profiles/types/ProfileModel';
import { SearchTermHarvestedType, SearchTermNegatedType } from '@/modules/search-terms/api/search-terms-contracts';
import { KeywordHarvestingPreviewDataRow } from '@/modules/search-terms/components/keyword-harvesting/models/KeywordHarvestingPreviewDataRow';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { isArray, isNil, isNumber } from 'lodash-es';
import { MatchType, OptimizationReason } from '../../../modules/optimizer/components/optimization/api/optimization-contracts';
import { BiddingEntity, isPercentEntity } from '../../../modules/optimizer/components/optimization/models/OptimizationModel';
import { PreviewDataRow } from '../../../modules/optimizer/components/optimization/models/PreviewDataRow';
import { FilterConditionDTO, FilterDTO } from '../api/filters-contracts';
import { FilterKey, FilterType } from '../types/FilterKey';
import { StartEndDatePairWithComparison } from '../types/StartEndDatePair';
import { AlMultiSelectOptionModel, sortOptionsAlphabetically } from './AlMultiSelectOptionModel';
import { assertUnhandledCase } from '@/modules/application/utils';

export const FILTERS_VERSION = 6;
const DATE_PERIOD_LENGTH_DAYS = 14;
export const MIN_DATE_OFFSET_FROM_CREATION_DAYS = 59;

export enum BooleanType {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export enum OperatorType {
  EQUAL = '=',
  NOT_EQUAL = '!=',
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL = '<=',
  GREATER_THAN = '>',
  GREATER_THAN_OR_EQUAL = '>=',
  LIKE = 'LIKE',
  NOT_LIKE = 'NOT_LIKE',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  IS_NULL = 'IS_NULL',
}

export const OperatorDisplayMap: Record<OperatorType, string> = {
  [OperatorType.EQUAL]: '=',
  [OperatorType.NOT_EQUAL]: '≠',
  [OperatorType.LESS_THAN]: '<',
  [OperatorType.LESS_THAN_OR_EQUAL]: '≤',
  [OperatorType.GREATER_THAN]: '>',
  [OperatorType.GREATER_THAN_OR_EQUAL]: '≥',
  [OperatorType.LIKE]: 'CONTAINS',
  [OperatorType.NOT_LIKE]: "DOESN'T CONTAIN",
  [OperatorType.IN]: 'IN',
  [OperatorType.NOT_IN]: 'NOT IN',
  [OperatorType.IS_NULL]: 'NEVER',
};

export enum LogicalOperatorType {
  AND = 'AND',
  OR = 'OR',
}

const DEFAULT_CONDITION_OPERATORS: Record<FilterType, OperatorType[]> = {
  [FilterType.BETWEEN]: [OperatorType.GREATER_THAN_OR_EQUAL, OperatorType.LESS_THAN_OR_EQUAL],
  [FilterType.DATE_SELECT]: [OperatorType.GREATER_THAN_OR_EQUAL, OperatorType.LESS_THAN_OR_EQUAL],
  [FilterType.SELECT]: [OperatorType.EQUAL],
  [FilterType.MULTI_SELECT]: [OperatorType.IN],
  [FilterType.STRING_COMPARISON]: [OperatorType.LIKE],
  [FilterType.STRING_EXACT_MATCH]: [OperatorType.IN],
};

export abstract class AlFilterModel {
  static key: FilterKey;
  static shortName: string;
  static longName?: string;
  static type: FilterType;
  static unitType?: UnitType;
  fetchCache?: AlFetchCache;
  teamProfiles?: TeamProfile[];
  isOperatorDisabled?: boolean;
  logicalOperator?: LogicalOperatorType;
  conditions?: FilterCondition[];
  options?: AlMultiSelectOptionModel[];
  isFilterBuilderFilter = true;
  isInverseFilter = false;
  isProfileSpecific = false;
  isLoading: boolean = false;

  constructor(args?: FilterArguments) {
    if (args) {
      this.logicalOperator = args.logicalOperator;
      this.conditions = args.conditions;
    }

    if (!this.logicalOperator) {
      switch (this.type) {
        case FilterType.BETWEEN:
          this.logicalOperator = LogicalOperatorType.AND;
          break;
        case FilterType.STRING_COMPARISON:
          this.logicalOperator = this.isInverseFilter ? LogicalOperatorType.AND : LogicalOperatorType.OR;
          break;
        case FilterType.DATE_SELECT:
          this.logicalOperator = LogicalOperatorType.AND;
          break;
        case FilterType.SELECT:
        case FilterType.MULTI_SELECT:
        case FilterType.STRING_EXACT_MATCH:
          this.logicalOperator = undefined; // These filters don't use a logical operator
          break;
        default:
          assertUnhandledCase(this.type);
      }
    }
  }
  get key(): FilterKey {
    return (this.constructor as typeof AlFilterModel).key;
  }

  get shortName(): string {
    return (this.constructor as typeof AlFilterModel).shortName;
  }

  get longName(): string {
    return (this.constructor as typeof AlFilterModel).longName ?? (this.constructor as typeof AlFilterModel).shortName;
  }

  get type(): FilterType {
    return (this.constructor as typeof AlFilterModel).type;
  }

  get unitType(): UnitType | undefined {
    return (this.constructor as typeof AlFilterModel).unitType;
  }

  // get logicalOperator(): LogicalOperatorType | undefined {
  //   return this.type === FilterType.BETWEEN && isNil(this._logicalOperator) ? LogicalOperatorType.AND : this._logicalOperator;
  // }

  // set logicalOperator(value: LogicalOperatorType | undefined) {
  //   this._logicalOperator = value;
  // }

  get defaultConditionOperators(): OperatorType[] {
    if (this.type === FilterType.STRING_COMPARISON && this.isInverseFilter) {
      return [OperatorType.NOT_LIKE];
    }

    return DEFAULT_CONDITION_OPERATORS[this.type];
  }

  async removeStaleOptions(): Promise<void> {}
  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([]);
  }

  isValid(): boolean {
    // TODO: add operator check and others?
    return Object.values(FilterKey).includes(this.key);
  }

  getOptionsTranslationKey(): string {
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  doesFilterPass(
    _: PreviewDataRow | KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation | ProfileWithMetricsModel,
  ): boolean {
    return true;
  }

  reset(): void {
    this.logicalOperator = undefined;
    this.conditions = undefined;
  }

  doesBetweenFilterPass(currentValue: number): boolean {
    if (this.conditions && this.conditions.length > 0) {
      for (const condition of this.conditions) {
        const operator = condition.operator;
        const conditionValue = Number.parseFloat(condition.values[0] as string);

        switch (operator) {
          case OperatorType.LESS_THAN: {
            if (currentValue >= conditionValue) {
              return false;
            }
            break;
          }

          case OperatorType.LESS_THAN_OR_EQUAL: {
            if (currentValue > conditionValue) {
              return false;
            }
            break;
          }

          case OperatorType.EQUAL: {
            if (currentValue != conditionValue) {
              return false;
            }
            break;
          }

          case OperatorType.GREATER_THAN: {
            if (currentValue <= conditionValue) {
              return false;
            }
            break;
          }

          case OperatorType.GREATER_THAN_OR_EQUAL: {
            if (currentValue < conditionValue) {
              return false;
            }
            break;
          }
        }
      }
    }
    return true;
  }

  doesDateBetweenFilterPass(currentValue: string | null): boolean {
    if (this.conditions && this.conditions.length > 0) {
      for (const condition of this.conditions) {
        const operator = condition.operator;
        const conditionValue = AlDate.parse(condition.values[0].toString());
        const currentValueDate = currentValue ? AlDate.parse(currentValue) : undefined;

        if (operator === OperatorType.IS_NULL) {
          if (currentValueDate) {
            return false;
          }
        } else if (operator === OperatorType.LESS_THAN_OR_EQUAL) {
          if (!currentValueDate || !conditionValue.isValid() || currentValueDate.isAfter(conditionValue.endOf('day'))) {
            return false;
          }
        } else if (operator === OperatorType.GREATER_THAN_OR_EQUAL) {
          if (!currentValueDate || !conditionValue.isValid() || currentValueDate.isBefore(conditionValue.startOf('day'))) {
            return false;
          }
        } else if (operator === OperatorType.LESS_THAN) {
          if (!currentValueDate || !conditionValue.isValid() || currentValueDate.isAfter(conditionValue.startOf('day'))) {
            return false;
          }
        } else if (operator === OperatorType.GREATER_THAN) {
          if (!currentValueDate || !conditionValue.isValid() || currentValueDate.isBefore(conditionValue.endOf('day'))) {
            return false;
          }
        } else if (operator === OperatorType.EQUAL) {
          if (
            !currentValueDate ||
            !conditionValue.isValid() ||
            currentValueDate.isBefore(conditionValue.startOf('day')) ||
            currentValueDate.isAfter(conditionValue.endOf('day'))
          ) {
            return false;
          }
        }
      }
    }
    return true;
  }
  doesExactMatchFilterPass(currentValue: string): boolean {
    const currentValueLowerCase = currentValue.toLowerCase();

    if (this.conditions && this.conditions.length > 0) {
      for (const conditionValue of this.conditions[0].values.map((value) => value.toString().toLowerCase())) {
        if (currentValueLowerCase == conditionValue) {
          return true;
        }
      }
    }

    return false;
  }

  doesStringComparisonFilterPass(currentValue: string): boolean {
    const currentValueLowerCase = currentValue.toLowerCase();
    if (this.conditions && this.conditions.length > 0) {
      switch (this.logicalOperator) {
        case LogicalOperatorType.AND:
          // All conditions must be true
          for (const condition of this.conditions) {
            const operator = condition.operator;
            // Loop over all condition values
            for (const conditionValue of condition.values.map((value) => value.toString().toLowerCase())) {
              if (operator === OperatorType.LIKE) {
                if (!currentValueLowerCase.includes(conditionValue)) {
                  return false;
                }
              } else if (operator === OperatorType.NOT_LIKE) {
                if (currentValueLowerCase.includes(conditionValue)) {
                  return false;
                }
              }
            }
          }
          break;
        case LogicalOperatorType.OR: {
          // At least one condition must be true
          let isConditionTrue = false;
          for (const condition of this.conditions) {
            const operator = condition.operator;
            // Loop over all condition values
            for (const conditionValue of condition.values.map<string>((value) => value.toString().toLowerCase())) {
              if (operator === OperatorType.LIKE) {
                if (currentValueLowerCase.includes(conditionValue)) {
                  isConditionTrue = true;
                  break;
                }
              } else if (operator === OperatorType.NOT_LIKE) {
                if (!currentValueLowerCase.includes(conditionValue)) {
                  isConditionTrue = true;
                  break;
                }
              }
            }
          }
          return isConditionTrue;
        }
      }
    }
    return true;
  }

  mapConditionsToDTOs(): FilterConditionDTO[] {
    if (isNil(this.conditions) || this.conditions.length === 0) {
      return [];
    }

    return this.conditions.map((condition) => this.mapConditionToDTO(condition));
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((value) => {
        if (this.type == FilterType.BETWEEN && !isNumber(value)) {
          // If users has entered 1,9 instead of 1.9, convert it to 1.9
          return parseFloat(value.replace(',', '.')).toString();
        }
        return value.toString();
      }),
      operator: condition.operator,
    };
  }

  percentageValueToRatio(value: number | string): string {
    if (isNumber(value)) {
      return (value / 100).toString();
    }
    // If users has entered 1,9 instead of 1.9, convert it to 1.9
    return (parseFloat(value.replace(',', '.')) / 100).toString();
  }

  toDTO(): FilterDTO {
    return {
      key: this.key,
      logical_operator: this.logicalOperator?.toString(),
      conditions: this.mapConditionsToDTOs(),
    };
  }

  toQueryKey(): string {
    return this.key + JSON.stringify([this.conditions, this.logicalOperator]);
  }
}

class BaseDateFilterModel extends AlFilterModel {
  static type = FilterType.DATE_SELECT;

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((value) => {
        if (condition.operator === OperatorType.IS_NULL) {
          return '';
        } else {
          const parsedDate = AlDate.parse(value.toString());
          return parsedDate.isValid() ? parsedDate.toDefaultFormat() : '';
        }
      }),
      operator: condition.operator,
    };
  }

  isValid(): boolean {
    const parentIsValid = super.isValid();
    if (!parentIsValid) return false;

    // If never is selected, we don't care about the other conditions
    const isNeverSelected = this.conditions?.[0]?.operator === OperatorType.IS_NULL;
    if (isNeverSelected) {
      return true;
    }

    // FIRST CONDITION
    // Check if has value
    const firstCondition = this.conditions?.[0];
    if (!firstCondition || !firstCondition.values || firstCondition.values.length === 0) {
      return false;
    }

    // Check if first condition is a valid date
    const firstValue = firstCondition.values[0] as string;
    const firstParsedDate = AlDate.fromISO(firstValue);
    if (!firstParsedDate || !firstParsedDate.isValid()) {
      return false;
    }

    // SECOND CONDITION
    const secondCondition = this.conditions?.[1];
    // Second condition is optional
    if (!secondCondition) {
      return true;
    }

    // Check if second condition has value
    if (!secondCondition.values || secondCondition.values.length === 0) {
      return false;
    }

    // Check if second condition is a valid date
    const secondValue = secondCondition.values[0] as string;
    const secondParsedDate = AlDate.fromISO(secondValue);
    if (!secondParsedDate || !secondParsedDate.isValid()) {
      return false;
    }

    return true;
  }
}

abstract class BaseMetricFilterModel extends AlFilterModel {
  protected static initializeBase(filterKey: FilterKey) {
    const config = getMetricConfigByFilterKey(filterKey);
    if (!config) {
      console.error(`Unknown metric filterKey: ${filterKey}`);
      return;
    }
    this.shortName = config.title;
    this.unitType = config.unitType;
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    if (this.unitType != UnitType.PERCENTAGE) {
      return {
        values: condition.values.map((value) => (!isNumber(value) ? parseFloat(value.replace(',', '.')) : value).toString()),
        operator: condition.operator,
      };
    }

    return {
      values: condition.values.map((v) => this.percentageValueToRatio(v)),
      operator: condition.operator,
    };
  }
}

export interface FilterArguments {
  conditions: FilterCondition[];
  logicalOperator?: LogicalOperatorType;
}

export interface FilterCondition {
  values: string[] | number[];
  operator: OperatorType;
}

export class EmptyFilterModel extends AlFilterModel {
  static key = '' as FilterKey;
}

export class AcosFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.ACOS;
  static longName = 'ACOS (Ad Cost of Sales)';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.acos) ? value.acos[0] : value.acos;
    return this.doesBetweenFilterPass(valueToCheck * 100);
  }
}

export class ActcFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.ACTC;
  static longName = 'aCTC';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.actc) ? value.actc[0] : value.actc;
    return this.doesBetweenFilterPass(valueToCheck * 100);
  }
}

export class AdGroupFilterModel extends AlFilterModel {
  static key = FilterKey.AD_GROUP_ID;
  static shortName = 'Ad Group(s)';
  static longName = 'Select Ad Groups';
  static type = FilterType.MULTI_SELECT;
  fetchCache: AlFetchCache;
  isProfileSpecific = true;

  constructor(adGroups: IdNameDTO[], fetchCache: AlFetchCache, args?: FilterArguments) {
    super(args);

    this.fetchCache = fetchCache;
    this.options = adGroups.map((adGroup) => {
      return new AlMultiSelectOptionModel(adGroup.name, adGroup.id);
    });

    this.removeStaleOptions();
  }

  async removeStaleOptions() {
    if (isNil(this.conditions) || isNil(this.conditions[0].values) || this.conditions[0].values.length == 0) {
      return;
    }

    await this.getOptions(); // Load options
    const availableOptionIDs = this.options?.map((o) => o.id.toString()); // Available Option IDs (string) from API

    const currentConditionValues = this.conditions[0].values.map((v) => v as string);
    this.conditions[0].values = currentConditionValues.filter((id) => availableOptionIDs?.includes(id));
  }

  async getOptions() {
    if (!this.fetchCache) return [];

    this.isLoading = true;
    const { res, cacheHit } = await this.fetchCache.fetchWithFunction({ fetchFunction: campaignService.getAdGroups });
    this.isLoading = false;
    if (cacheHit && !isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    // If existing filters are loaded via factory we need to load options
    if (res.isSuccess) {
      const options = res.payload.map((adGroup) => {
        return new AlMultiSelectOptionModel(adGroup.name, adGroup.id);
      });

      this.options = options.sort((a, b) => a.name.localeCompare(b.name));
      return this.options;
    }

    return [];
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as string).includes(value.adGroup);
  }
}

export class AdGroupNameFilterModel extends AlFilterModel {
  static key = FilterKey.AD_GROUP_NAME;
  static shortName = 'Ad Group Name (contains)';
  static type = FilterType.STRING_COMPARISON;
  isProfileSpecific = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.adGroup);
  }
}

export class SourceAdGroupNameFilterModel extends AlFilterModel {
  static key = FilterKey.SOURCE_AD_GROUP_NAME;
  static shortName = 'Source Ad Group Name (contains)';
  static type = FilterType.STRING_COMPARISON;
  isProfileSpecific = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.sourceAdGroupName ?? '');
  }
}

export class DestinationAdGroupNameFilterModel extends AlFilterModel {
  static key = FilterKey.DESTINATION_AD_GROUP_NAME;
  static shortName = 'Destination Ad Group Name (contains)';
  static type = FilterType.STRING_COMPARISON;
  isProfileSpecific = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.destinationAdGroupName ?? '');
  }
}

export class AdGroupNameNotFilterModel extends AlFilterModel {
  static key = FilterKey.AD_GROUP_NAME_NOT;
  static shortName = "Ad Group Name (doesn't contain)";
  static longName = "Ad Group Name (doesn't contain)";
  static type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;
  isProfileSpecific = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.adGroup);
  }
}

export class AdGroupStateFilterModel extends AlFilterModel {
  static key = FilterKey.AD_GROUP_STATE;
  static shortName = 'Ad Group State';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Paused', EnabledPausedArchivedState.PAUSED),
      new AlMultiSelectOptionModel('Enabled', EnabledPausedArchivedState.ENABLED),
      new AlMultiSelectOptionModel('Archived', EnabledPausedArchivedState.ARCHIVED),
    ]);
  }
}

export class SourceAdGroupNameNotFilterModel extends AlFilterModel {
  static key = FilterKey.SOURCE_AD_GROUP_NAME_NOT;
  static shortName = "Source Ad Group Name (doesn't contain)";
  static longName = "Source Ad Group Name (doesn't contain)";
  static type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;
  isProfileSpecific = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.sourceAdGroupName ?? '');
  }
}

export class DestinationAdGroupNameNotFilterModel extends AlFilterModel {
  static key = FilterKey.DESTINATION_AD_GROUP_NAME_NOT;
  static shortName = "Destination Ad Group Name (doesn't contain)";
  static longName = "Destination Ad Group Name (doesn't contain)";
  static type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;
  isProfileSpecific = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.destinationAdGroupName ?? '');
  }
}

export class SourceCampaignAdTypeFilterModel extends AlFilterModel {
  static key = FilterKey.SOURCE_CAMPAIGN_AD_TYPE;
  static shortName = 'Source Ad Type';
  static longName = 'Source Ad Type';
  static type = FilterType.MULTI_SELECT;
  isProfileSpecific = true;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    const options = [
      new AlMultiSelectOptionModel('Sponsored Products', CampaignAdType.PRODUCTS),
      new AlMultiSelectOptionModel('Sponsored Brands', CampaignAdType.BRANDS),
      new AlMultiSelectOptionModel('Sponsored Display', CampaignAdType.DISPLAY),
    ];

    return Promise.resolve(options);
  }
  doesFilterPass(row: KeywordHarvestingPreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }
    return this.conditions[0].values.map((v) => v as CampaignAdType).includes(row.sourceCampaignAdType);
  }
}

export class DestinationCampaignAdTypeFilterModel extends AlFilterModel {
  static key = FilterKey.DESTINATION_CAMPAIGN_AD_TYPE;
  static shortName = 'Destination Ad Type';
  static longName = 'Destination Ad Type';
  static type = FilterType.MULTI_SELECT;
  isProfileSpecific = true;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    const options = [
      new AlMultiSelectOptionModel('Sponsored Products', CampaignAdType.PRODUCTS),
      new AlMultiSelectOptionModel('Sponsored Brands', CampaignAdType.BRANDS),
      new AlMultiSelectOptionModel('Sponsored Display', CampaignAdType.DISPLAY),
    ];

    return Promise.resolve(options);
  }
  doesFilterPass(row: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    if (!row.destinationCampaignAdType || !Object.values(CampaignAdType).includes(row.destinationCampaignAdType as CampaignAdType)) {
      return false;
    }

    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }
    return this.conditions[0].values.map((v) => v as CampaignAdType).includes(row.destinationCampaignAdType as CampaignAdType);
  }
}

export class AovFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.AOV;
  static longName = 'AOV (Average Order Value)';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.aov) ? value.aov[0] : value.aov;
    return this.doesBetweenFilterPass(valueToCheck);
  }
}

export class BudgetFilterModel extends AlFilterModel {
  static key = FilterKey.BUDGET;
  static shortName = 'Budget';
  static type = FilterType.BETWEEN;
  static unitType = UnitType.CURRENCY;
}

interface CampaignFilterParams {
  campaigns?: IdNameDTO[];
  args?: FilterArguments;
  teamProfiles?: TeamProfile[];
  fetchCache: AlFetchCache;
}

export class CampaignFilterModel extends AlFilterModel {
  static key = FilterKey.CAMPAIGN_ID;
  static shortName = 'Select Campaign';
  static longName = 'Select Campaigns';
  static type = FilterType.MULTI_SELECT;
  _teamProfiles: TeamProfile[] = [];
  isProfileSpecific = true;

  constructor({ campaigns, fetchCache, args, teamProfiles }: CampaignFilterParams) {
    super(args);

    this.fetchCache = fetchCache;

    this.options = campaigns?.map((campaign) => {
      return new AlMultiSelectOptionModel(campaign.name, campaign.id);
    });

    this._teamProfiles = teamProfiles ?? [];

    this.removeStaleOptions();
  }

  async getOptions() {
    if (!this.fetchCache) return [];

    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    // go over team profiles and get campaigns
    if (this._teamProfiles.length > 0) {
      const options: AlMultiSelectOptionModel[] = [];
      for (const teamProfile of this._teamProfiles) {
        this.isLoading = true;
        const { res, cacheHit } = await this.fetchCache.fetchWithFunction({
          fetchFunction: campaignService.getNonArchivedCampaignIdsByTeamAndProfile,
          args: [teamProfile.teamId, teamProfile.profileId],
        });
        this.isLoading = false;

        if (cacheHit && !isNil(this.options) && this.options.length > 0) {
          return this.options;
        }

        if (res.isSuccess) {
          options.push(
            ...res.payload.map((campaign) => {
              return new AlMultiSelectOptionModel(campaign.name, campaign.id);
            }),
          );
        }
      }

      this.options = options.sort((a, b) => a.name.localeCompare(b.name));
      return this.options;
    }
    this.isLoading = true;
    const { res, cacheHit } = await this.fetchCache.fetchWithFunction({ fetchFunction: campaignService.getNonArchivedCampaignIds });
    this.isLoading = false;

    if (cacheHit && !isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    if (res.isSuccess) {
      const options = res.payload.map((campaign) => {
        return new AlMultiSelectOptionModel(campaign.name, campaign.id);
      });

      this.options = options.sort((a, b) => a.name.localeCompare(b.name));
      return this.options;
    }

    return [];
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as string).includes(value.campaignId);
  }
}

export class CampaignNameFilterModel extends AlFilterModel {
  static key = FilterKey.CAMPAIGN_NAME;
  static shortName = 'Campaign Name (contains)';
  static longName = 'Campaign Name (contains)';
  static type = FilterType.STRING_COMPARISON;
  isProfileSpecific = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.campaignName);
  }
}

export class SourceCampaignNameFilterModel extends AlFilterModel {
  static key = FilterKey.SOURCE_CAMPAIGN_NAME;
  static shortName = 'Source Campaign Name (contains)';
  static longName = 'Source Campaign Name (contains)';
  static type = FilterType.STRING_COMPARISON;
  isProfileSpecific = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.sourceCampaignName ?? '');
  }
}

export class DestinationCampaignNameFilterModel extends AlFilterModel {
  static key = FilterKey.DESTINATION_CAMPAIGN_NAME;
  static shortName = 'Destination Campaign Name (contains)';
  static longName = 'Destination Campaign Name (contains)';
  static type = FilterType.STRING_COMPARISON;
  isProfileSpecific = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.destinationCampaignName ?? '');
  }
}

export class CampaignNameNotFilterModel extends AlFilterModel {
  static key = FilterKey.CAMPAIGN_NAME_NOT;
  static shortName = "Campaign Name (doesn't contain)";
  static longName = "Campaign Name (doesn't contain)";
  static type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;
  isProfileSpecific = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.campaignName);
  }
}

export class SourceCampaignNameNotFilterModel extends AlFilterModel {
  static key = FilterKey.SOURCE_CAMPAIGN_NAME_NOT;
  static shortName = "Source Campaign Name (doesn't contain)";
  static longName = "Source Campaign Name (doesn't contain)";
  static type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;
  isProfileSpecific = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.sourceCampaignName ?? '');
  }
}

export class DestinationCampaignNameNotFilterModel extends AlFilterModel {
  static key = FilterKey.DESTINATION_CAMPAIGN_NAME_NOT;
  static shortName = "Destination Campaign Name (doesn't contain)";
  static longName = "Destination Campaign Name (doesn't contain)";
  static type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;
  isProfileSpecific = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    return this.doesStringComparisonFilterPass(value.destinationCampaignName ?? '');
  }
}

export class CampaignGroupNameFilterModel extends AlFilterModel {
  static key = FilterKey.CAMPAIGN_GROUP_NAME;
  static shortName = 'Opt Group Name (contains)';
  static longName = 'Opt Group Name (contains)';
  static type = FilterType.STRING_COMPARISON;
  isProfileSpecific = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.campaignGroup);
  }
}

export class CampaignGroupNameNotFilterModel extends AlFilterModel {
  static key = FilterKey.CAMPAIGN_GROUP_NAME_NOT;
  static shortName = "Opt Group Name (doesn't contain)";
  static longName = "Opt Group Name (doesn't contain)";
  static type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;
  isProfileSpecific = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.campaignGroup);
  }
}

export class CampaignGroupFilterModel extends AlFilterModel {
  static key = FilterKey.CAMPAIGN_GROUP;
  static shortName = 'Opt Group(s)';
  static longName = 'Optimization Groups';
  static type = FilterType.MULTI_SELECT;

  constructor(campaignGroups: CampaignGroupModel[], args?: FilterArguments) {
    super(args);
    this.options = campaignGroups
      .map((campaignGroup) => {
        return new AlMultiSelectOptionModel(campaignGroup.name, campaignGroup.id);
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    this.removeStaleOptions();
  }

  async removeStaleOptions() {
    if (isNil(this.conditions) || isNil(this.conditions[0].values) || this.conditions[0].values.length == 0) {
      return;
    }

    await this.getOptions(); // Load options
    const availableOptionIDs = this.options?.map((o) => o.id); // Available Options IDs (numbers) from API

    const currentConditionValues = this.conditions[0].values.map((v) => parseInt(v as string));
    this.conditions[0].values = currentConditionValues.filter((id) => availableOptionIDs?.includes(id));
  }

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }
    this.isLoading = true;
    const res = await campaignService.getGroups();
    this.isLoading = false;
    if (res.isSuccess) {
      res.payload.unshift(emptyUnAssignedCampaignGroup);
      const options = res.payload
        .map((campaign) => {
          return new AlMultiSelectOptionModel(campaign.name, campaign.id);
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      this.options = options;
      return options;
    }

    return [];
  }

  getGroupNames(): Array<string> {
    // Can't be set in constructor because conditions change
    if (!this.options || !this.conditions || this.conditions.length === 0) {
      return [];
    }

    const selectedOptionIds = new Set(this.conditions[0].values.map(String));
    const groupNames = this.options.filter((option) => selectedOptionIds.has(String(option.id))).map((option) => option.name);

    return groupNames;
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    const groupNames = this.getGroupNames();
    // Only have names to compare with
    if (groupNames.includes(emptyUnAssignedCampaignGroup.name) && value.campaignGroup == '') {
      return true;
    }

    return groupNames.includes(value.campaignGroup);
  }
}

export class CampaignStateFilterModel extends AlFilterModel {
  static key = FilterKey.CAMPAIGN_STATE;
  static shortName = 'Campaign State';
  static longName = 'Campaign State';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Paused', EnabledPausedArchivedState.PAUSED),
      new AlMultiSelectOptionModel('Enabled', EnabledPausedArchivedState.ENABLED),
      new AlMultiSelectOptionModel('Archived', EnabledPausedArchivedState.ARCHIVED),
    ]);
  }
}

export class CampaignAdTypeFilterModel extends AlFilterModel {
  static key = FilterKey.CAMPAIGN_AD_TYPE;
  static shortName = 'Ad Type';
  static longName = 'Campaign Ad Type';
  static type = FilterType.MULTI_SELECT;
  excludeDisplayAndTV: boolean | undefined;

  constructor(excludeDisplayAndTV?: boolean, args?: FilterArguments) {
    super(args);
    this.excludeDisplayAndTV = excludeDisplayAndTV;
  }

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    const options = [
      new AlMultiSelectOptionModel('Sponsored Products', CampaignAdType.PRODUCTS),
      new AlMultiSelectOptionModel('Sponsored Brands', CampaignAdType.BRANDS),
    ];

    if (!this.excludeDisplayAndTV) {
      options.push(new AlMultiSelectOptionModel('Sponsored Display', CampaignAdType.DISPLAY));
      options.push(new AlMultiSelectOptionModel('Sponsored TV', CampaignAdType.TV));
    }

    return Promise.resolve(options);
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as CampaignAdType).includes(value.campaignAdType);
  }
}

export class CreativeTypeFilterModel extends AlFilterModel {
  static key = FilterKey.CREATIVE_TYPE;
  static shortName = 'Creative Type';
  static longName = 'Creative Type';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Product Collection', CreativeType.PRODUCT_COLLECTION),
      new AlMultiSelectOptionModel('Store Spotlight', CreativeType.STORE_SPOTLIGHT),
      new AlMultiSelectOptionModel('Video', CreativeType.VIDEO),
      new AlMultiSelectOptionModel('Brand Video', CreativeType.BRAND_VIDEO),
    ]);
  }
}

export class CostTypeFilterModel extends AlFilterModel {
  static key = FilterKey.COST_TYPE;
  static shortName = 'Cost Type';
  static longName = 'Cost Type';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('CPC', CostType.CPC),
      new AlMultiSelectOptionModel('VCPM', CostType.VCPM),
      new AlMultiSelectOptionModel('CPM', CostType.CPM),
    ]);
  }
}

export class PlacementTypeFilterModel extends AlFilterModel {
  static key = FilterKey.PLACEMENT_TYPE;
  static shortName = 'Placement Type';
  static longName = 'Placement Type';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Top of Search', PlacementType.PLACEMENT_TOP),
      new AlMultiSelectOptionModel('Rest of Search', PlacementType.PLACEMENT_REST_OF_SEARCH), // Includes OTHER
      new AlMultiSelectOptionModel('Product Pages', PlacementType.PLACEMENT_PRODUCT_PAGE), // Includes DETAIL_PAGE
    ]);
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    const conditionValues: string[] = [];
    const placementTypes = new Set();
    condition.values.forEach((value) => {
      conditionValues.push(value.toString());
      placementTypes.add(value);

      if (value == PlacementType.PLACEMENT_REST_OF_SEARCH && !placementTypes.has(PlacementType.OTHER)) {
        conditionValues.push(PlacementType.OTHER);
      }

      if (value == PlacementType.PLACEMENT_PRODUCT_PAGE && !placementTypes.has(PlacementType.DETAIL_PAGE)) {
        conditionValues.push(PlacementType.DETAIL_PAGE);
      }
    });

    return {
      values: conditionValues,
      operator: condition.operator,
    };
  }
}

export class BidAdjustmentTypeFilterModel extends AlFilterModel {
  static key = FilterKey.BID_ADJUSTMENT;
  static shortName = 'Bid Adjustment';
  static longName = 'Bid Adjustment';
  static type = FilterType.BETWEEN;
  static unitType = UnitType.PERCENTAGE;

  // No need to use percentageValueToRatio() as percentages are stored as full numbers not fractions
}

export class BidAdjustmentLastOptimizedAtFilterModel extends BaseDateFilterModel {
  static key = FilterKey.BID_ADJUSTMENT_LAST_OPTIMIZED_AT;
  static shortName = 'Last Optimized';
  isOperatorDisabled = false;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesDateBetweenFilterPass(value.lastOptimizedAt);
  }
}

export class BidOptimizationFilterModel extends AlFilterModel {
  static key = FilterKey.BID_OPTIMIZATION;
  static shortName = 'SB Bid Optimization';
  static longName = 'SB Bid Optimization';
  static type = FilterType.SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return await Promise.resolve([
      new AlMultiSelectOptionModel('Auto', AmazonBrandsBidOptimization.AUTO),
      new AlMultiSelectOptionModel('Manual', AmazonBrandsBidOptimization.MANUAL),
    ]);
  }

  getOptionsTranslationKey(): string {
    return 'enums.bid_optimization';
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values[0] === AmazonBrandsBidOptimization.AUTO ? [BooleanType.TRUE] : [BooleanType.FALSE],
      operator: condition.operator,
    };
  }
}

export class BidStrategyFilterModel extends AlFilterModel {
  static key = FilterKey.BID_STRATEGY;
  static shortName = 'Bid Strategy';
  static longName = 'Bid Strategy';
  static type = FilterType.MULTI_SELECT;

  // TODO: Use translation optimizer_page.bid_strategy
  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Dynamic Bidding (Down Only)', BidStrategyType.LEGACY_FOR_SALES),
      new AlMultiSelectOptionModel('Dynamic Bidding (Up & Down)', BidStrategyType.AUTO_FOR_SALES),
      new AlMultiSelectOptionModel('Fixed Bids', BidStrategyType.MANUAL),
      new AlMultiSelectOptionModel('Rule Based bidding', BidStrategyType.RULE_BASED),
      new AlMultiSelectOptionModel('None', BidStrategyType.NONE),
    ]);
  }
}

export class CampaignStartDateFilterModel extends BaseDateFilterModel {
  static key = FilterKey.CAMPAIGN_START_DATE;
  static shortName = 'Start Date';
  static longName = 'Campaign Start Date';
}

export class CampaignEndDateFilterModel extends BaseDateFilterModel {
  static key = FilterKey.CAMPAIGN_END_DATE;
  static shortName = 'End Date';
  static longName = 'Campaign End Date';
}

export class ClicksFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.CLICKS;
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.clicks) ? value.clicks[0] : value.clicks;
    return this.doesBetweenFilterPass(valueToCheck);
  }
}

export class ComparisonDateFilterModel extends BaseDateFilterModel {
  static key = FilterKey.COMPARE_DATE;
  static shortName = 'Comparison Date';
  isOperatorDisabled = true;
  isFilterBuilderFilter = false;
}

export class CpaFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.CPA;
  static longName = 'CPA (Cost Per Acquisition)';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.cpa) ? value.cpa[0] : value.cpa;
    return this.doesBetweenFilterPass(valueToCheck);
  }
}

export class CpcFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.CPC;
  static longName = 'CPC (Cost Per Click)';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.cpc) ? value.cpc[0] : value.cpc;
    return this.doesBetweenFilterPass(valueToCheck);
  }
}
export class CpmFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.CPM;
  static longName = 'CPM (Cost Per Thousand Impressions)';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.cpm) ? value.cpm[0] : value.cpm;
    return this.doesBetweenFilterPass(valueToCheck);
  }
}

export class CtrFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.CTR;
  static longName = 'CTR (Click-Through Rate)';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.ctr) ? value.ctr[0] : value.ctr;
    return this.doesBetweenFilterPass(valueToCheck);
  }
}

export class CvrFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.CVR;
  static longName = 'CVR (Conversion Rate)';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.cvr) ? value.cvr[0] : value.cvr;
    return this.doesBetweenFilterPass(valueToCheck * 100);
  }
}

export class DateFilterModel extends BaseDateFilterModel {
  static key = FilterKey.DATE;
  static shortName = 'Date';
  isOperatorDisabled = true;
  isFilterBuilderFilter = false;
}

export class CampaignLastOptimizedAtFilterModel extends BaseDateFilterModel {
  static key = FilterKey.CAMPAIGN_LAST_OPTIMIZED_AT;
  static shortName = 'Campaign Last Optimized';
  isOperatorDisabled = false;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesDateBetweenFilterPass(value.campaignLastOptimizedAt);
  }
}

// Used in optimizer preview table
class LastOptimizedAtFilterModel extends BaseDateFilterModel {
  static key = FilterKey.LAST_OPTIMIZED_AT;
  static shortName = 'Last Optimized';
  isOperatorDisabled = false;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesDateBetweenFilterPass(value.lastOptimizedAt);
  }
}

class OldValueFilterModel extends AlFilterModel {
  static key = FilterKey.OLD_VALUE;
  static shortName = 'Current Value';
  static type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(isPercentEntity(value.biddingEntity) ? value.oldValue * 100 : value.oldValue);
  }
}

class NewValueFilterModel extends AlFilterModel {
  static key = FilterKey.NEW_VALUE;
  static shortName = 'New Value';
  static type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(isPercentEntity(value.biddingEntity) ? value.newValue * 100 : value.newValue);
  }
}

export class DeltaFilterModel extends AlFilterModel {
  static key = FilterKey.DELTA;
  static shortName = 'Delta';
  static type = FilterType.BETWEEN;
  static unitType = UnitType.PERCENTAGE;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.delta * 100);
  }
}

export class EntityTypeFilterModel extends AlFilterModel {
  static key = FilterKey.ENTITY_TYPE;
  static shortName = 'Entities';
  static longName = 'Bidding Entity';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    const SP_CATEGORY = 'SP Placements';
    const SB_CATEGORY = 'SB Placements';
    const TARGETING_CATEGORY = 'Targeting';

    return Promise.resolve([
      new AlMultiSelectOptionModel('Keyword', BiddingEntity.KEYWORD, TARGETING_CATEGORY),
      new AlMultiSelectOptionModel('Product Target & Audience', BiddingEntity.PRODUCT_TARGET, TARGETING_CATEGORY),

      new AlMultiSelectOptionModel('Top of Search', BiddingEntity.PLACEMENT_TOP, SP_CATEGORY),
      new AlMultiSelectOptionModel('Rest of Search', BiddingEntity.PLACEMENT_REST_OF_SEARCH, SP_CATEGORY),
      new AlMultiSelectOptionModel('Product Pages', BiddingEntity.PLACEMENT_PRODUCT_PAGE, SP_CATEGORY),

      new AlMultiSelectOptionModel('Top of Search', BiddingEntity.BRANDS_PLACEMENT_TOP, SB_CATEGORY),
      new AlMultiSelectOptionModel('Rest of Search', BiddingEntity.OTHER, SB_CATEGORY),
      new AlMultiSelectOptionModel('Product Pages', BiddingEntity.DETAIL_PAGE, SB_CATEGORY),
      new AlMultiSelectOptionModel('Home', BiddingEntity.HOME, SB_CATEGORY),
    ]);
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    const biddingEntities = this.conditions[0].values.map((v) => v as BiddingEntity);
    return biddingEntities.includes(value.biddingEntity);
  }
}

export class ImpressionsFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.IMPRESSIONS;
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.impressions) ? value.impressions[0] : value.impressions;
    return this.doesBetweenFilterPass(valueToCheck);
  }
}

export class MatchTypeFilterModel extends AlFilterModel {
  static key = FilterKey.MATCH_TYPE;
  static shortName = 'Match Type';
  static type = FilterType.MULTI_SELECT;
  includedTypes: MatchType[] = [];

  constructor(includedTypes?: MatchType[], args?: FilterArguments) {
    super(args);
    this.includedTypes = includedTypes ?? [];
  }

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    type categoryType = 'Keywords' | 'Product Targets';
    const KW_CATEGORY: categoryType = 'Keywords';
    const PT_CATEGORY: categoryType = 'Product Targets';

    const typeEnumToLabel: Record<MatchType, string> = {
      [MatchType.NONE]: '',
      [MatchType.EXACT]: 'Exact',
      [MatchType.PHRASE]: 'Phrase',
      [MatchType.BROAD]: 'Broad',
      [MatchType.THEME]: 'Theme',
      [MatchType.AUTO]: 'Auto',
      [MatchType.INDIVIDUAL]: 'Individual',
      [MatchType.EXPANDED]: 'Expanded',
      [MatchType.CATEGORY]: 'Category',
    };

    const typeEnumToCategory: Record<MatchType, categoryType> = {
      [MatchType.NONE]: KW_CATEGORY,
      [MatchType.EXACT]: KW_CATEGORY,
      [MatchType.PHRASE]: KW_CATEGORY,
      [MatchType.BROAD]: KW_CATEGORY,
      [MatchType.THEME]: KW_CATEGORY,
      [MatchType.AUTO]: PT_CATEGORY,
      [MatchType.INDIVIDUAL]: PT_CATEGORY,
      [MatchType.EXPANDED]: PT_CATEGORY,
      [MatchType.CATEGORY]: PT_CATEGORY,
    };

    let typesToUse = this.includedTypes;
    // If none given, include all
    if (this.includedTypes.length == 0) {
      // Default
      typesToUse = [
        MatchType.EXACT,
        MatchType.PHRASE,
        MatchType.BROAD,
        MatchType.THEME,
        MatchType.AUTO,
        MatchType.INDIVIDUAL,
        MatchType.EXPANDED,
        MatchType.CATEGORY,
      ];
    }

    const options = [];
    for (const t of typesToUse) {
      options.push(new AlMultiSelectOptionModel(typeEnumToLabel[t], t, typeEnumToCategory[t]));
    }

    return Promise.resolve(options);
  }

  doesFilterPass(row: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as MatchType).includes(row.match);
  }
}

export class NegativeKeywordMatchTypeFilterModel extends AlFilterModel {
  static key = FilterKey.NEGATIVE_KEYWORD_MATCH_TYPE;
  static shortName = 'Neg. keyword Match Type';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Negative exact', NegativeKeywordMatchType.NEGATIVE_EXACT),
      new AlMultiSelectOptionModel('Negative phrase', NegativeKeywordMatchType.NEGATIVE_PHRASE),
    ]);
  }
}

export class NegativeTargetLevelFilterModel extends AlFilterModel {
  static key = FilterKey.NEGATIVE_TARGETING_LEVEL;
  static shortName = 'Neg. Targeting Level';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Campaign', NegativeTargetingLevel.CAMPAIGN),
      new AlMultiSelectOptionModel('Ad Group', NegativeTargetingLevel.AD_GROUP),
    ]);
  }
}

export class BiddingMethodFilterModel extends AlFilterModel {
  static key = FilterKey.BIDDING_METHOD;
  static shortName = 'Bidding Method';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('AdLabs', BiddingMethod.ADLABS),
      new AlMultiSelectOptionModel('CPC plus', BiddingMethod.CPC_PLUS),
      new AlMultiSelectOptionModel('CPC minus', BiddingMethod.CPC_MINUS),
      new AlMultiSelectOptionModel('CPC times', BiddingMethod.CPC_TIMES),
      new AlMultiSelectOptionModel('Custom', BiddingMethod.CUSTOM),
    ]);
  }

  doesFilterPass(row: KeywordHarvestingPreviewDataRow | CampaignMappingModelWithValidation): boolean {
    if (!row.biddingMethod || !Object.values(BiddingMethod).includes(row.biddingMethod as BiddingMethod)) {
      return false;
    }

    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as BiddingMethod).includes(row.biddingMethod as BiddingMethod);
  }
}

export class OrdersFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.ORDERS;
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.orders) ? value.orders[0] : value.orders;
    return this.doesBetweenFilterPass(valueToCheck);
  }
}

export class UnitsFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.UNITS;
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.units) ? value.units[0] : value.units;
    return this.doesBetweenFilterPass(valueToCheck);
  }
}

interface PortfolioFilterParams {
  portfolios?: IdNameDTO[];
  args?: FilterArguments;
}

export class PortfolioFilterModel extends AlFilterModel {
  static key = FilterKey.PORTFOLIO_ID;
  static shortName = 'Portfolio(s)';
  static longName = 'Select Portfolios';
  static type = FilterType.MULTI_SELECT;
  isProfileSpecific = true;

  constructor({ portfolios, args }: PortfolioFilterParams = {}) {
    super(args);

    this.options = portfolios?.map((portfolio) => {
      return new AlMultiSelectOptionModel(portfolio.name, portfolio.id);
    });

    this.removeStaleOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    this.isLoading = true;
    const res = await campaignService.getNonArchivedPortfolioIds();
    this.isLoading = false;
    if (res.isSuccess) {
      const options = res.payload.map((portfolio) => {
        return new AlMultiSelectOptionModel(portfolio.name, portfolio.id);
      });

      this.options = options.sort((a, b) => a.name.localeCompare(b.name));
      return this.options;
    }

    return [];
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) return true;
    if (isNil(value.portfolioId)) return false;

    return this.conditions[0].values.map((v) => v as string).includes(value.portfolioId);
  }
}

export class OptimizationReasonFilterModel extends AlFilterModel {
  static key = FilterKey.REASON;
  static shortName = 'Change Reason';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    const keywordsGroupName = 'Keyword Condition';
    const placementsGroupName = 'Placements';
    const bidGroupName = 'Bid Limits';

    const KEYWORD_REASONS_1: AlMultiSelectOptionModel[] = [
      new AlMultiSelectOptionModel('High ACOS', OptimizationReason.HIGH_ACOS, keywordsGroupName),
      new AlMultiSelectOptionModel('High Spend', OptimizationReason.HIGH_SPEND, keywordsGroupName),
      new AlMultiSelectOptionModel('Low Visibility', OptimizationReason.LOW_VISIBILITY, keywordsGroupName),
      new AlMultiSelectOptionModel('Low ACOS', OptimizationReason.LOW_ACOS, keywordsGroupName),
    ];

    const KEYWORD_REASONS_2: AlMultiSelectOptionModel[] = [
      // No such filter right now new FilterSelectOptionModel('User bid floor', OptimizationReason.MAX_BID_DECREASE, keywordsGroupName),
      new AlMultiSelectOptionModel('User Bid Ceiling', OptimizationReason.USER_BID_CEILING, bidGroupName),
      new AlMultiSelectOptionModel('Keyword Bid Ceiling', OptimizationReason.KEYWORD_BID_CEILING, bidGroupName),
      new AlMultiSelectOptionModel('Ad Group Bid Ceiling', OptimizationReason.AD_GROUP_BID_CEILING, bidGroupName),
      new AlMultiSelectOptionModel('Campaign Bid Ceiling', OptimizationReason.CAMPAIGN_BID_CEILING, bidGroupName),
      new AlMultiSelectOptionModel('Opt Group Bid Ceiling', OptimizationReason.CAMPAIGN_GROUP_BID_CEILING, bidGroupName),
      new AlMultiSelectOptionModel('Profile Bid Ceiling', OptimizationReason.PROFILE_BID_CEILING, bidGroupName),
      new AlMultiSelectOptionModel('Lowest Possible Bid', OptimizationReason.LOWEST_POSSIBLE_BID, bidGroupName),
      new AlMultiSelectOptionModel('Smallest Possible Increase', OptimizationReason.SMALLEST_POSSIBLE_INCREASE, bidGroupName),
    ];

    const PLACEMENTS_REASONS_1: AlMultiSelectOptionModel[] = [
      new AlMultiSelectOptionModel('Campaign Performance', OptimizationReason.CAMPAIGN_PERFORMANCE, placementsGroupName),
      new AlMultiSelectOptionModel('Optimization Group Performance', OptimizationReason.CAMPAIGN_GROUP_PERFORMANCE, placementsGroupName),
      new AlMultiSelectOptionModel('Profile Performance', OptimizationReason.PROFILE_PERFORMANCE, placementsGroupName),
    ];

    const PLACEMENTS_REASONS_2: AlMultiSelectOptionModel[] = [
      // new AlMultiSelectOptionModel('Max One Time Increase (Deprecated)', OptimizationReason.MAX_ONE_TIME_CHANGE, placementsGroupName), // Deprecated
      new AlMultiSelectOptionModel('Max One Time Increase', OptimizationReason.MAX_ONE_TIME_INCREASE, placementsGroupName),
      new AlMultiSelectOptionModel('Max One Time Decrease', OptimizationReason.MAX_ONE_TIME_DECREASE, placementsGroupName),
      new AlMultiSelectOptionModel('Lowest Possible Adjustment (if 0%)', OptimizationReason.LOWEST_POSSIBLE_ADJUSTMENT, placementsGroupName),
      new AlMultiSelectOptionModel(
        'Highest Possible Adjustment (if 900%)',
        OptimizationReason.HIGHEST_POSSIBLE_ADJUSTMENT,
        placementsGroupName,
      ),
    ];

    return Promise.resolve([...KEYWORD_REASONS_1, ...KEYWORD_REASONS_2, ...PLACEMENTS_REASONS_1, ...PLACEMENTS_REASONS_2]);
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values
      .map((v) => v as OptimizationReason)
      .some((v) => {
        if (!value.reasons) {
          return false;
        }

        console.log({ v, reasons: value.reasons });
        return value.reasons.some((r) => r === v);
      });
  }
}

export class RoasFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.ROAS;
  static longName = 'ROAS (Return on Ad Spend)';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.roas) ? value.roas[0] : value.roas;
    return this.doesBetweenFilterPass(valueToCheck * 100);
  }
}

export class RpcFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.RPC;
  static longName = 'RPC (Revenue Per Click)';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.rpc) ? value.rpc[0] : value.rpc;
    return this.doesBetweenFilterPass(valueToCheck);
  }
}

export class SalesFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.SALES;
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.sales) ? value.sales[0] : value.sales;
    return this.doesBetweenFilterPass(valueToCheck);
  }
}

export class SpendFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.SPEND;
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }

  doesFilterPass(value: PreviewDataRow | KeywordHarvestingPreviewDataRow): boolean {
    const valueToCheck = isArray(value.spend) ? value.spend[0] : value.spend;
    return this.doesBetweenFilterPass(valueToCheck);
  }
}

export class TargetingFilterModel extends AlFilterModel {
  static key = FilterKey.TARGETING;
  static shortName = 'Target (contains)';
  static longName = 'Target (contains)';
  static type = FilterType.STRING_COMPARISON;
  isProfileSpecific = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.targeting);
  }
}

export class TargetingNotFilterModel extends AlFilterModel {
  static key = FilterKey.TARGETING_NOT;
  static shortName = "Target (doesn't contain)";
  static longName = "Target (doesn't contain)";
  static type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;
  isProfileSpecific = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.targeting);
  }
}

export class TargetingExactMatchFilterModel extends AlFilterModel {
  static key = FilterKey.TARGETING_EXACT_MATCH;
  static shortName = 'Target (matches)';
  static longName = 'Target (matches)';
  static type = FilterType.STRING_EXACT_MATCH;
  isProfileSpecific = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesExactMatchFilterPass(value.targeting);
  }
}

// Following models were created only to rename filter to neg. target
export class NegativeTargetingFilterModel extends AlFilterModel {
  static key = FilterKey.NEGATIVE_TARGETING; // This models must use same filter key as TargetingFilterModel
  static shortName = 'Neg. Target (contains)';
  static longName = 'Neg. Target (contains)';
  static type = FilterType.STRING_COMPARISON;
  isProfileSpecific = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.targeting);
  }
}

export class NegativeTargetingNotFilterModel extends AlFilterModel {
  static key = FilterKey.NEGATIVE_TARGETING_NOT; // This models must use same filter key as TargetingNotFilterModel
  static shortName = "Neg. Target (doesn't contain)";
  static longName = "Neg. Target (doesn't contain)";
  static type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;
  isProfileSpecific = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.targeting);
  }
}

export class NegativeTargetingExactMatchFilterModel extends AlFilterModel {
  static key = FilterKey.NEGATIVE_TARGETING_EXACT_MATCH;
  static shortName = 'Neg. Target (matches)';
  static longName = 'Neg. Target (matches)';
  static type = FilterType.STRING_EXACT_MATCH;
  isProfileSpecific = true;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesExactMatchFilterPass(value.targeting);
  }
}

export class TargetingTypeFilterModel extends AlFilterModel {
  static key = FilterKey.TARGETING_TYPE;
  static shortName = 'Targeting type';
  static type = FilterType.MULTI_SELECT;
}

export class TargetTypeFilterModel extends AlFilterModel {
  static key = FilterKey.TARGET_TYPE;
  static shortName = 'Target Type';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Keyword', TargetEntityType.KEYWORD),
      new AlMultiSelectOptionModel('Product Target & Audience', TargetEntityType.PRODUCT_TARGET),
    ]);
  }
}

export class TargetStateFilterModel extends AlFilterModel {
  static key = FilterKey.TARGET_STATE;
  static shortName = 'Target State';
  static longName = 'Target State';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Paused', EnabledPausedArchivedState.PAUSED),
      new AlMultiSelectOptionModel('Enabled', EnabledPausedArchivedState.ENABLED),
      new AlMultiSelectOptionModel('Archived', EnabledPausedArchivedState.ARCHIVED),
    ]);
  }
}

export class TargetLastOptimizedAtFilterModel extends BaseDateFilterModel {
  static key = FilterKey.TARGET_LAST_OPTIMIZED_AT;
  static shortName = 'Last Optimized';
  isOperatorDisabled = false;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesDateBetweenFilterPass(value.lastOptimizedAt);
  }
}

export class MultiAdGroupsEnabledFilterModel extends AlFilterModel {
  static key = FilterKey.MULTI_AD_GROUPS_ENABLED;
  static shortName = 'SB Version';
  static longName = 'SB Campaign Version';
  static type = FilterType.SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return await Promise.resolve([
      new AlMultiSelectOptionModel('Multi Ad Group', MultiAdGroupsEnabledType.TRUE),
      new AlMultiSelectOptionModel('Legacy', MultiAdGroupsEnabledType.FALSE),
    ]);
  }

  getOptionsTranslationKey(): string {
    return 'enums.multi_ad_groups_enabled';
  }
}

abstract class DataGroupItemFilterModel extends AlFilterModel {
  static type = FilterType.MULTI_SELECT;
  options?: AlMultiSelectOptionModel[];
  dataGroupType = DataGroupType.TARGET;

  constructor(dataGroupType: DataGroupType, dataGroups: DataGroupModel[], args?: FilterArguments) {
    super(args);
    this.dataGroupType = dataGroupType;
    this.options = dataGroups
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((dataGroup) => {
        return dataGroup.items
          .map((item) => new AlMultiSelectOptionModel(item.name, item.id, dataGroup.name))
          .sort((a, b) => a.name.localeCompare(b.name));
      })
      .flat();

    this.removeStaleOptions();
  }

  async removeStaleOptions() {
    if (isNil(this.conditions) || isNil(this.conditions[0].values) || this.conditions[0].values.length == 0) {
      return;
    }

    await this.getOptions(); // Load options
    const availableOptionIDs = this.options?.map((o) => o.id); // Available Options IDs (numbers) from API

    const currentConditionValues = this.conditions[0].values.map((v) => parseInt(v as string));
    this.conditions[0].values = currentConditionValues.filter((id) => availableOptionIDs?.includes(id));
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    // If existing filters are loaded via factory we need to load options
    this.isLoading = true;
    const res = await dataGroupsService.getAllGroups();
    this.isLoading = false;

    if (res.isSuccess) {
      const options = res.payload
        .filter((dataGroup) => dataGroup.type === this.dataGroupType)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((dataGroup) => {
          return dataGroup.items
            .map((item) => new AlMultiSelectOptionModel(item.name, item.id, dataGroup.name))
            .sort((a, b) => a.name.localeCompare(b.name));
        })
        .flat();

      this.options = options;
      return this.options;
    }

    return [];
  }
}

export class CampaignDataGroupItemFilterModel extends DataGroupItemFilterModel {
  static key = FilterKey.CAMPAIGN_DATA_GROUP_ITEM;
  static shortName = 'Tag (Campaigns)';

  constructor(dataGroups: DataGroupModel[], args?: FilterArguments) {
    super(DataGroupType.CAMPAIGN, dataGroups, args);
  }
}

export class TargetDataGroupItemFilterModel extends DataGroupItemFilterModel {
  static key = FilterKey.TARGET_DATA_GROUP_ITEM;
  static shortName = 'Tag (Targets)';

  constructor(dataGroups: DataGroupModel[], args?: FilterArguments) {
    super(DataGroupType.TARGET, dataGroups, args);
  }
}

export class SearchTermDataGroupItemFilterModel extends DataGroupItemFilterModel {
  static key = FilterKey.SEARCH_TERM_DATA_GROUP_ITEM;
  static shortName = 'Tag (Search Terms)';

  constructor(dataGroups: DataGroupModel[], args?: FilterArguments) {
    super(DataGroupType.SEARCHTERM, dataGroups, args);
  }
}

export class SearchTermFilterModel extends AlFilterModel {
  static key = FilterKey.SEARCH_TERM;
  static shortName = 'Search Term (contains)';
  static longName = 'Search Term (contains)';
  static type = FilterType.STRING_COMPARISON;
  isProfileSpecific = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.searchTerm);
  }
}

export class SearchTermNotFilterModel extends AlFilterModel {
  static key = FilterKey.SEARCH_TERM_NOT;
  static shortName = "Search Term (doesn't contain)";
  static longName = "Search Term (doesn't contain)";
  static type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;
  isProfileSpecific = true;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.searchTerm);
  }
}

export class SearchTermExactMatchFilterModel extends AlFilterModel {
  static key = FilterKey.SEARCH_TERM_EXACT_MATCH;
  static shortName = 'Search Term (matches)';
  static longName = 'Search Term (matches)';
  static type = FilterType.STRING_EXACT_MATCH;
  isProfileSpecific = true;
}

export class SearchTermNegatedFilterModel extends AlFilterModel {
  static key = FilterKey.SEARCH_TERM_NEGATED;
  static shortName = 'Search Term Negated';
  static longName = 'Search Term Negated';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Negated', SearchTermNegatedType.NEGATED),
      new AlMultiSelectOptionModel('Not Negated', SearchTermNegatedType.NOT_NEGATED),
    ]);
  }
}

export class SearchTermHarvestedFilterModel extends AlFilterModel {
  static key = FilterKey.SEARCH_TERM_HARVESTED;
  static shortName = 'Search Term Harvested';
  static longName = 'Search Term Harvested';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('Harvested', SearchTermHarvestedType.HARVESTED),
      new AlMultiSelectOptionModel('Not Harvested', SearchTermHarvestedType.NOT_HARVESTED),
    ]);
  }
}

export class CreatedAtFilterModel extends BaseDateFilterModel {
  static key = FilterKey.CREATED_AT;
  static shortName = 'Created Date';
  static longName = 'Created Date';
}

export class BidFilterModel extends AlFilterModel {
  static key = FilterKey.BID;
  static shortName = 'Bid';
  static longName = 'Bid';
  static type = FilterType.BETWEEN;

  doesFilterPass(value: KeywordHarvestingPreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.bid);
  }
}

export class ProductTitleFilterModel extends AlFilterModel {
  static key = FilterKey.PRODUCT_TITLE;
  static shortName = 'Product Title (contains)';
  static longName = 'Product Title (contains)';
  static type = FilterType.STRING_COMPARISON;
  isProfileSpecific = true;
}

export class ProductTitleNotFilterModel extends AlFilterModel {
  static key = FilterKey.PRODUCT_TITLE_NOT;
  static shortName = "Product Title (doesn't contain)";
  static longName = "Product Title (doesn't contain)";
  static type = FilterType.STRING_COMPARISON;
  isInverseFilter = true;
  isProfileSpecific = true;
}

export interface FilterParamsWithTeamProfiles {
  args?: FilterArguments;
  teamProfiles?: TeamProfile[];
}

export class ProductAsinFilterModel extends AlFilterModel {
  static key = FilterKey.PRODUCT_ASIN;
  static shortName = 'Product ASIN';
  static longName = 'Product ASIN';
  static type = FilterType.MULTI_SELECT;
  teamProfiles?: TeamProfile[];
  isProfileSpecific = true;

  constructor({ teamProfiles, args }: FilterParamsWithTeamProfiles = {}) {
    super(args);
    this.teamProfiles = teamProfiles;
    this.removeStaleOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    const options: AlMultiSelectOptionModel[] = [];

    this.isLoading = true;
    if (this.teamProfiles && this.teamProfiles.length > 0) {
      await Promise.all(
        this.teamProfiles.map((profile) =>
          productsService.getASINsByProfileId(profile.profileId).then((res) => {
            if (res.isSuccess) {
              const opts: AlMultiSelectOptionModel[] = [];
              res.payload.forEach((asin) => {
                opts.push(new AlMultiSelectOptionModel(asin, asin, profile.profileName));
              });
              options.push(...opts.sort((a, b) => a.name.localeCompare(b.name)));
            }
          }),
        ),
      );
    } else {
      const res = await productsService.getActiveProfileASINs();
      if (res.isSuccess) {
        const opts: AlMultiSelectOptionModel[] = [];
        res.payload.forEach((asin) => {
          opts.push(new AlMultiSelectOptionModel(asin, asin));
        });
        options.push(...opts.sort((a, b) => a.name.localeCompare(b.name)));
      }
    }
    this.isLoading = false;

    this.options = options;
    return this.options;
  }
}

export class ProductParentAsinFilterModel extends AlFilterModel {
  static key = FilterKey.PRODUCT_PARENT_ASIN;
  static shortName = 'Product Parent ASIN';
  static longName = 'Product Parent ASIN';
  static type = FilterType.MULTI_SELECT;
  teamProfiles?: TeamProfile[];
  isProfileSpecific = true;

  constructor({ teamProfiles, args }: FilterParamsWithTeamProfiles = {}) {
    super(args);
    this.teamProfiles = teamProfiles;
    this.removeStaleOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    const options: AlMultiSelectOptionModel[] = [];
    this.isLoading = true;
    if (this.teamProfiles && this.teamProfiles.length > 0) {
      await Promise.all(
        this.teamProfiles.map((profile) =>
          productsService.getParentASINsByProfileId(profile.profileId).then((res) => {
            if (res.isSuccess) {
              const opts: AlMultiSelectOptionModel[] = [];
              res.payload.forEach((asin) => {
                opts.push(new AlMultiSelectOptionModel(asin, asin, profile.profileName));
              });
              options.push(...opts.sort((a, b) => a.name.localeCompare(b.name)));
            }
          }),
        ),
      );
    } else {
      const res = await productsService.getActiveProfileParentASINs();
      if (res.isSuccess) {
        const opts: AlMultiSelectOptionModel[] = [];
        res.payload.forEach((asin) => {
          opts.push(new AlMultiSelectOptionModel(asin, asin));
        });
        options.push(...opts.sort((a, b) => a.name.localeCompare(b.name)));
      }
    }
    this.isLoading = false;

    this.options = options;
    return this.options;
  }
}

export class ProductSkuFilterModel extends AlFilterModel {
  static key = FilterKey.PRODUCT_SKU;
  static shortName = 'Product SKU';
  static longName = 'Product SKU';
  static type = FilterType.MULTI_SELECT;
  teamProfiles?: TeamProfile[];
  isProfileSpecific = true;

  constructor({ teamProfiles, args }: FilterParamsWithTeamProfiles = {}) {
    super(args);
    this.teamProfiles = teamProfiles;
    this.removeStaleOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    const options: AlMultiSelectOptionModel[] = [];
    this.isLoading = true;
    if (this.teamProfiles && this.teamProfiles.length > 0) {
      await Promise.all(
        this.teamProfiles.map((profile) =>
          productsService.getSKUsByProfileId(profile.profileId).then((res) => {
            if (res.isSuccess) {
              const opts: AlMultiSelectOptionModel[] = [];
              res.payload.forEach((sku) => {
                opts.push(new AlMultiSelectOptionModel(sku, sku, profile.profileName));
              });
              options.push(...opts.sort((a, b) => a.name.localeCompare(b.name)));
            }
          }),
        ),
      );
    } else {
      const res = await productsService.getActiveProfileSKUs();
      if (res.isSuccess) {
        const opts: AlMultiSelectOptionModel[] = [];
        res.payload.forEach((sku) => {
          opts.push(new AlMultiSelectOptionModel(sku, sku));
        });
        options.push(...opts.sort((a, b) => a.name.localeCompare(b.name)));
      }
    }
    this.isLoading = false;

    this.options = options;
    return this.options;
  }
}

export class ProductBrandFilterModel extends AlFilterModel {
  static key = FilterKey.PRODUCT_BRAND;
  static shortName = 'Product Brand(s)';
  static longName = 'Select Product Brand';
  static type = FilterType.MULTI_SELECT;
  teamProfiles?: TeamProfile[];
  isProfileSpecific = true;

  constructor({ teamProfiles, args }: FilterParamsWithTeamProfiles = {}) {
    super(args);
    this.teamProfiles = teamProfiles;
    this.removeStaleOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    const options: AlMultiSelectOptionModel[] = [];
    this.isLoading = true;
    if (this.teamProfiles && this.teamProfiles.length > 0) {
      await Promise.all(
        this.teamProfiles.map((profile) =>
          productsService.getBrandsByProfileId(profile.profileId).then((res) => {
            if (res.isSuccess) {
              const opts: AlMultiSelectOptionModel[] = [];
              res.payload.forEach((brand) => {
                opts.push(new AlMultiSelectOptionModel(brand, brand, profile.profileName));
              });
              options.push(...sortOptionsAlphabetically(opts, '< No Brands >'));
            }
          }),
        ),
      );
    } else {
      const res = await productsService.getActiveProfileBrands();
      if (res.isSuccess) {
        const opts: AlMultiSelectOptionModel[] = [];
        res.payload.forEach((brand) => {
          opts.push(new AlMultiSelectOptionModel(brand, brand));
        });
        options.push(...sortOptionsAlphabetically(opts, '< No Brands >'));
      }
    }
    this.isLoading = false;

    this.options = options;
    return this.options;
  }
}

export class ProductCategoryFilterModel extends AlFilterModel {
  static key = FilterKey.PRODUCT_CATEGORY;
  static shortName = 'Product Category';
  static longName = 'Select Product Category';
  static type = FilterType.MULTI_SELECT;
  teamProfiles?: TeamProfile[];
  isProfileSpecific = true;

  constructor({ teamProfiles, args }: FilterParamsWithTeamProfiles = {}) {
    super(args);
    this.teamProfiles = teamProfiles;
    this.removeStaleOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    const options: AlMultiSelectOptionModel[] = [];
    this.isLoading = true;
    if (this.teamProfiles && this.teamProfiles.length > 0) {
      await Promise.all(
        this.teamProfiles.map((profile) =>
          productsService.getCategoriesByProfileId(profile.profileId).then((res) => {
            if (res.isSuccess) {
              const opts: AlMultiSelectOptionModel[] = [];
              res.payload.forEach((category) => {
                opts.push(new AlMultiSelectOptionModel(category, category, profile.profileName));
              });
              options.push(...sortOptionsAlphabetically(opts, '< No Category >'));
            }
          }),
        ),
      );
    } else {
      const res = await productsService.getActiveProfileCategories();
      if (res.isSuccess) {
        const opts: AlMultiSelectOptionModel[] = [];
        res.payload.forEach((category) => {
          opts.push(new AlMultiSelectOptionModel(category, category));
        });
        options.push(...sortOptionsAlphabetically(opts, '< No Category >'));
      }
    }
    this.isLoading = false;

    this.options = options;
    return this.options;
  }
}

export class ProductDataGroupItemFilterModel extends DataGroupItemFilterModel {
  static key = FilterKey.PRODUCT_DATA_GROUP_ITEM;
  static shortName = 'Tag (Products)';

  constructor(dataGroups: DataGroupModel[], args?: FilterArguments) {
    super(DataGroupType.PRODUCT, dataGroups, args);
  }
}

export class ProductAvailabilityFilterModel extends AlFilterModel {
  static key = FilterKey.PRODUCT_AVAILABILITY;
  static shortName = 'Availability';
  static longName = 'Availability';
  static type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<AlMultiSelectOptionModel[]> {
    return Promise.resolve([
      new AlMultiSelectOptionModel('In Stock', ProductAvailability.IN_STOCK),
      new AlMultiSelectOptionModel('Low Inventory', ProductAvailability.IN_STOCK_SCARCE),
      new AlMultiSelectOptionModel('Out Of Stock', ProductAvailability.OUT_OF_STOCK),
      new AlMultiSelectOptionModel('Pre-order', ProductAvailability.PREORDER),
      new AlMultiSelectOptionModel('Lead time', ProductAvailability.LEADTIME),
      new AlMultiSelectOptionModel('Available Date', ProductAvailability.AVAILABLE_DATE),
    ]);
  }
}

export class AcotsFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.ACOTS;
  static longName = 'Total Ad Cost of Sales (TACOS)'; // was Ad Cost of Total Sales (ACOTS)
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class AspFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.ASP;
  static longName = 'Average Sales Price (ASP)';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class OrganicSalesFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.ORGANIC_SALES;
  static longName = 'Organic Sales';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class OrganicTrafficFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.ORGANIC_TRAFFIC;
  static longName = 'Organic Traffic';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class TotalPageViewsFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.TOTAL_VIEWS;
  static longName = 'Total Page Views';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class TotalUnitsFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.TOTAL_UNITS;
  static longName = 'Total Units';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class UpsFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.UPS;
  static longName = 'Units Per Session';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class TotalCvrFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.TCVR;
  static longName = 'Total CVR';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class TotalClicksFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.TOTAL_CLICKS;
  static longName = 'Total Sessions';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class TotalOrdersFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.TOTAL_ORDERS;
  static longName = 'Total Orders';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class TotalSalesFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.TOTAL_SALES;
  static longName = 'Total Sales';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class TotalCpaFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.TCPA;
  static longName = 'Total CPA (Cost Per Acquisition)';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class TotalRoasFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.TROAS;
  static longName = 'Total ROAS (Return on Ad Spend)';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class TotalAovFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.TAOV;
  static longName = 'Total AOV (Average Order Value)';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class AdSalesOfTotalFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.AD_SALES_OF_TOTAL;
  static longName = 'Ad Sales % of Total';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class UnitsPerPageViewFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.UPPW;
  static longName = 'Unit Pageview %';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

// export class TotalActcFilterModel extends BaseMetricFilterModel {
//   static key = FilterKey.TOTAL_ACTC;
//   static longName = 'Total aCTC';
//   static type = FilterType.BETWEEN;

//   static {
//     this.initializeBase(this.key);
//   }
// }

export class SameSkuOrdersFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.SAME_SKU_ORDERS;
  static longName = 'Same SKU Orders';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class SameSkuSalesFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.SAME_SKU_SALES;
  static longName = 'Same SKU Sales';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class OtherSkuSalesFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.OTHER_SKU_SALES;
  static longName = 'Other SKU Sales';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class TeamFilterModel extends AlFilterModel {
  static key = FilterKey.TEAM;
  static shortName = 'Team';
  static longName = 'Team';
  static type = FilterType.MULTI_SELECT;

  constructor(teamIdNames: IdNameDTO[], args?: FilterArguments) {
    super(args);
    this.options = teamIdNames
      .map((teamIdName) => {
        return new AlMultiSelectOptionModel(teamIdName.name, teamIdName.id.toString());
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    this.removeStaleOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    return Promise.resolve([]);
  }
  async removeStaleOptions() {
    if (isNil(this.conditions) || isNil(this.conditions[0].values) || this.conditions[0].values.length == 0) {
      return;
    }

    await this.getOptions(); // Load options
    const availableOptionIDs = this.options?.map((o) => o.id);

    const currentConditionValues = this.conditions[0].values.map((v) => v.toString());
    this.conditions[0].values = currentConditionValues.filter((id) => availableOptionIDs?.includes(id));
  }

  doesFilterPass(value: ProfileWithMetricsModel): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as string).includes(value.teamId.toString());
  }
}

export class ProfileFilterModel extends AlFilterModel {
  static key = FilterKey.PROFILE;
  static shortName = 'Profile';
  static longName = 'Profile';
  static type = FilterType.MULTI_SELECT;

  constructor(profileIdNames: IdNameDTO[], args?: FilterArguments) {
    super(args);
    this.options = profileIdNames
      .map((profileIdName) => {
        return new AlMultiSelectOptionModel(profileIdName.name, profileIdName.id.toString());
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    this.removeStaleOptions();
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    return Promise.resolve([]);
  }
  async removeStaleOptions() {
    if (isNil(this.conditions) || isNil(this.conditions[0].values) || this.conditions[0].values.length == 0) {
      return;
    }

    await this.getOptions(); // Load options
    const availableOptionIDs = this.options?.map((o) => o.id);

    const currentConditionValues = this.conditions[0].values.map((v) => v.toString());
    this.conditions[0].values = currentConditionValues.filter((id) => availableOptionIDs?.includes(id));
  }

  doesFilterPass(value: ProfileWithMetricsModel): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as string).includes(value.id.toString());
  }
}

export class VendorTotalPageViewsFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.GLANCE_VIEWS;
  static longName = 'Total Page Views';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class CustomerReturnsFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.CUSTOMER_RETURNS;
  static longName = 'Units Refunded';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class VendorTotalSalesFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.ORDERED_REVENUE;
  static longName = 'Total Sales';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class VendorTotalUnitsFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.ORDERED_UNITS;
  static longName = 'Total Units';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class ShippedCogsFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.SHIPPED_COGS;
  static longName = 'Shipped COGS';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class ShippedRevenueFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.SHIPPED_REVENUE;
  static longName = 'Shipped Revenue';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class ShippedUnitsFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.SHIPPED_UNITS;
  static longName = 'Shipped Units';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export class ReturnRateFilterModel extends BaseMetricFilterModel {
  static key = FilterKey.RETURN_RATE;
  static longName = 'Refund Rate';
  static type = FilterType.BETWEEN;

  static {
    this.initializeBase(this.key);
  }
}

export function createCampaignFilters(
  campaignGroups: CampaignGroupModel[],
  campaignDataGroups: DataGroupModel[],
  fetchCache: AlFetchCache,
): AlFilterModel[] {
  return [
    new DateFilterModel(),
    new ComparisonDateFilterModel(),
    new CampaignFilterModel({ fetchCache }),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new CampaignAdTypeFilterModel(),
    new PortfolioFilterModel(),
    new CampaignLastOptimizedAtFilterModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new CampaignDataGroupItemFilterModel(campaignDataGroups),
    new CampaignStateFilterModel(),
    new CreativeTypeFilterModel(),
    new BudgetFilterModel(),
    new CostTypeFilterModel(),
    new BidOptimizationFilterModel(),
    new BidStrategyFilterModel(),
    new MultiAdGroupsEnabledFilterModel(),
    new CampaignStartDateFilterModel(),
    new CampaignEndDateFilterModel(),
    ...createMetricFilters(),
  ];
}
export function createCampaignFiltersForMultipleProfiles(
  campaignGroups: CampaignGroupModel[],
  campaignDataGroups: DataGroupModel[],
  teamProfiles: TeamProfile[],
  fetchCache: AlFetchCache,
): AlFilterModel[] {
  return [
    new DateFilterModel(),
    new ComparisonDateFilterModel(),
    new CampaignFilterModel({
      teamProfiles,
      fetchCache,
    }),
    new CampaignNameFilterModel(),
    new CampaignAdTypeFilterModel(),
    new CampaignNameNotFilterModel(),
    new PortfolioFilterModel(),
    new CampaignLastOptimizedAtFilterModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new CampaignDataGroupItemFilterModel(campaignDataGroups),
    new CampaignStateFilterModel(),
    new CreativeTypeFilterModel(),
    new BudgetFilterModel(),
    new CostTypeFilterModel(),
    new BidOptimizationFilterModel(),
    new BidStrategyFilterModel(),
    new MultiAdGroupsEnabledFilterModel(),
    new CampaignStartDateFilterModel(),
    new CampaignEndDateFilterModel(),
    ...createMetricFilters(),
  ];
}

export function createPlacementFiltersForMultipleProfiles(
  campaignGroups: CampaignGroupModel[],
  campaignDataGroups: DataGroupModel[],
  teamProfiles: TeamProfile[],
  fetchCache: AlFetchCache,
): AlFilterModel[] {
  return [
    new DateFilterModel(),
    new ComparisonDateFilterModel(),
    new PlacementTypeFilterModel(),
    new BidAdjustmentTypeFilterModel(),
    new CampaignFilterModel({
      teamProfiles,
      fetchCache,
    }),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new CampaignAdTypeFilterModel(),
    new PortfolioFilterModel(),
    new BidAdjustmentLastOptimizedAtFilterModel(),
    new CampaignLastOptimizedAtFilterModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new CampaignDataGroupItemFilterModel(campaignDataGroups),
    new CampaignStateFilterModel(),
    new CreativeTypeFilterModel(),
    new BudgetFilterModel(),
    new CostTypeFilterModel(),
    new BidOptimizationFilterModel(),
    new BidStrategyFilterModel(),
    new MultiAdGroupsEnabledFilterModel(),
    new CampaignStartDateFilterModel(),
    new CampaignEndDateFilterModel(),
    ...createMetricFilters(),
  ];
}

export function createProductFiltersForMultipleProfiles(
  teamProfiles: TeamProfile[],
  productDataGroups: DataGroupModel[],
  isSeller: boolean,
  isVendor: boolean,
): AlFilterModel[] {
  const filters = [
    new DateFilterModel(),
    new ComparisonDateFilterModel(),
    new ProductTitleFilterModel(),
    new ProductTitleNotFilterModel(),
    new ProductAsinFilterModel({ teamProfiles }),
    new ProductSkuFilterModel({ teamProfiles }),
    new ProductCategoryFilterModel({ teamProfiles }),
    new ProductBrandFilterModel({ teamProfiles }),
    new ProductDataGroupItemFilterModel(productDataGroups),
    new SameSkuOrdersFilterModel(),
    new SameSkuSalesFilterModel(),
    new OtherSkuSalesFilterModel(),
    ...createMetricFilters(),
  ];

  if (isSeller || isVendor) {
    filters.push(
      new AcotsFilterModel(),
      new AspFilterModel(),
      new OrganicSalesFilterModel(),
      new TotalRoasFilterModel(),
      new AdSalesOfTotalFilterModel(),
      new UnitsPerPageViewFilterModel(),
    );
  }

  if (isSeller) {
    filters.push(
      new TotalSalesFilterModel(),
      new TotalPageViewsFilterModel(),
      new TotalUnitsFilterModel(),
      new ProductParentAsinFilterModel(), // TODO: Get Parent SIN for all profiles
      new OrganicTrafficFilterModel(),
      new TotalCpaFilterModel(),
      new TotalAovFilterModel(),
      // new TotalActcFilterModel(),
      new UpsFilterModel(),
      new TotalCvrFilterModel(),
      new TotalClicksFilterModel(),
      new TotalOrdersFilterModel(),
    );
  }

  if (isVendor) {
    filters.push(
      new VendorTotalPageViewsFilterModel(),
      new CustomerReturnsFilterModel(),
      new VendorTotalSalesFilterModel(),
      new VendorTotalUnitsFilterModel(),
      new ShippedCogsFilterModel(),
      new ShippedRevenueFilterModel(),
      new ShippedUnitsFilterModel(),
      new ReturnRateFilterModel(),
    );
  }

  return filters;
}

export function createOptimizerFilters(
  adGroups: IdNameDTO[],
  fetchCache: AlFetchCache,
  campaignGroups: CampaignGroupModel[],
  campaigns: IdNameDTO[],
  portfolios: IdNameDTO[],
): AlFilterModel[] {
  return [
    new EntityTypeFilterModel(),
    new CampaignAdTypeFilterModel(),
    new CampaignFilterModel({ campaigns, fetchCache }),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new PortfolioFilterModel({ portfolios }),
    new AdGroupFilterModel(adGroups, fetchCache),
    new AdGroupNameFilterModel(),
    new TargetingFilterModel(),
    new TargetingNotFilterModel(),
    new TargetingExactMatchFilterModel(),
    new MatchTypeFilterModel(),
    new LastOptimizedAtFilterModel(),
    new CampaignLastOptimizedAtFilterModel(), // Add filter for targets and placements
    new CampaignGroupFilterModel(campaignGroups),
    new OldValueFilterModel(),
    new NewValueFilterModel(),
    new DeltaFilterModel(),
    new OptimizationReasonFilterModel(),
    ...createMetricFilters(),
  ];
}

export function createTargetingFilters(
  fetchCache: AlFetchCache,
  campaignGroups: CampaignGroupModel[],
  campaignDataGroups: DataGroupModel[],
  targetDataGroups: DataGroupModel[],
): AlFilterModel[] {
  return [
    new CampaignAdTypeFilterModel(),
    new TargetTypeFilterModel(),
    new TargetStateFilterModel(),
    new TargetLastOptimizedAtFilterModel(),
    new CampaignFilterModel({ fetchCache }),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new CampaignStateFilterModel(),
    new AdGroupStateFilterModel(),
    new PortfolioFilterModel(),
    new AdGroupFilterModel([], fetchCache),
    new AdGroupNameFilterModel(),
    new TargetingFilterModel(),
    new TargetingNotFilterModel(),
    new TargetingExactMatchFilterModel(),
    new MatchTypeFilterModel(),
    new BidFilterModel(),
    new CampaignLastOptimizedAtFilterModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new CampaignDataGroupItemFilterModel(campaignDataGroups),
    new TargetDataGroupItemFilterModel(targetDataGroups),
    ...createMetricFilters(),
  ];
}

export function createTargetingFiltersForMultipleProfiles(
  fetchCache: AlFetchCache,
  campaignGroups: CampaignGroupModel[],
  campaignDataGroups: DataGroupModel[],
  targetDataGroups: DataGroupModel[],
  teamProfiles: TeamProfile[],
): AlFilterModel[] {
  return [
    new CampaignAdTypeFilterModel(),
    new TargetTypeFilterModel(),
    new TargetStateFilterModel(),
    new TargetLastOptimizedAtFilterModel(),
    new CampaignFilterModel({ teamProfiles, fetchCache }),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new CampaignStateFilterModel(),
    new PortfolioFilterModel(),
    new AdGroupFilterModel([], fetchCache),
    new AdGroupNameFilterModel(),
    new TargetingFilterModel(),
    new TargetingNotFilterModel(),
    new TargetingExactMatchFilterModel(),
    new MatchTypeFilterModel(),
    new CampaignLastOptimizedAtFilterModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new CampaignDataGroupItemFilterModel(campaignDataGroups),
    new TargetDataGroupItemFilterModel(targetDataGroups),
    ...createMetricFilters(),
  ];
}

export function createPlacementsFilters(
  campaignGroups: CampaignGroupModel[],
  campaignDataGroups: DataGroupModel[],
  fetchCache: AlFetchCache,
): AlFilterModel[] {
  return [
    new DateFilterModel(),
    new ComparisonDateFilterModel(),
    new PlacementTypeFilterModel(),
    new BidAdjustmentTypeFilterModel(),
    new CampaignFilterModel({ fetchCache }),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new CampaignAdTypeFilterModel(),
    new PortfolioFilterModel(),
    new BidAdjustmentLastOptimizedAtFilterModel(),
    new CampaignLastOptimizedAtFilterModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new CampaignDataGroupItemFilterModel(campaignDataGroups),
    new CampaignStateFilterModel(),
    new CreativeTypeFilterModel(),
    new BudgetFilterModel(),
    new CostTypeFilterModel(),
    new BidOptimizationFilterModel(),
    new BidStrategyFilterModel(),
    new MultiAdGroupsEnabledFilterModel(),
    new CampaignStartDateFilterModel(),
    new CampaignEndDateFilterModel(),
    ...createMetricFilters(),
  ];
}

export function createSearchTermsFilters(
  campaignGroups: CampaignGroupModel[],
  fetchCache: AlFetchCache,
  campaignDataGroups: DataGroupModel[],
  targetDataGroups: DataGroupModel[],
  searchTermDataGroups: DataGroupModel[],
): AlFilterModel[] {
  return [
    new SearchTermFilterModel(),
    new SearchTermNotFilterModel(),
    new SearchTermExactMatchFilterModel(),
    new SearchTermNegatedFilterModel(),
    new SearchTermHarvestedFilterModel(),
    new CampaignAdTypeFilterModel(true),
    new TargetTypeFilterModel(),
    new TargetStateFilterModel(),
    new CampaignFilterModel({ fetchCache }),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new CampaignStateFilterModel(),
    new PortfolioFilterModel(),
    new AdGroupFilterModel([], fetchCache),
    new AdGroupNameFilterModel(),
    new AdGroupStateFilterModel(),
    new TargetingFilterModel(),
    new TargetingNotFilterModel(),
    new TargetingExactMatchFilterModel(),
    new MatchTypeFilterModel(),
    new BidFilterModel(),
    new CampaignGroupFilterModel(campaignGroups),
    new CampaignDataGroupItemFilterModel(campaignDataGroups),
    new TargetDataGroupItemFilterModel(targetDataGroups),
    new SearchTermDataGroupItemFilterModel(searchTermDataGroups),
    new SameSkuOrdersFilterModel(),
    new SameSkuSalesFilterModel(),
    new OtherSkuSalesFilterModel(),
    ...createMetricFilters(),
  ];
}

export function createNegativeTargetingFilters() {
  return [
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new CampaignStateFilterModel(),
    new CampaignAdTypeFilterModel(),
    new AdGroupNameFilterModel(),
    new AdGroupNameNotFilterModel(),
    new TargetTypeFilterModel(),
    new TargetStateFilterModel(),
    new NegativeTargetLevelFilterModel(),
    new NegativeKeywordMatchTypeFilterModel(),
    new NegativeTargetingFilterModel(),
    new NegativeTargetingNotFilterModel(),
    new NegativeTargetingExactMatchFilterModel(),
    new CreatedAtFilterModel(),
  ];
}

export function createCampaignGroupsFilters(): AlFilterModel[] {
  return [new DateFilterModel(), new ComparisonDateFilterModel(), new CampaignGroupNameFilterModel(), new CampaignGroupNameNotFilterModel()];
}

export function createKeywordHarvestingFilters(): AlFilterModel[] {
  return [
    new SearchTermFilterModel(),
    new SearchTermNotFilterModel(),
    new MatchTypeFilterModel([MatchType.EXACT, MatchType.PHRASE, MatchType.BROAD, MatchType.INDIVIDUAL, MatchType.EXPANDED]),
    new BiddingMethodFilterModel(),
    new BidFilterModel(),
    new SourceCampaignNameFilterModel(),
    new SourceCampaignNameNotFilterModel(),
    new DestinationCampaignNameFilterModel(),
    new DestinationCampaignNameNotFilterModel(),
    new SourceAdGroupNameFilterModel(),
    new SourceAdGroupNameNotFilterModel(),
    new DestinationAdGroupNameFilterModel(),
    new DestinationAdGroupNameNotFilterModel(),
    new EntityTypeFilterModel(),
    ...createMetricFilters(),
  ];
}

export function createCampaignMappingFilters(): AlFilterModel[] {
  return [
    new SourceCampaignNameFilterModel(),
    new SourceCampaignNameNotFilterModel(),
    new SourceAdGroupNameFilterModel(),
    new SourceAdGroupNameNotFilterModel(),
    // new SourceCampaignAdTypeFilterModel(),
    new DestinationCampaignNameFilterModel(),
    new DestinationCampaignNameNotFilterModel(),
    new DestinationAdGroupNameFilterModel(),
    new DestinationAdGroupNameNotFilterModel(),
    new DestinationCampaignAdTypeFilterModel(),
    new BiddingMethodFilterModel(),
  ];
}

export function createProductFilters(productDataGroups: DataGroupModel[], isSeller: boolean, isVendor: boolean): AlFilterModel[] {
  const filters = [
    new ProductTitleFilterModel(),
    new ProductTitleNotFilterModel(),
    new ProductAsinFilterModel(),
    new ProductSkuFilterModel(),
    new ProductCategoryFilterModel(),
    new ProductBrandFilterModel(),
    new ProductDataGroupItemFilterModel(productDataGroups),
    new ProductAvailabilityFilterModel(),
    new SameSkuOrdersFilterModel(),
    new SameSkuSalesFilterModel(),
    new OtherSkuSalesFilterModel(),
    ...createMetricFilters(),
  ];

  if (isSeller || isVendor) {
    filters.push(
      new AcotsFilterModel(),
      new AspFilterModel(),
      new OrganicSalesFilterModel(),
      new TotalRoasFilterModel(),
      new AdSalesOfTotalFilterModel(),
      new UnitsPerPageViewFilterModel(),
    );
  }

  if (isSeller) {
    filters.push(
      new TotalSalesFilterModel(),
      new TotalPageViewsFilterModel(),
      new TotalUnitsFilterModel(),
      new ProductParentAsinFilterModel(),
      new OrganicTrafficFilterModel(),
      new TotalCpaFilterModel(),
      new TotalAovFilterModel(),
      // new TotalActcFilterModel(),
      new UpsFilterModel(),
      new TotalCvrFilterModel(),
      new TotalClicksFilterModel(),
      new TotalOrdersFilterModel(),
      new TotalClicksFilterModel(),
    );
  }

  if (isVendor) {
    filters.push(
      new VendorTotalSalesFilterModel(),
      new VendorTotalPageViewsFilterModel(),
      new CustomerReturnsFilterModel(),
      new VendorTotalUnitsFilterModel(),
      new ShippedCogsFilterModel(),
      new ShippedRevenueFilterModel(),
      new ShippedUnitsFilterModel(),
      new ReturnRateFilterModel(),
    );
  }

  return filters;
}

export function createAdvertisedProductFilters(
  fetchCache: AlFetchCache,
  productDataGroups: DataGroupModel[],
  campaigns: IdNameDTO[],
  isSeller: boolean,
): AlFilterModel[] {
  const filters = [
    new ProductTitleFilterModel(),
    new ProductTitleNotFilterModel(),
    new ProductAsinFilterModel(),
    new ProductSkuFilterModel(),
    new ProductDataGroupItemFilterModel(productDataGroups),
    new CampaignFilterModel({ campaigns, fetchCache }),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new CampaignStateFilterModel(),
    new AdGroupFilterModel([], fetchCache),
    new AdGroupNameFilterModel(),
    new AdGroupStateFilterModel(),
    new TotalUnitsFilterModel(),
    new SameSkuOrdersFilterModel(),
    new SameSkuSalesFilterModel(),
    new OtherSkuSalesFilterModel(),
    ...createMetricFilters(),
  ];

  if (isSeller) {
    filters.push(new ProductParentAsinFilterModel());
  }

  return filters;
}

export function createProfileStatsFilters(teamIdName: IdNameDTO[], profileIdName: IdNameDTO[]): AlFilterModel[] {
  // If we plan to add stats filters, we need to fetch metrics again for the previous period.
  // For example, if in the current period there are 3 profiles with an ad spend of more than 1000,
  // we cannot simply take the previous stats for only these 3 profiles. Instead,
  // we need to fetch the profiles whose ad spend was more than 1000 in the previous period.
  // The resulting profiles may differ, with more or fewer profiles included.
  return [new TeamFilterModel(teamIdName), new ProfileFilterModel(profileIdName)];
}

function createMetricFilters(): AlFilterModel[] {
  return [
    new ImpressionsFilterModel(),
    new ClicksFilterModel(),
    new OrdersFilterModel(),
    new UnitsFilterModel(),
    new SpendFilterModel(),
    new SalesFilterModel(),
    new CtrFilterModel(),
    new CvrFilterModel(),
    new CpcFilterModel(),
    new AcosFilterModel(),
    new ActcFilterModel(),
    new RoasFilterModel(),
    new RpcFilterModel(),
    new CpaFilterModel(),
    new AovFilterModel(),
    new CpmFilterModel(),
  ];
}

export const getUpdatedFiltersValue = (previousValue: AlFilterModel[], updatedFilter: AlFilterModel) => {
  const updatedFilters = [...previousValue];
  const existingFilterIndex = updatedFilters.findIndex((filterItem) => filterItem.key === updatedFilter.key);

  if (existingFilterIndex !== -1) {
    updatedFilters[existingFilterIndex] = updatedFilter;
  } else {
    updatedFilters.push(updatedFilter);
  }

  return updatedFilters;
};

export function getDefaultStartEndDate(): StartEndDatePairWithComparison {
  const today = AlDate.now();
  const startDate = today.subtractDays(DATE_PERIOD_LENGTH_DAYS);
  const endDate = today.subtractDays(1);

  return {
    startDate,
    endDate,
    comparisonStartDate: startDate.subtractDays(DATE_PERIOD_LENGTH_DAYS),
    comparisonEndDate: endDate.subtractDays(DATE_PERIOD_LENGTH_DAYS),
  };
}

export function getDefaultDateFilters(): AlFilterModel[] {
  const { startDate, endDate, comparisonStartDate, comparisonEndDate } = getDefaultStartEndDate();

  const defaultDateRange: AlFilterModel[] = [
    new ComparisonDateFilterModel({
      logicalOperator: LogicalOperatorType.AND,
      conditions: [
        {
          values: [comparisonStartDate.toDefaultFormat()],
          operator: OperatorType.GREATER_THAN_OR_EQUAL,
        },
        {
          values: [comparisonEndDate.toDefaultFormat()],
          operator: OperatorType.LESS_THAN_OR_EQUAL,
        },
      ],
    }),
    new DateFilterModel({
      logicalOperator: LogicalOperatorType.AND,
      conditions: [
        {
          values: [startDate.toDefaultFormat()],
          operator: OperatorType.GREATER_THAN_OR_EQUAL,
        },
        {
          values: [endDate.toDefaultFormat()],
          operator: OperatorType.LESS_THAN_OR_EQUAL,
        },
      ],
    }),
  ];

  return defaultDateRange;
}

export function getMonthToDateDateRange(): AlFilterModel[] {
  const today = AlDate.now();
  const isFirstDayOfMonth = today.startOf('day').isSame(today.startOf('month'));

  const startDate = isFirstDayOfMonth ? today.startOf('day') : today.startOf('month');
  const endDate = today.startOf('day');

  const comparisonStartDate = startDate.subtractDays(DATE_PERIOD_LENGTH_DAYS);
  const comparisonEndDate = endDate.subtractDays(DATE_PERIOD_LENGTH_DAYS);

  const monthToDateDateRange: AlFilterModel[] = [
    new ComparisonDateFilterModel({
      logicalOperator: LogicalOperatorType.AND,
      conditions: [
        {
          values: [comparisonStartDate.toDefaultFormat()],
          operator: OperatorType.GREATER_THAN_OR_EQUAL,
        },
        {
          values: [comparisonEndDate.toDefaultFormat()],
          operator: OperatorType.LESS_THAN_OR_EQUAL,
        },
      ],
    }),
    new DateFilterModel({
      logicalOperator: LogicalOperatorType.AND,
      conditions: [
        {
          values: [startDate.toDefaultFormat()],
          operator: OperatorType.GREATER_THAN_OR_EQUAL,
        },
        {
          values: [endDate.toDefaultFormat()],
          operator: OperatorType.LESS_THAN_OR_EQUAL,
        },
      ],
    }),
  ];

  return monthToDateDateRange;
}

function getGreaterThanZeroImpressionsFilter(): AlFilterModel {
  return new ImpressionsFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: ['0'],
        operator: OperatorType.GREATER_THAN,
      },
    ],
  });
}

export function getDefaultCampaignFilters(): AlFilterModel[] {
  const campaignStateFilter: AlFilterModel = new CampaignStateFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: [EnabledPausedArchivedState.ENABLED],
        operator: OperatorType.IN,
      },
    ],
  });

  return [...getDefaultDateFilters(), campaignStateFilter];
}

export function getDefaultTargetFilters(): AlFilterModel[] {
  const campaignStateFilter: AlFilterModel = new CampaignStateFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: [EnabledPausedArchivedState.ENABLED],
        operator: OperatorType.IN,
      },
    ],
  });

  const targetStateFilter: AlFilterModel = new TargetStateFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: [EnabledPausedArchivedState.ENABLED],
        operator: OperatorType.IN,
      },
    ],
  });

  return [...getDefaultDateFilters(), campaignStateFilter, targetStateFilter, getGreaterThanZeroImpressionsFilter()];
}

export function getDefaultPlacementsFilters(): AlFilterModel[] {
  return getDefaultCampaignFilters();
}

export function getDefaultSearchTermsFilters(): AlFilterModel[] {
  const targetStateFilter: AlFilterModel = new TargetStateFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: [EnabledPausedArchivedState.ENABLED],
        operator: OperatorType.IN,
      },
    ],
  });

  return [...getDefaultCampaignFilters(), targetStateFilter];
}

export function getDefaultNegativeTargetingFilters(): AlFilterModel[] {
  return getDefaultCampaignFilters();
}

export function getDefaultCampaignGroupsFilters(): AlFilterModel[] {
  return getDefaultDateFilters();
}

export function getDefaultCampaignMappingFilters(): AlFilterModel[] {
  return [];
}

export function getDefaultProductsFilters(): AlFilterModel[] {
  return [...getDefaultDateFilters(), getGreaterThanZeroImpressionsFilter()];
}

export function getDefaultAdvertisedProductsFilters(): AlFilterModel[] {
  const adGroupStateFilter: AlFilterModel = new AdGroupStateFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: [EnabledPausedArchivedState.ENABLED],
        operator: OperatorType.IN,
      },
    ],
  });
  return [...getDefaultDateFilters(), getGreaterThanZeroImpressionsFilter(), adGroupStateFilter];
}

// TODO: use everywhere instead of filters.map((filter) => filter.toQueryKey())
export function createQueryKeyFromFilters(filters: AlFilterModel[]): string[] {
  return filters.map((filter) => filter.toQueryKey());
}

// TODO: instead of profile createdAt use profile.dataStartDate
function getLifetimeDateRange(profile: ProfileModel): AlFilterModel[] {
  const today = AlDate.now();

  const parsedDate = profile.createdAt
    ? AlDate.parse(profile.createdAt).subtractDays(MIN_DATE_OFFSET_FROM_CREATION_DAYS)
    : today.subtractDays(MIN_DATE_OFFSET_FROM_CREATION_DAYS);

  const startDate = parsedDate.isValid() ? parsedDate : today.subtractDays(MIN_DATE_OFFSET_FROM_CREATION_DAYS);

  const endDate = today.subtractDays(DATE_PERIOD_LENGTH_DAYS);

  const dateRange: AlFilterModel[] = [
    new ComparisonDateFilterModel({
      logicalOperator: LogicalOperatorType.AND,
      conditions: [
        {
          values: [startDate.subtractDays(DATE_PERIOD_LENGTH_DAYS).toDefaultFormat()],
          operator: OperatorType.GREATER_THAN_OR_EQUAL,
        },
        {
          values: [endDate.subtractDays(DATE_PERIOD_LENGTH_DAYS).toDefaultFormat()],
          operator: OperatorType.LESS_THAN_OR_EQUAL,
        },
      ],
    }),
    new DateFilterModel({
      logicalOperator: LogicalOperatorType.AND,
      conditions: [
        {
          values: [startDate.toDefaultFormat()],
          operator: OperatorType.GREATER_THAN_OR_EQUAL,
        },
        {
          values: [endDate.toDefaultFormat()],
          operator: OperatorType.LESS_THAN_OR_EQUAL,
        },
      ],
    }),
  ];

  return dateRange;
}

export function createTargetingNoStatsFilterSet(profile: ProfileModel): AlFilterModel[] {
  const dateRanges = getLifetimeDateRange(profile);

  const targetStateFilter: AlFilterModel = new TargetStateFilterModel({
    logicalOperator: LogicalOperatorType.OR,
    conditions: [
      {
        values: [EnabledPausedArchivedState.ENABLED, EnabledPausedArchivedState.PAUSED],
        operator: OperatorType.IN,
      },
    ],
  });

  const spendFilter: AlFilterModel = new SpendFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: ['0'],
        operator: OperatorType.EQUAL,
      },
    ],
  });

  const impressionsFilter: AlFilterModel = new ImpressionsFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: ['0'],
        operator: OperatorType.EQUAL,
      },
    ],
  });

  return [spendFilter, impressionsFilter, targetStateFilter, ...dateRanges];
}

export function extractValidFilters(filters: AlFilterModel[]): AlFilterModel[] {
  return filters.filter((f) => f.isValid());
}

export function areFilterSetsEqual(filterSet1: AlFilterModel[], filterSet2: AlFilterModel[]): boolean {
  if (filterSet1.length !== filterSet2.length) {
    return false;
  }

  const sortedFilterSet1DTOs = [...filterSet1].sort((a, b) => (a.key > b.key ? 1 : -1)).map((f) => f.toDTO());
  const sortedFilterSet2DTOs = [...filterSet2].sort((a, b) => (a.key > b.key ? 1 : -1)).map((f) => f.toDTO());

  return JSON.stringify(sortedFilterSet1DTOs) === JSON.stringify(sortedFilterSet2DTOs);
}
