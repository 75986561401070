import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';

export enum PlacementUpdateType {
  SET_PLACEMENT_TO_PERCENTAGE = 'SET_PLACEMENT_TO_PERCENTAGE',
  INCREASE_PLACEMENT_BY_PERCENTAGE_POINTS = 'INCREASE_PLACEMENT_BY_PERCENTAGE_POINTS',
  DECREASE_PLACEMENT_BY_PERCENTAGE_POINTS = 'DECREASE_PLACEMENT_BY_PERCENTAGE_POINTS',
  NO_CHANGE = 'NO_CHANGE',
}

export enum BidUpdateType {
  SET_BID_TO_AMOUNT = 'SET_BID_TO_AMOUNT',
  INCREASE_BID_BY_AMOUNT = 'INCREASE_BID_BY_AMOUNT',
  DECREASE_BID_BY_AMOUNT = 'DECREASE_BID_BY_AMOUNT',
  INCREASE_BID_BY_PERCENTAGE = 'INCREASE_BID_BY_PERCENTAGE',
  DECREASE_BID_BY_PERCENTAGE = 'DECREASE_BID_BY_PERCENTAGE',
  SET_CPC_TIMES_X = 'SET_CPC_TIMES_X',

  NO_CHANGE = 'NO_CHANGE',
}

export type BidUpdateOption = {
  value: BidUpdateType;
  label: string;
};

export interface BidUpdateData {
  bidUpdateType: BidUpdateType;
  newBidValue: number;
}

export interface PlacementUpdateData {
  placementUpdateType: PlacementUpdateType;
  newPlacementValue: number;
}

export interface TotalUpdateData {
  placementUpdateData: PlacementUpdateData;
  bidUpdateData: BidUpdateData;
}

export interface NewBidUpdateDetails {
  currentBid: number;
  updateData: BidUpdateData;
  campaignAdType: CampaignAdType;
  campaignIsVideo: boolean;
  campaignBudgetAmount: number;
  currentCPC: number;
  warnings: Set<string>; // List of warnings that will be filled in place
  includeAdjustedToMessage?: boolean;
}
