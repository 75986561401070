import type {
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  ManagedGridOptionKey,
  RowDataUpdatedEvent,
  RowGroupOpenedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { useState } from 'react';

const DEFAULT_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN = 68;
export const EXPANDED_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN = DEFAULT_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN - 16;

interface useDynamicHeightProps {
  VISIBLE_ABOVE_PX_ON_SCROLL_DOWN?: number;
}

const useDynamicHeight = ({ VISIBLE_ABOVE_PX_ON_SCROLL_DOWN = DEFAULT_VISIBLE_ABOVE_PX_ON_SCROLL_DOWN }: useDynamicHeightProps) => {
  const [heightCssValue, setHeightCssValue] = useState<string>('260px');

  type CombinedType = FirstDataRenderedEvent | RowGroupOpenedEvent | FilterChangedEvent | SortChangedEvent | RowDataUpdatedEvent;
  const functionNames: ManagedGridOptionKey[] = [
    'onFirstDataRendered',
    'onRowGroupOpened',
    'onSortChanged',
    'onFilterChanged',
    'onColumnRowGroupChanged',
    'onRowDataUpdated',
  ];

  const updateRowCount = (gridApi: GridApi) => {
    const displayedRowCount = gridApi.getDisplayedRowCount();
    const cssValue = getTableHeightCssValue(displayedRowCount);

    setHeightCssValue((prevCssValue) => {
      if (prevCssValue !== cssValue) {
        return cssValue;
      }
      return prevCssValue; // No change if the value is the same - should save a rerender usually
    });
  };

  function getTableHeightCssValue(amountOfRows: number): string {
    const minHeight = 500;

    const gridTopBarsHeight = 48 * 2; // 2 top bars
    const filterBar = 56;
    const rowHeight = 67; // products row height
    const dynamicHeightBasedOnAmountOfRows = filterBar + gridTopBarsHeight + rowHeight * amountOfRows;
    const viewportHeight = window.innerHeight;
    const fullScreenHeight = viewportHeight - VISIBLE_ABOVE_PX_ON_SCROLL_DOWN;

    if (minHeight > dynamicHeightBasedOnAmountOfRows && minHeight < fullScreenHeight) {
      return `${minHeight}px`;
    }

    if (dynamicHeightBasedOnAmountOfRows < fullScreenHeight) {
      return `${dynamicHeightBasedOnAmountOfRows + 64}px`; // same as in .extra-padding css class
    }

    return `calc(100vh - ${VISIBLE_ABOVE_PX_ON_SCROLL_DOWN}px)`;
  }

  function onGridReadyCallback(params: GridReadyEvent) {
    for (const functionName of functionNames) {
      const existingFunction = params.api.getGridOption(functionName);
      const newFunction = (event: CombinedType) => {
        if (existingFunction) {
          existingFunction(event);
        }
        updateRowCount(event.api);
      };
      if (newFunction != existingFunction) {
        params.api.setGridOption(functionName, newFunction);
      }
    }
  }

  return { heightCssValue, onGridReadyCallback, updateRowCount };
};

export default useDynamicHeight;
