import { FilterBuilder } from '@/components/filter-builder/FilterBuilder';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { ContextKey } from '@/types/context-shared';
import { Dialog, DialogContent } from '@mui/material';
import { Dispatch, FunctionComponent, RefObject, SetStateAction, useEffect, useState } from 'react';
import { splitFiltersToApplicableAndDiscarded } from './hooks/useFilters';
import { AlFilterModel } from './models/AlFilterModel';
import { FilterPresetModel } from './models/FilterPresetModel';
import { FilterKey } from './types/FilterKey';

interface FilterBuilderDialogProps {
  buttonRef: RefObject<HTMLButtonElement>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  availableFilters: AlFilterModel[];
  defaultFilterKey: FilterKey;
  appliedFilters: AlFilterModel[];
  onFiltersChanged: (filters: AlFilterModel[]) => void;
  contextKey?: ContextKey;
}

export const FilterBuilderDialog: FunctionComponent<FilterBuilderDialogProps> = ({
  isOpen,
  setIsOpen,
  availableFilters,
  defaultFilterKey,
  appliedFilters,
  onFiltersChanged,
  contextKey,
}) => {
  const { activeProfileIdChange, activeProfile } = useActiveTeamContext();

  function onClose() {
    setIsOpen(false);
  }

  function notifyAboutDiscardedFilters(filters: AlFilterModel[]) {
    const discardedFiltersNames = filters.map((filter) => filter.shortName).join(', ');

    toastService.info(`Not applying filters with empty fields: ${discardedFiltersNames}`);
  }

  function onFilterBuilderFiltersChanged(filters: AlFilterModel[]) {
    const { applicableFilters, discardedFilters } = splitFiltersToApplicableAndDiscarded(filters);
    onFiltersChanged(applicableFilters);
    if (discardedFilters.length > 0) notifyAboutDiscardedFilters(discardedFilters);
    onClose();
  }

  const defaultFilter = availableFilters.find((f) => f.key == defaultFilterKey);
  const filtersShownOnOpen =
    appliedFilters.filter((f) => f.isFilterBuilderFilter).length > 0 || !defaultFilter ? appliedFilters : [defaultFilter, ...appliedFilters];

  // FILTER PRESETS
  // Persistant state over open/closing the modal
  const [selectedPreset, setSelectedPreset] = useState<FilterPresetModel | 'new' | null>(null);
  useEffect(() => {
    if (activeProfileIdChange && activeProfileIdChange.hasChanged && activeProfile?.id) {
      setSelectedPreset(null);
    }
  }, [activeProfileIdChange]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={contextKey ? 'lg' : 'md'} fullWidth>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden', // Prevent DialogContent from forcing expansion
        }}
      >
        <FilterBuilder
          onSearch={onFilterBuilderFiltersChanged}
          filtersDefaultValue={filtersShownOnOpen}
          availableFilters={availableFilters}
          onCancel={onClose}
          onClose={onClose}
          contextKey={contextKey}
          selectedPreset={selectedPreset}
          setSelectedPreset={setSelectedPreset}
        />
      </DialogContent>
    </Dialog>
  );
};
