import OppositeModeOverlayBar from '@/components/OppositeModeOverlayBar';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import { scrollToRowWithId } from '@/components/grid/utils';
import { Environment } from '@/config/Environment';
import useFormatting from '@/hooks/useFormatting';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import useBidLimits from '@/modules/amazon-constants/hooks/useBidLimits';
import BugButton from '@/modules/application/components/BugButton';
import useCampaignToAdGroupsMappingData from '@/modules/campaign-mapping/hooks/useCampaignToAdGroupsMappingData';
import { CampaignMappingModel } from '@/modules/campaign-mapping/models/CampaignMappingModel';
import {
  AD_GROUP_NEGATIVES,
  CAMPAIGN_NEGATIVES,
  KEYWORD_NEGATIVES,
  PRODUCT_TARGET_NEGATIVES,
} from '@/modules/negative-targets/api/negative-targets-contracts';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SendIcon from '@mui/icons-material/Send';
import { Button, Checkbox, Divider, Tooltip, useTheme } from '@mui/material';
import type { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, useRef, useState } from 'react';
import useKeywordHarvestingErrors from '../hooks/useKeywordHarvestingErrors';
import { BidUpdateData, BidUpdateType } from '../models/BidUpdate';
import { KeywordHarvestingModel } from '../models/KeywordHarvestingModel';
import { KeywordHarvestingPreviewDataRow } from '../models/KeywordHarvestingPreviewDataRow';
import KeywordHarvestingBulkEditPopover from './KeywordHarvestingBulkEditPopover';

interface KeywordHarvestingPreviewActionsBarProps {
  harvestingPreviewSelection: KeywordHarvestingPreviewDataRow[];
  visibleRowCount: number;
  onApply: () => void;
  isApplyLoading: boolean;
  keywordHarvestingData: KeywordHarvestingModel | null;
  keywordHarvestingTableApi: GridApi<KeywordHarvestingPreviewDataRow> | null;
  setHarvestingPreviewSelection: (harvestingPreviewSelection: KeywordHarvestingPreviewDataRow[]) => void;
}

const KeywordHarvestingPreviewActionsBar: FunctionComponent<KeywordHarvestingPreviewActionsBarProps> = ({
  harvestingPreviewSelection,
  visibleRowCount,
  onApply,
  isApplyLoading,
  keywordHarvestingData,
  keywordHarvestingTableApi,
  setHarvestingPreviewSelection,
}) => {
  const theme = useTheme();
  const { formatWithThousandsSeparator } = useFormatting();
  const { toastWarnWithSetMessages } = useHelperComponents();
  const { campaignToAdGroupsMap } = useCampaignToAdGroupsMappingData();

  // CONFIRMATION CHECKBOX
  const [isConfirmationCheckboxChecked, setIsConfirmationCheckboxChecked] = useState(false);
  const handleConfirmationCheckboxChange = () => {
    setIsConfirmationCheckboxChecked((prevState) => !prevState);
  };

  // BULK EDIT
  const { getClampedBidWithWarnings } = useBidLimits();
  const bulkEditButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isEditSelectionModalOpen, setIsEditSelectionModalOpen] = useState(false);
  function onEditSelectionClicked() {
    setIsEditSelectionModalOpen(true);
  }

  function onApplySelectionEdit(updateData: BidUpdateData) {
    if (!keywordHarvestingData) return;

    // Apply updates to preview data rows
    keywordHarvestingData.keywordHarvestingPreviewData = applyUpdatesToPreviewDataRows(
      keywordHarvestingData.keywordHarvestingPreviewData,
      updateData,
      harvestingPreviewSelection,
    );

    // Refresh edited cells
    if (keywordHarvestingTableApi) {
      keywordHarvestingTableApi.refreshCells({
        columns: [ColumnId.BID, ColumnId.NEGATIVE_AD_GROUP, ColumnId.NEGATIVE_CAMPAIGN],
      });
    }

    // Update the selection
    setHarvestingPreviewSelection(
      harvestingPreviewSelection.map((harvestingApplyData) => {
        const newModel = new KeywordHarvestingPreviewDataRow(harvestingApplyData.dto);

        const adjustedPreviewData = keywordHarvestingData.keywordHarvestingPreviewData.find((pd) => harvestingApplyData.id === pd.id);
        newModel.bid = adjustedPreviewData?.bid ?? harvestingApplyData.bid;

        newModel.adGroupNegativeExact = adjustedPreviewData?.adGroupNegativeExact ?? harvestingApplyData.adGroupNegativeExact;
        newModel.adGroupNegativePhrase = adjustedPreviewData?.adGroupNegativePhrase ?? harvestingApplyData.adGroupNegativePhrase;
        newModel.adGroupNegativeProductTarget =
          adjustedPreviewData?.adGroupNegativeProductTarget ?? harvestingApplyData.adGroupNegativeProductTarget;
        newModel.campaignNegativeExact = adjustedPreviewData?.campaignNegativeExact ?? harvestingApplyData.campaignNegativeExact;
        newModel.campaignNegativePhrase = adjustedPreviewData?.campaignNegativePhrase ?? harvestingApplyData.campaignNegativePhrase;
        newModel.campaignNegativeProductTarget =
          adjustedPreviewData?.campaignNegativeProductTarget ?? harvestingApplyData.campaignNegativeProductTarget;
        return newModel;
      }),
    );
  }

  function applyUpdatesToPreviewDataRows(
    previewDataRows: KeywordHarvestingPreviewDataRow[],
    updateData: BidUpdateData,
    selectedRows: KeywordHarvestingPreviewDataRow[],
  ): KeywordHarvestingPreviewDataRow[] {
    if (Environment.isDev()) {
      console.log({
        previewDataRows,
        updateData,
        selectedRows,
      });
    }

    const warnings = new Set<string>();

    const modifiedRows = previewDataRows.map((row) => {
      if (selectedRows.some((selectedRow) => selectedRow.id === row.id)) {
        if (updateData.bidUpdateType === BidUpdateType.SET_NEGATIVES) {
          applyNegativesUpdate(row, updateData, warnings);
        } else {
          applyBidUpdate(row, updateData, warnings);
        }
      }
      return row;
    });

    if (warnings.size > 0) {
      toastWarnWithSetMessages(warnings);
    }

    return modifiedRows;
  }

  function applyNegativesUpdate(row: KeywordHarvestingPreviewDataRow, updateData: BidUpdateData, warnings: Set<string>) {
    let negativesToAdd = updateData.selectedNegatives.filter((n) => {
      if (row.destinationAdGroupEntityType === TargetEntityType.KEYWORD) {
        return KEYWORD_NEGATIVES.includes(n);
      } else if (row.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET) {
        return PRODUCT_TARGET_NEGATIVES.includes(n);
      }

      return false;
    });

    // Convert harvesting row to campaign mapping for validation
    const campaignMapping = CampaignMappingModel.fromKeywordHarvestingPreviewDataRow(row);

    // TODO: move the following section into CampaignMappingModel to reduce duplication between here and campaign mapping bulk edit
    // AD GROUP NEGATIVES
    if (negativesToAdd.some((n) => AD_GROUP_NEGATIVES.includes(n))) {
      const createNegativeAdGroupsWarning = campaignMapping.createNegativeAdGroupsWarning(campaignToAdGroupsMap);
      if (createNegativeAdGroupsWarning) {
        negativesToAdd = negativesToAdd.filter((n) => !AD_GROUP_NEGATIVES.includes(n));
        warnings.add(createNegativeAdGroupsWarning);
      }
    }

    // CAMPAIGN NEGATIVES
    if (negativesToAdd.some((n) => CAMPAIGN_NEGATIVES.includes(n))) {
      const createNegativeCampaignWarning = campaignMapping.createNegativeCampaignWarning(campaignToAdGroupsMap);
      if (createNegativeCampaignWarning) {
        negativesToAdd = negativesToAdd.filter((n) => !CAMPAIGN_NEGATIVES.includes(n));
        warnings.add(createNegativeCampaignWarning);
      }
    }

    row.setNegativesFromArray(negativesToAdd);
  }

  function applyBidUpdate(row: KeywordHarvestingPreviewDataRow, updateData: BidUpdateData, warnings: Set<string>) {
    let newValue;
    switch (updateData.bidUpdateType) {
      case BidUpdateType.SET_BID_TO_AMOUNT:
        newValue = updateData.newBidValue;
        break;
      case BidUpdateType.INCREASE_BID_BY_AMOUNT:
        newValue = row.bid + updateData.newBidValue;
        break;
      case BidUpdateType.DECREASE_BID_BY_AMOUNT:
        newValue = row.bid - updateData.newBidValue;
        break;
      case BidUpdateType.INCREASE_BID_BY_PERCENTAGE:
        newValue = row.bid * (1 + updateData.newBidValue / 100);
        break;
      case BidUpdateType.DECREASE_BID_BY_PERCENTAGE:
        newValue = row.bid * (1 - updateData.newBidValue / 100);
        break;
      case BidUpdateType.NO_CHANGE:
      default:
        return;
    }

    let clampedValue = getClampedBidWithWarnings(
      newValue,
      row.destinationCampaignAdType,
      row.destinationCampaignIsVideo,
      row.destinationCampaignBudgetAmount,
      warnings,
    );

    if (clampedValue > row.bidCeiling && row.bidCeiling) {
      warnings.add(`Bid is above ceiling. Setting to ceiling`);
      clampedValue = row.bidCeiling;
    }
    row.bid = clampedValue;
  }

  // HANDLE SELECTION
  function onDeselectAll() {
    setHarvestingPreviewSelection([]);
    if (keywordHarvestingTableApi) {
      keywordHarvestingTableApi.deselectAll();
    }
  }

  function onSelectAll() {
    if (keywordHarvestingTableApi) {
      keywordHarvestingTableApi.selectAll();
    }
  }

  // Error feedback
  const { errorDetails } = useKeywordHarvestingErrors(keywordHarvestingTableApi);

  function onGoToErrorRowClicked() {
    scrollToRowWithId(errorDetails.firstErrorRowId, keywordHarvestingTableApi);
  }

  return (
    <>
      <OppositeModeOverlayBar>
        <div className="flex flex-row gap-4 items-center px-1.5">
          <Tooltip title="Deselect all">
            <span onClick={onDeselectAll} className="flex whitespace-nowrap text-sm font-bold items-center hover:cursor-pointer">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(harvestingPreviewSelection.length)} of {formatWithThousandsSeparator(visibleRowCount)}
            </span>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <Button
            variant={isEditSelectionModalOpen ? 'contained' : 'outlined'}
            ref={bulkEditButtonRef}
            onClick={onEditSelectionClicked}
            startIcon={<EditRoundedIcon />}
            disabled={harvestingPreviewSelection.length === 0}
            className="flex-shrink-0"
          >
            {harvestingPreviewSelection.length > 0
              ? `Edit ${formatWithThousandsSeparator(harvestingPreviewSelection.length)} item${harvestingPreviewSelection.length > 1 ? 's' : ''}`
              : 'Edit Selection'}
          </Button>

          <Divider className="my-2" orientation="vertical" flexItem />

          <BugButton />

          <Divider className="my-2" orientation="vertical" flexItem />

          {errorDetails.errorCount > 0 && (
            <Tooltip title={'Go to error row'}>
              <span onClick={onGoToErrorRowClicked} className="flex items-center font-medium cursor-pointer">
                <ErrorOutlineIcon fontSize="small" style={{ marginRight: '6px', color: theme.palette.error.light }} />
                {errorDetails.errorCount} {errorDetails.errorCount == 1 ? 'Error' : 'Errors'}
              </span>
            </Tooltip>
          )}

          <Button
            className="whitespace-nowrap"
            onClick={harvestingPreviewSelection.length == visibleRowCount ? onDeselectAll : onSelectAll}
            variant="text"
          >
            {harvestingPreviewSelection.length == visibleRowCount ? 'Deselect All' : 'Select All'}
          </Button>

          <span onClick={handleConfirmationCheckboxChange} className="flex whitespace-nowrap text-sm items-center hover:cursor-pointer">
            <Checkbox checked={isConfirmationCheckboxChecked} name="confirmationCheckbox" color="secondary" />I confirm changes
          </span>

          <Button
            onClick={onApply}
            endIcon={<SendIcon />}
            loading={isApplyLoading}
            loadingPosition="end"
            variant="contained"
            disabled={!isConfirmationCheckboxChecked || harvestingPreviewSelection.length == 0}
            color="primary"
            className="whitespace-nowrap"
          >
            <span>Add Targets</span>
          </Button>
        </div>
      </OppositeModeOverlayBar>

      {isEditSelectionModalOpen && (
        <KeywordHarvestingBulkEditPopover
          selectedItems={harvestingPreviewSelection}
          isOpen={isEditSelectionModalOpen}
          onClose={() => setIsEditSelectionModalOpen(false)}
          onApply={onApplySelectionEdit}
          buttonRef={bulkEditButtonRef}
        />
      )}
    </>
  );
};

export default KeywordHarvestingPreviewActionsBar;
