import { FunctionComponent, PropsWithChildren } from 'react';

import { Button, Paper } from '@mui/material';

interface SettingsCardProps extends PropsWithChildren {
  title: string;
  buttonTitle: string;
  onClick: () => void;
  isUpdating: boolean;
  isButtonDisabled?: boolean;
}

export const SettingsCard: FunctionComponent<SettingsCardProps> = ({
  title,
  buttonTitle,
  onClick,
  isButtonDisabled,
  isUpdating,
  children,
}) => {
  return (
    <Paper elevation={3} className="flex flex-col w-84 justify-between p-4">
      <div className="flex flex-col gap-y-2 ">
        <div className="font-semibold text-slate-600">{title}</div>
        {children}
      </div>

      <Button className="mt-4" variant="contained" onClick={onClick} disabled={isButtonDisabled} loading={isUpdating}>
        {buttonTitle}
      </Button>
    </Paper>
  );
};
