import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext, userService } from '@/modules/users';
import { toastService } from '@/services/toast.service';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useState } from 'react';
import RegistrationWizardPage from '../components/RegistrationWizardPage';
import RegistrationWizardPageTitle from '../components/RegistrationWizardPageTitle';
import { useRegistrationContext } from '../contexts/registration/RegistrationContext';

const RegistrationConnectAmazonAccountPage: FunctionComponent = () => {
  const { teamId: registrationTeamID, userId: registrationUserID } = useRegistrationContext();
  const { user } = useUserContext();
  const { activeTeam } = useActiveTeamContext();
  const userId = user ? user.id : registrationUserID;
  const teamId = activeTeam ? activeTeam.id : registrationTeamID;

  async function getAuthUrl(): Promise<string> {
    if (userId && teamId) {
      const res = await userService.getAmazonAuthorizationUrl(userId, teamId, true);

      if (res.isSuccess) {
        return res.payload;
      } else {
        toastService.error('Unable to get auth url. Please try again later.');
      }
    } else {
      toastService.error('User or active team not set.');
    }

    return '';
  }

  const [isGetAuthUrlLoading, setIsGetAuthUrlLoading] = useState(false);
  async function onConnectAmazonClicked() {
    setIsGetAuthUrlLoading(true);
    const authUrl = await getAuthUrl();
    setIsGetAuthUrlLoading(false);
    if (isEmpty(authUrl)) {
      toastService.error('Something went wrong. Auth url is empty. Please try again later.');
    } else {
      window.location.replace(authUrl); // user will not be able to use browser's back button
    }
  }

  return (
    <RegistrationWizardPage>
      <div className="flex flex-col items-center pt-10">
        <RegistrationWizardPageTitle>Please connect your Amazon Advertising</RegistrationWizardPageTitle>
        <div className="mt-4 text-md max-w-xl text-center text-xl text-slate-600">
          We&apos;ve observed that users who utilized their own profiles during the onboarding call have been able to extract significantly
          more value from AdLabs.
        </div>
        <Button size="large" className="mt-4 min-w-32" variant="contained" onClick={onConnectAmazonClicked} loading={isGetAuthUrlLoading}>
          Connect Amazon and take me to AdLabs
        </Button>
        <div className="mt-4 items-center">
          <Typography>
            <a href="https://help.adlabs.app/en/articles/20-why-do-you-need-to-connect-amazon" target="_blank" rel="noopener noreferrer">
              Why does AdLabs need me to connect my Amazon Account?
            </a>
          </Typography>
        </div>
      </div>
    </RegistrationWizardPage>
  );
};

export default RegistrationConnectAmazonAccountPage;
