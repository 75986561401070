import { CurrencyCode } from '@/modules/amazon-constants/types/CurrencyCode';
import { useQuery } from '@tanstack/react-query';
import { toastService } from '@/services/toast.service';
import { currencyService } from '../api/currency-service';

const useCurrency = (baseCurrencyCode = CurrencyCode.USD) => {
  const { data: conversionRatesModel } = useQuery({
    queryKey: currencyService.createConversionRatesQueryKey(baseCurrencyCode),
    queryFn: async () => {
      const result = await currencyService.getConversionRates(baseCurrencyCode);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toastService.error(`Error loading rates ${result.message}`);
      }
    },
    enabled: true,
  });

  return { conversionRatesModel };
};

export default useCurrency;
