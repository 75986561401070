import { ColumnId } from '@/components/grid/columns/columns.enum';
import type { ColumnState } from 'ag-grid-enterprise';

export function generateOptimizationTableDefaultColumnState(isAdminModeActive: boolean = false): ColumnState[] {
  const defaultColumnState = [
    { colId: ColumnId.CHECKBOX, hide: false, pinned: true },
    { colId: ColumnId.ID, hide: true, pinned: true },
    { colId: ColumnId.CAMPAIGN_AD_TYPE, hide: false, pinned: true },
    { colId: ColumnId.BIDDING_ENTITY, hide: false, pinned: true },
    { colId: ColumnId.TARGETING, hide: false, pinned: true },
    { colId: ColumnId.CAMPAIGN_NAME, hide: false, pinned: true },
    { colId: ColumnId.PORTFOLIO_NAME, hide: false, pinned: false },
    { colId: ColumnId.GROUP_NAME, hide: true, pinned: false },
    { colId: ColumnId.MATCH, hide: true, pinned: false },
    { colId: ColumnId.AD_GROUP, hide: true, pinned: false },
    { colId: ColumnId.GROUP_TACOS, hide: false, pinned: false },
    { colId: ColumnId.GROUP_PRESET, hide: false, pinned: false },
    { colId: ColumnId.OLD_VALUE, hide: false, pinned: false },
    { colId: ColumnId.NEW_VALUE, hide: false, pinned: false },
    { colId: ColumnId.DELTA, hide: false, pinned: false },
    { colId: ColumnId.REASONS, hide: false, pinned: false },
    { colId: ColumnId.CAMPAIGN_LAST_OPTIMIZED, hide: false, pinned: false },
    { colId: ColumnId.ACOS, hide: false, pinned: false },
    { colId: ColumnId.ACTC, hide: true, pinned: false },
    { colId: ColumnId.SPEND, hide: false, pinned: false },
    { colId: ColumnId.SALES, hide: false, pinned: false },
    { colId: ColumnId.IMPRESSIONS, hide: false, pinned: false },
    { colId: ColumnId.CLICKS, hide: false, pinned: false },
    { colId: ColumnId.ORDERS, hide: false, pinned: false },
    { colId: ColumnId.UNITS, hide: false, pinned: false },
    { colId: ColumnId.CTR, hide: false, pinned: false },
    { colId: ColumnId.CVR, hide: false, pinned: false },
    { colId: ColumnId.CPC, hide: false, pinned: false },
    { colId: ColumnId.ROAS, hide: true, pinned: false },
    { colId: ColumnId.RPC, hide: true, pinned: false },
    { colId: ColumnId.CPA, hide: true, pinned: false },
    { colId: ColumnId.AOV, hide: true, pinned: false },
    { colId: ColumnId.CPM, hide: true, pinned: false },
  ];

  // Additional column when isAdminModeActive is true
  if (isAdminModeActive) {
    defaultColumnState.unshift({ colId: ColumnId.DEBUG, hide: false, pinned: false });
  }

  return defaultColumnState;
}
