import { PlanType } from '@/modules/plans/types/SubscriptionPlan';
import { ReactNode } from 'react';

export class AdLabsPlanModel {
  planType: PlanType;
  name: ReactNode;
  price: number;
  features: string[];
  priceDescription: ReactNode;
  priceLabel: ReactNode;

  constructor(args: AdLabsPlanArguments) {
    this.planType = args.planType;
    this.name = args.name;
    this.price = args.price;
    this.features = args.features;
    this.priceDescription = args.priceDescription;
    this.priceLabel = args.priceLabel;
  }
}

interface AdLabsPlanArguments {
  planType: PlanType;
  name: ReactNode;
  price: number;
  features: string[];
  priceDescription: ReactNode;
  priceLabel: ReactNode;
}
