import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography, useTheme } from '@mui/material';
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';

interface InfoMessageProps extends PropsWithChildren {
  content: ReactNode;
}

const InfoMessage: FunctionComponent<InfoMessageProps> = ({ content }) => {
  const theme = useTheme();
  return (
    <div className="flex flex-row mt-2 w-full items-center">
      <InfoOutlinedIcon fontSize="small" style={{ marginRight: '8px', color: theme.palette.info.main }} />
      <Typography variant="body2" component="div">
        {content}
      </Typography>
    </div>
  );
};

export default InfoMessage;
