import type { ColDef, ColGroupDef, GridApi } from 'ag-grid-enterprise';

export function scrollToRowWithId<T>(rowId: string | null, api: GridApi<T> | null) {
  if (!api || !rowId) return;
  const rowNode = api.getRowNode(rowId); // Get the row node by ID
  if (rowNode) {
    api.ensureNodeVisible(rowNode, 'middle'); // Scroll to the row and center it
  } else {
    console.warn(`Row with ID ${rowId} not found`);
  }
}

// Type guard to check if the column is a ColDef
export function isColDef(column: ColDef | ColGroupDef): column is ColDef {
  return 'field' in column || 'valueGetter' in column || 'headerName' in column;
}
