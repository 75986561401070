import { MetricData, MetricDataWithPreviousDays } from '@/components/metrics/types/MetricData';
import { SelectedEntityForDataGroup } from '@/modules/data-groups/models/DataItem';
import { DTO } from '@/types/dto-wrapper';
import { isEmpty, isNil } from 'lodash-es';
import {
  AdGroupDTO,
  AmazonBrandsBidOptimization,
  BidStrategyType,
  CampaignAdType,
  CampaignDTO,
  CostType,
  CreativeType,
  EnabledPausedArchivedState,
  MultiAdGroupsEnabledType,
  TargetingType,
} from '../campaign-contracts';

export class CampaignModel extends DTO<CampaignDTO> {
  constructor(data: CampaignDTO) {
    super(data);
  }

  public get id(): string {
    return this.dto.i;
  }

  public get name(): string {
    return this.dto.n;
  }

  public get adType(): CampaignAdType {
    return this.dto.ad;
  }

  public get portfolioId(): string | null {
    return isNil(this.dto.pi) ? null : this.dto.pi;
  }

  public get portfolioName(): string | null {
    return isNil(this.dto.p) ? null : this.dto.p;
  }

  public get creativeType(): CreativeType {
    return isEmpty(this.dto.cr) ? CreativeType.NONE : this.dto.cr;
  }

  public get costType(): CostType {
    return isEmpty(this.dto.co) ? CostType.NONE : this.dto.co;
  }

  public get multiAdGroupsEnabled(): MultiAdGroupsEnabledType {
    return this.dto.mag == true
      ? MultiAdGroupsEnabledType.TRUE
      : this.dto.mag == false
        ? MultiAdGroupsEnabledType.FALSE
        : MultiAdGroupsEnabledType.NONE;
  }

  public get hasOptRule(): boolean {
    // existing opt rule doesn't allow bid changing (manual change, optimizing etc)
    return this.dto.o ?? false;
  }

  public get groupId(): number {
    return this.dto.gi;
  }

  public get groupName(): string {
    return this.dto.gn;
  }

  public get state(): EnabledPausedArchivedState {
    return this.dto.s;
  }

  public get startDate(): string | null {
    return this.dto.sd;
  }

  public get endDate(): string | null {
    return this.dto.ed;
  }

  public get targetingType(): TargetingType {
    return this.dto.tt;
  }

  public get budgetAmount(): number {
    return this.dto.ba;
  }

  public get bidStrategy(): BidStrategyType {
    return this.dto.bs;
  }

  public get bidOptimization(): AmazonBrandsBidOptimization {
    return isEmpty(this.dto.bo) ? AmazonBrandsBidOptimization.NONE : this.dto.bo;
  }

  public get lastOptimizedAt(): string | null {
    return this.dto.lo;
  }

  // Metric getters
  public get impressions(): MetricData {
    return this.dto.impressions;
  }

  public get clicks(): MetricData {
    return this.dto.clicks;
  }

  public get orders(): MetricData {
    return this.dto.orders;
  }

  public get units(): MetricData {
    return this.dto.units;
  }

  public get ctr(): MetricData {
    return this.dto.ctr;
  }

  public get cvr(): MetricData {
    return this.dto.cvr;
  }

  public get cpc(): MetricData {
    return this.dto.cpc;
  }

  public get spend(): MetricDataWithPreviousDays {
    return this.dto.spend;
  }

  public get sales(): MetricDataWithPreviousDays {
    return this.dto.sales;
  }

  public get sso(): MetricData {
    return this.dto.sso;
  }

  public get sss(): MetricData {
    return this.dto.sss;
  }

  public get acos(): MetricData {
    return this.dto.acos;
  }

  public get actc(): MetricData {
    return this.dto.actc;
  }

  public get roas(): MetricData {
    return this.dto.roas;
  }

  public get rpc(): MetricData {
    return this.dto.rpc;
  }

  public get cpa(): MetricData {
    return this.dto.cpa;
  }

  public get aov(): MetricData {
    return this.dto.aov;
  }

  public get cpm(): MetricData {
    return this.dto.cpm;
  }

  public get campaignDataItemIds(): number[] {
    return this.dto.cd ?? [];
  }

  public get hasEnded(): boolean {
    return !isNil(this.endDate) && new Date(this.endDate) < new Date();
  }

  // Static methods
  public static fromResponse(dto: CampaignDTO): CampaignModel {
    return new CampaignModel(dto);
  }

  public static fromResponseArray(dtos: CampaignDTO[] | null): CampaignModel[] {
    return isNil(dtos) ? [] : dtos.map(CampaignModel.fromResponse);
  }
}

export interface SelectedCampaignDTO extends SelectedEntityForDataGroup {
  name: string;
  groupId: number;
  adType: CampaignAdType;
  state: EnabledPausedArchivedState;
  costType: CostType;
  bidStrategy: BidStrategyType;
  bidOptimization: AmazonBrandsBidOptimization;
  multiAdGroupsEnabled: MultiAdGroupsEnabledType;
  hasOptRule: boolean;
  endDate: null | string;
  lastOptimizedAt: string | null;
  budgetAmount: number;
}

export interface CampaignToAdGroupsDTO {
  i: string;
  n: string;
  at: CampaignAdType;
  ags: AdGroupDTO[];
  s: EnabledPausedArchivedState;
  tt: TargetingType;
  // targeting: string;
  iv: boolean;
  cb: number; //campaign budget amount
}
