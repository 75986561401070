import { JSX, useEffect, useContext, useState, useRef } from 'react';
import { init, getInstanceByDom } from 'echarts';
import type { CSSProperties } from 'react';
import type { EChartsOption, ECharts, SetOptionOpts, EChartsType } from 'echarts';
import { ColorModeContext } from '@/modules/application';
import useEchartsResize from './hooks/useEchartsResize';

interface ReactEChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  onChartReady?: (instance: ECharts) => void;
}

export function ReactECharts({ option, style, settings, loading, onChartReady }: ReactEChartsProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null);
  const { mode: theme } = useContext(ColorModeContext);

  const [echarts, setEcharts] = useState<EChartsType>();
  const containerDivRef = useEchartsResize(echarts);

  useEffect(() => {
    // Initialize chart
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current, theme);
      if (onChartReady) {
        onChartReady(chart);
      }
      setEcharts(chart);
    }

    // Return cleanup function
    return () => {
      chart?.dispose();
    };
  }, [theme]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (chart === undefined) {
        return;
      }

      option.backgroundColor = 'transparent';
      chart.setOption(option, settings);
    }
  }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (chart === undefined) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      loading === true ? chart.showLoading() : chart.hideLoading();
    }
  }, [loading, theme]);
  return (
    <div ref={containerDivRef} className="h-full w-full flex-1">
      <div ref={chartRef} style={{ width: '100%', height: '100%', ...style }} />
    </div>
  );
}
