import { ColumnId } from '@/components/grid/columns/columns.enum';
import type { ColumnState } from 'ag-grid-enterprise';

export function generateCampaignMappingImportPreviewTableColumnState(): ColumnState[] {
  return [
    { colId: ColumnId.CHECKBOX, hide: false, pinned: 'left' },
    { colId: ColumnId.CAMPAIGN_NAME, hide: false, pinned: 'left' },
    { colId: ColumnId.AD_GROUP, hide: false, pinned: 'left' },
    { colId: ColumnId.CAMPAIGN_AD_TYPE, hide: false },
    { colId: ColumnId.CAMPAIGN_NAME_DESTINATION, hide: false },
    { colId: ColumnId.AD_GROUP_DESTINATION, hide: false },
    { colId: ColumnId.ENTITY_TYPE, hide: false },
    { colId: ColumnId.BID_METHOD, hide: false },
    { colId: ColumnId.BID_METHOD_VALUE, hide: false },
    { colId: ColumnId.BID_FLOOR, hide: false },
    { colId: ColumnId.BID_CEILING, hide: false },
    { colId: ColumnId.MATCH, hide: false },
    { colId: ColumnId.NEGATIVE_CAMPAIGN, hide: false },
    { colId: ColumnId.NEGATIVE_AD_GROUP, hide: false },
    { colId: ColumnId.ACTIONS, hide: false, pinned: 'right' },
  ];
}
