import { FunctionComponent } from 'react';
import { Editor } from '@tiptap/react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import { isEmpty } from 'lodash-es';

interface RichTextEditorMenuBarProps {
  editor: Editor | null;
}

const RichTextEditorMenuBar: FunctionComponent<RichTextEditorMenuBarProps> = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="flex flex-col items-start gap-2">
      <div className="flex flex-wrap items-center gap-2 origin-top-left">
        {/* Heading buttons */}
        <ToggleButtonGroup
          size="small"
          exclusive
          value={
            editor.isActive('heading', { level: 1 })
              ? 'h1'
              : editor.isActive('heading', { level: 2 })
                ? 'h2'
                : editor.isActive('heading', { level: 3 })
                  ? 'h3'
                  : ''
          }
          onChange={(_, value) => {
            if (value === 'h1') editor.chain().focus().toggleHeading({ level: 1 }).run();
            if (value === 'h2') editor.chain().focus().toggleHeading({ level: 2 }).run();
            if (value === 'h3') editor.chain().focus().toggleHeading({ level: 3 }).run();
            if (isEmpty(value)) editor.chain().focus().setParagraph().run();
          }}
        >
          <ToggleButton value="h1">
            <span className="text-xs leading-[15px]">H1</span>
          </ToggleButton>
          <ToggleButton value="h2">
            <span className="text-xs leading-[15px]">H2</span>
          </ToggleButton>
          <ToggleButton value="h3">
            <span className="text-xs leading-[15px]">H3</span>
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Text formatting buttons */}
        <ToggleButtonGroup size="small" aria-label="text formatting">
          <ToggleButton value="bold" selected={editor.isActive('bold')} onClick={() => editor.chain().focus().toggleBold().run()}>
            <FormatBoldIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
          <ToggleButton value="italic" selected={editor.isActive('italic')} onClick={() => editor.chain().focus().toggleItalic().run()}>
            <FormatItalicIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Alignment buttons */}
        <ToggleButtonGroup
          size="small"
          exclusive
          value={
            editor.isActive({ textAlign: 'left' })
              ? 'left'
              : editor.isActive({ textAlign: 'center' })
                ? 'center'
                : editor.isActive({ textAlign: 'right' })
                  ? 'right'
                  : editor.isActive({ textAlign: 'justify' })
                    ? 'justify'
                    : ''
          }
          onChange={(_, value) => {
            if (value === 'left') editor.chain().focus().setTextAlign('left').run();
            if (value === 'center') editor.chain().focus().setTextAlign('center').run();
            if (value === 'right') editor.chain().focus().setTextAlign('right').run();
            if (value === 'justify') editor.chain().focus().setTextAlign('justify').run();
          }}
        >
          <ToggleButton value="left">
            <FormatAlignLeftIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
          <ToggleButton value="center">
            <FormatAlignCenterIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
          <ToggleButton value="right">
            <FormatAlignRightIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
          <ToggleButton value="justify">
            <FormatAlignJustifyIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default RichTextEditorMenuBar;
