import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import { useTranslation } from '@/lib/i18n/useTranslate';
import { Card } from '@mui/material';
import type { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { isEmpty } from 'lodash-es';

import { FunctionComponent, useMemo, useRef } from 'react';

interface LogPreviewTableProps {
  rowData: object[] | undefined;
  isLoading: boolean;
  isLoadingError: boolean;
  loadingErrorMessage: string;
  headerTranslationKey?: string;
}

const LogPreviewTable: FunctionComponent<LogPreviewTableProps> = ({
  rowData,
  isLoading,
  isLoadingError,
  loadingErrorMessage,
  headerTranslationKey,
}) => {
  const gridApiRef = useRef<GridApi>();
  const { t } = useTranslation();

  const columnDefs: ColDef[] = useMemo(() => {
    if (!rowData || rowData.length === 0) {
      return []; // Return an empty array if data is empty or undefined
    }

    const columns = new Set<string>();

    for (const item of rowData) {
      for (const key in item) {
        columns.add(key);
      }
    }

    return Array.from(columns).map((key) => {
      let headerName = (key.charAt(0).toUpperCase() + key.slice(1)).replace(/_/g, ' ');
      if (headerTranslationKey) {
        const translatedHeaderName = t(headerTranslationKey + '.' + key);
        if (!translatedHeaderName.includes(headerTranslationKey)) {
          headerName = translatedHeaderName;
        }
      }

      return {
        headerName,
        field: key,
        valueGetter: (params) => {
          const value = params.data[key];
          return typeof value === 'object' && value !== null ? JSON.stringify(value) : value;
        },
      };
    });
  }, [rowData]);

  const customGridOptions: GridOptions = useMemo(() => {
    return {
      ...DEFAULT_GRID_OPTIONS,
      //   getRowId: (params) => params.data.id.toString(),
    };
  }, []);

  function onGridReady(params: GridReadyEvent) {
    gridApiRef.current = params.api;
  }

  if (!isLoading && (!rowData || isEmpty(rowData))) {
    return <div>No Data</div>;
  }

  return (
    <>
      {isLoadingError ? (
        <Card className="flex-grow rounded-xl py-0">
          <ErrorLoadingDataAlert details={loadingErrorMessage} />
        </Card>
      ) : (
        <div style={{ height: `calc(100vh - 220px)` }}>
          <AlGrid
            colDefs={columnDefs}
            rowData={rowData}
            gridOptions={customGridOptions}
            isLoading={isLoading}
            onGridReadyCallback={onGridReady}
            noTopBorderRadius={false}
            fitToResizeEnabled={true}
            addExtraBottomPadding={false}
          />
        </div>
      )}
    </>
  );
};

export default LogPreviewTable;
