import InfoMessage from '@/components/feedback/InfoMessage';
import LoadingOverlay from '@/components/feedback/LoadingOverlay';
import { AlMultiSelectOptionModel } from '@/components/filter-builder/models/AlMultiSelectOptionModel';
import { AlMultiSelect } from '@/components/form/AlMultiSelect';
import { AlMultiTextInput } from '@/components/form/AlMultiTextInput';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import { useTranslation } from '@/lib';
import { cleanAsinsReturnErrorMessage, removeInvalidKeywords } from '@/modules/application/amazon-utils';
import { UpdateResponseDTO } from '@/modules/application/components/UpdateResponseModal';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';
import { CampaignAdType, EnabledPausedArchivedState } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { CampaignService, campaignService } from '@/modules/optimizer/api/campaign/campaign-service';
import { AdGroupModel } from '@/modules/optimizer/api/campaign/models/AdGroupModel';
import { CREATE_NEGATIVES_DEFAULT_PARAMS, CreateNegativesParams } from '@/modules/search-terms/models/SearchTermModel';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { toastService } from '@/services/toast.service';
import { TargetEntityTypeColors, TargetingTypeColors } from '@/types/colors.enum';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { ChangeEvent, FunctionComponent, SetStateAction, useEffect, useMemo, useState } from 'react';
import { CAMPAIGN_NEGATIVES, NegativeMatchType } from '../../api/negative-targets-contracts';
import { invalidateProfile_negativeTargetingQueryKeys, negativeTargetingService } from '../../api/negative-targets-service';
import { CampaignToAdGroups } from '../../models/CampaignToAdGroupModel';
import { AdGroupNegativeCheckArguments, CreateNegativeGeneratorArguments, CreateNegativeModel } from '../../models/CreateNegativeModel';

interface CreateNegativesDirectModalPreset {
  sourceCampaignIds: string[];
}

interface CreateNegativesDirectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApplied: (applyResponse: SetStateAction<UpdateResponseDTO | undefined>) => void;
  flowType: FlowType;
  preset?: CreateNegativesDirectModalPreset;
}

interface WarningIndicators {
  campaignCountFilteredOutDueNoAdGroups: number;
  presetNoCampaignsLeftAfterEmptyAdGroupRemoved: boolean;
  notAllNegativesCanBeMadeForSelectedCampaigns: boolean;
}

export const CreateNegativesDirectModal: FunctionComponent<CreateNegativesDirectModalProps> = ({
  isOpen,
  onClose,
  onApplied,
  flowType,
  preset,
}) => {
  const { activeProfile } = useActiveTeamContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { createMultilineMessageComponent } = useHelperComponents();
  const [negativeModelsToCreate, setNegativeModelsToCreate] = useState<CreateNegativeModel[]>([]);

  const [warningsIndicators, setWarningsIndicators] = useState<WarningIndicators>({
    campaignCountFilteredOutDueNoAdGroups: 0,
    presetNoCampaignsLeftAfterEmptyAdGroupRemoved: false,
    notAllNegativesCanBeMadeForSelectedCampaigns: false,
  });

  // DATA
  const { data: campaignToAdGroupsRecord, isLoading: isCampaignToAdGroupsRecord } = useQuery({
    queryKey: CampaignService.getCampaignsToAdGroupsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignService.getCampaignToAdGroups();
      if (result.isSuccess) {
        // Remove archived campaigns and ad groups
        const campaignToAdGroupsRecord: Record<string, CampaignToAdGroups> = result.payload
          .filter((campaign) => campaign.state !== EnabledPausedArchivedState.ARCHIVED)
          .reduce(
            (acc, campaign) => {
              const adGroupsRecord: Record<string, AdGroupModel> = {};
              Object.entries(campaign.adGroupIdToAdGroup).forEach(([adGroupId, adGroup]) => {
                if (adGroup.state !== EnabledPausedArchivedState.ARCHIVED) {
                  adGroupsRecord[adGroupId] = adGroup;
                }
              });

              campaign.adGroupIdToAdGroup = adGroupsRecord;
              acc[campaign.id] = campaign;
              return acc;
            },
            {} as Record<string, CampaignToAdGroups>,
          );

        return campaignToAdGroupsRecord;
      } else {
        toastService.error(`Error loading groups ${result.message}`);
      }
    },
    enabled: !isNil(activeProfile),
  });

  const adGroupIdToAdGroupRecord = useMemo(() => {
    if (!campaignToAdGroupsRecord) {
      return {};
    }

    const record: Record<string, AdGroupModel> = {};

    Object.values(campaignToAdGroupsRecord).forEach((campaign) => {
      Object.entries(campaign.adGroupIdToAdGroup).forEach(([adGroupId, adGroup]) => {
        record[adGroupId] = adGroup;
      });
    });

    return record;
  }, [campaignToAdGroupsRecord]);

  // Checked negatives
  const [negativeMatchOptions, setNegativeMatchOptions] = useState<CreateNegativesParams>({ ...CREATE_NEGATIVES_DEFAULT_PARAMS });

  useEffect(() => {
    // Preset warnings, only when preset is present
    if (!campaignToAdGroupsRecord || !preset?.sourceCampaignIds) return;

    // Warning setting
    const warningsIndicatorsToSet = {
      campaignCountFilteredOutDueNoAdGroups: 0,
      presetNoCampaignsLeftAfterEmptyAdGroupRemoved: false,
      notAllNegativesCanBeMadeForSelectedCampaigns: false,
    };

    const campaignsWithAdGroupsIds = preset.sourceCampaignIds.filter(
      (campaignId) =>
        !isNil(
          campaignToAdGroupsRecord[campaignId] &&
            !isNil(campaignToAdGroupsRecord[campaignId].adGroupIdToAdGroup) &&
            Object.keys(campaignToAdGroupsRecord[campaignId].adGroupIdToAdGroup).length > 0,
        ),
    );

    if (campaignsWithAdGroupsIds.length != preset.sourceCampaignIds.length) {
      warningsIndicatorsToSet.campaignCountFilteredOutDueNoAdGroups = preset.sourceCampaignIds.length - campaignsWithAdGroupsIds.length;
    }

    if (campaignsWithAdGroupsIds.length == 0) {
      warningsIndicatorsToSet.presetNoCampaignsLeftAfterEmptyAdGroupRemoved = true;
    }

    setWarningsIndicators(warningsIndicatorsToSet);

    // Set preset campaigns that have ad groups as chosen campaigns
    setSelectedCampaignIds(campaignsWithAdGroupsIds);
  }, [campaignToAdGroupsRecord, preset, negativeMatchOptions]);

  useEffect(() => {
    // Can't create any negatives for these campaigns
    if ((isSomeKeywordsChecked && !isSomeAdGroupsKeywordType) || (isSomeProductTargetsChecked && !isSomeAdGroupsProductTargetType)) {
      setWarningsIndicators((prev) => ({ ...prev, notAllNegativesCanBeMadeForSelectedCampaigns: true }));
      return;
    }

    if (!campaignToAdGroupsRecord || negativeModelsToCreate.length == 0) return;

    // Haven't yet filled in, don't warn
    if (
      (selectedNegativeKeywords.length == 0 && isSelectNegativeKeywordsVisible) ||
      (selectedNegativeProductTargetAsins.length == 0 && isSelectProductAsinsVisible)
    ) {
      setWarningsIndicators((prev) => ({ ...prev, notAllNegativesCanBeMadeForSelectedCampaigns: false }));
      return;
    }

    setWarningsIndicators((prev) => ({ ...prev, notAllNegativesCanBeMadeForSelectedCampaigns: false }));
  }, [negativeModelsToCreate]);

  const handleNegativeMatchOptionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    if (type === 'checkbox') {
      setNegativeMatchOptions({
        ...negativeMatchOptions,
        [name]: checked,
      });
    } else {
      setNegativeMatchOptions({
        ...negativeMatchOptions,
        [name]: value,
      });
    }
  };

  // Negative Keywords input
  const [selectedNegativeKeywords, setSelectedNegativeKeywords] = useState<string[]>([]);
  function onSelectedNegativeKeywordsSet(negativeKeywords: string[]) {
    const negativeMatchMap = {
      adGroupNegativeExact: NegativeMatchType.AD_GROUP_NEGATIVE_EXACT,
      adGroupNegativePhrase: NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE,
      campaignNegativeExact: NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT,
      campaignNegativePhrase: NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE,
    };

    const negativeMatches = Object.entries(negativeMatchMap)
      .filter(([key]) => negativeMatchOptions[key as keyof CreateNegativesParams])
      .map(([, value]) => value);

    const { validatedKeywords, warnings } = removeInvalidKeywords(negativeKeywords, negativeMatches);
    if (warnings.size > 0) {
      const msg =
        negativeKeywords.length == 1
          ? `Keyword cannot be added due to amazon constraints`
          : `${negativeKeywords.length - validatedKeywords.length} of ${negativeKeywords.length} keywords were removed due to amazon constraints`;
      toastService.warn(createMultilineMessageComponent([msg, ...Array.from(warnings)]));
    }
    setSelectedNegativeKeywords(validatedKeywords);
  }

  // Negative Product Targets input
  const [selectedNegativeProductTargetAsins, setSelectedNegativeProductTargetAsins] = useState<string[]>([]);
  function setNegativeProductTargetAsinsWithValidationAndFormatting(asins: string[]) {
    const { expressions, errorMessage } = cleanAsinsReturnErrorMessage(asins);
    if (errorMessage) {
      toastService.error(errorMessage);
    }
    setSelectedNegativeProductTargetAsins(expressions);
  }

  // Controlling
  const isSomeKeywordsChecked = [
    negativeMatchOptions.campaignNegativeExact,
    negativeMatchOptions.campaignNegativePhrase,
    negativeMatchOptions.adGroupNegativeExact,
    negativeMatchOptions.adGroupNegativePhrase,
  ].some(Boolean);

  const isSomeProductTargetsChecked = [
    negativeMatchOptions.campaignNegativeProductTarget,
    negativeMatchOptions.adGroupNegativeProductTarget,
  ].some(Boolean);

  useEffect(() => {
    if (!isSomeKeywordsChecked) {
      setSelectedNegativeKeywords([]);
    }
  }, [isSomeKeywordsChecked]);

  useEffect(() => {
    if (!isSomeProductTargetsChecked) {
      setSelectedNegativeProductTargetAsins([]);
    }
  }, [isSomeProductTargetsChecked]);

  const isSomeAdGroupsChecked = [
    negativeMatchOptions.adGroupNegativeExact,
    negativeMatchOptions.adGroupNegativePhrase,
    negativeMatchOptions.adGroupNegativeProductTarget,
  ].some(Boolean);

  const isSomeCampaignsChecked = [
    negativeMatchOptions.campaignNegativeExact,
    negativeMatchOptions.campaignNegativePhrase,
    negativeMatchOptions.campaignNegativeProductTarget,
  ].some(Boolean);

  useEffect(() => {
    if (!isSomeAdGroupsChecked) {
      setSelectedAdGroupIds([]);
    }
  }, [isSomeAdGroupsChecked]);

  // CAMPAIGN SELECT
  const [selectedCampaignIds, setSelectedCampaignIds] = useState<string[]>([]);
  // All profile campaigns
  const campaignSelectOptions = useMemo(() => {
    if (!campaignToAdGroupsRecord) return [];

    if (preset?.sourceCampaignIds) {
      return preset.sourceCampaignIds
        .filter((campaignId) => !isNil(campaignToAdGroupsRecord[campaignId]))
        .map((campaignId) => {
          const campaign = campaignToAdGroupsRecord[campaignId];
          const targetingType = t(`enums.targeting_type.${campaign.targetingType}`);
          const color = TargetingTypeColors[campaign.targetingType];
          return new AlMultiSelectOptionModel(campaign.name, campaign.id, undefined, targetingType, color);
        });
    }

    return Object.keys(campaignToAdGroupsRecord)
      .map((campaignId) => {
        const campaign = campaignToAdGroupsRecord[campaignId];
        const targetingType = t(`enums.targeting_type.${campaign.targetingType}`);
        const color = TargetingTypeColors[campaign.targetingType];
        return new AlMultiSelectOptionModel(campaign.name, campaign.id, undefined, targetingType, color);
      })
      .filter((option) => {
        const campaign = campaignToAdGroupsRecord[option.id];
        return CreateNegativeModel.canCreateSomeNegativesForCampaign(campaign, negativeMatchOptions);
      });
  }, [campaignToAdGroupsRecord, isSomeAdGroupsChecked, isSomeProductTargetsChecked, negativeMatchOptions, isSomeCampaignsChecked]);

  // TODO: Temp Creating negatives for display campaigns not allowed
  const isOnlyDisplayCampaignsChosen =
    campaignToAdGroupsRecord &&
    selectedCampaignIds.map((id) => campaignToAdGroupsRecord[id]?.campaignAdType).every((type) => type === CampaignAdType.DISPLAY);

  const selectionContainsProductCampaigns =
    campaignToAdGroupsRecord &&
    selectedCampaignIds.map((id) => campaignToAdGroupsRecord[id]?.campaignAdType).some((type) => type === CampaignAdType.PRODUCTS);

  // AD GROUP SELECT
  const [selectedAdGroupIds, setSelectedAdGroupIds] = useState<string[]>([]);
  // Selected campaigns' ad groups
  const adGroupSelectOptions = useMemo(() => {
    if (!campaignToAdGroupsRecord || !selectedCampaignIds) return [];

    return selectedCampaignIds.flatMap((campaignId) => {
      const campaign = campaignToAdGroupsRecord[campaignId];
      if (!campaign) return [];

      // TODO:Temp DISPLAY is disabled
      if (campaign.campaignAdType === CampaignAdType.DISPLAY) return [];
      if (campaign.campaignAdType === CampaignAdType.TV) return [];

      return Object.keys(campaign.adGroupIdToAdGroup)
        .map((adGroupId) => {
          const adGroup = campaign.adGroupIdToAdGroup[adGroupId];
          const type = adGroup.entityType;
          const color = TargetEntityTypeColors[type];
          return new AlMultiSelectOptionModel(adGroup.name, adGroup.id, undefined, t(`enums.bidding_entity_short.${type}`), color);
        })
        .filter((option) => {
          const adGroup = campaign.adGroupIdToAdGroup[option.id];

          const checkArgs: AdGroupNegativeCheckArguments = {
            campaignId: campaignId,
            adGroupId: adGroup.id,
            campaignAdType: campaign.campaignAdType,
            targetingType: campaign.targetingType,
            entityType: adGroup.entityType,
          };

          return CreateNegativeModel.canCreateAnyNegativesForAdGroup(checkArgs, negativeMatchOptions);
        });
    });
  }, [campaignToAdGroupsRecord, selectedCampaignIds, negativeMatchOptions]);

  const isSomeAdGroupsKeywordType = selectedAdGroupIds
    .map((id) => adGroupIdToAdGroupRecord[id].entityType)
    .some((type) => type === TargetEntityType.KEYWORD);
  const isSomeAdGroupsProductTargetType = selectedAdGroupIds
    .map((id) => adGroupIdToAdGroupRecord[id].entityType)
    .some((type) => type === TargetEntityType.PRODUCT_TARGET);

  useEffect(() => {
    if (!campaignToAdGroupsRecord) return;
    const validAdGroupIds = new Set<string>();

    selectedCampaignIds.forEach((campaignId) => {
      const campaign = campaignToAdGroupsRecord[campaignId];
      if (campaign) {
        Object.keys(campaign.adGroupIdToAdGroup).forEach((adGroupId) => {
          validAdGroupIds.add(adGroupId);
        });
      }
    });

    const filteredAdGroupIds = selectedAdGroupIds.filter((adGroupId) => validAdGroupIds.has(String(adGroupId)));
    if (filteredAdGroupIds.length !== selectedAdGroupIds.length) {
      setSelectedAdGroupIds(filteredAdGroupIds);
    }
  }, [selectedCampaignIds, campaignToAdGroupsRecord]);

  // FIELD VISIBILITY
  const isSelectCampaignsVisible = isSomeKeywordsChecked || isSomeProductTargetsChecked || !isNil(preset?.sourceCampaignIds);
  const isSelectAdGroupsVisible = selectedCampaignIds.length > 0 && isSomeAdGroupsChecked;

  // Check what can be created for selected ad groups
  const { canCreateSomeNegativeKeywordsForAdGroups, canCreateSomeNegativeProductTargetsForAdGroups } = useMemo(() => {
    if (!campaignToAdGroupsRecord || selectedCampaignIds.length === 0 || selectedAdGroupIds.length === 0)
      return { canCreateSomeNegativeKeywordsForAdGroups: false, canCreateSomeNegativeProductTargetsForAdGroups: false };

    let canCreateKWNeg = false;
    let canCreatePTNeg = false;

    for (const campaignId of selectedCampaignIds) {
      if (!campaignToAdGroupsRecord?.[campaignId]) continue;

      for (const adGroupId of selectedAdGroupIds) {
        const adGroup = campaignToAdGroupsRecord?.[campaignId]?.adGroupIdToAdGroup?.[adGroupId];
        if (!adGroup) continue;

        const adGroupNegArgs: AdGroupNegativeCheckArguments = {
          campaignId: campaignId,
          adGroupId: adGroupId,
          campaignAdType: campaignToAdGroupsRecord[campaignId].campaignAdType,
          targetingType: campaignToAdGroupsRecord[campaignId].targetingType,
          entityType: adGroup.entityType,
        };

        // KW check
        if (!canCreateKWNeg && (negativeMatchOptions.adGroupNegativeExact || negativeMatchOptions.adGroupNegativePhrase)) {
          const result = CreateNegativeModel.canCreateAnyNegativesForAdGroup(adGroupNegArgs, {
            campaignNegativeExact: false,
            campaignNegativePhrase: false,
            campaignNegativeProductTarget: false,
            adGroupNegativeExact: negativeMatchOptions.adGroupNegativeExact,
            adGroupNegativePhrase: negativeMatchOptions.adGroupNegativePhrase,
            adGroupNegativeProductTarget: false, // not checking PT here
          });
          canCreateKWNeg = canCreateKWNeg || result;
        }

        // PT check
        if (!canCreatePTNeg && negativeMatchOptions.adGroupNegativeProductTarget) {
          const result = CreateNegativeModel.canCreateAnyNegativesForAdGroup(adGroupNegArgs, {
            campaignNegativeExact: false,
            campaignNegativePhrase: false,
            campaignNegativeProductTarget: false,
            adGroupNegativeExact: false, // not checking KW here
            adGroupNegativePhrase: false, // not checking KW here
            adGroupNegativeProductTarget: negativeMatchOptions.adGroupNegativeProductTarget,
          });
          canCreatePTNeg = canCreatePTNeg || result;
        }

        // If both can be made, no point in checking other ad groups
        if (canCreateKWNeg && canCreatePTNeg) {
          return { canCreateSomeNegativeKeywordsForAdGroups: true, canCreateSomeNegativeProductTargetsForAdGroups: true };
        }
      }
    }

    return { canCreateSomeNegativeKeywordsForAdGroups: canCreateKWNeg, canCreateSomeNegativeProductTargetsForAdGroups: canCreatePTNeg };
  }, [campaignToAdGroupsRecord, selectedCampaignIds, selectedAdGroupIds, negativeMatchOptions]);

  // KEYWORD FIELD VISIBILITY
  const isCampaignNegativeKeywordsSelected = negativeMatchOptions.campaignNegativeExact || negativeMatchOptions.campaignNegativePhrase;
  const canCreateCampaignNegativeKeywords = selectedCampaignIds.length > 0 && isCampaignNegativeKeywordsSelected;

  const isSelectNegativeKeywordsVisible = canCreateCampaignNegativeKeywords || canCreateSomeNegativeKeywordsForAdGroups;

  // PRODUCT TARGET FIELD VISIBILITY
  const isCampaignNegativeProductTargetsSelected = negativeMatchOptions.campaignNegativeProductTarget;
  const canCreateCampaignNegativeProductTargets = selectedCampaignIds.length > 0 && isCampaignNegativeProductTargetsSelected;

  const isSelectProductAsinsVisible = canCreateCampaignNegativeProductTargets || canCreateSomeNegativeProductTargetsForAdGroups;

  // CONFIRMATION
  const [isApplying, setIsApplying] = useState(false);

  async function onCreate(negativeTargets: CreateNegativeModel[]) {
    const response = await negativeTargetingService.createNegativeTargets(negativeTargets, flowType);

    if (response.isSuccess) {
      onApplied({ responseErrorMsg: null, payload: response.payload.created });

      // Sleep a bit so backend has time to update
      setTimeout(() => {
        if (activeProfile) {
          invalidateProfile_negativeTargetingQueryKeys(queryClient, activeProfile.id);
        }
      }, 1500);
    } else {
      onApplied({ responseErrorMsg: `Did not receive a response from server: ${response.message}`, payload: undefined });
    }
  }

  const onConfirmActionClicked = () => {
    setIsApplying(true);

    if (negativeModelsToCreate.length > 0) {
      onCreate(negativeModelsToCreate);
    } else {
      toastService.warn('No negatives to create');
    }

    onClose();
    setIsApplying(false);
  };

  function onCancelClicked() {
    setIsApplying(false);
    onClose();
  }

  // Precreate negatives that can be created with current selections - also used as preview
  useEffect(() => {
    if (selectedNegativeKeywords.length === 0 && selectedNegativeProductTargetAsins.length === 0) setNegativeModelsToCreate([]);

    const negativesToSet: CreateNegativeModel[] = [];

    for (const campaignId of selectedCampaignIds) {
      if (!campaignToAdGroupsRecord?.[campaignId]) continue;

      // If no ad groups, create only campaign negatives
      if (selectedAdGroupIds.length === 0) {
        for (const selectedNegativeKeywordOrAsin of [...selectedNegativeKeywords, ...selectedNegativeProductTargetAsins]) {
          const negArgs: CreateNegativeGeneratorArguments = {
            entityType: TargetEntityType.KEYWORD, // not used for campaign
            targetingType: campaignToAdGroupsRecord[campaignId].targetingType,
            adGroupId: null, // not used for campaign
            campaignId: campaignId,
            keywordOrAsin: selectedNegativeKeywordOrAsin,
            campaignAdType: campaignToAdGroupsRecord[campaignId].campaignAdType,
          };

          negativesToSet.push(
            ...CreateNegativeModel.generateCreateNegativeModels(negArgs, {
              ...negativeMatchOptions,
              adGroupNegativeExact: false,
              adGroupNegativePhrase: false,
              adGroupNegativeProductTarget: false,
            }),
          );
        }
      }

      // Ad group negative creation automatically creates also campaign level negatives - if no ad groups, then campaign negatives need to be created separately
      for (const adGroupId of selectedAdGroupIds) {
        const adGroup = campaignToAdGroupsRecord?.[campaignId]?.adGroupIdToAdGroup?.[adGroupId];
        if (!adGroup) continue;

        for (const selectedNegativeKeywordOrAsin of [...selectedNegativeKeywords, ...selectedNegativeProductTargetAsins]) {
          const negArgs: CreateNegativeGeneratorArguments = {
            entityType: adGroup.entityType,
            targetingType: campaignToAdGroupsRecord[campaignId].targetingType,
            adGroupId: adGroup.id,
            campaignId: campaignId,
            keywordOrAsin: selectedNegativeKeywordOrAsin,
            campaignAdType: campaignToAdGroupsRecord[campaignId].campaignAdType,
          };

          negativesToSet.push(...CreateNegativeModel.generateCreateNegativeModels(negArgs, negativeMatchOptions));
        }
      }
    }

    setNegativeModelsToCreate(negativesToSet);
  }, [negativeMatchOptions, selectedCampaignIds, selectedAdGroupIds, selectedNegativeKeywords, selectedNegativeProductTargetAsins]);

  const warnings = preset
    ? [
        warningsIndicators.campaignCountFilteredOutDueNoAdGroups > 0 &&
          !warningsIndicators.presetNoCampaignsLeftAfterEmptyAdGroupRemoved &&
          `${warningsIndicators.campaignCountFilteredOutDueNoAdGroups} Campaign(s) filtered out due to having no ad groups`,
        warningsIndicators.presetNoCampaignsLeftAfterEmptyAdGroupRemoved && 'None of the selected campaigns have any ad groups',
        warningsIndicators.notAllNegativesCanBeMadeForSelectedCampaigns && 'Not all selected negatives can be made for selected campaigns',
      ].filter(Boolean)
    : null;

  // Preview component of what will be created
  function negativeKeywordsToCreateToString(neg: CreateNegativeModel): string {
    const campaignName = campaignToAdGroupsRecord?.[neg.campaignId]?.name || '';
    const adGroupName = campaignToAdGroupsRecord?.[neg.campaignId]?.adGroupIdToAdGroup?.[neg.adGroupId]?.name || '';

    const campaignOrAdGroupName = CAMPAIGN_NEGATIVES.includes(neg.negativeMatchType) ? campaignName : adGroupName;
    // Add bullet in front
    return `\u2022 ${neg.keywordOrAsin} - ${t(`enums.negative_match_type.${neg.negativeMatchType}`)} - ${campaignOrAdGroupName}`;
  }

  const theme = useTheme();
  const negativesToCreateComponent = useMemo(() => {
    if (negativeModelsToCreate.length === 0) return null;

    return (
      <Tooltip
        title={createMultilineMessageComponent(
          negativeModelsToCreate.map((n) => negativeKeywordsToCreateToString(n)),
          6,
          1,
        )}
      >
        <div className="flex flex-row ml-3">
          <InfoOutlinedIcon fontSize="small" style={{ marginRight: '8px', color: theme.palette.info.main }} />
          <Typography variant="body2">This will create {negativeModelsToCreate.length} negative targets</Typography>
        </div>
      </Tooltip>
    );
  }, [negativeModelsToCreate]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle style={{ paddingBottom: '0px' }}>Create Negative Targets</DialogTitle>
      <DialogContent>
        {/* Checkboxes */}
        <div className="flex justify-between p-4 items-center">
          <div>
            <Typography variant="h6">Campaign Negatives</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={negativeMatchOptions.campaignNegativeExact}
                    onChange={handleNegativeMatchOptionsChange}
                    name="campaignNegativeExact"
                    disabled={preset && (isOnlyDisplayCampaignsChosen || !selectionContainsProductCampaigns)}
                  />
                }
                label="Negative Exact"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={negativeMatchOptions.campaignNegativePhrase}
                    onChange={handleNegativeMatchOptionsChange}
                    name="campaignNegativePhrase"
                    disabled={preset && (isOnlyDisplayCampaignsChosen || !selectionContainsProductCampaigns)}
                  />
                }
                label="Negative Phrase"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={negativeMatchOptions.campaignNegativeProductTarget}
                    onChange={handleNegativeMatchOptionsChange}
                    name="campaignNegativeProductTarget"
                    disabled={preset && (isOnlyDisplayCampaignsChosen || !selectionContainsProductCampaigns)}
                  />
                }
                label="Negative Product Target"
              />
            </FormGroup>
          </div>
          <div>
            <Typography variant="h6">Ad Group Negatives</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={negativeMatchOptions.adGroupNegativeExact}
                    onChange={handleNegativeMatchOptionsChange}
                    name="adGroupNegativeExact"
                    disabled={preset && isOnlyDisplayCampaignsChosen}
                  />
                }
                label="Negative Exact"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={negativeMatchOptions.adGroupNegativePhrase}
                    onChange={handleNegativeMatchOptionsChange}
                    name="adGroupNegativePhrase"
                    disabled={preset && isOnlyDisplayCampaignsChosen}
                  />
                }
                label="Negative Phrase"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={negativeMatchOptions.adGroupNegativeProductTarget}
                    onChange={handleNegativeMatchOptionsChange}
                    name="adGroupNegativeProductTarget"
                    disabled={preset && isOnlyDisplayCampaignsChosen}
                  />
                }
                label="Negative Product Target"
              />
            </FormGroup>
          </div>
        </div>
        {/* Dropdowns */}
        <div className="flex flex-col gap-4 items-center">
          {isSelectCampaignsVisible && (
            <AlMultiSelect
              options={campaignSelectOptions}
              selectedOptionIds={selectedCampaignIds}
              setSelectedOptionIds={setSelectedCampaignIds}
              label="Campaign(s)"
              placeholderText="Select Campaign(s)"
              isDisabled={!isNil(preset?.sourceCampaignIds)}
            />
          )}

          {isSelectAdGroupsVisible && (
            <AlMultiSelect
              options={adGroupSelectOptions}
              selectedOptionIds={selectedAdGroupIds}
              setSelectedOptionIds={setSelectedAdGroupIds}
              label="Ad Group(s)"
              placeholderText="Select Ad Group(s)"
              isDisabled={selectedCampaignIds.length === 0}
            />
          )}

          {isSelectNegativeKeywordsVisible && (
            <AlMultiTextInput label="Negative Keyword(s)" tags={selectedNegativeKeywords} setTags={onSelectedNegativeKeywordsSet} />
          )}

          {isSelectProductAsinsVisible && (
            <AlMultiTextInput
              label="Negative Product ASIN(s)"
              tags={selectedNegativeProductTargetAsins}
              setTags={setNegativeProductTargetAsinsWithValidationAndFormatting}
            />
          )}
        </div>
      </DialogContent>
      <Divider />
      {warnings && (
        <div className="flex flex-col mx-5">{warnings?.map((warning, index) => <InfoMessage key={index} content={warning as string} />)}</div>
      )}

      <DialogActions>
        {negativesToCreateComponent}
        <Box flexGrow={1} />
        <Button onClick={onCancelClicked} variant="text">
          Cancel
        </Button>
        <Button
          variant="contained"
          loading={isApplying || isCampaignToAdGroupsRecord}
          onClick={onConfirmActionClicked}
          endIcon={<SendIcon />}
          disabled={negativeModelsToCreate.length === 0}
        >
          Add {negativeModelsToCreate.length > 0 ? negativeModelsToCreate.length + ' ' : ''}Negatives
        </Button>
      </DialogActions>
      <LoadingOverlay isVisible={isApplying} message="Sending new data to Amazon..." />
    </Dialog>
  );
};
